<template>
    <zk-admin-table title="疾病监测" :loading="loading" :table="table" @change="handleTableChange" @select="handleTableSelect">
        <template v-slot:buttons>
            <el-row style="float: right">
                <el-button size="mini" icon="el-icon-search" circle @click="searchVisible = !searchVisible"></el-button>
                <el-button size="mini" icon="el-icon-refresh" circle @click="getData"></el-button>
                <el-button size="mini" type="warning" icon="el-icon-plus" circle @click="handleAdd"></el-button>
                <el-button size="mini" type="primary" icon="el-icon-edit" circle @click="handleMultiUpdate"></el-button>
                <el-button size="mini" type="danger" icon="el-icon-delete" circle @click="handleDelete"></el-button>
            </el-row>
        </template>
        <template v-slot:search>
            <search-form @search="handleSearch" v-show="searchVisible" />
        </template>
        <el-table-column type="selection" width="55" />
        <el-table-column align="left" v-for="(v, idx) in table.columns" :prop="v.field" :label="v.title"
            :width="v.width" :formatter="v.formatter" :key="idx" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" width="120" label="操作" fixed="right">
            <template slot-scope="scope">
                <el-button type="warning" size="mini" @click="handleUpdate(scope.row)" icon="el-icon-edit"></el-button>
            </template>
        </el-table-column>
        <template v-slot:dialog>
            <data-form :visible="dialogVisible" :form="form" @close="dialogClose" @update="getData" :isNew="isNew"/>
        </template>
    </zk-admin-table>
</template>

<script>
import SearchForm from './widgets/searchForm'
import DataForm from './widgets/dataForm'
import data from './data'

export default {
    name: 'settings-analysis',
    path: '/main/settings/analysis',
    components: {
        SearchForm,
        DataForm
    },
    data() {
        return {
            loading: false,
            table: data.table,
            dialogVisible: false,
            searchVisible: true,
            search: {},
            form: {},
            isNew: false,
            multipleSelection: []
        }
    },
    methods: {
        dialogClose() {
            this.dialogVisible = false
            this.uploadVisible = false
            this.getData()
        },
        handleAdd() {
            this.form = {}
            this.isNew = true
            this.dialogVisible = true
        },
        handleSearch(e) {
            this.search = e
            this.table.page = 1
            this.getData()
        },
        handleTableChange(e) {
            this.table = e
            this.getData()
        },
        handleTableSelect(val) {
            this.multipleSelection = val
        },
        async handleMultiUpdate() {
            if (this.multipleSelection.length !== 1) {
                this.$message.error('请选择一条记录')
                return
            }
            this.handleUpdate(this.multipleSelection[0])
        },
        async handleUpdate(row) {
            this.isNew = false
            this.form = JSON.parse(JSON.stringify(row))
            this.dialogVisible = true
        },
        async handleDelete() {
            if (this.multipleSelection.length < 1) {
                this.$message.error('请选择要删除的对象')
                return
            }
            this.loading = true
            for (let e of this.multipleSelection) {
                await this.$http.delete(`/settings/analysis/${e.id}`)
            }
            this.$message.success('删除成功')
            this.getData()
            this.loading = false
        },
        async getData() {
            this.loading = true
            try {
                const resp = await this.$http.get(`/settings/analysis?page=${this.table.page}&pageSize=${this.table.pageSize}`, { params: this.search })
                if (!resp.data) {
                    this.$message.error(resp.data.msg)
                } else {
                    this.table.data = resp.data.data.records
                    this.table.total = resp.data.data.total
                }
            } catch (e) {
                this.$message.error(e.message)
            }
            this.loading = false
        }
    },
    mounted() {
        this.getData()
    }
}
</script>
