const data = {
    table: {
        columns: [
            {title: 'No.', field: 'id', width: '100'},
            {title: '机构名称', field: 'hospital_name', width: '180'},
            {title: 'IP 地址', field: 'ipaddr', width: '200'},
            {title: '创建时间', field: 'created', width: '180'},
            {title: '备注', field: 'remarks', width: ''}
        ],
        data: [],
        total: 0,
        page: 1,
        pageSize: 20
    }
}

export default data
