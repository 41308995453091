<template>
    <el-card class="box-card" shadow="hover">
        <div slot="header" class="clearfix">
            <span>提前{{ search.status == 1 ? '登记' : '预约' }}天数分布</span>
            <el-form :inline="true" :model="form" style="float:right">
                <el-button style="padding: 3px 0" type="text" @click="getData" icon="el-icon-refresh"></el-button>
            </el-form>
        </div>
        <v-chart autoresize ref="chart" :option="option" style="height: 300px" v-loading="loading"></v-chart>
    </el-card>
</template>

<script>
export default {
    name: 'statAppointTiqianyuyuetianshu',
    props: {
        search: {
            type: [Object],
            default: () => { }
        }
    },
    data() {
        return {
            loading: false,
            form: { daterange: [] },
            option: {
                grid: {
                    top: 20,
                    bottom: 20,
                    left: 50,
                    right: 15
                },
                tooltip: {
                    trigger: 'item',
                    formatter: '提前{b}天预约{c}人'
                },
                xAxis: {
                    type: 'category',
                    data: []
                },
                yAxis: {
                    type: 'value'
                },
                series: [
                    {
                        data: [],
                        type: 'bar',
                        color: '#1890ff',
                        label: {
                            show: true, //开启显示
                            position: 'top', //在上方显示
                            color: 'black',
                            fontSize: 12
                        }
                    }
                ]
            }

        }
    },
    methods: {
        async getData() {
            this.loading = true
            const resp = await this.$http.get('/components/charts/statistics/appoint/tiqianyuyuetianshu', { params: this.search })
            this.option.xAxis.data = []
            this.option.series[0].data = []
            resp.data.forEach(e => {
                this.option.xAxis.data.push(e.diff_days)
                this.option.series[0].data.push(e.count)
            })
            this.$refs['chart'].setOption(this.option)
            this.loading = false
        }
    },
    mounted() {
        this.getData().then(() => {
        })
    }
}
</script>
