<template>
    <div>
        <el-page-header @back="$router.go(-1)" content="体检人员状态" />
        <box-count style="margin: 20px auto" />
        <el-row style="margin:30px 0">
            <el-col :xs="24" :sm="24" :md="24" :lg="16" :xl="16">
                <calendar-chart />
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
                <pie-chart />
            </el-col>
        </el-row>
    </div>
</template>

<script>
export default {
    name: 'statistics-staff',
    path: '/main/statistics/staff'
}
</script>
