<template>
    <zk-admin-table title="体检人员数量" :loading="loading" :table="table" @change="handleTableChange" :isDemo="true"
        @select="handleTableSelect">
        <template v-slot:buttons>
            <el-row style="float: right">
                <el-button size="mini" icon="el-icon-search" circle @click="searchVisible = !searchVisible"></el-button>
                <el-button size="mini" icon="el-icon-refresh" circle @click="getData"></el-button>
                <el-button size="mini" icon="el-icon-download" circle @click="doDownLoad"></el-button>
                <el-button size="mini" type="warning" icon="el-icon-plus" circle @click="handleAdd"></el-button>
                <el-button size="mini" type="primary" icon="el-icon-edit" circle @click="handleMultiUpdate"></el-button>
                <el-button size="mini" type="danger" icon="el-icon-delete" circle @click="handleDelete"></el-button>
            </el-row>
        </template>
        <template v-slot:search>
            <el-row>
                <el-col :xs="12" :sm="12" :md="6" :lg="6" :xl="6" style="padding:10px">
                    <div class="el-card is-hover-shadow">
                        <div class="el-card__body" style="padding: 0px;">
                            <div class="grid-content grid-con-1">
                                <i class="el-icon-s-custom grid-con-icon"></i>
                                <div class="grid-cont-right">
                                    <div class="grid-num">{{ counts.total }}</div>
                                    <div>用户总数</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-col>
                <el-col :xs="12" :sm="12" :md="6" :lg="6" :xl="6" style="padding:10px">
                    <div class="el-card is-hover-shadow">
                        <div class="el-card__body" style="padding: 0px;">
                            <div class="grid-content grid-con-2">
                                <i class="el-icon-s-shop grid-con-icon"></i>
                                <div class="grid-cont-right">
                                    <div class="grid-num">{{ counts.today }}</div>
                                    <div>待检人数</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-col>
                <el-col :xs="12" :sm="12" :md="6" :lg="6" :xl="6" style="padding:10px">
                    <div class="el-card is-hover-shadow">
                        <div class="el-card__body" style="padding: 0px;">
                            <div class="grid-content grid-con-3">
                                <i class="el-icon-s-flag grid-con-icon"></i>
                                <div class="grid-cont-right">
                                    <div class="grid-num">{{ counts.doctors }}</div>
                                    <div>已完成</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-col>
                <el-col :xs="12" :sm="12" :md="6" :lg="6" :xl="6" style="padding:10px">
                    <div class="el-card is-hover-shadow">
                        <div class="el-card__body" style="padding: 0px;">
                            <div class="grid-content grid-con-4">
                                <i class="el-icon-s-opportunity grid-con-icon"></i>
                                <div class="grid-cont-right">
                                    <div class="grid-num">{{ counts.departments }}</div>
                                    <div>已报告</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-col>
            </el-row>
            <!-- <search-form @search="handleSearch" v-show="searchVisible"/> -->
        </template>
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column align="center" v-for="(v, idx) in table.columns" :prop="v.field" :label="v.title" :width="v.width"
            :formatter="v.formatter" :key="idx">
        </el-table-column>
        <el-table-column align="center" width="120" label="操作" fixed="right">
            <template slot-scope="scope">
                <el-button type="warning" size="mini" @click="handleUpdate(scope.row)" icon="el-icon-edit"></el-button>
            </template>
        </el-table-column>
        <template v-slot:dialog>
            <data-form :visible="dialogVisible" :form="form" @close="dialogClose" @update="getData" :isNew="isNew">
            </data-form>
        </template>
        <template v-slot:download>
            <data-down-load :visible="downloadVisible" :form="search" :total="table.total"
                @close="downloadVisible = false"></data-down-load>
        </template>
    </zk-admin-table>
</template>

<script>
import DataForm from './widgets/dataForm'
import DataDownLoad from './widgets/dataDownLoad'
import data from './data'

export default {
    name: 'task-user',
    path: '/main/task/user',
    components: {
        DataForm,
        DataDownLoad
    },
    data() {
        return {
            loading: false,
            table: data.table,
            dialogVisible: false,
            searchVisible: true,
            downloadVisible: false,
            search: {},
            form: {},
            isNew: false,
            multipleSelection: [],
            counts: {
                total: Math.floor(Math.random() * 1000),
                today: Math.floor(Math.random() * 500),
                doctors: Math.floor(Math.random() * 100),
                departments: Math.floor(Math.random() * 100)
            }
        }
    },
    methods: {
        dialogClose() {
            this.dialogVisible = false
            this.getData()
        },
        doDownLoad() {
            this.downloadVisible = true
        },
        handleAdd() {
            this.form = {}
            this.isNew = true
            this.dialogVisible = true
        },
        handleSearch(e) {
            this.search = e
            this.table.page = 1
            this.getData()
        },
        handleTableChange(e) {
            this.table = e
            this.getData()
        },
        handleTableSelect(val) {
            this.multipleSelection = val
        },
        async handleMultiUpdate() {
            if (this.multipleSelection.length !== 1) {
                this.$message.error('请选择一条记录')
                return
            }
            this.handleUpdate(this.multipleSelection[0])
        },
        async handleUpdate(row) {
            this.isNew = false
            var temp = JSON.stringify(row)
            this.form = JSON.parse(temp)
            this.dialogVisible = true
        },
        async handleDelete() {
            this.loading = true
            if (this.multipleSelection.length < 1) {
                this.$message.error('请选择要删除的对象')
                return
            }
            for (const e of this.multipleSelection) {
                await this.$http.delete(`/staff/${e.id}`)
            }
            this.$message.success('删除成功')
            this.getData()
            this.loading = false
        },
        async getData() {
            this.loading = true
            try {
                const resp = await this.$http.get(`/staff?page=${this.table.page}&pageSize=${this.table.pageSize}`, { params: this.search })
                if (!resp.data) {
                    this.$message.error(resp.data.msg)
                } else {
                    this.table.data = resp.data.data.records
                    this.table.total = resp.data.data.total
                }
            } catch (e) {
                this.$message.error(e.message)
            }
            this.loading = false
        }
    },
    mounted() {
        this.getData()
    }
}
</script>

<style scoped>
.grid-content {
    display: flex;
    align-items: center;
    height: 130px;
}

.grid-cont-right {
    flex: 1;
    text-align: center;
    font-size: 14px;
    color: #999;
}

.grid-num {
    font-size: 30px;
    font-weight: bold;
}

.grid-con-icon {
    font-size: 50px;
    width: 100px;
    height: 130px;
    text-align: center;
    line-height: 130px;
    color: #fff;
}

.grid-con-3 .grid-con-icon {
    background: rgb(195, 191, 226);
}

.grid-con-3 .grid-num {
    color: rgb(195, 191, 226);
}

.grid-con-1 .grid-con-icon {
    background: rgb(128, 176, 210);
}

.grid-con-1 .grid-num {
    color: rgb(128, 176, 210);
}

.grid-con-2 .grid-con-icon {
    background: rgb(141, 210, 198);
}

.grid-con-2 .grid-num {
    color: rgb(141, 210, 198);
}

.grid-con-4 .grid-con-icon {
    background: rgb(144, 147, 153);
}

.grid-con-4 .grid-num {
    color: rgb(144, 147, 153);
}
</style>
