<template>
    <v-chart ref="chart" v-loading="loading" class="chart" :option="option" />
</template>

<script>
export default {
    name: 'analysisAgeScatterChart',
    props: {
        id: {
            type: [String, Number, Object]
        }
    },
    data() {
        return {
            loading: false,
            option: {
                title: {
                    text: '年龄数量分布',
                    left: 'center'
                },
                tooltip: {
                    trigger: 'item',
                    formatter: (c) => {
                        return `年龄: ${c.data[0]}<br>数量: ${c.data[1]}`
                    }
                },
                grid: {
                    top: '15%',
                    left: '3%',
                    right: '4%',
                    bottom: '10%',
                    containLabel: true
                },
                xAxis: {
                    name: '年龄',
                    max: 100,
                    min: 0,
                    splitNumber: 5,
                    interval: 5
                },
                yAxis: {
                    name: '数量'
                },
                series: [
                    {
                        symbolSize: 20,
                        data: [
                            // [7.08, 5.82],
                            // [5.02, 5.68]
                        ],
                        type: 'scatter'
                    }
                ]
            }
        }
    },
    methods: {
        async getData() {
            this.loading = true
            const resp = await this.$http.get(`/statistics/analysis_age_scatter_chart?id=${this.id}`)
            resp.data.map(e => {
                // console.log(e.age, e.value)
                this.option.series[0].data.push([e.age, e.value])
            })
            this.loading = false
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.$refs.chart.resize()
        })
        window.addEventListener('resize', () => {
            if (this.$refs && this.$refs.chart) {
                this.$refs.chart.resize()
            }
        })
        this.getData()
    }
}
</script>

<style scoped>
.chart {
    width: 100%;
    height: 400px;
}
</style>
