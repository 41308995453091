<template>
    <v-chart ref="chart" :option="option" style="width: 100%;height:400px" />
</template>

<script>
export default {
    name: 'tjlxBarChart',
    data() {
        return {
            loading: false,
            option: {
                title: {
                    text: '机构体检类型分布',
                    left: 'center'
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                toolbox: {
                    show: true,
                    top: 0,
                    right: '5%',
                    feature: {
                        saveAsImage: {
                            show: true,
                            excludeComponents: ['toolbox'],
                            pixelRatio: 2
                        }
                    }
                },
                // color:['#de6e6a','#5971c0','#FCCE10','#E87C25','#B5C334'],
                color: ['#FCCE10', '#3c78d8', '#cc4125', '#E87C25', '#B5C334'],
                xAxis: {
                    type: 'category',
                    data: ['大庆油田总医院', '大庆市龙南医院', '大庆市人民医院', '大庆市第二医院']
                },
                yAxis: {
                    type: 'value'
                },
                series: [
                    {
                        data: [Math.floor(Math.random() * 1000), Math.floor(Math.random() * 1000), Math.floor(Math.random() * 1000), Math.floor(Math.random() * 1000)],
                        type: 'bar'
                    },
                    {
                        data: [Math.floor(Math.random() * 1000), Math.floor(Math.random() * 1000), Math.floor(Math.random() * 1000), Math.floor(Math.random() * 1000)],
                        type: 'bar'
                    }
                ]
            }
        }
    },
    methods: {
        async getData() {
            const ids = ['1000', '1001', '1002', '1003']
            const key = ['健康体检', '职业体检', '女工体检']
            const request = ids.map(x => this.$http.get(`/statistics/tjlx_pie_chart?company_id=${x}`))
            // const resp1 = this.$http.get('/statistics/age_pie_chart?company_id=1000')
            // const resp2 = this.$http.get('/statistics/age_pie_chart?company_id=1001')
            const a = await Promise.all(request)
            const aaa = a.map(e => {
                let result = {}
                let td = e.data.reduce((obj, item) => ((obj[item.name] = item.value), obj), {})
                for (var i = 0; i < key.length; i++) {
                    result[key[i]] = td[key[i]] || 0
                }
                return result
            })
            this.option.series = []
            for (var i = 0; i < key.length; i++) {
                this.option.series.push({
                    name: key[i],
                    data: [
                        aaa[0][key[i]],
                        aaa[1][key[i]],
                        aaa[2][key[i]],
                        aaa[3][key[i]]
                    ],
                    type: 'bar'
                })
            }
            this.$refs['chart'].setOption(this.option)
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.$refs.chart.resize()
        })
        window.addEventListener('resize', ()=>{
            if (this.$refs && this.$refs.chart) {
                this.$refs.chart.resize()
            }
        })
        this.getData()
    }
}
</script>

<style scoped>

</style>
