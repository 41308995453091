/* eslint-disable */
import Vue from 'vue'
import Router from 'vue-router'
import getRoutes from './getRoutes'

Vue.use(Router)

const requireComponent = require.context('../pages', true, /\.vue$/)
const main_routes = getRoutes(requireComponent)

const router = new Router({
    // electron 用hash
    mode: (window && window.process && window.process.versions && window.process.versions['electron']) ? 'hash' : 'history',
    routes: [
        { path: '/', redirect: '/login' },
        { path: '/login', component: () => import(`../pages/main/login`) },
        { path: '/logout', component: () => import(`../pages/main/logout`) },
        {
            path: '/main', component: () => import(`../pages/main/layout/layout_admin`),
            children: main_routes.concat([
                { path: '', name: 'main', redirect: 'dashboard' },
                { path: 'password', component: () => import(`../pages/main/password`) },
                { path: 'message', component: () => import(`../pages/main/message`) },
                { path: 'routes', component: () => import(`../pages/main/getAllRoutes`) },
                { path: '*', component: () => import(`../pages/main/notfound`) }
            ]),
        },
        { path: '*', component: () => import(`../pages/main/notfound`) }
    ]
})

// let _router = getRoutes(require.context('./pages', true, /\.vue$/))
// _router.beforeEach((to, from, next) => {
//     if (to.path != '/login') {
//         // 检查是否有 token
//         if (!localStorage.getItem('token')) {
//             next({ path: '/login' })
//         } else {
//             next()
//         }
//     } else {
//         next()
//     }
// })

console.log('@')

export default router
