<template>
    <div>
        <el-page-header @back="$router.go(-1)" content="角色权限设置" />
        <div style="margin: 20px 0">
            <div>角色名称：{{ role.name }}</div>
            <div>唯一代码：{{ role.code }}</div>
            <div>角色说明：{{ role.remarks }}</div>
        </div>
        <el-tabs v-model="activeName" style="margin-top: 10px">
            <el-tab-pane label="角色功能管理" name="first">
                <el-table v-if="table.show" :data="table.data" style="width: 100%;margin-bottom: 20px;" row-key="id"
                    border :default-expand-all="table.expands"
                    :tree-props="{ children: 'children', hasChildren: 'hasChildren' }">
                    <el-table-column width="280">
                        <template slot="header">
                            <el-radio-group v-model="table.expands" size="mini" @change="handleTableExpand">
                                <el-radio-button :label="true">展开</el-radio-button>
                                <el-radio-button :label="false">折叠</el-radio-button>
                            </el-radio-group>
                        </template>
                        <template slot-scope="scope">
                            <span v-if="!scope.row.children" style="width: 20px;display: inline-block;">&nbsp;</span>
                            <i :class="scope.row.icon" />
                            {{ $t(scope.row.label) }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="path" label="地址" show-overflow-tooltip/>
                    <el-table-column prop="enabled" label="启用">
                        <template slot-scope="scope">
                            <el-switch v-model="scope.row.enabled" active-color="#13ce66" inactive-color="#ff4949">
                            </el-switch>
                        </template>
                    </el-table-column>
                </el-table>
                <div style="padding:20px;text-align:center">
                    <el-button type="danger" @click="handleMenu('reset')">重 置</el-button>
                    <el-button type="primary" @click="handleMenu('save')">保 存</el-button>
                </div>
            </el-tab-pane>
            <el-tab-pane label="角色数据权限" name="second">
                <el-table :data="permissionTable" tooltip-effect="dark" @row-click="selectPermission">
                    <el-table-column label="选择" width="55" align="center">
                        <template slot-scope="scope">
                            <el-radio v-model="data_permission" :label="scope.row.value">{{ '' }}</el-radio>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="名称" header-align="left" width="200"></el-table-column>
                    <el-table-column prop="desc" label="说明" header-align="left" show-overflow-tooltip></el-table-column>
                </el-table>
                <div style="padding:20px;text-align:center">
                    <el-button type="primary" @click="handleDataPermission">保 存</el-button>
                </div>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
// import DataForm from './widgets/dataForm'
export default {
    name: 'settings-roles-permission',
    path: '/main/settings/roles/permission/:id',
    components: {
        // DataForm
    },
    data() {
        return {
            loading: false,
            activeName: 'first',
            role: {},
            table: {
                show: true,
                expands: false,
                data: []
            },
            permissionTable: [{
                value: 'all',
                name: '全部数据权限',
                desc: '可以访问所有数据。'
            }, {
                value: 'ejdw',
                name: '二级单位数据权限',
                desc: '可以访问用户所在二级单位、下属单位数据及单位所有医疗机构的数据。'
            }, {
                value: 'sjdw',
                name: '三级单位数据权限',
                desc: '可以访问用户所在三级单位及单位所有全部医疗机构的数据。'
            }, {
                value: 'hospital',
                name: '体检机构数据权限',
                desc: '可访问此机构相关的数据。'
            }],
            data_permission: 'all',
            dialogVisible: false
        }
    },
    methods: {
        selectPermission(row) {
            this.data_permission = row.value
        },
        handleTableExpand(e) {
            this.table.expands = e
            this.table.show = false
            this.$nextTick(() => {
                this.table.show = true
            })
        },
        async handleMenu(action) {
            this.loading = true
            try {
                let params = {}
                if (action == 'save') params = this.table.data
                const resp = await this.$http.post('/role/menu', { id: this.$route.params.id, menu: JSON.stringify(params) })
                if (resp.data.code != 200) {
                    this.$message.error(resp.data.msg)
                } else {
                    this.$message.success('保存成功')
                }
            } catch (e) {
                this.$message.error(e.message)
            }
            this.getData()
            this.loading = false
        },
        async handleDataPermission() {
            this.loading = true
            try {
                const resp = await this.$http.post('/role/data_permission', { id: this.$route.params.id, data_permission: this.data_permission })
                if (resp.data.code != 200) {
                    this.$message.error(resp.data.msg)
                } else {
                    this.$message.success('保存成功')
                }
            } catch (e) {
                this.$message.error(e.message)
            }
            this.loading = false
        },
        async getData() {
            this.loading = true
            try {
                const resp = await this.$http.get(`/role/${this.$route.params.id}`, { params: this.search })
                if (!resp.data) {
                    this.$message.error(resp.data.msg)
                } else {
                    this.role = resp.data.data
                    this.table.data = resp.data.data.menu
                    this.data_permission = resp.data.data.data_permission
                }
            } catch (e) {
                this.$message.error(e.message)
            }
            this.loading = false
        }
    },
    mounted() {
        this.getData()
    }
}
</script>
