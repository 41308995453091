<template>
    <el-container>
        <el-aside width="">
            <el-header class="header-left">
                <div class="logo">
                    <img :src="logo" alt="logo">
                </div>
                <div class="title" v-if="!isCollapse">
                    {{ $t('app.title') }}
                </div>
            </el-header>
            <el-menu class="el-menu-vertical-demo" noborder router :default-active="$route.path" unique-opened
                v-loading="loading" :collapse="isCollapse">
                <template v-for="(menu, index) in menuItems" v-if="menu.enabled">
                    <el-submenu v-if="menu.children && (menu.children.length >= 1)" :index="menu.path" :key="index">
                        <template slot="title">
                            <i :class="menu.icon"></i>
                            <span slot="title">{{ $t(menu.label) }}</span>
                        </template>
                        <el-menu-item v-for="(sb, sid) in menu.children" :key="sid" :index="sb.path" v-if="sb.enabled"
                            @click="handleSelect(sb)">{{ $t(sb.label) }}
                        </el-menu-item>
                    </el-submenu>
                    <el-menu-item v-else :index="menu.path" :key="menu.path" @click="handleSelect(menu)">
                        <i :class="menu.icon"></i>
                        <span slot="title">{{ $t(menu.label) }}</span>
                    </el-menu-item>
                </template>
            </el-menu>
        </el-aside>
        <el-container>
            <el-header class="header-right">
                <div class="iconButton" @click="handleCollapse">
                    <i :class="isCollapse ? 'el-icon-s-unfold' : 'el-icon-s-fold'"></i>
                </div>
                <router-history />
                <router-link class="iconButton" to="/main/message">
                    <el-badge value="..." class="badge" v-if="$store.state.messages.length > 0">
                        <i class="el-icon-message"></i>
                    </el-badge>
                    <i class="el-icon-message" v-else></i>
                </router-link>
                <div class="iconButton" style="border-left:none" @click="screen">
                    <i class="el-icon-full-screen"></i>
                </div>
                <el-dropdown trigger="click" class='dropdownMenu' style="border-right: 1px solid #eee"
                    @command="handleSetYear">
                    <span>
                        {{ year }}年度
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item v-for="(v, k) in yearList" :key="k" :command="k">{{ v }}</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
                <el-dropdown trigger="click" class='dropdownMenu' style="border-right: 1px solid #eee"
                    @command="handleSetTheme">
                    <span>
                        主题
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item v-for="(v, k) in themeList" :key="k" :command="k">{{ v }}</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
                <el-dropdown trigger="click" class='dropdownMenu' style="border-right: 1px solid #eee"
                    @command="handleSetLanguage">
                    <span>
                        {{ lang }}
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item v-for="(v, k) in langList" :key="k" :command="k">{{ v }}</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
                <el-dropdown class="dropdownMenu" trigger="click">
                    <span class="username">
                        <!--                        <el-avatar src="//cdn.zhack.com/zkui/img/avatar.png"></el-avatar>-->
                        {{ $store.getters.username }}
                        <i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <router-link class="link" to="/main">
                            <el-dropdown-item>{{ $t('app.home') }}</el-dropdown-item>
                        </router-link>
                        <router-link class="link" to="/main/password">
                            <el-dropdown-item>{{ $t('app.passwd') }}</el-dropdown-item>
                        </router-link>
                        <router-link class="link" to="/logout">
                            <el-dropdown-item divided>{{ $t('app.logout') }}</el-dropdown-item>
                        </router-link>
                    </el-dropdown-menu>
                </el-dropdown>
            </el-header>
            <el-main>
                <router-view></router-view>
            </el-main>
        </el-container>
    </el-container>
</template>

<script>
import Logo from '@/assets/logo.png'
import routerHistory from './components/routerHistory'

export default {
    components: {
        routerHistory
    },
    data() {
        return {
            loading: false,
            logo: Logo,
            isCollapse: true,
            fullscreen: false,
            lang: '',
            langList: {
                en: 'EN',
                zh_CN: '中文'
            },
            theme: 'lightBlue',
            themeList: {
                lightBlue: '浅蓝',
                darkBlue: '深蓝',
                blackWhite: '黑白',
            },
            year: '',
            yearList: {
                '2023': '2023年度',
                '2024': '2024年度',
            },
            menuItems: [] //menu.menuItems
        }
    },
    methods: {
        handleCollapse() {
            this.isCollapse = !this.isCollapse
        },
        handleSelect(item) {
            this.$store.commit('setRouterHistory', { label: this.$t(item.label), path: item.path })
        },
        async getYear() {
            const resp = await this.$http.get('/system/getYear')
            this.year = resp.data.data
        },
        async handleSetYear(year) {
            const resp = await this.$http.post('/system/setYear', { year: year })
            if (resp.data.code == 200) {
                this.year = year
                this.$message.success(resp.data.msg)
            } else {
                this.$message.error(resp.data.msg)
            }
        },
        handleSetTheme(theme) {
            this.theme = theme
            localStorage.setItem('theme', theme)
            document.getElementById('app').setAttribute('class', theme)
        },
        handleSetLanguage(lang) {
            this.$i18n.locale = lang
            this.lang = this.langList[lang]
            localStorage.setItem('lang', lang)
            this.$router.go(0)
        },
        screen() {
            let element = document.documentElement
            if (this.fullscreen) {
                if (document.exitFullscreen) {
                    document.exitFullscreen()
                } else if (document.webkitCancelFullScreen) {
                    document.webkitCancelFullScreen()
                } else if (document.mozCancelFullScreen) {
                    document.mozCancelFullScreen()
                } else if (document.msExitFullscreen) {
                    document.msExitFullscreen()
                }
            } else {
                if (element.requestFullscreen) {
                    element.requestFullscreen()
                } else if (element.webkitRequestFullScreen) {
                    element.webkitRequestFullScreen()
                } else if (element.mozRequestFullScreen) {
                    element.mozRequestFullScreen()
                } else if (element.msRequestFullscreen) {
                    // IE11
                    element.msRequestFullscreen()
                }
            }
            this.fullscreen = !this.fullscreen
        },
        async getData() {
            this.loading = true
            const resp = await this.$http.get('/menu')
            this.menuItems = resp.data.data || []
            this.loading = false
        },
        resize() {
            let clientWidth = document.documentElement.clientWidth || document.body.clientHeight
            if (clientWidth < 768) {//当屏幕小于768时，设置Vuex里的数据为true
                this.isCollapse = true
            } else {//反之，设置Vuex里的数据为false
                this.isCollapse = false
            }
        },
        async getPermission() {
            const resp = await this.$http.get('/permission')
            this.$store.commit('set_permission', resp.data)
        }
    },
    mounted() {
        this.lang = this.langList[localStorage.getItem('lang') || 'zh_CN']
        // this.theme = this.themeList[localStorage.getItem('theme') || 'darkBlue']
        this.theme = localStorage.getItem('theme') || 'lightBlue'
        this.handleSetTheme(this.theme)
        window.onresize = () => this.resize()
        this.getYear()
        this.getData()
        this.getPermission()
        this.resize()
    }
}
</script>

<style lang="scss" scoped>
.header-left {
    background: #2081c0;
    display: flex;
    flex-direction: row;
    line-height: 60px;

    .logo {
        vertical-align: middle;
        display: flex;
        align-items: center;

        img {
            width: 20px;
            height: 20px;
            border-radius: 6px;
        }
    }

    .title {
        color: #fff;
        margin-left: 5px;
        flex: 1;
    }

}

.header-right {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.el-aside {
    /*background-color: #eeeeee;*/
    color: #333;
    border-right: 1px solid #e6e6e6;
}

.link {
    text-decoration: none;
}

.el-menu {
    border-right: none;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
    min-height: 400px;
    border: none;
}

::v-deep .el-menu-item,
::v-deep .el-submenu__title {
    color: #000;
}

.header-right {
    padding: 0;
    line-height: 60px;
    color: #333;
    border-bottom: 1px solid #eeeeee;
    line-height: 60px;
    display: flex;
    flex-direction: row;

    .iconButton {
        width: 60px;
        margin: 0;
        padding: 0;
        text-align: center;
        cursor: pointer;
        border-left: 1px solid #eeeeee;
        border-right: 1px solid #eeeeee;
        color: #777;

        .el-badge {
            line-height: 20px;
            height: 20px;
            padding: 0
        }

        i {
            /*font-size: 120%;*/
        }
    }

    .dropdownMenu {
        cursor: pointer;
        padding: 0 20px;

        .user-avatar {
            width: 25px;
            height: 25px;
        }
    }
}

::v-deep .el-divider__text {
    color: #ccc;
}
</style>
