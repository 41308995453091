<template>
    <zk-search-form-inline :form="form" @search="$emit('search', form)" style="text-align:left">
        <el-form-item label="医院">
            <select-hospital v-model="form.hospital_id" style="width:150px" />
        </el-form-item>
        <el-form-item label="状态">
            <el-select v-model="form.status" placeholder="请选择" @change="doSearch" style="width:100px">
                <el-option label="已预约" :value="0"></el-option>
                <el-option label="已收费" :value="1"></el-option>
                <el-option label="已取消" :value="-1"></el-option>
                <el-option label="已退费" :value="-2"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="来源">
            <el-select v-model="form.source" clearable placeholder="请选择" @change="doSearch" style="width:100px">
                <el-option label="微信预约" value="微信预约"></el-option>
                <el-option label="团体预约" value="团体预约"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="二级单位" prop="ejdw_id" clearable>
            <select-subsidiary-2 v-model="form.ejdw_id" />
        </el-form-item>
        <el-form-item label="三级单位" prop="sjdw_id" clearable>
            <select-subsidiary-3 :ejdw_id="form.ejdw_id" v-model="form.sjdw_id" />
        </el-form-item>
        <el-form-item label="姓名">
            <el-input v-model.trim="form.name" clearable style="width:100px"></el-input>
        </el-form-item>
        <el-form-item label="身份证号">
            <el-input v-model.trim="form.idnum" clearable @clear="doSearch"></el-input>
        </el-form-item>
        <el-form-item label="预约日期">
            <el-date-picker v-model="form.daterange" type="daterange" range-separator="至" start-placeholder="开始日期"
                value-format="yyyy-MM-dd" end-placeholder="结束日期">
            </el-date-picker>
        </el-form-item>
    </zk-search-form-inline>
</template>

<script>
export default {
    props: ['form'],
    data() {
        return {
            search: {},
        }
    },
    methods: {
        reset() {

        },
        doSearch() {
            this.$emit('search', this.form)
        }
    }
}
</script>

