<template>
    <el-dialog title="编辑" :visible="visible" width="60%" @close="close">
        <el-form ref="form" :model="form" label-width="100px">
            <el-form-item label="名称" prop="name" :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                <el-input v-model.trim="form.name"></el-input>
            </el-form-item>
            <el-form-item label="性别限制">
                <el-checkbox-group v-model="form.gender">
                    <el-checkbox label="男"></el-checkbox>
                    <el-checkbox label="女"></el-checkbox>
                </el-checkbox-group>
            </el-form-item>
            <el-form-item label="体检类型限制">
                <el-radio-group v-model="form.type" @change="changeType">
                    <el-radio label="健康体检"></el-radio>
                    <el-radio label="职业体检"></el-radio>
                    <el-radio label="女工/家属体检"></el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="员工类型限制">
                <el-checkbox-group v-model="form.zglx">
                    <el-checkbox :label="e" v-for="(e, i) in zglx" :key="i"></el-checkbox>
                </el-checkbox-group>
            </el-form-item>
            <el-form-item label="介绍" prop="desc" :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                <el-input type="textarea" :rows="4" v-model.trim="form.desc"></el-input>
            </el-form-item>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="合计金额" prop="feeHeji" :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                        <el-input v-model.trim="form.feeHeji"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="折后金额" prop="feeZhehou" :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                        <el-input v-model.trim="form.feeZhehou"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-form-item label="备注">
                <el-input type="textarea" v-model.trim="form.remarks"></el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="close">{{ $t('form.cancel') }}</el-button>
            <el-button @click="formSubmit" type="primary" :loading="loading" :disabled="loading">{{ $t('form.ok') }}
            </el-button>
        </span>
    </el-dialog>
</template>
<script>
export default {
    props: {
        visible: {
            default: false
        },
        form: {
            checkList: [],
            packages: []
        },
        isNew: {
            default: false
        }
    },
    data() {
        return {
            loading: false,
            activeName: 'tab1',
            zglx: this.changeType(this.form.type)
        }
    },
    watch: {
        form(e) {
            this.changeType(e.type)
        }
    },
    methods: {
        changeType(e) {
            // this.form.zglx = []
            const dic = {
                '健康体检': ['34岁及以下', '35-44岁', '45岁及以上', '二级工程师', '一级工程师', '企业高级专家', '企业首席专家', '处级干部', '局级干部'],
                '职业体检': ['粉尘', '放射', '毒物', '噪声', '电工', '高温', '驾驶员', '高处作业', '压力作业'],
                '女工/家属体检': ['女工', '家属']
            }
            this.zglx = dic[e] || []
        },
        async formSubmit() {
            const valid = await this.$refs['form'].validate().catch(() => false)
            if (!valid) return
            const resp = await this.$http.post('/hospital/package2', this.form)
            if (resp.data.code != 200) {
                this.$message.error(resp.data.msg)
            } else {
                this.$message.success('保存成功')
                this.$emit('update')
                this.$emit('close')
            }
        },
        close() {
            this.$emit('close')
        }
    },
    mounted() {

    }
}
</script>

<style scoped></style>
