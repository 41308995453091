<template>
    <zk-admin-table title="单位员工管理" :loading="loading" :table="table" @change="handleTableChange"
        @select="handleTableSelect">
        <template v-slot:buttons>
            <el-row style="float: right">
                <el-button size="mini" icon="el-icon-search" circle @click="searchVisible = !searchVisible"></el-button>
                <el-button size="mini" icon="el-icon-refresh" circle @click="getData"></el-button>
                <el-button size="mini" icon="el-icon-download" circle @click="doDownLoad" :loading="loading"
                    v-if="permission.staff_export"></el-button>
                <el-button size="mini" @click="handleTrans" v-if="permission.staff_trans">批量迁移</el-button>
                <el-button size="mini" type="warning" icon="el-icon-plus" @click="handleAdd"
                    v-if="permission.staff_add">添加</el-button>
                <!-- <el-button size="mini" type="primary" icon="el-icon-edit" circle @click="handleMultiUpdate"></el-button> -->
                <el-button size="mini" type="danger" icon="el-icon-delete" @click="handleDelete"
                    v-if="permission.staff_delete">删除</el-button>
            </el-row>
        </template>
        <template v-slot:search>
            <search-form :form="search" @search="handleSearch" v-show="searchVisible" />
        </template>
        <el-table-column type="selection" width="55" />
        <el-table-column label="单位" width="180">
            <template slot-scope="scope">
                <div>{{ scope.row.EJDW }}</div>
                <small>{{ scope.row.SJDW }}</small>
                <div><small>{{ scope.row.FJDW }}</small></div>
            </template>
        </el-table-column>
        <el-table-column label="人员信息" width="170">
            <template slot-scope="scope">
                <div>{{ scope.row.XM }} <small>{{ scope.row.XB }} {{ scope.row.NL }}岁</small></div>
                <small > {{ isFullIdCard[scope.$index] ? scope.row.SFZH : maskIdCardRegex(scope.row.SFZH) }}</small>
                <i class="el-icon-view" style="cursor: pointer;padding-left: 10px" @click="toggleIdCard(scope.$index)"></i>
            </template>
        </el-table-column>
        <el-table-column label="职工信息" width="110">
            <template slot-scope="scope">
                <div>{{ scope.row.ZGLX }}</div>
                <small>档案年份: {{ scope.row.DANF }}</small>
            </template>
        </el-table-column>
        <!-- <el-table-column v-for="(v, idx) in table.columns" :prop="v.field" :label="v.title"
            :width="flexColumnWidth(v.width, v.field, table.data)" :align="v.align" :formatter="v.formatter" :key="idx">
            <template slot-scope="scope">
                <div :class="scope.row[v.field] === '是' ? 'green' : ''">{{ scope.row[v.field] }}</div>
            </template>
        </el-table-column> -->
        <el-table-column label="职业体检" width="" align="left">
            <template slot-scope="scope">
                <el-tag v-if="scope.row.SFZS=='是'">噪声</el-tag>
                <el-tag v-if="scope.row.SFDW=='是'">毒物</el-tag>
                <el-tag v-if="scope.row.SFFC=='是'">粉尘</el-tag>
                <el-tag v-if="scope.row.SFFS=='是'">放射</el-tag>
                <el-tag v-if="scope.row.SFDG=='是'">电工</el-tag>
                <el-tag v-if="scope.row.SFGW=='是'">高温</el-tag>
                <el-tag v-if="scope.row.SFJSY=='是'">驾驶员</el-tag>
                <el-tag v-if="scope.row.SFGC=='是'">高处</el-tag>
                <el-tag v-if="scope.row.SFYL=='是'">压力</el-tag>
            </template>
        </el-table-column>
        <el-table-column label="体检费" width="100" align="left">
            <template slot-scope="scope">
                <div>{{ scope.row.fee }}</div>
                <small v-if="scope.row.TJLX_NGJS">{{ scope.row.XB == '女' ? '女工' : '家属' }}:181.00</small>
            </template>
        </el-table-column>
        <!-- <el-table-column label="接害因素" width="" align="center">
            <template slot-scope="scope">
                <div>{{ scope.row.JHYS ? scope.row.JHYS.join(',') : '' }}</div>
            </template>
        </el-table-column> -->
        <el-table-column label="备注" prop="remarks"></el-table-column>
        <el-table-column align="center" width="100" label="操作" v-if="permission.staff_edit">
            <template slot-scope="scope">
                <el-button type="default" size="mini" @click="handleUpdate(scope.row)">编辑</el-button>
            </template>
        </el-table-column>
        <template v-slot:dialog>
            <data-form :visible="dialogVisible" :form="form" @close="dialogClose" @update="getData" :isNew="isNew" />
            <trans-form :visible="transVisible" :data="multipleSelection" @close="dialogClose" />
        </template>
    </zk-admin-table>
</template>

<script>
import SearchForm from './widgets/searchForm'
import DataForm from './widgets/dataForm'
import TransForm from './widgets/transForm'
import data from './data'

export default {
    name: 'company-staff',
    path: '/main/company/staff',
    components: {
        SearchForm,
        TransForm,
        DataForm
    },
    data() {
        return {
            loading: false,
            table: data.table,
            dialogVisible: false,
            transVisible: false,
            searchVisible: true,
            SFZH: "",
            search: {
                TJLX: this.$route.query.TJLX,
                sjdw_id: this.$route.query.sjdw_id,
                fjdw_id: this.$route.query.fjdw_id,
                dwid: this.$route.query.dwid,
                label: this.$route.query.label,
                status: 0,
            },
            form: {},
            isNew: false,
            multipleSelection: [],
            permission: {},
            isFullIdCard: {}
        }
    },
    methods: {
        maskIdCardRegex(idCard) {
            return idCard.replace(/^(.{4})(.*)(.{4})$/, function (match, p1, p2, p3) {
                return p1 + p2.replace(/./g, '*') + p3;
            });
        },
        toggleIdCard(index){
            // 切换显示状态
            this.$set(this.isFullIdCard, index, !this.isFullIdCard[index]);
        },
        flexColumnWidth(width, column, tableData) {
            if (!tableData || !tableData.length || tableData.length === 0 || tableData === undefined) {
                return width
            }
            if (!column || !column.length || column.length === 0 || column === undefined) {
                return width
            }
            for (let t of tableData) {
                if (!t[column]) continue
                let flexWidth = 0
                for (let i = 0; i < t[column].length; i++) {
                    let char = t[column][i]
                    if ((char >= 'A' && char <= 'Z') || (char >= 'a' && char <= 'z')) {
                        // 如果是英文字符，为字符分配8个单位宽度
                        flexWidth += 8
                    } else if (char >= '\u4e00' && char <= '\u9fa5') {
                        // 如果是中文字符，为字符分配15个单位宽度
                        flexWidth += 16
                    } else {
                        // 其他种类字符，为字符分配8个单位宽度
                        flexWidth += 4
                    }
                }
                if (flexWidth > width) width = flexWidth
            }
            // console.log(column, width)
            return width
        },
        dialogClose() {
            this.dialogVisible = false
            this.transVisible = false
            this.getData()
        },
        handleAdd() {
            this.form = { TJLX_NGJS: '', FJDWID: null, status: 0, JHYS: [],XB:'',NL:'',
            SFZS: '否', SFDW: '否', SFFC: '否', SFFS: '否', SFGW:'否', SFDG: '否', SFJSY: '否', SFGC: '否', SFYL: '否'
            }
            this.isNew = true
            this.dialogVisible = true
        },
        handleSearch(e) {
            this.search = e
            delete this.search.export
            this.table.page = 1
            this.getData()
        },
        handleTableChange(e) {
            this.table = e
            this.getData()
        },
        handleTableSelect(val) {
            this.multipleSelection = val
        },
        async handleMultiUpdate() {
            if (this.multipleSelection.length !== 1) {
                this.$message.error('请选择一条记录')
                return
            }
            this.handleUpdate(this.multipleSelection[0])
        },
        async handleUpdate(row) {
            this.isNew = false
            var temp = JSON.stringify(row)
            this.form = JSON.parse(temp)
            console.log(this.form)
            this.form.JHYS = this.form.JHYS || []
            this.dialogVisible = true
        },
        async handleTrans() {
            // if (this.multipleSelection.length < 1) {
            //     this.$message.error('请选择要迁移的对象')
            //     return
            // }
            this.transVisible = true
        },
        async handleDelete() {
            if (this.multipleSelection.length < 1) {
                this.$message.error('请选择要删除的对象')
                return
            }
            for (let e of this.multipleSelection) {
                const resp = await this.$http.delete(`/company/staff/${e.id}`)
                if (resp.data.code == 200)
                    this.$message.success('删除成功')
                else
                    this.$message.error(resp.data.msg)
            }
            this.getData()
        },
        async doDownLoad() {
            try {
                this.loading = true
                let params = this.search
                params.export = 'xlsx'//'csv'
                const resp = await this.$http.get(`/company/staff?page=${this.table.page}&pageSize=${this.table.pageSize}`, { params: params, responseType: 'blob' })
                let blob = new Blob([resp.data], { type: 'application/vnd.openxmlformats;charset=utf-8;' })
                var link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = decodeURI(escape(resp.headers['content-disposition'].split('=')[1]))
                link.click()
                window.URL.revokeObjectURL(link.href)
            } catch (e) {
                this.$message.error(e.message)
            } finally {
                this.loading = false
            }
        },
        async getData() {
            this.loading = true
            try {
                const resp = await this.$http.get(`/company/staff?page=${this.table.page}&pageSize=${this.table.pageSize}`, { params: this.search })
                if (!resp.data) {
                    this.$message.error(resp.data.msg)
                } else {
                    this.table.data = resp.data.data.records
                    this.table.total = resp.data.data.total
                }
            } catch (e) {
                this.$message.error(e.message)
            }
            this.loading = false
        },
        async getPermission() {
            const resp = await this.$http.get('/permission')
            this.permission = resp.data
        }
    },
    mounted() {
        this.getPermission()
        this.getData()
    }
}
</script>

<style scoped>
.green {
    color: white;
    background-color: green;
    width: 30px;
    line-height: 30px;
    margin: 0 auto;
}
</style>
