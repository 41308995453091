<template>
    <zk-admin-table title="职业体检变更申请" :loading="loading" :table="table" @change="handleTableChange">
        <template v-slot:buttons>
            <el-row style="float: right">
                <el-button type="default" size="small" @click="getData('xlsx')" icon="el-icon-download">导出EXCEL</el-button>
                <el-button type="default" size="small" @click="getData" icon="el-icon-refresh">刷新</el-button>
                <el-button type="primary" size="small" @click="handleAdd" v-if="permission.zhiye_biangeng_add"
                    icon="el-icon-plus">新建申请</el-button>
            </el-row>
        </template>
        <template v-slot:search>
            <zk-search-form-inline :form="search" @search="getData">
                <el-form-item label="状态" prop="status" clearable>
                    <el-select v-model="search.status" placeholder="审批状态" clearable style="width:120px">
                        <el-option label="已申请" value="0"></el-option>
                        <el-option label="已审批" value="1"></el-option>
                        <el-option label="审批未通过" value="-1"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="类型" prop="status" clearable>
                    <el-select v-model="search.type" placeholder="类型" clearable style="width:120px">
                        <el-option label="上岗" value="上岗"></el-option>
                        <el-option label="转岗" value="转岗"></el-option>
                        <el-option label="离岗" value="离岗"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="二级单位" prop="ejdw_id" clearable>
                    <select-subsidiary-2 v-model="search.ejdw_id" />
                </el-form-item>
                <el-form-item label="三级单位" prop="sjdw_id" clearable>
                    <select-subsidiary-3 :ejdw_id="search.ejdw_id" v-model="search.sjdw_id" />
                </el-form-item>
                <el-form-item label="姓名">
                    <el-input v-model.trim="search.name" clearable style="width:120px"></el-input>
                </el-form-item>
                <el-form-item label="身份证号">
                    <el-input v-model.trim="search.idnum" clearable></el-input>
                </el-form-item>
            </zk-search-form-inline>
        </template>
        <el-table-column type="index" width="50" align="center" />
        <el-table-column prop="status" label="状态" width="120" align="center">
            <template slot-scope="scope">
                <el-tag size="small" type="primary" v-if="scope.row.status === 0">已申请</el-tag>
                <el-tag size="small" type="success" v-else-if="scope.row.status === 1">已审批</el-tag>
                <el-tooltip v-else-if="scope.row.status === -1" :content="scope.row.text_shenpi" placement="top">
                    <el-tag size="small" type="danger">审批不通过</el-tag>
                </el-tooltip>
                <el-tag size="small" type="defalult" v-else>{{ scope.row.status }}</el-tag>
            </template>
        </el-table-column>
        <el-table-column prop="type" label="类型" width="80" align="center" />
        <el-table-column label="单位" width="">
            <template slot-scope="scope">
                <div>{{ scope.row.EJDW }}</div>
                <small>{{ scope.row.SJDW }}</small>
                <div>
                    <small>{{ scope.row.FJDW }}</small>
                </div>
            </template>
        </el-table-column>
        <el-table-column label="人员信息" width="180">
            <template slot-scope="scope">
                <div>{{ scope.row.XM }} <small>{{ scope.row.XB }} {{ scope.row.NL }}岁</small></div>
                <small > {{ isFullIdCard[scope.$index] ? scope.row.SFZH : maskIdCardRegex(scope.row.SFZH) }}</small>
                <i class="el-icon-view" style="cursor: pointer;padding-left: 10px" @click="toggleIdCard(scope.$index)"></i>
            </template>
        </el-table-column>
        <el-table-column label="职工信息" width="130">
            <template slot-scope="scope">
                <div>{{ scope.row.ZGLX }}</div>
                <small>档案年份: {{ scope.row.DANF }}</small>
            </template>
        </el-table-column>
        <el-table-column prop="hospital" label="医院" width="150" />
        <el-table-column prop="user_shenqing" label="申请信息" width="220">
            <template slot-scope="scope">
                <div>申请人：{{ scope.row.user_shenqing }}</div>
                <small>申请时间: {{ scope.row.time_shenqing }}</small>
            </template>
        </el-table-column>
        <el-table-column align="center" width="120" label="操作" fixed="right">
            <template slot-scope="scope">
                <el-button size="mini" @click="handleUpdate(scope.row)">
                    查看详情
                </el-button>
            </template>
        </el-table-column>
        <template v-slot:dialog>
            <el-dialog title="填写表单" :visible.sync="dialogVisible" width="60%">
                <el-form ref="form" :model="form" label-width="80px">
                    <el-form-item label="员工信息" prop="staff_id"
                        :rules="{ required: true, message: '不能为空', trigger: 'change' }">
                        <select-staff v-model="form.staff_id" :disabled="form._readonly" />
                    </el-form-item>
                    <el-form-item label="选择医院" prop="hospital_id"
                        :rules="{ required: true, message: '不能为空', trigger: 'change' }">
                        <select-hospital v-model="form.hospital_id" :disabled="form._readonly" />
                    </el-form-item>
                    <el-form-item label="申请类型" prop="type" :rules="{ required: true, message: '不能为空', trigger: 'change' }">
                        <el-radio-group v-model="form.type" :disabled="form._readonly">
                            <el-radio label="上岗"></el-radio>
                            <el-radio label="转岗"></el-radio>
                            <el-radio label="离岗"></el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="职业体检" prop="zhiye" :rules="{
                        type: 'array',
                        required: true,
                        message: '不能为空',
                        trigger: 'change',
                    }">
                        <el-checkbox-group v-model="form.zhiye" :disabled="form._readonly">
                            <el-checkbox label="噪声" name="zhiye"></el-checkbox>
                            <el-checkbox label="毒物" name="zhiye"></el-checkbox>
                            <el-checkbox label="粉尘" name="zhiye"></el-checkbox>
                            <el-checkbox label="放射" name="zhiye"></el-checkbox>
                            <el-checkbox label="电工" name="zhiye"></el-checkbox>
                            <el-checkbox label="高温" name="zhiye"></el-checkbox>
                            <el-checkbox label="驾驶员" name="zhiye"></el-checkbox>
                            <el-checkbox label="高处作业" name="zhiye"></el-checkbox>
                            <el-checkbox label="压力容器作业" name="zhiye"></el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>
                    <el-form-item label="接害因素" v-if="form.zhiye.includes('毒物')">
                        <el-checkbox-group v-model="form.JHYS" :disabled="form._readonly">
                            <el-checkbox label="硫化氢" name="JHYS"></el-checkbox>
                            <el-checkbox label="溶剂汽油" name="JHYS"></el-checkbox>
                            <el-checkbox label="氨" name="JHYS"></el-checkbox>
                            <el-checkbox label="苯" name="JHYS"></el-checkbox>
                            <el-checkbox label="氯气" name="JHYS"></el-checkbox>
                            <el-checkbox label="锰及化合物" name="JHYS"></el-checkbox>
                            <el-checkbox label="甲醇" name="JHYS"></el-checkbox>
                            <el-checkbox label="盐酸" name="JHYS"></el-checkbox>
                            <el-checkbox label="一氧化碳" name="JHYS"></el-checkbox>
                            <el-checkbox label="硫酸" name="JHYS"></el-checkbox>
                            <el-checkbox label="氢氧化钠" name="JHYS"></el-checkbox>
                            <el-checkbox label="氮氧化钠" name="JHYS"></el-checkbox>
                            <el-checkbox label="二氧化硫" name="JHYS"></el-checkbox>
                            <el-checkbox label="戊烷" name="JHYS"></el-checkbox>
                            <el-checkbox label="正己烷" name="JHYS"></el-checkbox>
                            <el-checkbox label="四氯化碳" name="JHYS"></el-checkbox>
                            <el-checkbox label="酸雾酸酐" name="JHYS"></el-checkbox>
                            <el-checkbox label="致喘物" name="JHYS"></el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>
                    <el-form-item label="申请说明" prop="text_shenqing"
                        :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                        <el-input type="textarea" v-model="form.text_shenqing" :disabled="form._readonly"></el-input>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button style="float:left" @click="handleDel" type="danger" icon="el-icon-delete">删 除</el-button>
                    <el-button style="float:left" @click="handleReset" type="warning" icon="el-icon-delete">重 置</el-button>
                    <el-button @click="dialogVisible = false">取 消</el-button>
                    <el-button type="primary" v-if="!form._readonly" @click="handleSubmit" :loading="loading">确
                        定</el-button>
                    <template v-if="form.status == 0">
                        <el-button type="warning" v-if="permission.zhiye_biangeng_shenpi" @click="handleShenpi(-1)"
                            :loading="loading">审批不通过</el-button>
                        <el-button type="primary" v-if="permission.zhiye_biangeng_shenpi" @click="handleShenpi(1)"
                            :loading="loading">审批通过</el-button>
                    </template>
                </span>
            </el-dialog>
        </template>
    </zk-admin-table>
</template>

<script>
export default {
    name: "company-zhiyebiangeng",
    path: "/main/company/zhiyebiangeng",
    data() {
        return {
            loading: false,
            dialogVisible: false,
            tableData: [],
            form: { zhiye: [], JHYS: [] },
            permission: [],
            search: {},
            table: {
                columns: [],
                data: [],
                total: 0,
                page: 1,
                pageSize: 20
            },
            isFullIdCard: {}
        };
    },
    methods: {
        maskIdCardRegex(idCard) {
            return idCard.replace(/^(.{4})(.*)(.{4})$/, function (match, p1, p2, p3) {
                return p1 + p2.replace(/./g, '*') + p3;
            });
        },
        toggleIdCard(index){
            // 切换显示状态
            this.$set(this.isFullIdCard, index, !this.isFullIdCard[index]);
        },
        handleSearch(e) {
            this.search = e
            this.table.page = 1
            this.getData()
        },
        handleTableChange(e) {
            this.table = e
            this.getData()
        },
        async querySearchAsync(queryString, cb) {
            if (!queryString) return {}
            const resp = await this.$http.get(
                "/company/userquery?idnum=" + queryString
            );
            cb(resp.data)
        },
        async handleFormSelect(e) {
            this.form.XM = e.XM
            this.form.staff_id = e.id
        },
        handleAdd() {
            this.form = { zhiye: [], JHYS: [], _readonly: false }
            this.dialogVisible = true
        },
        async handleReset() {
            console.log(this.form.id)
            this.$confirm('确认要重置状态重新审批吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                try {
                    const resp = await this.$http.post(`/zhiye/biangeng/shenqing/reset/${this.form.id}`)
                    this.$message[resp.data.status](resp.data.msg)
                    if (resp.data.status == 'success') {
                        await this.getData()
                        this.dialogVisible = false
                    }
                } catch (err) {
                    this.$message.error(err.message)
                }
            }).catch(() => {
            });
        },
        async handleDel() {
            this.$confirm('确认删除吗,该操作不可恢复？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                try {
                    const resp = await this.$http.delete(`/zhiye/biangeng/shenqing/${this.form.id}`)
                    this.$message[resp.data.status](resp.data.msg)
                    if (resp.data.status == 'success') {
                        await this.getData()
                        this.dialogVisible = false
                    }
                } catch (err) {
                    this.$message.error(err.message)
                }
            }).catch(() => {
            });
        },
        handleUpdate(row) {
            this.form = JSON.parse(JSON.stringify(row))
            this.form._readonly = true
            this.dialogVisible = true
        },
        async handleShenpi(status) {
            let reason = ''
            if (status == -1) {
                reason = await this.$prompt('请输入原因', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                }).then(({ value }) => {
                    return value
                }).catch(() => {
                    return ''
                });
                if (!reason) return
            }
            this.form.status = status
            this.form.reason = reason
            try {
                const resp = await this.$http.post(`/zhiye/biangeng/shenpi`, this.form)
                this.$message[resp.data.status](resp.data.msg)
                if (resp.data.status == 'success') {
                    await this.getData()
                    this.dialogVisible = false
                }
            } catch (err) {
                this.$message.error(err.message)
            }
        },
        async handleSubmit() {
            // 如果没选毒物，清理掉接害因素
            if (!this.form.zhiye.includes('毒物')) {
                this.form.JHYS = []
            } else {
                if (this.form.JHYS.length == 0) {
                    this.$message.error('请选择接害因素')
                    return
                }
            }
            const valid = await this.$refs["form"].validate().catch(() => false)
            if (!valid) return
            this.loading = true
            try {
                const resp = await this.$http.post('/zhiye/biangeng/shenqing', this.form)
                this.$message[resp.data.status](resp.data.msg)
                if (resp.data.status == 'success') {
                    await this.getData()
                    this.dialogVisible = false
                }
            } catch (err) {
                this.$message.error(err.message)
            }
            this.loading = false
        },
        async getData(xlsx) {
            this.loading = true
            try {
                if (xlsx == 'xlsx') {
                    this.search.export = xlsx
                    const resp = await this.$http.get(`/zhiye/biangeng/shenqing?page=${this.table.page}&pageSize=${this.table.pageSize}`, { params: this.search, responseType: 'blob' })
                    let blob = new Blob([resp.data], { type: 'application/vnd.openxmlformats;charset=utf-8;' })
                    var link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.download = decodeURI(escape(resp.headers['content-disposition'].split('=')[1]))
                    link.click()
                    window.URL.revokeObjectURL(link.href)
                } else {
                    this.search.export = ''
                    const resp = await this.$http.get(`/zhiye/biangeng/shenqing?page=${this.table.page}&pageSize=${this.table.pageSize}`, { params: this.search })
                    this.table.data = resp.data.data
                    this.table.total = resp.data.total
                }
            } catch (e) {
                this.$message.error(e.message)
            }
            this.loading = false
        },
        async getPermission() {
            const resp = await this.$http.get('/permission')
            this.permission = resp.data
        }
    },
    mounted() {
        this.getPermission()
        this.getData();
    },
};
</script>

<style scoped>
::v-deep .el-dialog__body {
    padding: 0 20px;
}

::v-deep .el-textarea.is-disabled .el-textarea__inner,
::v-deep .el-checkbox__input.is-disabled+span.el-checkbox__label,
::v-deep .el-radio__input.is-disabled+span.el-radio__label {
    background-color: #fff;
    color: #666;
}

::v-deep .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after,
::v-deep .el-checkbox__input.is-disabled .el-checkbox__inner::after {
    border-color: #fff;
}

::v-deep .el-radio__input.is-disabled.is-checked .el-radio__inner::after {
    background-color: #fff;
}

::v-deep .el-radio__input.is-disabled.is-checked .el-radio__inner {
    border-color: #409EFF;
    background: #409EFF;
}

::v-deep .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
    background-color: #409EFF;
    border-color: #409EFF;
}

::v-deep .el-input.is-disabled .el-input__inner {
    background-color: #fff;
    border: 1px solid #e4e7ed;
    color: #666;
}


.header {
    margin-bottom: 20px;
}
</style>
