const data = {
    table: {
        columns: [
            {title: '名称', field: 'name', width: '200'},
            {title: '唯一标识', field: 'code', width: '180'},
            {title: '描述', field: 'remarks', width: ''}
        ],
        data: [],
        total: 0,
        page: 1,
        pageSize: 20
    }
}

export default data
