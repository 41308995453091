const data = {
    table: {
        columns: [
            {title: '二级单位名称', field: 'EJDW', width: '220'},
            {title: '三级单位名称', field: 'SJDW', width: '220'},
            {title: '姓名', field: 'XM', width: '100'},
            {title: '性别', field: 'XB', width: '80'},
            {title: '年龄', field: 'NL', width: '80'},
            {title: '慢病名称', field: 'ZDMC', width: ''}
        ],
        data: [],
        total: 0,
        page: 1,
        pageSize: 20
    }
}

export default data
