<template>
    <el-container>
        <el-header class="header">
            <div class="container">
                <div class="logo">
                    <img src="./logo.png" alt="大庆油田总医院">
                </div>
            </div>
        </el-header>
        <el-main class="main">
            <div class="container flex">
                <div class="solution">
                    <h1>团体健康体检管理平台</h1>
                </div>
                <div class="login">
                    <h1>用户登录</h1>
                    <form charset="utf-8" ref="ruleForm" @submit.prevent="submitForm" :model="ruleForm" :rules="rules">
                        <div>
                            <label for="id_username">用户名</label>
                            <input type="text" v-model="ruleForm.username" autofocus required id="id_username"
                                placeholder="请输入用户名">
                        </div>
                        <div>
                            <label for="id_password">密码</label>
                            <input id="id_password" type="password" v-model="ruleForm.password" placeholder="请输入密码"
                                required>
                        </div>
                        <div>
                            <el-button native-type="submit" :loading="loading" @click="submitForm"
                                class="btn btn-success btn-block btn-large">用户登录</el-button>
                        </div>
                    </form>
                </div>
            </div>
        </el-main>
        <el-footer class="footer">
            <div>
                建议使用谷歌浏览器1280*800以上分辨率浏览
            </div>
            <div>
                <!-- Copyright © 2023 油田公司质量安全环保部 -->
                <!-- <div>技术支持：上海佐相信息科技有限公司</div> -->
                <a href="http://www.beian.miit.gov.cn" target="_blank"
                    style="text-decoration: none; color: rgb(102, 102, 102);">沪ICP备19020320号-7</a>
            </div>
        </el-footer>
    </el-container>
</template>

<script>
export default {
    data() {
        return {
            loading: false,
            ruleForm: {
                username: '',
                password: ''
            },
            rules: {
                username: [
                    { required: true, message: '请输入用户名', trigger: 'blur' },
                    { min: 2, max: 16, message: '长度在 2 到 16 个字符', trigger: 'blur' }
                ],
                password: [
                    { required: true, message: '请输入密码', trigger: 'blur' },
                    { min: 4, max: 64, message: '长度在 4 到 64 个字符', trigger: 'blur' }
                ],
            }
        }
    },
    methods: {
        async submitForm() {
            // const valid = await this.$refs['ruleForm'].validate().catch(() => false)
            // if (!valid) return
            this.loading = true
            try {
                const resp = await this.$http.post('/login', this.ruleForm)
                if (resp.data.code !== 200) {
                    this.$message.error(resp.data.msg)
                } else {
                    this.$store.commit('set_username', this.ruleForm.username)
                    this.$store.commit('set_token', resp.data.token)
                    await this.$router.push('/main/dashboard')
                }
            } catch (e) {
                this.$message.error(e.message)
            }
            this.loading = false
        }
    }
}
</script>

<style lang="scss" scoped>
.container {
    max-width: 1200px;
    margin: 0 auto;
}

.header {
    height: 160px !important;
    line-height: 160px !important;
    background-color: #ffffff;

    .logo {
        padding: 25px;

        img {
            height: 70px;
        }
    }
}

.main {
    // background-color: #2081c0;
    background: linear-gradient(190deg, #2081c0, #8cc5e6);
    min-height: 450px;
    flex: none;
    padding: 0;

    .flex {
        height: 450px;
        background-image: url(./yougongsi.png);
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-position: 0 100%;
        display: flex;
        algin-items: center;
        just-content: space-between;
    }

    .solution {
        flex: 1;
        min-width: 0;
        text-align: center;
        padding-top: 40px;
        padding-right: 50px;
        color: #ffffff;
    }

    .solution h1 {
        font-size: 180%;
        padding: 10px;
    }

    .solution h2 {
        font-size: 120%;
        padding: 10px;
        color: #fff;
    }

    .login {
        border: 1px solid #8bbad5;
        border-top: 6px solid #4f9fe7;
        border-radius: 3px;
        box-sizing: border-box;
        margin: 30px 30px 50px 0;
        width: 350px;
        background: #ffffff;
    }

    .login h1 {
        padding-top: 25px;
        font-size: 150%;
        text-align: center;
    }

    .login form {
        margin: 20px;
    }

    .login div {
        margin: 20px auto;
    }

    .login label {
        display: block;
        padding-left: 5px;
        font-size: 120%;
    }

    .login input {
        display: block;
        border: none;
        box-sizing: border-box;
        width: 100%;
        padding: 5px;
        background: transparent;
        border-bottom: 1px solid #cccccc;
        outline: 0px;
    }

    .login button {
        margin: 20px auto;
        display: block;
        box-sizing: border-box;
        width: 100%;
        padding: 10px;
        color: #ffffff;
        border: none;
        border-radius: 5px;
        background: -webkit-gradient(linear, left top, right bottom, from(#409eff), to(#409eff));
    }

    @media (max-width: 800px) {

        .header,
        .solution,
        .footer {
            display: none;
        }

        .flex {
            background-color: #2081c0;
            background-image: none;
            height: 100vh;
            width: 100%;
        }

        .login {
            margin: 20px auto;
            border: none;
        }
    }
}

.footer {
    margin-top: 20px;
    text-align: center;

    div {
        line-height: 50px;
    }

}
</style>
