<template>
    <el-card class="box-card" shadow="hover">
        <div slot="header" class="clearfix">
            <span>职业体检完成进度</span>
            <div style="float:right">
                <el-button style="padding: 3px 0" type="text" @click="exportXLSX" icon="el-icon-download"></el-button>
                <el-button style="padding: 3px 0" type="text" @click="getData" icon="el-icon-refresh"></el-button>
                <el-popover placement="bottom" title="过滤条件" width="350" trigger="click" v-model="visible">
                    <el-form ref="form" :model="form" label-width="90px" size="mini">
                        <el-form-item label="体检日期">
                            <el-date-picker v-model="form.daterange" type="daterange" style="width:260px"
                                range-separator="至" start-placeholder="开始日期" value-format="yyyy-MM-dd"
                                end-placeholder="结束日期">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="二级单位">
                            <select-subsidiary-2-multi size="mini" v-model="form.ejdw_ids" />
                        </el-form-item>
                    </el-form>
                    <div style="text-align: right; margin: 0">
                        <el-button size="mini" type="text" @click="visible = false">关闭</el-button>
                        <el-button type="primary" size="mini" @click="getData">确定</el-button>
                    </div>
                    <el-button slot="reference" style="padding: 3px 0;margin-left:10px;" type="text"
                        icon="el-icon-setting"></el-button>
                </el-popover>
            </div>
        </div>
        <v-chart ref="chart" class="chart" :option="option" v-loading="loading" />
    </el-card>
</template>

<script>
import * as XLSX from 'xlsx';

export default {
    name: 'dashboardJinduZhiye',
    data() {
        return {
            loading: false,
            visible: false,
            form: { daterange: ['2023-01-01', '2023-12-31'] },
            data: [],
            option: {
                tooltip: {
                    trigger: 'item',
                    // formatter: '{b} : {c}'
                    formatter: function (params) {
                        // 自定义 tooltip 的显示内容
                        return `${params.name}<br>
                        完成度比例： ${params.data.value}%<br/>
                        完成度明细：${params.data.appoint_count}/${params.data.staff_count}
                        `
                    }
                },
                grid: {
                    top: 25,
                    bottom: 60,
                    left: 30,
                    right: 15
                },
                xAxis: {
                    type: 'category',
                    axisLabel: {
                        color: '#666',
                        interval: 0,
                        formatter: function (value) {
                            return value.split('').join('\n')
                        }
                    },
                    data: []
                },
                yAxis: {
                    type: 'value'
                },
                series: [
                    {
                        data: [],
                        type: 'bar',
                        color: '#1890ff',
                        label: {
                            show: true, //开启显示
                            // position: 'inside', //在上方显示
                            // // position: [0, -20], // 在柱子上方 10px 的位置
                            // align: 'center', // 水平对齐方式为居中
                            // verticalAlign: 'middle', // 垂直对齐方式为居中
                            // color: 'white',
                            // fontSize: 10,
                            // formatter: '{c}%',
                            // rotate: 90 // 将标签文字旋转 90 度
                            position: 'top',
                            fontSize: 8,
                            formatter: '{c}%',
                            // rotate: 30 // 将标签文字旋转 90 度
                        }
                    }
                ]
            }
        }
    },
    methods: {
        exportXLSX() {
            const ws = XLSX.utils.aoa_to_sheet(this.data);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

            XLSX.writeFile(wb, 'export.xlsx');
        },
        async getData() {
            this.loading = true
            this.option.xAxis.data = []
            this.option.series[0].data = []
            const resp = await this.$http.get('/components/charts/dashboard/jindu/zhiye', { params: this.form })
            resp.data.sort((a, b) => { return b.value - a.value })
            this.data.push(['单位', '总人数', '已检人数', '完成率', '剩余未检人数'])
            resp.data.map(e => {
                this.data.push([e.name, e.staff_count, e.appoint_count, `${e.value}%`, e.surplus_count])
                this.option.xAxis.data.push(e.name)
                // this.option.series[0].data.push(e.value)
                this.option.series[0].data.push({ value: e.value, staff_count: e.staff_count, appoint_count: e.appoint_count })
            })
            this.$refs['chart'].setOption(this.option)
            this.loading = false
        }
    },
    mounted() {
        this.getData().then(() => {
            this.$nextTick(() => {
                this.$refs.chart.resize()
            })
            window.addEventListener('resize', () => {
                if (this.$refs && this.$refs.chart) {
                    this.$refs.chart.resize()
                }
            })
        })
    }
}
</script>

<style scoped>
.chart {
    width: 100%;
    height: 400px;
}

.title {
    text-align: center;
}
</style>
