<template>
    <div class="page">
        <el-page-header @back="$router.go(-1)" content="体检计划管理" />
        <div style="margin: 20px 0 10px;font-size: 120%;">当前体检任务：2024年度体检计划
            <div style="float:right">
                <el-button @click="exportXLSX" size="small" icon="el-icon-refresh"
                    v-if="permission.task_plan_export">导出Excel</el-button>
                <el-button @click="getData" size="small" icon="el-icon-refresh">刷新</el-button>
            </div>
        </div>
        <el-tabs v-model="activeName" @tab-click="handleClick" class="page-main page">
            <el-tab-pane label="健康体检" name="健康体检" class="page-main">
                <table-plan-1 :target="activeName" :tableLabel="tableLabel" :tableData="tableData" :table="table"
                    v-loading="loading" class="page-main" v-if="activeName=='健康体检'"/>
            </el-tab-pane>
            <el-tab-pane label="职业体检" name="职业体检" class="page-main">
                <table-plan-2 :target="activeName" :tableLabel="tableLabel" :tableData="tableData" :table="table"
                    v-loading="loading" v-if="activeName=='职业体检'" />
            </el-tab-pane>
            <el-tab-pane label="女工/家属体检" name="女工体检" class="page-main">
                <table-plan-1 :target="activeName" :tableLabel="tableLabel" :tableData="tableData" :table="table"
                    v-loading="loading" v-if="activeName=='女工体检'" />
            </el-tab-pane>
        </el-tabs>
        <!-- <div style="padding: 20px; text-align:center;">
                <el-pagination layout="total, prev, pager, next, sizes" :page-sizes="[10, 20, 30, 50, 100]"
                    :current-page="table.page" :total="table.total" :page-size="table.pageSize"
                    @current-change="handleCurrentChange" @size-change="handleSizeChange" />
            </div> -->
    </div>
</template>

<script>
import TablePlan1 from './widgets/tablePlan1'
import TablePlan2 from './widgets/tablePlanZY'
export default {
    name: 'task-plan',
    path: '/main/task/plan',
    components: {
        TablePlan1,
        TablePlan2,
    },
    data() {
        return {
            loading: false,
            activeName: '健康体检',
            form: {},
            table: {
                total: 100,
                page: 1,
                pageSize: 20
            },
            tableLabel: [], //['处级干部', '45岁以上', '35-44岁', '34岁以下', '二级工程师', '一级工程师', '企业高级专家', '企业首席专家', '女工/家属'],
            tableData: [],
            permission: {}
        }
    },
    methods: {
        handleCurrentChange(e) {
            this.table.page = e
            this.getData()
        },
        handleSizeChange(e) {
            this.table.pageSize = e
            this.table.page = 1
            this.getData()
        },
        // eslint-disable-next-line
        handleClick(tab, event) {
            this.tableLabel = []
            this.tableData = []
            this.getData()
        },
        async exportXLSX() {
            try {
                this.loading = true
                let params = {}
                params.export = 'xlsx'
                const resp = await this.$http.get(`/task/2023/export`, { params: params, responseType: 'blob' })
                let blob = new Blob([resp.data], { type: 'application/vnd.openxmlformats;charset=utf-8;' })
                var link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = decodeURI(escape(resp.headers['content-disposition'].split('=')[1]))
                link.click()
                window.URL.revokeObjectURL(link.href)
            } catch (error) {
                this.$message.error(error.message)
            } finally {
                this.loading = false
            }

        },
        async getData() {
            this.loading = true
            const resp = await this.$http.get(`/task/2023?type=${this.activeName}&page=${this.table.page}&pageSize=${this.table.pageSize}`)
            this.tableLabel = resp.data.tableLabel
            this.tableData = resp.data.tableData
            this.table.total = resp.data.tableData.total
            this.loading = false
        },
        async getPermission() {
            const resp = await this.$http.get('/permission')
            this.permission = resp.data
        }
    },
    mounted() {
        this.getPermission()
        this.getData()
    }
}
</script>
<style lang="scss" scoped>
.page {
    display: flex;
    flex-direction: column;
    /*justify-content: flex-start;*/
    /*align-items: flex-start;*/
    height: 100%;

    ::v-deep .el-tabs__content {
        display: flex;
        flex-direction: column;
        /*justify-content: flex-start;*/
        /*align-items: flex-start;*/
        height: 100%;

    }

    .page-header {
        padding-bottom: 10px;
    }

    .page-search {
        background-color: #f7f7f8;
        border: 1px solid #EBEEF5;
        border-bottom: 0px;
        text-align: center;

        .el-form-item {
            margin: 0 5px;
        }

    }

    .page-main {
        flex: 1;
        min-height: 100px;
    }

    .page-footer {
        text-align: center;
        padding-top: 20px;
    }
}
</style>
