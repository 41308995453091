<template>
    <div class="page">
        <el-page-header @back="$router.go(-1)" content="体检时间安排" />
        <zk-search-form-inline class="search" :form="search" @search="getData">
            <el-form-item label="名称">
                <el-input v-model.trim="search.name" clearable></el-input>
            </el-form-item>
            <el-form-item>
            <el-date-picker v-model="search.daterange" type="daterange" size="small" range-separator="至"
                @change="getData" start-placeholder="开始日期" value-format="yyyy-MM-dd" end-placeholder="结束日期">
            </el-date-picker>
            </el-form-item>
        </zk-search-form-inline>
        <el-tabs v-model="activeName" class="page-main page" @tab-click="handleClick">
            <el-tab-pane label="体检时间安排" name="tj" class="page-main page">
                <div class="page-main page">
                    <el-table border :data="tableData" style="width: 100%" height="100%" size="mini"  v-loading="loading">
                        <el-table-column type="index" label="序号" align="center" width="50" />
                        <el-table-column prop="name" label="单位" align="left" width="" />
                        <el-table-column prop="yy_note" label="提示文字" align="left" width="280" />
                        <el-table-column prop="yy_start" label="开始日期" align="left" width="180" />
                        <el-table-column prop="yy_end" label="结束日期" align="left" width="180" />
                        <el-table-column align="center" width="120" label="操作" fixed="right">
                            <template slot-scope="scope">
                                <el-button type="warning" size="mini" @click="handleUpdate(scope.row)" icon="el-icon-edit">
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </el-tab-pane>
            <el-tab-pane label="体检时间段" name="gtt" class="page-main page">
                <date-range-bar ref="drbar" class="page-main page"/>
            </el-tab-pane>

        </el-tabs>
        <el-dialog title="限制二级单位可预约日期范围" :visible.sync="dialogVisible" width="40%">
            <el-form ref="form" :model="form" label-width="120px" width="100%">
                <el-form-item label="二级单位" prop="name">
                    <el-input v-model.trim="form.name" readonly style="width:100%"></el-input>
                </el-form-item>
                <el-form-item label="提示信息" prop="yy_note">
                    <el-input v-model.trim="form.yy_note" clearable style="width:100%"></el-input>
                </el-form-item>
                <el-form-item label="可预约日期">
                    <el-date-picker v-model="form.daterange" type="daterange" range-separator="至" start-placeholder="开始日期"
                        style="width: 100%" value-format="yyyy-MM-dd" end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="submit">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    export default {
    name: 'task-daterange',
    path: '/main/task/daterange',
    data() {
        return {
            activeName: 'tj',
            loading: false,
            dialogVisible: false,
            search: { name: '', daterange: [] },
            form: { id: null, name: '', yy_note: '', daterange: [] },
            tableData: []
        }
    },
    methods: {
        handleClick(){
            if(this.activeName!="tj"){
                this.$refs.drbar.init()
            }
        },
        submit() {
            this.form.yy_start = this.form.daterange[0]
            this.form.yy_end = this.form.daterange[1]
            this.$http.post('/task/daterange', this.form)
                .then(resp => {
                    this.$message.success(resp.data.msg)
                    this.dialogVisible = false
                    this.getData()
                }).catch(err => {
                    this.$message.error(err.message)
                })
        },
        handleUpdate(e) {
            this.form.id = e.id
            this.form.name = e.name
            this.form.yy_note = e.yy_note
            this.form.daterange = [e.yy_start, e.yy_end]
            this.dialogVisible = true
        },
        async getData() {
            this.loading = true
            await this.$http.get('/task/daterange', {params: this.search})
                .then(resp => {
                    console.log(resp.data.data)
                    this.tableData = resp.data.data
                }).catch(err => {
                    this.$message.error(err.message)
                })
            this.loading = false
        }
    },
    mounted() {
        this.getData()
    }
}
</script>

<style lang="scss" scoped >


.page {
    .search {
        margin-top: 15px;
        padding: 10px;
        text-align: center;
        border-top: 1px solid #EBEEF5;
        /*border-left: 1px solid #EBEEF5;*/
        /*border-right: 1px solid #EBEEF5;*/
    }
    display: flex;
    flex-direction: column;
    /*justify-content: flex-start;*/
    /*align-items: flex-start;*/
    height: 100%;

    ::v-deep .el-tabs__content{
        display: flex;
        flex-direction: column;
        /*justify-content: flex-start;*/
        /*align-items: flex-start;*/
        height: 100%;

    }
    .page-main {
        flex: 1;
        min-height: 100px;
    }

}



</style>
