const data = {
    table: {
        columns: [
            {title: '标识', field: 'slug', width: '150'},
            {title: '标题', field: 'title', width: '200'},
            {title: '内容', field: 'content', width: ''}
        ],
        data: [],
        total: 0,
        page: 1,
        pageSize: 20
    }
}

export default data
