<template>
    <div class="page">
        <div class="header">
            <div style="float:right">
                <selectHospital size="mini" v-model="hospital_id" @input="getData" />
            </div>
            <el-page-header @back="$router.go(-1)" content="团体预约" />
        </div>
        <div class="main-content">
            <el-card class="box-card hidden-md-and-down" style="min-width:300px;max-width:340px;" shadow="hover">
                <div slot="header" class="clearfix">
                    <span>体检人信息</span>
                    <el-button style="float: right; padding: 3px 0" type="text" @click="info = {}">清空</el-button>
                </div>
                <div style="line-height: 36px" v-if="info.SFZH">
                    <div><b style="font-size:140%">{{ info.XM }}</b>
                        <small> {{ info.XB }} {{ info.NL }}岁 </small>
                        <el-tag class="tag" size="small" v-if="info.fee <= 1750" type="success">{{ info.ZGLX }}</el-tag>
                        <el-tag class="tag" size="small" v-else type="danger">{{ info.ZGLX }}</el-tag>
                    </div>
                    <div>身份证号：{{ info.SFZH }}</div>
                    <div>二级单位：{{ info.EJDW }}</div>
                    <div style="overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">三级单位：{{ info.SJDW }}
                    </div>
                    <div>联系电话：{{ info.phone }}</div>
                    <div>职工类型：{{ info.ZGLX }}</div>
                    <div>体检费用：{{ info.fee }}</div>
                    <div>女工家属：{{ info.TJLX_NGJS }}</div>
                    <div>职业体检：
                        <el-tag class="tag" size="small" v-if="info.SFZS == '是'">噪声</el-tag>
                        <el-tag class="tag" size="small" v-if="info.SFFC == '是'">粉尘</el-tag>
                        <el-tag class="tag" size="small" v-if="info.SFFS == '是'">放射</el-tag>
                        <el-tag class="tag" size="small" v-if="info.SFDW == '是'">毒物</el-tag>
                        <el-tag class="tag" size="small" v-if="info.SFDG == '是'">电工</el-tag>
                        <el-tag class="tag" size="small" v-if="info.SFGW == '是'">高温</el-tag>
                        <el-tag class="tag" size="small" v-if="info.SFGC == '是'">高处作业</el-tag>
                        <el-tag class="tag" size="small" v-if="info.SFYL == '是'">压力容器作业</el-tag>
                        <el-tag class="tag" size="small" v-if="info.SFJSY == '是'">驾驶员</el-tag>
                    </div>
                    <div v-if="info.JHYS">接害因素：{{ info.JHYS.join(', ') }}</div>
                    <div style="margin: 10px 0">
                        <el-tag class="tag" v-if="info.openid" type="success">已绑定微信</el-tag>
                        <el-tag class="tag" v-else type="warning">未绑定微信</el-tag>

                        <el-tag class="tag" v-if="info.appoint_status == 0" type="danger">已预约</el-tag>
                        <el-tag class="tag" v-else-if="info.appoint_status == 1" type="danger">已收费</el-tag>
                        <el-tag class="tag" v-else-if="info.appoint_status == -1" type="danger">已取消</el-tag>
                        <el-tag class="tag" v-else-if="info.appoint_status == -2" type="danger">已删除</el-tag>
                        <el-tag class="tag" v-else type="success">未预约</el-tag>

                        <el-tag class="tag" v-if="info.daikou_id" type="danger">已代扣</el-tag>
                        <el-tag class="tag" v-else type="success">未代扣</el-tag>
                    </div>
                    <div v-if="info.appoint_id" style="margin: 20px 0">
                        <div style="line-height:20px">
                            <small>
                                于{{ info.appoint_created }} 通过 {{ info.source }} <br>
                                在{{ info.hospital }} {{ info.date }} {{ info.time }}的体检
                            </small>
                        </div>
                        <div style="line-height:20px" v-if="info.appoint_paytime">
                            <small>
                                已于{{ info.appoint_paytime }}收费
                            </small>
                        </div>
                    </div>
                </div>
                <el-empty v-else description="请输入身份证号"></el-empty>
            </el-card>
            <el-card class="box-card" style="margin:0 20px;flex:1;" shadow="hover">
                <div slot="header" class="clearfix">
                    <span>信息录入</span>
                    <div style="float:right" v-if="zixuanFee > 0">自由选项费用合计：¥{{ zixuanFee }}元</div>
                </div>
                <el-form ref="form" :model="form" label-width="100px" size="small" :rules="rules">
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="身份证号" prop="idnum">
                                <el-autocomplete ref="autoComplete" v-model="form.idnum" @input="handleFormChange"
                                    :fetch-suggestions="querySearchAsync" value-key="SFZH" placeholder="请输入" clearable
                                    style="width:100%" @select="handleSelect">
                                    <template slot-scope="{ item }">
                                        <div class="name">{{ item.XM }}</div>
                                        <span class="sbname">{{ item.SFZH }}</span>
                                    </template>
                                    <template slot="append">
                                        <btn-read-id-card @result="readCard" />
                                    </template>
                                </el-autocomplete>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="姓名" prop="name">
                                <el-input v-model="form.name" placeholder="请输入姓名"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="联系电话" prop="phone">
                                <el-input v-model="form.phone" placeholder="请输入联系电话"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="选择日期" prop="date">
                                <el-select v-model="form.date" placeholder="请选择" @change="getTimes" style="width:100%"
                                    @input="handleFormChange">
                                    <el-option :label="`${d.date}(剩余${d.total - d.used})`" :value="d.date"
                                        v-for="d, i in dates" :key="i"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="选择时间" prop="time">
                                <el-select v-model="form.time" placeholder="请选择" style="width:100%"
                                    @input="handleFormChange">
                                    <el-option :label="d.text" :value="d.value" v-for="d, i in times"
                                        :key="i"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="选择套餐" prop="project.taocan">
                                <el-select v-model="form.project.taocan" value-key="id" placeholder="选择套餐" size="small"
                                    filterable clearable style="width:100%" @change="selectTaocan"
                                    @input="handleFormChange">
                                    <el-option :label="t.name" :value="t" v-for="(t, i2) in taocan" :key="i2"
                                        :disabled="canSelected(t, info.fee)"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="女工家属" prop="project.nvgong">
                                <el-select v-model="form.project.nvgong" value-key="id" placeholder="选择女工家属套餐"
                                    size="small" filterable clearable style="width:100%" @change="selectNvgong"
                                    @input="handleFormChange">
                                    <el-option :label="t.name" :value="t" v-for="(t, i2) in nvgong"
                                        :key="i2"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="自由选项" prop="project.zixuan">
                                <el-select @change="zixuanChange" size="small" style="width:100%"
                                    @input="handleFormChange" v-model="form.project.zixuan" filterable multiple
                                    placeholder="请选择自选项目" value-key="id">
                                    <el-option v-for="item in zixuan" :key="item.id"
                                        :label="`${item.name}(${(item.fee * 0.85).toFixed(2)})`" :value="item">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="职业体检" prop="project.zhiye">
                                <el-select @change="zhiyeChange" size="small" style="width:100%"
                                    @input="handleFormChange" v-model="form.project.zhiye" filterable multiple
                                    placeholder="请选择职业体检" value-key="id">
                                    <el-option v-for="item in zhiye" :key="item.id"
                                        :label="`${item.name}(${item.feeHeji})`" :value="item">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="6">
                            <el-form-item label="费用合计" prop="fee">
                                <el-input v-model="form.fee_total" placeholder="请输入内容" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="健康体检" prop="fee">
                                <el-input v-model="form.fee" placeholder="请输入内容" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="职业体检" prop="fee">
                                <el-input v-model="form.fee_zhiye" placeholder="请输入内容" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="女工家属" prop="fee">
                                <el-input v-model="form.fee_nvgong" placeholder="请输入内容" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="状态" prop="status">
                                <el-radio-group v-model="form.status">
                                    <el-radio :label="0">仅预约</el-radio>
                                    <el-radio :label="1">预约并收费</el-radio>
                                </el-radio-group>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                        </el-col>
                    </el-row>
                    <el-form-item label="备注">
                        <el-input type="textarea" v-model="form.remarks" />
                    </el-form-item>
                    <el-form-item style="padding-top: 40px;text-align: right">
                        <el-button type="default" @click="clearForm" :loading="loading">重 置</el-button>
                        <el-button type="primary" @click="onSubmit" :loading="loading">确 定</el-button>
                    </el-form-item>
                </el-form>
            </el-card>
            <el-card class="box-card  hidden-lg-and-down" style="min-width:200px;max-width:400px;" shadow="hover">
                <div slot="header" class="clearfix">
                    <span>最近添加</span>
                    <el-button style="float: right; padding: 3px 0" type="text" @click="getLatest">刷新</el-button>
                </div>
                <div style="overflow: scroll;height:100%">
                    <div v-for="(o, i) in latest" :key="i" class="latest-item" @click="onLatest(o)">
                        <div>{{ o.name }}</div>
                        <small>{{ o.idnum }}</small>
                    </div>
                </div>
            </el-card>
        </div>
        <print-form :visible="dialogVisible" :form="form" @close="dialogVisible = false" />
    </div>
</template>

<script>
import PrintForm from './appoint/widgets/printForm'
export default {
    name: 'task-tuanti-solo',
    path: '/main/task/tuanti-solo',
    components: {
        PrintForm
    },
    data() {
        var validateTaocan = (rule, value, callback) => {
            if (!this.form.project.taocan && this.form.project.zixuan.length === 0) {
                callback(new Error('套餐或自选项目必须选择一种!'))
            } else {
                callback()
            }
        }
        var validateZhiye = (rule, value, callback) => {
            if (this.info.JHYS && this.info.JHYS.length > 0) {
                if (this.form.project.zhiye.length == 0)
                    callback(new Error('有职业检!'))
                else
                    callback()
            } else {
                callback()
            }
        }
        var validateNvgong = (rule, value, callback) => {
            if (this.info.TJLX_NGJS && ['女工', '家属'].includes(this.info.TJLX_NGJS) && this.form.project.nvgong == null) {
                callback(new Error('女工家属套餐未选择!'))
            } else {
                callback()
            }
        }
        return {
            loading: false,
            dialogVisible: false,
            hospital_id: null,
            form: {
                name: '',
                idnum: '',
                date: '',
                time: '',
                project: {
                    taocan: null,
                    zixuan: [],
                    zhiye: [],
                    nvgong: null
                }
            },
            info: {},
            dates: [],
            times: [],
            nvgong: [],
            zhiye: [],
            zixuan: [],
            taocan: [],
            rules: {
                name: [
                    { required: true, message: '此项为必填项', trigger: 'blur' }
                ],
                idnum: [
                    { required: true, message: '此项为必填项', trigger: 'blur' }
                ],
                date: [
                    { required: true, message: '此项为必填项', trigger: 'change' }
                ],
                time: [
                    { required: true, message: '此项为必填项', trigger: 'change' }
                ],
                fee_total: [
                    { required: true, message: '此项为必填项', trigger: 'blur' }
                ],
                fee: [
                    { required: true, message: '此项为必填项', trigger: 'blur' }
                ],
                fee_zhiye: [
                    { required: true, message: '此项为必填项', trigger: 'blur' }
                ],
                fee_nvgong: [
                    { required: true, message: '此项为必填项', trigger: 'blur' }
                ],
                project: {
                    taocan: [
                        { validator: validateTaocan, trigger: 'change' }
                    ],
                    zixuan: [
                        { validator: validateTaocan, trigger: 'change' }
                    ],
                    zhiye: [
                        { validator: validateZhiye, trigger: 'change' }
                    ],
                    nvgong: [
                        { validator: validateNvgong, trigger: 'change' }
                    ]
                }
            },
            latest: []
        }
    },
    computed: {
        zixuanFee() {
            let fee = 0
            if (this.form.project.zixuan && this.form.project.zixuan.length > 0) {
                this.form.project.zixuan.forEach(e => {
                    fee += parseFloat((parseFloat(e.fee) * 0.85).toFixed(2))
                })
            }
            return fee.toFixed(2)
        }
    },
    methods: {
        readCard(e) {
            if (e.ret == 1) {
                // this.$message.success(e.data.code)
                this.form.idnum = e.data.code
                this.form.name = e.data.name
                this.$nextTick(() => {
                    try {
                        this.$refs.autoComplete.$refs.input.focus() // 聚焦到输入框
                        this.$refs.autoComplete.$refs.input.dispatchEvent(new Event('input')) // 触发搜索
                    } catch (e) {
                        // this.$message.error(e.message)
                    }
                })
            }
            else
                this.$message.error(e.msg)
        },
        async querySearchAsync(queryString, cb) {
            if (!queryString) return {}
            const resp = await this.$http.get('/company/userquery?idnum=' + queryString)
            cb(resp.data)
        },
        clearForm() {
            this.form = {
                idnum: '',
                name: '',
                phone: '',
                date: '',
                time: '',
                project: {
                    taocan: null,
                    zixuan: [],
                    zhiye: [],
                    nvgong: null
                },
                fee: 0.0,
                fee_total: 0.0,
                fee_zhiye: 0.0,
                fee_nvgong: 0.0,
                status: 0,
                remarks: ''
            }
            this.$refs.form.resetFields()
        },
        handleFormChange() {
            // eslint-disable-next-line 
            this.$refs['form'].validate().catch((err) => { return false })
        },
        makeFee() {
            let fee_zhiye = this.form.project.zhiye.reduce((sum, item) => sum + parseFloat(item.feeHeji), 0)
            this.form.fee_zhiye = fee_zhiye

            this.form.fee = this.form.fee ? parseFloat(this.form.fee) : 0.0
            this.form.fee_nvgong = this.form.project.nvgong && this.form.project.nvgong.name ? 181.00 : 0.0
            this.form.fee_total = this.form.fee + this.form.fee_zhiye + this.form.fee_nvgong
        },
        async handleSelect(e) {
            let date = this.dates && this.dates.length > 0 ? this.dates[0] : null
            if (date) {
                await this.getTimes(date.date)
            }
            let time = this.times && this.times.length > 0 ? this.times[0] : null
            console.log(date, time)
            const resp = await this.$http.get('/company/userinfo?idnum=' + e.SFZH)
            if (resp.data.length != 1) {
                this.$message.error('同身份证号人数错误')
                return
            }
            let item = resp.data[0]
            this.clearForm()
            item.JHYS = item.JHYS ? item.JHYS : []
            if (item.SFZS == '是') item.JHYS.push('噪声')
            if (item.SFFC == '是') item.JHYS.push('粉尘')
            if (item.SFFS == '是') item.JHYS.push('放射')
            if (item.SFDW == '是') item.JHYS.push('毒物')
            if (item.SFDG == '是') item.JHYS.push('电工')
            if (item.SFGW == '是') item.JHYS.push('高温')
            if (item.SFGC == '是') item.JHYS.push('高处作业')
            if (item.SFYL == '是') item.JHYS.push('压力容器作业')
            if (item.SFJSY == '是') item.JHYS.push('驾驶员')
            item.JHYS = item.JHYS.filter(Boolean)
            item.JHYS = [...new Set(item.JHYS)]

            this.info = item
            if (item.appoint_id) {
                this.$message.error('已预约')
                // this.clearForm()
                // return
            }
            if (item.daikou_id) {
                this.$message.error('已代扣')
                // this.clearForm()
                // return
            }
            this.form.id = item.appoint_id
            this.form.status = item.appoint_status || 1
            this.form.name = item.XM
            this.form.idnum = item.SFZH
            this.form.fee = item.fee
            this.form.XB = item.XB
            this.form.EJDW = item.EJDW
            this.form.SJDW = item.SJDW
            this.form.FJDW = item.FJDW
            this.form.phone = item.phone
            this.form.date = item.date || date ? date.date : ''
            this.form.time = item.time || time ? time.value : ''
            this.form.project.bixuan = []
            this.form.project.taocan = item.project?.taocan
            this.form.project.nvgong = item.project?.nvgong
            let zixuan_ids = item.project?.zixuan.map(e => e.id)
            this.form.project.zixuan = this.zixuan.filter(e => zixuan_ids && zixuan_ids.includes(e.id))
            // let zhiye_ids = item.project?.zhiye.map(e => e.id)
            // this.form.project.zhiye = this.zhiye.filter(e => zhiye_ids && zhiye_ids.includes(e.id))
            if (item.JHYS) {
                this.zhiye.forEach(e => {
                    if (e.jhys.some(j => item.JHYS.includes(j)))
                        this.form.project.zhiye.push({ id: e.id, name: e.name, feeHeji: e.feeHeji, feeZhehou: e.feeZhehou })
                })
            }
            this.makeFee()
            setTimeout(() => {
                this.handleFormChange()
            }, 200)
        },
        getTJLX(fee) {
            if (parseInt(fee) >= 1750) return '45岁及以上'  // 1750
            if (parseInt(fee) >= 1050) return '35-44岁'  // 35-44岁 1050
            if (parseInt(fee) >= 700) return '34岁及以下' // 34岁及以下 700
        },
        canSelected(t, fee) {
            return !t.zglx.includes(this.getTJLX(fee))
        },
        selectTaocan(e) {
            this.form.project.taocan = { id: e.id, name: e.name, feeHeji: e.feeHeji, feeZhehou: e.feeZhehou }
            this.makeFee()
        },
        selectNvgong(e) {
            this.form.project.nvgong = { id: e.id, name: e.name, feeHeji: e.feeHeji, feeZhehou: e.feeZhehou }
            this.makeFee()
        },
        // eslint-disable-next-line 
        zixuanChange(zixuan) {
            this.makeFee()
        },
        zhiyeChange(zhiye) {
            this.form.project.zhiye = []
            zhiye.forEach(e => {
                this.form.project.zhiye.push({ id: e.id, name: e.name, feeHeji: e.feeHeji, feeZhehou: e.feeZhehou })
            })
            this.makeFee()
        },
        async onSubmit() {
            // eslint-disable-next-line 
            const valid = await this.$refs['form'].validate().then(res => res).catch(error => { return false })
            if (!valid) return
            this.loading = true
            const resp = await this.$http.post('/appoint/form', this.form)
            if (resp.data.code === 200) {
                if (this.form.status === 1)
                    this.dialogVisible = true
                this.$message.success('保存成功')
                this.dialogVisible = true
                // this.clearForm()
                // this.$refs.form.resetFields()
                // this.getLatest()
            } else {
                // this.$message.error(resp.data.msg)
                this.$alert(resp.data.msg, '保存失败', { type: 'warning' })
            }
            this.loading = false
        },
        async getTimes(e) {
            this.form.time = ''
            const resp = await this.$http.get(`/task/tuanti/times?date=${e}`)
            this.times = resp.data
        },
        getData() {
            this.$http.get('/task/tuanti/project/2')
                .then(resp => {
                    this.taocan = resp.data.data
                })
            this.$http.get(`/task/tuanti/project/3?hospital_id=${this.hospital_id}`)
                .then(resp => {
                    this.zixuan = resp.data.data
                })
            this.$http.get('/task/tuanti/project/4')
                .then(resp => {
                    this.zhiye = resp.data.data
                })
            this.$http.get('/task/tuanti/project/5')
                .then(resp => {
                    this.nvgong = resp.data.data
                })
            this.$http.get('/task/tuanti/dates')
                .then(resp => {
                    this.dates = resp.data.dates
                })
        },
        onLatest(e) {
            this.clearForm()
            this.form = e
            // setTimeout(() => {
            //     this.$refs.autoComplete.$refs.input.focus() // 聚焦到输入框
            //     this.$refs.autoComplete.$refs.input.dispatchEvent(new Event('input')) // 触发搜索
            // }, 0)
        },
        getLatest() {
            this.$http.get('/appoint/latest').then(resp => {
                this.latest = resp.data
            }).catch(err => {
                this.$message.error(err.message)
            })
        }
    },
    mounted() {
        this.getData()
        this.getLatest()
    }
}
</script>

<style lang="scss" scoped>
.page {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.header {
    // line-height: 40px;

    .btns {
        float: right;
    }
}

.main-content {
    margin-top: 18px;
    height: 100%;
    flex: 1;

    display: flex;
    flex-direction: row;
    height: calc(100% - 30px);
    box-sizing: border-box;

    .box-card {
        height: calc(100% - 20px);
    }

    .box-card ::v-deep .el-card__body {
        height: calc(100% - 100px);
        overflow: scroll;
    }
}

.tag {
    margin: 5px 10px 5px 0;
}

.latest-item {
    cursor: pointer;
    border-bottom: 1px solid #ebebeb;
    padding-bottom: 7px;
    margin-bottom: 10px;
}
</style>