<template>
    <el-table :data="tableData" style="width: 100%" height="100%">
        <el-table-column type="index" label="序号" align="center"
                         :index="(index) => { return (this.table.page - 1) * this.table.pageSize + index + 1 }"
                         width="50"/>
        <el-table-column prop="alias" label="单位" align="left" width="">
            <template slot-scope="scope">
                {{ scope.row.alias||scope.row.name }}
            </template>
        </el-table-column>
        <el-table-column label="已检" align="center" width="70">
            <template slot-scope="scope">
                <router-link class="link" 
                :to="`/main/task/checkup?TJLX=${target}&dwid=${scope.row.id}`">{{ scope.row.used || 0 }}</router-link>
            </template>
        </el-table-column>
        <el-table-column label="总人数" align="center" width="90">
            <template slot-scope="scope">
                <router-link class="link" 
                :to="`/main/company/staff?TJLX=${target}&dwid=${scope.row.id}`">{{ scope.row.count || 0 }}</router-link>
            </template>
        </el-table-column>
        <el-table-column :label="item.name" align="center" v-for="(item, index) in tableLabel" :key="index">
            <el-table-column prop="item" align="center" label="计划" width="60">
                <template slot-scope="scope">
                    <div @click="showDlg(scope.row)" style="cursor: pointer">
                        <!-- <plan-count :type="activeName" :sid="scope.row.id" :label="item" /> -->
                        <router-link class="link"
                                     :to="`/main/company/staff?TJLX=${target}&dwid=${scope.row.id}&label=${item.name}`">
                            {{
                            scope.row[item.count] || 0 }}
                        </router-link>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="item" align="center" label="剩余" width="60">
                <template slot-scope="scope">
                    <router-link class="link"
                                 :to="`/main/task/surplus?target=${target}&dwid=${scope.row.id}&label=${item.name}`">{{
                        scope.row[item.surplus] || 0 }}
                    </router-link>
                </template>
            </el-table-column>
        </el-table-column>
        <!-- <el-table-column prop="total" label="合计" align="center" width="80"/>
        <el-table-column prop="chongfu" label="重复" align="center" width="80"/> -->
    </el-table>
</template>

<script>
    export default {
        props: ['tableLabel', 'tableData', 'table', 'target'],
        data() {
            return {
                // tableData: []
            }
        },
        methods: {
            showDlg() {

            }
        }
    }
</script>

<style scoped>
    .link {
        text-decoration: none;
        color: #333;
    }
</style>
