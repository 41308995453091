<template>
    <el-main>
        <div class="notfound">
            <h1>403</h1>
            <h2>没有权限访问！</h2>
            <el-button size="large" @click="goback" type="primary">
                返 回
            </el-button>
        </div>
    </el-main>
</template>

<script>
export default {
    name: 'main.no_permission',
    path: '/main/help/no_permission',
    methods: {
        goback() {
            this.$router.go(-1)
        }
    }
}
</script>


<style scoped>
h1 {
    font-size: 400%;
}

.notfound {
    padding: 40px 0;
    text-align: center;
}
</style>
