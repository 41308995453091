<template>
    <div class="page">
        <div>
            <el-page-header @back="$router.go(-1)" content="套餐设置" />
        </div>
        <el-tabs style="margin-top:20px" v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="必选项目" name="bixuan">
                <tableBixuanTaocan />
            </el-tab-pane>
            <el-tab-pane label="固定套餐" name="guding">
                <tableGudingTaocan />
            </el-tab-pane>
            <el-tab-pane label="自选项目" name="zixuan">
                <TableZixuanXiangmu />
            </el-tab-pane>
            <el-tab-pane label="职业套餐" name="zhiye">
                <tableZhiyeTaocan />
            </el-tab-pane>
            <el-tab-pane label="女工/家属套餐" name="nvgong">
                <tableNvgongjiashu />
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import TableBixuanTaocan from './widgets/tableBixuanTaocan.vue'
import TableGudingTaocan from './widgets/tableGudingTaocan.vue'
import TableZixuanXiangmu from './widgets/tableZixuanXiangmu.vue'
import tableZhiyeTaocan from './widgets/tableZhiyeTaocan.vue'
import tableNvgongjiashu from './widgets/tableNvgongjiashu.vue'
export default {
    name: 'hospital-package2',
    path: '/main/hospital/package2',
    components: {
        TableBixuanTaocan,
        TableGudingTaocan,
        TableZixuanXiangmu,
        tableZhiyeTaocan,
        tableNvgongjiashu
    },
    data() {
        return {
            activeName: 'bixuan'
        }
    },
    methods: {
        handleClick(tab, event) {
            console.log(tab, event)
        }
    }
}
</script>

<style lang="scss" scoped>

    @media (max-width: 992px) {
        ::v-deep .el-dialog{
            width: 96% !important;
            margin-top: 5vh !important;

        }
    }

    @media (max-width: 1200px) {
        ::v-deep .el-dialog{
            width: 90% !important;
        }
    }
</style>
