<template>
    <div>
        <div v-loading="loading">
            <el-button v-for="(e, i) in list" type="text"
                @click="$emit('edit', e)" :key="i">{{ e.name }}</el-button>
            <el-button size="mini" @click="$emit('add')">+ 添加管理员</el-button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'subsidiary_admin',
    props: {
        sid: {
            type: [String, Number]
        }
    },
    data() {
        return {
            loading: false,
            list: []
        }
    },
    watch: {
        sid: {
            handler(n, o) {
                this.getData()
            }
        }
    },
    methods: {
        async getData() {
            this.loading = true
            const resp = await this.$http.get(`/user?page=1&pageSize=200&role_id=3&ejdw_id=${this.sid}`)
            this.list = resp.data.data.records
            this.loading = false
        }
    },
    mounted() {
        // console.log(this.name, this.label)
        this.getData()
    }
}
</script>