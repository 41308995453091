<template>
    <div>
        <div v-for="(w, i) in times" :key="i">
            <div style="display: flex">
                <el-form-item prop="time">
                    <el-input v-model="times[i].time" style="width: 120px;margin-right:10px" placeholder="时间"
                        size="small"></el-input>
                </el-form-item>
                <el-form-item prop="count">
                    <el-input-number v-model="times[i].count" style="width:100px;margin-right:10px" placeholder="号数"
                        size="small" controls-position="right" :min="20" :max="500"></el-input-number>
                </el-form-item>
                <el-form-item>
                    <el-button @click="timesDel(i)" size="mini" icon="el-icon-close" round></el-button>
                </el-form-item>
            </div>
        </div>
        <el-button @click="timesAdd" size="small">添加</el-button>
        <el-button @click="importDlgShow" size="small">批量导入</el-button>
        <el-dialog title="批量导入" :visible.sync="dialogVisible" width="60%" :close-on-click-modal="false" append-to-body>
            <el-input type="textarea" :rows="14" placeholder="请输入内容" v-model="textImport" />
            <span v-clipboard="copyText" @success="handleSuccess" class="copy">
                批量导入文本格式 <b>点击复制</b>
            </span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="handleImport">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: 'time-inputs',
    props: {
        times: {
            type: Array,
            default: function () {
                return [{ time: '', count: 1 }]
            }
        }
    },
    model: {
        prop: 'times',
        event: 'cc'
    },
    watch: {
        times: {
            // eslint-disable-next-line no-unused-vars
            handler(val, oldVal) {
                this.$emit('cc', this.times)
            },
            deep: true
        }
    },
    data() {
        return {
            dialogVisible: false,
            textImport: '',
            copyText: '07:30-08:00,3\n' +
                '08:00-08:30,3\n' +
                '08:30-09:00,3\n' +
                '09:00-09:30,3\n' +
                '09:30-10:00,3\n' +
                '10:00-10:30,3'
        }
    },
    methods: {
        handleSuccess(e) {
            this.$message({
                message: '复制成功，请在文本框里粘贴',
                type: 'success'
            })
        },
        timesAdd() {
            this.times.push({})
        },
        timesDel(i) {
            this.times.splice(i, 1)
        },
        importDlgShow() {
            this.textImport = ''
            this.times.forEach(e => {
                this.textImport += `${e.time},${e.count}\n`
            })
            this.dialogVisible = true
        },
        handleImport() {
            let arr = this.textImport.split('\n')
            for (let i = 0; i < arr.length; i++) {
                let temp = arr[i].split(',')
                this.times.push({ time: temp[0], count: temp[1] })
            }
            this.dialogVisible = false
        }
    }
}
</script>
<style>
.copy {
    padding-top: 10px;
    cursor: pointer;
    display: block;
}

.copy b {
    color: #409EFF
}</style>