<template>
    <el-select :value="value" @input="$emit('input', $event)" placeholder="请选择" :size="size" clearable>
        <el-option v-for="item in options" :key="item.path" :label="item.path" :value="item.path" />
        <el-option v-for="item in options" :key="item.path" :label="item.path" :value="item.path">
            <span style="float: left">{{ item.path }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">{{ item.redirect }}</span>
        </el-option>
    </el-select>
</template>

<script>
export default {
    name: 'selectRoute',
    props: {
        value: {
            type: [String, Number, Object],
            default: ''
        },
        size: {
            default: 'small'
        }
    },
    data() {
        return {
            options: this.$router.options.routes[3].children
        }
    },
    methods: {
        async getData() {
            // const hl = await this.$http.get('/hospital/hospital?page=1&pageSize=200')
            // this.options = hl.data.data.records
            console.log(this.$router.options.routes)
        }
    },
    mounted() {
        this.getData()
    }
}
</script>

<style lang="scss" scoped>

</style>
