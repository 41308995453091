<template>
    <el-card class="box-card" body-style="padding:0" shadow="hover">
        <div slot="header" class="clearfix">
            <span>二级单位数量明细</span>
            <el-button style="float: right; padding: 3px 0" type="text" @click="getTableData">刷新</el-button>
        </div>
        <el-table :data="table.tableData" strip style="width: 100%;" height="530px" v-loading="table.loading">
            <el-table-column type="index" width="50" align="center">
                <template slot-scope="scope">
                    <div v-text="scope.$index + 1 + ((table.page - 1) * table.pageSize)"></div>
                </template>
            </el-table-column>
            <el-table-column prop="name" label="单位名称" width="380" />
            <el-table-column prop="value" label="数量" width="" />
        </el-table>
    </el-card>
</template>

<script>
export default {
    name: 'analysis-ejdw-table',
    props: {
        id: {
            type: [String, Number, Object]
        }
    },
    data() {
        return {
            table: {
                loading: false,
                page: 1,
                pageSize: 20,
                total: 0,
                tableData: []
            }
        }
    },
    methods: {
        handleCurrentChange(e) {
            this.table.page = e
            this.getTableData()
        },
        handleSizeChange(e) {
            this.table.pageSize = e
            this.table.page = 1
            this.getTableData()
        },
        async getTableData() {
            this.table.loading = true
            const resp = await this.$http.get(`/statistics/analysis_bar_chart?id=${this.$route.params.id}`)
            resp.data.sort((a,b)=>{ return b.value-a.value})
            console.log(resp.data)
            this.table.tableData = resp.data
            this.table.loading = false
        }
    },
    mounted() {
        this.getTableData()
    }
}
</script>