<template>
    <el-select :value="value" multiple collapse-tags @input="$emit('input', $event)" placeholder="选择医院" :size="size"
    style="width:200px"
        clearable>
        <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id" />
    </el-select>
</template>

<script>
export default {
    name: 'selectHospitalMulti',
    props: {
        value: {
            type: [Array],
            default: ''
        },
        size: {
            default: 'small'
        }
    },
    data() {
        return {
            options: []
        }
    },
    methods: {
        async getData() {
            const hl = await this.$http.get('/hospital/hospital?page=1&pageSize=200')
            this.options = hl.data.data.records
        }
    },
    mounted() {
        this.getData()
    }
}
</script>

<style lang="scss" scoped></style>
