const list_formatter = (row, column) => {
    const value = row[column.property]
    return value.join(',')
}

const data = {
    table: {
        columns: [
            {title: '体检类型', field: 'type', width: '120'},
            {title: '套餐名称', field: 'name', width: '280'},
            {title: '可用性别', field: 'gender', width: '120', formatter: list_formatter},
            {title: '员工类型限制', field: 'zglx', width: '', formatter: list_formatter},
            {title: '合计金额', field: 'feeHeji', width: '120'},
            {title: '折后金额', field: 'feeZhehou', width: '120'}
        ],
        data: [],
        total: 0,
        page: 1,
        pageSize: 20
    }
}

export default data
