<template>
    <el-card style="display: flex;flex-direction: column;"
        body-style="flex:1;padding:0;display:flex;flex-direction: column;" shadow="hover">
        <div slot="header" class="clearfix">
            <span>费用明细查询</span>
            <div style="float:right;margin-top:-2px">
                <el-form :inline="true" :model="search" size="mini">
                    <!-- <el-form-item label="收费方式">
                        <el-select v-model="search.type" placeholder="请选择收费方式" style="width:140px">
                            <el-option label="现场刷卡" value="appoint"></el-option>
                            <el-option label="医院代扣" value="daikou"></el-option>
                        </el-select>
                    </el-form-item> -->
                    <el-form-item label="">
                        <select-hospital size="mini" v-model="search.hospital_id" @input="getData" />
                    </el-form-item>
                    <el-form-item label="">
                        <select-subsidiary-2 v-model="search.ejdw_id" size="mini" style="width:150px" @input="getData" />
                    </el-form-item>
                    <el-form-item label="">
                        <select-subsidiary-3 :ejdw_id="search.ejdw_id" v-model="search.sjdw_id" size="mini"
                            style="width:150px" @input="getData" />
                    </el-form-item>
                    <el-form-item label="">
                        <select-subsidiary-4 :sjdw_id="search.sjdw_id" v-model="search.fjdw_id" size="mini"
                            style="width:150px" @input="getData" />
                    </el-form-item>
                    <!-- <el-form-item label="姓名">
                        <el-input v-model="search.name" placeholder="请输入姓名" clearable />
                    </el-form-item>
                    <el-form-item label="身份证号">
                        <el-input v-model="search.idnum" placeholder="请输入身份证号" clearable />
                    </el-form-item> -->
                    <el-form-item label="">
                        <el-date-picker v-model="search.daterange" type="daterange" range-separator="至"
                            start-placeholder="开始日期" style="width:260px" value-format="yyyy-MM-dd" end-placeholder="结束日期"
                            @change="getData">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" plain @click="getData" :loading="table.loading">查询</el-button>
                        <el-button type="default" plain @click="exportXLSX" :loading="table.loading">导出excel</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <el-table style="width:100%;flex:1;" height="100%" :data="table.data" strip v-loading="table.loading">
            <el-table-column type="index" width="50" align="center">
                <template slot-scope="scope">
                    <div v-text="scope.$index + 1 + ((table.page - 1) * table.pageSize)"></div>
                </template>
            </el-table-column>
            <el-table-column prop="hospital" label="体检机构" width="180">
                <template slot-scope="scope">
                    <div>{{ scope.row.hospital }}</div>
                    <small>{{ scope.row.source }}</small>
                </template>
            </el-table-column>
            <el-table-column prop="pay_time" label="单位" width="">
                <template slot-scope="scope">
                    <div>{{ scope.row.EJDW }}</div>
                    <small>{{ scope.row.SJDW }}</small>
                    <small>{{ scope.row.FJDW }}</small>
                </template>
            </el-table-column>
            <el-table-column label="姓名" prop="name" width="">
                <template slot-scope="scope">
                    {{ scope.row.name }}
                    <small>{{ scope.row.XB }} </small>
                    <div style="font-size:90%">{{ scope.row.idnum }}</div>
                </template>
            </el-table-column>
            <el-table-column label="费用" width="">
                <template slot-scope="scope">
                    <div>{{ scope.row.fee_total }}</div>
                    <small>{{ scope.row.fee }}+{{ scope.row.fee_zhiye }}+{{ scope.row.fee_nvgong }}</small>
                </template>
            </el-table-column>
            <el-table-column prop="pay_time" label="预约时间" width="">
                <template slot-scope="scope">
                    <div>预约时间： {{ scope.row.date }} {{ scope.row.time }}</div>
                    <div>收费时间： {{ scope.row.payTime || scope.row.created }}</div>
                </template>
            </el-table-column>
        </el-table>
        <div style="padding:10px;text-align: center;">
            <el-pagination layout="total, prev, pager, next, sizes" :page-sizes="[10, 20, 30, 50, 100]"
                :current-page="table.page" :total="table.total" :page-size="table.pageSize"
                @current-change="handleCurrentChange" @size-change="handleSizeChange" />
        </div>
    </el-card>
</template>

<script>
export default {
    name: 'fee-table',
    props: {
        id: {
            type: [String, Number, Object]
        }
    },
    data() {
        return {
            search: {},
            table: {
                loading: false,
                page: 1,
                pageSize: 20,
                total: 0,
                data: []
            }
        }
    },
    methods: {
        handleCurrentChange(e) {
            this.table.page = e
            this.getData()
        },
        handleSizeChange(e) {
            this.table.pageSize = e
            this.table.page = 1
            this.getData()
        },
        async exportXLSX() {
            this.table.loading = true
            let params = JSON.parse(JSON.stringify(this.search))
            params.export = 'xlsx'
            const resp = await this.$http.get(`/components/table/fee/logtable?page=${this.table.page}&pageSize=${this.table.pageSize}`, { params: params, responseType: 'blob' })
            let blob = new Blob([resp.data], { type: 'application/vnd.openxmlformats;charset=utf-8;' })
            var link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = decodeURI(escape(resp.headers['content-disposition'].split('=')[1]))
            link.click()
            window.URL.revokeObjectURL(link.href)
            this.table.loading = false
        },
        async getData() {
            this.table.loading = true
            const resp = await this.$http.get(`/components/table/fee/logtable?page=${this.table.page}&pageSize=${this.table.pageSize}`, { params: this.search })
            if (!resp.data) {
                this.$message.error(resp.data.msg)
            } else {
                this.table.data = resp.data.data
                this.table.total = resp.data.total
            }
            this.table.loading = false
        }
    },
    mounted() {
        // this.getTableData()
    }
}
</script>
<style scoped>
::v-deep .el-card__header {
    padding: 18px 20px;
    height: 60px;
}

::v-deep .el-form-item {
    padding: 0 !important;
    margin-bottom: 0 !important;
}
::v-deep .el-card__header{
    height: auto;
}
</style>