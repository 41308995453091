<template>
    <v-chart ref="chart" v-loading="loading" class="chart" :option="option" />
</template>

<script>
export default {
    name: 'analysisBarChart',
    props: {
        id: {
            type: [String, Number, Object]
        }
    },
    data() {
        return {
            loading: false,
            option: {
                title: {
                    text: '所在单位分布',
                    left: 'center'
                },
                tooltip: {
                    trigger: 'item',
                    formatter: '{b} : {c}'
                },
                grid: {
                    // top: '15%',
                    left: '3%',
                    right: '3%',
                    // bottom: '10%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    axisLabel: {
                        interval:0,
                        rotate: 60
                    },
                    data: []
                },

                yAxis: {
                    type: 'value'
                },
                series: [
                    {
                        data: [],
                        type: 'bar'
                    }
                ]
            }
        }
    },
    methods: {
        async getData() {
            this.loading = true
            const resp = await this.$http.get(`/statistics/analysis_bar_chart?id=${this.id||1}`)
            resp.data.map(e => {
                // console.log(e.name, e.value)
                this.option.xAxis.data.push(e.name)
                this.option.series[0].data.push(e.value)
            })
            this.$refs['chart'].setOption(this.option)
            this.loading = false
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.$refs.chart.resize()
        })
        window.addEventListener('resize', ()=>{
            if (this.$refs && this.$refs.chart) {
                this.$refs.chart.resize()
            }
        })
        this.getData()
    }
}
</script>

<style scoped>
.chart {
    width: 100%;
    height: 600px;
}
</style>
