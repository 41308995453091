<template>
    <el-dialog title="编辑" :visible="visible" width="40%" @close="close">
        <el-tabs v-model="activeName">
            <el-tab-pane label="机构用户信息" name="tab1">
                <el-form ref="form" :model="form" label-width="100px">
                    <el-form-item label="体检机构" prop="company_id" :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                        <select-hospital v-model="form.company_id" />
                    </el-form-item>
                    <el-form-item label="用户名" prop="username" :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                        <el-input v-model.trim="form.username"></el-input>
                    </el-form-item>
                    <el-form-item label="密码" prop="password" :rules="{ required: true, message: '不能为空', trigger: 'blur' }" v-if="isNew">
                        <el-input v-model.trim="form.password"></el-input>
                    </el-form-item>
                    <el-form-item label="姓名" prop="name" :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                        <el-input v-model.trim="form.name"></el-input>
                    </el-form-item>
                    <el-form-item label="联系电话" prop="contact"
                        :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                        <el-input v-model.trim="form.contact"></el-input>
                    </el-form-item>
                    <el-form-item label="备注">
                        <el-input type="textarea" v-model.trim="form.remarks"></el-input>
                    </el-form-item>
                </el-form>
            </el-tab-pane>
        </el-tabs>
        <span slot="footer" class="dialog-footer">
            <el-button @click="close">{{ $t('form.cancel') }}</el-button>
            <el-button @click="formSubmit" type="primary" :loading="loading" :disabled="loading">{{ $t('form.ok') }}
            </el-button>
        </span>
    </el-dialog>
</template>
<script>
export default {
    props: {
        visible: {
            default: false
        },
        form: {
            hospital: null,
            default: {}
        },
        isNew: {
            default: false
        }
    },
    data() {
        return {
            loading: false,
            activeName: 'tab1'
        }
    },
    methods: {
        async formSubmit() {
            const valid = await this.$refs['form'].validate().catch(() => false)
            if (!valid) return
            const resp = await this.$http.post('/user', this.form)
            if (resp.data.code != 200) {
                this.$message.error(resp.data.msg)
            } else {
                this.$message.success('保存成功')
                this.$emit('update')
                this.$emit('close')
            }
        },
        close() {
            this.$emit('close')
        }
    },
    mounted() {

    }
}
</script>

<style scoped>
::v-deep .el-dialog__body {
    padding: 0 20px;
}
</style>
