<template>
    <el-card class="box-card" shadow="hover">
        <div slot="header" class="clearfix">
            <span>单位体检预约人数</span>
            <el-button style="float: right; padding: 3px 0" type="text" @click="getData"
                icon="el-icon-refresh"></el-button>
        </div>
        <v-chart v-if="option.series[0].data.length > 0" ref="chart" class="chart" :option="option"
            v-loading="loading" />
        <el-empty v-else class="chart" description="没有记录"></el-empty>
    </el-card>
</template>

<script>
export default {
    name: "dashboardDanweiPaihang",
    data() {
        return {
            loading: false,
            option: {
                tooltip: {
                    trigger: 'item',
                    formatter: '{b} : {c}'
                },
                grid: {
                    top: '1%',
                    left: '1%',
                    right: '3%',
                    bottom: '1%',
                    containLabel: true
                },
                xAxis: {
                    type: 'value'
                },
                yAxis: {
                    type: 'category',
                    axisLabel: {
                        interval: 0
                    },
                    data: []
                },
                series: [
                    {
                        data: [],
                        type: 'bar',
                        // itemStyle: {
                        //     normal: {
                        //         color: function () { return "#" + Math.floor(Math.random() * (256 * 256 * 256 - 1)).toString(16); }
                        //     },
                        // },
                    }
                ]
            }
        }
    },
    methods: {
        async getData() {
            this.loading = true
            this.option.yAxis.data = []
            this.option.series[0].data = []
            const resp = await this.$http.get('/components/charts/dashboard/danweipaihang')
            resp.data.sort((a, b) => { return a.value - b.value })
            resp.data.map(e => {
                this.option.yAxis.data.push(e.name)
                this.option.series[0].data.push(e.value)
            })
            // this.$refs['chart'].setOption(this.option)
            this.loading = false
        }
    },
    mounted() {
        this.getData().then(() => {
            this.$nextTick(() => {
                if (this.$refs.chart)
                    this.$refs.chart.resize()
            })
            window.addEventListener('resize', () => {
                if (this.$refs && this.$refs.chart) {
                    this.$refs.chart.resize()
                }
            })
        })
    }
};
</script>

<style scoped>
.chart {
    width: 100%;
    height: 522px;
}

.title {
    text-align: center;
}
</style>
