<template>
    <zk-search-form-inline :form="form" @search="$emit('search', form)">
        <el-form-item label="机构">
            <select-hospital v-model="form.hospital_id" />
        </el-form-item>
        <el-form-item label="体检编号">
            <el-input v-model.trim="form.code" clearable></el-input>
        </el-form-item>
        <el-form-item label="姓名">
            <el-input v-model.trim="form.name" clearable></el-input>
        </el-form-item>
        <el-form-item label="体检日期">
            <el-date-picker v-model="form.daterange" size="small" type="daterange"
                value-format="yyyy-MM-dd" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期">
            </el-date-picker>
        </el-form-item>
    </zk-search-form-inline>
</template>

<script>
export default {
    data() {
        return {
            form: {}
        }
    }
}
</script>

