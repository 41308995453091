<template>
    <zk-admin-table title="预约记录" :loading="loading" :table="table" @change="handleTableChange" @select="handleTableSelect">
        <template v-slot:buttons>
            <el-row style="float: right">
                <!-- <el-button size="mini" @click="testRead">读二维码</el-button> -->
                <btn-read-id-card @result="readCard" v-if="permission.appoint_readcard" />
                <el-button size="mini" icon="el-icon-search" circle @click="searchVisible = !searchVisible"></el-button>
                <el-button size="mini" icon="el-icon-refresh" circle @click="getData"></el-button>
                <el-button size="mini" icon="el-icon-download" circle @click="handleExport" :loading="loading"
                    v-if="permission.appoint_export"></el-button>
            </el-row>
        </template>
        <template v-slot:search>
            <search-form :form="search" @search="handleSearch" v-show="searchVisible" />
        </template>
        <el-table-column type="index" align="center"
            :index="(index) => { return (this.table.page - 1) * this.table.pageSize + index + 1 }" width="55" />
        <el-table-column type="expand" width="25">
            <template slot-scope="props">
                <el-tabs v-model="activeName" style="margin-left:50px">
                    <el-tab-pane label="基本信息" name="basic">
                        <div>预约来源：{{ props.row.source }}</div>
                        <div>体检机构：{{ props.row.hospital }}</div>
                        <div>体检费用：{{ props.row.fee }}</div>
                        <div>接害因素：{{ props.row.JHYS }}</div>
                    </el-tab-pane>
                    <el-tab-pane label="必选项目" name="second">
                        <el-table :data="props.row.project.bixuan">
                            <el-table-column type="index" width="55" />
                            <el-table-column prop="name" label="名称" width="280" />
                            <el-table-column prop="fee" label="费用" width="180" />
                        </el-table>
                    </el-tab-pane>
                    <el-tab-pane label="自选项目" name="first">
                        <el-table :data="props.row.project.zixuan">
                            <el-table-column type="index" width="55" />
                            <el-table-column prop="name" label="名称" width="280" />
                            <el-table-column prop="fee" label="费用" width="180" />
                        </el-table>
                    </el-tab-pane>
                    <el-tab-pane label="职业检项目" name="three" v-if="props.row.project">
                        <el-table :data="props.row.project.zhiye">
                            <el-table-column type="index" width="55" />
                            <el-table-column prop="name" label="名称" width="280" />
                            <el-table-column prop="fee" label="费用" width="180" />
                        </el-table>
                    </el-tab-pane>
                    <el-tab-pane label="操作" name="option">
                        <el-popconfirm title="确定要设置此条记录为收费状态吗？" @confirm="handleSetStatus(props.row, 1)">
                            <el-button slot="reference" type="warning" plain size="mini"
                                style="margin-left:10px">补费</el-button>
                        </el-popconfirm>
                        <el-popconfirm :title="`确定要给 ${props.row.name} 退费吗？`" @confirm="handleDelete(props.row)">
                            <el-button slot="reference" type="danger" plain size="mini"
                                style="margin-left:10px">退费</el-button>
                        </el-popconfirm>
                    </el-tab-pane>
                </el-tabs>
            </template>
        </el-table-column>
        <el-table-column label="体检时间" width="100">
            <template slot-scope="scope">
                {{ scope.row.date }}
                <div style="font-size:90%">{{ scope.row.time }}</div>
            </template>
        </el-table-column>
        <el-table-column label="姓名" prop="name" width="170">
            <template slot-scope="scope">
                {{ scope.row.name }}
                <small>{{ scope.row.XB }} {{ scope.row.NL }}岁 {{ scope.row.ZGLX }}</small>
                <div style="font-size:90%">{{ scope.row.idnum }}</div>
            </template>
        </el-table-column>
        <el-table-column label="费用信息" width="150">
            <template slot-scope="scope">
                <div>{{ scope.row.fee_total }}</div>
                <small>{{ scope.row.fee }}+{{ scope.row.fee_zhiye }}+{{ scope.row.fee_nvgong }}</small>
            </template>
        </el-table-column>
        <el-table-column label="单位" width="240">
            <template slot-scope="scope">
                {{ scope.row.EJDW }}
                <div style="font-size:90%">{{ scope.row.SJDW }}</div>
            </template>
        </el-table-column>
        <el-table-column label="体检项目" width="200">
            <template slot-scope="scope">
                <el-popover placement="top" width="" trigger="click" v-if="scope.row.project.taocan"
                    :content="`${scope.row.project.taocan.name} - ${parseFloat(scope.row.project.taocan.feeZhehou) + 340}元`">
                    <el-tag slot="reference" effect="plain" size="small" type="primary" v-if="scope.row.project.taocan">
                        {{ scope.row.project.taocan.name }}
                    </el-tag>
                </el-popover>
                <el-popover placement="right" title="自选套餐" width="400" trigger="click"
                    v-if="scope.row.project.zixuan.length > 0">
                    <el-table :data="scope.row.project.zixuan" size="small" width="400" height="300">
                        <el-table-column width="" property="name" label="名称"></el-table-column>
                        <el-table-column width="100" property="fee" label="金额"></el-table-column>
                    </el-table>
                    <div style="padding-top:10px;text-align:center">实收：{{ scope.row.fee }}</div>
                    <el-tag slot="reference" type="warning" size="small" effect="plain">
                        自选套餐
                    </el-tag>
                </el-popover>
                <el-popover placement="right" title="职业体检" width="400" trigger="click"
                    v-if="scope.row.project.zhiye && scope.row.project.zhiye.length > 0">
                    <el-table :data="scope.row.project.zhiye" size="small" width="400" heigth="300">
                        <el-table-column width="" property="name" label="名称"></el-table-column>
                        <el-table-column width="100" property="feeHeji" label="金额"></el-table-column>
                    </el-table>
                    <div style="padding-top:10px;text-align:center">合计：{{ scope.row.fee_zhiye }}</div>
                    <el-tag slot="reference" type="success" size="small" effect="plain">
                        职业体检
                    </el-tag>
                </el-popover>
                <el-popover placement="top" width="" trigger="click" v-if="scope.row.project.nvgong"
                    :content="`${scope.row.project.nvgong.name} - 181元`">
                    <el-tag slot="reference" effect="plain" type="danger" size="small">
                        {{ scope.row.project.nvgong.name }}
                    </el-tag>
                </el-popover>
            </template>
        </el-table-column>
        <el-table-column label="时间" width="">
            <template slot-scope="scope">
                <div style="font-size:90%">创建时间：{{ scope.row.created | formatTime }}</div>
                <div style="font-size:90%" v-if="scope.row.payTime">收费时间：{{ scope.row.payTime | formatTime }}</div>
            </template>
        </el-table-column>
        <!-- <el-table-column label="状态" width="100">
            <template slot-scope="scope">
                <el-tag size="small" type="info" effect="dark" v-if="scope.row.status == -1">已取消</el-tag>
                <el-tag size="small" type="success" effect="dark" v-if="scope.row.status == 0">已预约</el-tag>
                <el-tag size="small" type="danger" effect="dark" v-if="scope.row.status == 1">已收费</el-tag>
            </template>
        </el-table-column> -->

        <!-- <el-table-column :align="v.align || 'left'" v-for="(v, idx) in table.columns" :prop="v.field" :label="v.title"
            :width="v.width" :formatter="v.formatter" :key="idx">
        </el-table-column> -->
        <el-table-column align="center" width="200" label="操作" fixed="right" v-if="permission.appoint_option">
            <template slot-scope="scope">
                <!-- <el-button type="primary" plain size="mini" @click="handlePrint(scope.row)">
                    打印小票
                </el-button> -->
                <el-button v-if="scope.row.status == 0" type="primary" plain size="mini" @click="handlePay(scope.row)">
                    确认收费
                </el-button>
                <el-button v-if="scope.row.status == 1" type="primary" plain size="mini" @click="handlePrint(scope.row)">
                    打印小票
                </el-button>
                <template v-if="[-1, 1].includes(scope.row.status)">
                    <el-popconfirm title="确定设置此条记录为已预约状态吗？" @confirm="handleReset(scope.row)">
                        <el-button slot="reference" type="danger" plain size="mini"
                            style="margin-left:10px">重置状态</el-button>
                    </el-popconfirm>
                </template>
            </template>
        </el-table-column>
        <template v-slot:dialog>
            <print-form :visible="dialogVisible" :form="form" @close="dialogClose" @update="getData" />
        </template>
    </zk-admin-table>
</template>

<script>
import PrintForm from './widgets/printForm'
import SearchForm from './widgets/searchForm'
import data from './data'

export default {
    name: 'task-appoint',
    path: '/main/task/appoint',
    components: {
        PrintForm,
        SearchForm
    },
    data() {
        let today = new Date().format('yyyy-MM-dd')
        return {
            loading: false,
            table: data.table,
            activeName: 'basic',
            dialogVisible: false,
            searchVisible: true,
            downloadVisible: false,
            search: { idnum: '', status: 0, ejdw_id: null, sjdw_id: null, daterange: [today, today] },
            form: {},
            isNew: false,
            multipleSelection: [],
            permission: {}
        }
    },
    filters: {
        formatTime(t) {
            return t.toString().slice(0, 19)
        }
    },
    methods: {
        readCard(e) {
            if (e.ret == 1) {
                this.search.idnum = e.data.code
                this.getData()
            }
            else
                this.$message.error(e.msg)
        },
        dialogClose() {
            this.dialogVisible = false
            // this.getData()
        },
        handleSearch(e) {
            this.search = e
            this.table.page = 1
            this.getData()
        },
        handleTableChange(e) {
            this.table = e
            this.getData()
        },
        handleTableSelect(val) {
            this.multipleSelection = val
        },
        async handleMultiUpdate() {
            if (this.multipleSelection.length !== 1) {
                this.$message.error('请选择一条记录')
                return
            }
            this.handleUpdate(this.multipleSelection[0])
        },
        async handleUpdate(row) {
            this.isNew = false
            // var temp = JSON.stringify(row)
            // this.form = JSON.parse(temp)
            this.form = row
            this.dialogVisible = true
        },
        handlePrint(row) {
            this.form = row
            this.dialogVisible = true
        },
        handlePay(row) {
            this.$http.post('/task/appoint/pay', row)
                .then(resp => {
                    if (resp.data.code == 200) {
                        this.$message.success(resp.data.msg)
                        this.handlePrint(row)
                        this.getData()
                    } else {
                        this.$message.error(resp.data.msg)
                    }
                }).catch((err) => {
                    this.$message.error(err.message)
                })
        },
        handleSetStatus(row, status) {
            this.$http.put(`/task/appoint/record/${row.id}/status`, {status})
                .then(resp => {
                    if (resp.data.code == 200)
                        this.$message.success(resp.data.msg)
                    else
                        this.$message.error(resp.data.msg)
                    this.getData()
                }).catch((err) => {
                    this.$message.error(err.message)
                })
        },
        handleDelete(row) {
            this.$http.delete(`/task/appoint/record/${row.id}`)
                .then(resp => {
                    if (resp.data.code == 200)
                        this.$message.success(resp.data.msg)
                    else
                        this.$message.error(resp.data.msg)
                    this.getData()
                }).catch((err) => {
                    this.$message.error(err.message)
                })
        },
        handleReset(row) {
            this.$http.post('/task/appoint/reset', row)
                .then(resp => {
                    if (resp.data.code == 200)
                        this.$message.success(resp.data.msg)
                    else
                        this.$message.error(resp.data.msg)
                    this.getData()
                }).catch((err) => {
                    this.$message.error(err.message)
                })
        },
        async handleExport() {
            this.loading = true
            try {
                let params = JSON.parse(JSON.stringify(this.search))
                params.export = 'xlsx'//'csv'
                const resp = await this.$http.get(`/task/appoint/record?page=${this.table.page}&pageSize=${this.table.pageSize}`, { params: params, responseType: 'blob' })
                let blob = new Blob([resp.data], { type: 'application/vnd.openxmlformats;charset=utf-8;' })
                var link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = decodeURI(escape(resp.headers['content-disposition'].split('=')[1]))
                link.click()
                window.URL.revokeObjectURL(link.href)
            } catch (e) {
                this.$message.error(e.message)
            }
            this.loading = false
        },
        async getData() {
            this.loading = true
            try {
                const resp = await this.$http.get(`/task/appoint/record?page=${this.table.page}&pageSize=${this.table.pageSize}`, { params: this.search })
                if (!resp.data) {
                    this.$message.error(resp.data.msg)
                } else {
                    this.table.data = resp.data.data.records
                    this.table.total = resp.data.data.total
                }
            } catch (e) {
                this.$message.error(e.message)
            }
            this.loading = false
        },
        async getPermission() {
            const resp = await this.$http.get('/permission')
            this.permission = resp.data
        }
    },
    mounted() {
        this.getPermission()
        this.getData()
    },
    destroyed() {
    }
}
</script>

<style scoped>
.el-tag {
    margin: 2px;
}
</style>