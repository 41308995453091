<template>
    <el-dialog title="批量迁移" :visible="visible" width="60%" @close="close">
        <div style="margin-top: -19px">
            <div>
                <el-button size="mini" type="primary" style="float: right;"
                    @click="importVisible = true">按身份证号导入</el-button>
                人员名单：({{ this.form.staff_ids.length }})
            </div>
            <div style="margin: 10px 0">
                <el-select v-model="form.staff_ids" multiple placeholder="请选择" style="width: 100%">
                    <el-option v-for="item in options" :key="item.id" :label="item.XM" :value="item.id">
                    </el-option>
                </el-select>
            </div>
            <div>
                <el-form inline>
                    <el-form-item label="二级单位：" prop="ejdw_id" clearable
                        :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                        <select-subsidiary-2 v-model="form.ejdw_id" />
                    </el-form-item>
                    <el-form-item label="三级单位：" prop="sjdw_id" clearable
                        :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                        <select-subsidiary-3 :ejdw_id="form.ejdw_id" v-model="form.sjdw_id" />
                    </el-form-item>
                    <el-form-item label="四级单位：" prop="fjdw_id" clearable>
                        <select-subsidiary-4 :sjdw_id="form.sjdw_id" v-model="form.fjdw_id" />
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="close">取 消</el-button>
            <el-button @click="formSubmit" type="primary" :loading="loading" :disabled="loading">批量迁移</el-button>
        </span>
        <el-dialog title="导入" :visible="importVisible" width="60%" @close="importVisible = false" append-to-body>
            <el-form label-width="100px" class="demo-ruleForm" v-loading="loading">
                <el-form-item label="身份证" prop="importValue">
                    <el-input v-model="importValue" type="textarea" clearable :autosize="{ minRows: 8, maxRows: 8 }"
                        style="width:100%"></el-input>
                    <div style="color: #999999"><i class="el-icon-warning"></i><small>请一行输入一个身份证号！</small>
                        <el-button type="primary" size="mini" @click="getSFZHs">自动提取身份证号</el-button>
                    </div>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="importVisible = false">取 消</el-button>
                <el-button @click="importSubmit" type="primary" :loading="loading" :disabled="loading">导入</el-button>
            </span>
        </el-dialog>
    </el-dialog>
</template>

<script>
export default {
    props: {
        visible: {
            default: false
        },
        data: {
            default: () => []
        }
    },
    data() {
        return {
            loading: false,
            importVisible: false,
            importValue: '',
            options: this.data || [],
            form: {
                staff_ids: []
            }
        }
    },
    watch: {
        data(val) {
            this.options = [...val]
            this.form.staff_ids = this.options.map(e => e.id) || []
        }
    },
    methods: {
        getSFZHs() {
            const regex = /\b\d{17}[\dXx]\b/g;
            const matches = this.importValue.match(regex);

            if (matches) {
                this.importValue = matches.join('\n');
            }
        },
        async importSubmit() {
            const resp = await this.$http.post(`/company/staff/importBySFZH`, { ids: this.importValue })

            const existingIds = new Set();
            this.options = [...this.options, ...resp.data.data].filter(item => {
                if (!existingIds.has(item.id)) {
                    existingIds.add(item.id);
                    return true;
                }
                return false;
            });
            this.options = resp.data.data
            this.form.staff_ids = [...existingIds]
            this.importVisible = false
        },
        async formSubmit() {
            if (!this.form.sjdw_id) return
            const resp = await this.$http.post('/company/subsidiary/trans', this.form)
            if (resp.data.code != 200) {
                this.$message.error(resp.data.msg)
            } else {
                this.$message.success('保存成功')
                this.$emit('update')
                this.$emit('close')
            }
            // this.$emit('close')
        },
        close() {
            this.$emit('close')
        }
    }
}
</script>

<style scoped></style>
