<template>
    <div>
        <el-page-header @back="$router.go(-1)" :content="title" />
        <div style="float:right">
            <!-- <el-button size="mini" :loading="export_loading" @click="exportXLSX">导出excel</el-button> -->
            <el-button size="mini" @click="getData">刷新</el-button>
        </div>
        <el-table :data="table.tableData" style="width: 100%" v-loading="loading">
            <el-table-column prop="hospital_name" label="体检机构" width="180" />
            <el-table-column prop="ejdw" label="二级单位" width="140" />
            <el-table-column prop="sjdw" label="三级单位" width="" />
            <el-table-column prop="XM" label="姓名" width="180" />
            <el-table-column prop="gender" label="性别" width="80" />
            <el-table-column prop="age" label="年龄" width="80" />
            <el-table-column prop="jdrq" label="体检时间" width="80" />
            <el-table-column prop="keyword" label="关键词" />
        </el-table>
    </div>
</template>

<script>
export default {
    name: 'statistics-analysis-compose-view',
    path: '/main/statistics/analysis_compose/view',
    data() {
        return {
            loading: false,
            export_loading:false,
            title: '疾病分析',
            search: {},
            form: {},
            table: {
                loading: false,
                page: 1,
                pageSize: 20,
                total: 0,
                tableData: []
            }
        }
    },
    methods: {
        async exportXLSX() {
            this.export_loading = true
            let params = {export: 'xlsx'}
            const resp = await this.$http.get(`/analysis/analysis_compose/view?name=${this.$route.query.name}`, {params: params, responseType: 'blob'})
            let blob = new Blob([resp.data], {type: 'application/vnd.openxmlformats;charset=utf-8;'})
            var link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = decodeURI(escape(resp.headers['content-disposition'].split('=')[1]))
            link.click()
            window.URL.revokeObjectURL(link.href)
            this.export_loading = false
        },
        async getData() {
            this.table.tableData = []
            this.loading = true
            const resp = await this.$http.get(`/analysis/analysis_compose/view?name=${this.$route.query.name}`)
            this.table.tableData = resp.data.data
            this.loading = false
        }
    },
    mounted() {
        this.title = `疾病分析 - ${this.$route.query.name}`
        this.getData()
    }
}
</script>

<style lang="scss" scoped>
.form {
    margin: 10px auto;
    text-align: center;
    max-width: 380px;

    .title {
        padding: 20px;
        font-weight: bold;
        color: #4f9fe7;
    }
}
</style>