<template>
    <el-card class="box-card" shadow="hover">
        <div slot="header" class="clearfix">
            <span>热门健康体检套餐</span>
            <div style="float:right">
                <el-button style="padding: 3px 0" type="text" @click="getData" icon="el-icon-refresh"></el-button>
            </div>
        </div>
        <v-chart ref="chart" class="chart" :option="option" v-loading="loading" />
    </el-card>
</template>

<script>
export default {
    name: "statAppointXiangmuJiankang",
    props: {
        search: {
            type: [Object],
            default: {}
        }
    },
    data() {
        return {
            loading: false,
            form: {},
            option: {
                tooltip: {
                    trigger: 'item',
                    formatter: '{b} : {c}'
                },
                grid: {
                    top: '1%',
                    left: '1%',
                    right: '3%',
                    bottom: '1%',
                    containLabel: true
                },
                xAxis: {
                    type: 'value'
                },
                yAxis: {
                    type: 'category',
                    axisLabel: {
                        interval: 0
                    },
                    data: []
                },
                series: [
                    {
                        data: [],
                        type: 'bar',
                        itemStyle: {
                            color: '#1890ff'
                        }
                    }
                ]
            }
        }
    },
    methods: {
        async getData() {
            this.loading = true
            this.option.yAxis.data = []
            this.option.series[0].data = []
            const resp = await this.$http.get('/components/charts/statistics/appoint/xiangmujiankang', {params: this.search})
            resp.data.sort((a, b) => { return a.value - b.value })
            resp.data.map(e => {
                this.option.yAxis.data.push(e.taocan)
                this.option.series[0].data.push(e.count)
            })
            this.$refs['chart'].setOption(this.option)
            this.loading = false
        }
    },
    mounted() {
        this.getData().then(() => {
            this.$nextTick(() => {
                this.$refs.chart.resize()
            })
            window.addEventListener('resize', () => {
                if (this.$refs && this.$refs.chart) {
                    this.$refs.chart.resize()
                }
            })
        })
    }
};
</script>

<style scoped>
.chart {
    width: 100%;
    height: 400px;
}

.title {
    text-align: center;
}
</style>
