<template>
    <zk-admin-table title="冻结人员管理" :loading="loading" :table="table" @change="handleTableChange"
        @select="handleTableSelect">
        <template v-slot:buttons>
            <el-row style="float: right">
                <el-button size="mini" icon="el-icon-search" circle @click="searchVisible = !searchVisible"></el-button>
                <el-button size="mini" icon="el-icon-refresh" circle @click="getData"></el-button>
                <el-button size="mini" icon="el-icon-plus" type="primary" @click="handleAdd">添加</el-button>
                <el-button size="mini" icon="el-icon-edit-outline" @click="handleUpdate">编辑</el-button>
                <!-- <el-button size="mini" icon="el-icon-minus" type="danger" @click="handleJiedong">解除冻结</el-button> -->
            </el-row>
        </template>

        <template v-slot:search>
            <zk-search-form-inline :form="search" @search="getData" v-show="searchVisible">
                <el-form-item label="" prop="ejdw_id" clearable>
                    <select-subsidiary-2 v-model="search.ejdw_id" />
                </el-form-item>
                <el-form-item label="" prop="sjdw_id" clearable>
                    <select-subsidiary-3 :ejdw_id="search.ejdw_id" v-model="search.sjdw_id" />
                </el-form-item>
                <el-form-item label="">
                    <el-select v-model="search.DJYY" placeholder="请选择冻结原因" clearable>
                            <el-option label="本人自愿不参加体检" value="本人自愿不参加体检"></el-option>
                            <el-option label="内退" value="内退"></el-option>
                            <el-option label="歇业不在庆" value="歇业不在庆"></el-option>
                            <el-option label="退二线不在庆" value="退二线不在庆"></el-option>
                            <el-option label="因病行动不便" value="因病行动不便"></el-option>
                            <el-option label="因公驻外" value="因公驻外"></el-option>
                            <el-option label="退休" value="退休"></el-option>
                            <el-option label="死亡" value="死亡"></el-option>
                            <el-option label="解除劳动合同" value="解除劳动合同"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="">
                    <el-select v-model="search.SFTJ" placeholder="请选择是否体检" clearable>
                        <el-option label="已体检" value="已体检"></el-option>
                        <el-option label="未体检" value="未体检"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="姓名">
                    <el-input v-model.trim="search.name" clearable></el-input>
                </el-form-item>
                <el-form-item label="身份证号">
                    <el-input v-model.trim="search.SFZH" clearable></el-input>
                </el-form-item>
            </zk-search-form-inline>
        </template>
        <el-table-column type="selection" width="55" align="center" />
        <el-table-column label="单位" prop='hospital' width="200">

            <template slot-scope="scope">
                <div>{{ scope.row.EJDW }}</div>
                <small>{{ scope.row.SJDW }} {{ scope.row.FJDW }}</small>
            </template>
        </el-table-column>
        <el-table-column label="人员信息" width="180">
            <template slot-scope="scope">
                <div>{{ scope.row.XM }} <small>{{ scope.row.XB }} {{ scope.row.NL }}岁</small></div>
                <small> {{ isFullIdCard[scope.$index] ? scope.row.SFZH : maskIdCardRegex(scope.row.SFZH) }}</small>
                <i class="el-icon-view" style="cursor: pointer;padding-left: 10px"
                    @click="toggleIdCard(scope.$index)"></i>
            </template>
        </el-table-column>
        <el-table-column label="职工信息" width="100">

            <template slot-scope="scope">
                <div>{{ scope.row.ZGLX }}</div>
                <small>档案年份: {{ scope.row.DANF }}</small>
            </template>
        </el-table-column>
        <el-table-column label="体检费" width="100" align="left">

            <template slot-scope="scope">
                <div>{{ scope.row.fee }}</div>
                <small v-if="scope.row.TJLX_NGJS">{{ scope.row.XB == '女' ? '女工' : '家属' }}:181.00</small>
            </template>
        </el-table-column>
        <el-table-column label="冻结原因" prop="DJYY"></el-table-column>
        <el-table-column label="是否体检" prop="SFTJ"></el-table-column>
        <el-table-column label="冻结时间" prop="DJSJ"></el-table-column>
        <!-- <el-table-column label="备注" prop="remarks"></el-table-column> -->
        <el-table-column align="center" width="100" label="操作">

            <template slot-scope="scope">
                <el-button type="danger" size="mini" @click="handleJiedong(scope.row)">解除冻结</el-button>
            </template>
        </el-table-column>

        <template v-slot:dialog>
            <el-dialog title="冻结" :visible="dialogVisible" width="60%" @close="dialogClose">
                <el-form :model="form" ref="ruleForm" label-width="100px" class="demo-ruleForm" v-loading="loading">
                    <el-form-item label="身份证" prop="SFZH" :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                        <el-input v-model="form.SFZH" type="textarea" clearable :autosize="{ minRows: 8, maxRows: 8 }"
                            style="width:80%"></el-input>
                        <div style="color: #999999"><i class="el-icon-warning"></i><small>请一行输入一个身份证号！</small>
                            <el-button type="primary" size="mini" @click="getSFZHs">自动提取身份证号</el-button>
                        </div>
                    </el-form-item>
                    <el-form-item label="冻结原因" prop="DJYY"
                        :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                        <el-select v-model="form.DJYY" placeholder="请选择" clearable style="width:80%">
                            <el-option label="本人自愿不参加体检" value="本人自愿不参加体检"></el-option>
                            <el-option label="内退" value="内退"></el-option>
                            <el-option label="歇业不在庆" value="歇业不在庆"></el-option>
                            <el-option label="退二线不在庆" value="退二线不在庆"></el-option>
                            <el-option label="因病行动不便" value="因病行动不便"></el-option>
                            <el-option label="因公驻外" value="因公驻外"></el-option>
                            <el-option label="退休" value="退休"></el-option>
                            <el-option label="死亡" value="死亡"></el-option>
                            <el-option label="解除劳动合同" value="解除劳动合同"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="是否体检" prop="SFTJ"
                        :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                        <el-radio-group v-model="form.SFTJ">
                            <el-radio label="已体检"></el-radio>
                            <el-radio label="未体检"></el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item>
                        <div style="text-align: right">
                            <el-button type="" @click="dialogClose">取 消</el-button>
                            <el-button type="primary" @click="handleSubmit">提 交</el-button>
                        </div>
                    </el-form-item>
                </el-form>
            </el-dialog>
        </template>
    </zk-admin-table>
</template>

<script>
export default {
    name: 'company-dongjie',
    path: '/main/company/dongjie',
    data() {
        return {
            loading: false,
            dialogVisible: false,
            searchVisible: true,
            multipleSelection: [],
            form: {
                SFZH: '',
                DJYY: '',
                SFTJ: '未体检'
            },
            search: { source: 'appoint' },
            table: {
                data: [],
                page: 1,
                pageSize: 20
            },
            isFullIdCard: {}
        }
    },
    methods: {
        dialogClose() {
            this.dialogVisible = false
        },
        handleSearch(e) {
            this.search = e
            this.table.page = 1
            this.getData()
        },
        handleTableChange(e) {
            this.table = e
            this.getData()
        },
        handleTableSelect(val) {
            this.multipleSelection = val
        },
        handleAdd() {
            this.dialogVisible = true
        },
        getSFZHs() {
            const regex = /\b\d{17}[\dXx]\b/g;
            const matches = this.form.SFZH.match(regex);

            if (matches) {
                this.form.SFZH = matches.join('\n');
            }
        },
        async handleUpdate() {
            if (this.multipleSelection.length !== 1) {
                this.$message.error('请选择一条记录')
                return
            }
            let row = this.multipleSelection[0]
            this.form = JSON.parse(JSON.stringify(row))
            this.dialogVisible = true
        },
        async handleJiedong(row) {
            try {
                const confirm = await this.$confirm(`确认要解除 ${row.XM} 的冻结状态吗?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                })
                this.loading = true
                const resp = await this.$http.post(`/company/jiedong`, row)
                this.$message[resp.data.status](resp.data.msg)
                if (resp.data.status == 'success') this.getData()
            } catch (error) {
                if (error.message) this.$message.error(error.message)
            }
            this.loading = false
        },
        async handleSubmit() {
            this.loading = true
            try {
                const resp = await this.$http.post(`/company/dongjie`, this.form)
                this.$message[resp.data.status](resp.data.msg)
                if (resp.data.status == 'success') {
                    this.getData()
                    this.dialogVisible = false
                }
            } catch (error) {
                this.$message.error(error.message)
            }
            this.loading = false
        },
        async getData() {
            this.loading = true
            try {
                const resp = await this.$http.get(`/company/dongjie?page=${this.table.page}&pageSize=${this.table.pageSize}`, { params: this.search })
                if (!resp.data) {
                    this.$message.error(resp.data.msg)
                } else {
                    this.table.data = resp.data.data
                    this.table.total = resp.data.total
                }
            } catch (error) {
                this.$message.error(error.message)
            }
            this.loading = false
        },
        maskIdCardRegex(idCard) {
            return idCard.replace(/^(.{4})(.*)(.{4})$/, function (match, p1, p2, p3) {
                return p1 + p2.replace(/./g, '*') + p3;
            });
        },
        toggleIdCard(index) {
            // 切换显示状态
            this.$set(this.isFullIdCard, index, !this.isFullIdCard[index]);
        }
    },
    mounted() {
        this.search = { ...this.search, ...this.$route.query }
        this.getData()
    }
}
</script>
