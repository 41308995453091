const data = {
    table: {
        columns: [
            {title: '日期', field: 'date', width: '200'},
            {title: '时间', field: 'time', width: '180'},
            {title: '已预约', field: 'appoint_count', width: '150'},
            {title: '配额', field: 'count', width: '150'},
            {title: '备注', field: 'desc', width: ''}
        ],
        data: [],
        total: 0,
        page: 1,
        pageSize: 20
    }
}

export default data
