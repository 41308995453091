const data = {
    table: {
        columns: [
            {title: 'No.', field: 'id', width: '140'},
            {title: '机构', field: 'hospital', width: '200'},
            {title: '日志', field: 'title', width: '150'},
            {title: '数据量', field: 'count', width: '180'},
            {title: '时间', field: 'created', width: '180'},
            {title: '状态', field: 'status', width: ''}
        ],
        data: [],
        total: 0,
        page: 1,
        pageSize: 20
    }
}

export default data
