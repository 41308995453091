const data = {
    table: {
        columns: [
            // {title: 'No.', field: 'id', width: '140'},
            // { title: '体检日期', field: 'date', width: '100' },
            // { title: '体检时间', field: 'time', width: '100' },
            // { title: '姓名', field: 'name', width: '90', align: 'left' },
            // { title: '身份证号', field: 'idnum', width: '180' },
            // { title: '体检项目', field: 'project', width: '', align: 'left', formatter: projectFormatter },
            // { title: '金额', field: 'fee', width: '80', formatter: statusFormatter },
            // { title: '状态', field: 'status', width: '80', formatter: statusFormatter },
            { title: '创建时间', field: 'created', width: '220' },
            { title: '体检机构', field: 'hospital', width: '220' }
        ],
        data: [],
        total: 0,
        page: 1,
        pageSize: 20
    }
}

export default data
