<template>
    <el-dialog title="体检结果" :visible="visible" width="70%" @close="close">
        <div style="height: 60vh;overflow: auto;" v-loading="loading">
            <div style="display: flex;flex-wrap: wrap; align-content: space-between;align-items: flex-end;">
                <div style="padding: 20px 20px 20px 0;font-size: 120%;font-weight: bold;">{{ form.jcxx.XM|hide_name }}</div>
                <div style="padding: 20px">{{ form.jcxx.XB }}</div>
                <div style="padding: 20px">{{ form.jcxx.NL }}岁</div>
                <div style="padding:20px;flex:1;text-align: right">体检类型：{{ form.jcxx.TJLX }}</div>
            </div>
            <div
                style="padding-bottom: 10px;display: flex;flex-wrap: wrap; align-content: space-between;align-items: flex-end;">
                <div>体检套餐： {{ form.jcxx.TCMC }}</div>
                <div style="flex:1;text-align:right">体检时间：{{ form.jcxx.TJRQ }}</div>
            </div>
            <div>
                <!-- 体检项目： -->
                <!-- <el-tag style="margin: 2px" type="info" size="mini" v-for="(e, i) in report.ycxm" :key="i">
                    {{ e.name }}
                </el-tag> -->
            </div>
            <!-- <div>完成科室: {{ report.jcxx.wcks }}</div> -->
            <el-tabs v-model="activeName">
                <el-tab-pane label="体检结论" name="JL">
                    <div>
                        <div v-html="form.jcxx.JL || ''" style="white-space:pre-wrap" />
                    </div>
                </el-tab-pane>
                <el-tab-pane label="体检建议" name="JY">
                    <div>
                        <div v-html="form.jcxx.JY || ''" style="white-space:pre-wrap" />
                    </div>
                </el-tab-pane>
            </el-tabs>
        </div>
        <span slot="footer" class="dialog-footer">
            <!-- <el-button @click="close">{{ $t('form.cancel') }}</el-button> -->
            <el-button @click="close" type="primary" :loading="loading" :disabled="loading">{{ $t('form.ok') }}
            </el-button>
        </span>
    </el-dialog>
</template>
<script>
export default {
    props: {
        visible: {
            default: false
        },
        form: {
        },
        loading: {
            default: false
        }
    },
    data() {
        return {
            activeName: 'JL'
        }
    },
    filters: {
        hide_name(value) {
            return value.substring(0, 1) + new Array(value.length).join('*')
        }
    },
    methods: {
        close() {
            this.$emit('close')
        }
    }
}
</script>

<style scoped>
::v-deep .el-dialog__body {
    padding: 0 20px;
}
</style>
