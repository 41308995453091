<template>
    <div>
        <!-- <el-alert title="当前为演示页面，内容非真实数据" type="warning" show-icon style="margin-bottom: 10px" /> -->
        <div>
            <div style="float:right">
                <el-button size="mini" type="default" plain @click="exportXLSX" :loading="loading">导出excel</el-button>
            </div>
            <el-page-header @back="$router.go(-1)" content="费用代扣审批" />
        </div>
        <el-table :data="tableData" border style="width: 100%;margin-top:20px">
            <el-table-column type="index" width="50" />
            <el-table-column prop="EJDW" label="二级单位" width="180" />
            <el-table-column prop="SJDW" label="三级单位" width="180" />
            <el-table-column prop="name" label="姓名" width="80" />
            <el-table-column prop="idnum" label="身份证号" width="180" />
            <el-table-column prop="date" label="体检日期" width="110" />
            <el-table-column prop="time" label="体检时间" width="110" />
            <el-table-column prop="package" label="体检项目" width="180" />
            <el-table-column prop="fee" label="费用" width="180" />
            <el-table-column prop="created" label="创建时间" width="210" />
            <el-table-column align="center" width="220" label="操作" fixed="right">
                <template slot-scope="scope">
                    <el-button type="success" size="mini" @click="handleUpdate(scope.row)">
                        审核通过
                    </el-button>
                    <el-button type="danger" size="mini" @click="handleUpdate(scope.row)">
                        审核不通过
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-dialog title="编辑" :visible.sync="dialogVisible" width="60%">
            <el-form ref="form" :model="form" label-width="80px">
                <el-form-item label="人员" prop="packages">
                    <el-table border ref="multipleTable" :data="userData" tooltip-effect="dark" style="width: 100%;"
                        height="250" @selection-change="handleSelectionChange">
                        <el-table-column type="selection" width="55" align="center" />
                        <el-table-column prop="name" label="二级单位" width="120" />
                        <el-table-column prop="gender" label="三级单位" align="center" width="100"
                            :filters="[{ text: '男', value: '男' }, { text: '女', value: '女' }]" :filter-method="filterHandler"
                            filter-placement="bottom-end" />
                        <el-table-column prop="name" label="姓名" width="120" />
                        <el-table-column prop="name" label="身份证号" width="120" />
                        <el-table-column prop="age" label="年龄" align="center" width="100" />
                        <el-table-column prop="age" label="性别" align="center" width="100" />
                        <el-table-column prop="desc" label="备注" show-overflow-tooltip />
                    </el-table>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="submit">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: 'fee-daikoushenpi',
    path: '/main/fee/daikoushenpi',
    data() {
        return {
            active: 1,
            loading: false,
            dialogVisible: false,
            search: {},
            tableData: [],
            form: { users: [] },
            userData: []
        }
    },
    methods: {
        handleUpdate(row) {
            this.$message.info('状态异常，稍后重试')
        },
        handleSelectionChange() {

        },
        filterHandler() {

        },
        submit() {
            this.dialogVisible = false
        },
        async exportXLSX() {
            this.loading = true
            let params = JSON.parse(JSON.stringify(this.search))
            params.export = 'xlsx'
            const resp = await this.$http.get(`/fee/daikou/shenpi?page=1&pageSize=20`, { params: params, responseType: 'blob' })
            let blob = new Blob([resp.data], { type: 'application/vnd.openxmlformats;charset=utf-8;' })
            var link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = decodeURI(escape(resp.headers['content-disposition'].split('=')[1]))
            link.click()
            window.URL.revokeObjectURL(link.href)
            this.loading = false
        },
        async getData() {
            this.loading = true
            const resp = await this.$http.get('/fee/daikou/shenpi?page=1&pageSize=20', { params: this.search })
            if (!resp.data) {
                this.$message.error(resp.data.msg)
            } else {
                this.tableData = resp.data.data
            }
            this.loading = false
        }
    },
    mounted() {
        this.getData()
    }
}
</script>

<style scoped>
.notfound {
    padding: 40px 0;
    text-align: left;
}
</style>