<template>
    <el-dialog :visible="visible" width="30%" @close="handleClose" class="dialog" :title="title">
        <div style="display: flex;justify-content: center;" id="printTest">
            <div class="text">
                <div class="hospital"><span>{{ form.hospital || '收费信息' }}</span></div>
                <div><span>日期：</span>{{ form.date }} {{ form.time }}</div>
                <div><span>姓名：</span>{{ form.name }}</div>
                <div><span>身份证：</span>{{ form.idnum }}</div>
                <div><span>单位：</span>油田公司{{ form.EJDW }}{{ form.SJDW }}</div>
                <div class="project" v-if="form.project">
                    <el-row>
                        <el-col :span="24"><span>健康检项目:</span></el-col>
                    </el-row>
                    <div v-if="form.project.taocan">{{ form.project.taocan.name }}</div>
                    <div v-if="form.project.zixuan && form.project.zixuan.length > 0">
                        <div v-for="(e, i) in form.project.zixuan" :key="i">{{ e.name }}</div>
                    </div>
                </div>
                <div style="padding-bottom:30px"><span>金额：</span>￥{{ form.fee }}</div>

                <div v-if="form.project && form.project.zhiye && form.project.zhiye.length > 0" style="padding-bottom:30px">
                    <div class="hospital"><span>{{ form.hospital || '收费信息' }}</span></div>
                    <div><span>日期：</span>{{ form.date }} {{ form.time }}</div>
                    <div><span>姓名：</span>{{ form.name }}</div>
                    <div><span>身份证：</span>{{ form.idnum }}</div>
                    <div><span>单位：</span>油田公司{{ form.EJDW }}{{ form.SJDW }}</div>
                    <div class="project">
                        <el-row>
                            <el-col :span="24"><span>职业检项目:</span></el-col>
                        </el-row>
                        <div>
                            <div v-for="(e, i) in form.project.zhiye" :key="i">{{ e.name }}</div>
                        </div>
                    </div>
                    <div><span>金额：</span>￥{{ form.fee_zhiye }}</div>
                </div>

                <div v-if="form.project && form.project.nvgong">
                    <div class="hospital"><span>{{ form.hospital || '收费信息' }}</span></div>
                    <div><span>日期：</span>{{ form.date }} {{ form.time }}</div>
                    <div><span>姓名：</span>{{ form.name }}</div>
                    <div><span>身份证：</span>{{ form.idnum }}</div>
                    <div><span>单位：</span>油田公司{{ form.EJDW }}{{ form.SJDW }}</div>
                    <div class="project">
                        <el-row>
                            <el-col :span="24"><span>女工/家属项目:</span></el-col>
                        </el-row>
                        <div v-if="form.project.nvgong">{{ form.project.nvgong.name }}</div>
                    </div>
                    <div><span>金额：</span>￥181.00</div>
                </div>
                <div style="text-align: center; padding-top: 50px;">1</div>
            </div>
        </div>
        <div style="text-align: right">
            <el-button type="primary" size="small" v-print="'#printTest'">打印小票</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    props: {
        visible: {
            default: false
        },
        title: {
            default: '打印小票'
        },
        form: {
            default: {}
        }
    },
    data() {
        return {}
    },
    methods: {
        handleClose() {
            this.$emit('close')
        }
    }
}
</script>
<style lang="scss" scoped>
.text {
    width: 236px;
    font-size: 15px;
    font-weight: 500;

    span {
        letter-spacing: 2px
    }

    div {
        padding: 1px 0;
    }

    .project {
        text-align: left;
        border-bottom: 1px dashed #909399;
        border-top: 1px dashed #909399;
        margin: 10px 0;
    }
}

.hospital {
    font-size: 25px;
    font-weight: bold;
    text-align: center;
}
</style>
