<template>
    <zk-search-form-inline :form="form" @search="$emit('search', form)">
        <el-form-item label="体检类型">
            <el-select v-model="form.type" placeholder="请选择" clearable>
                <el-option label="健康体检" value="健康体检"></el-option>
                <el-option label="职业体检" value="职业体检"></el-option>
                <el-option label="女工/家属体检" value="女工/家属体检"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="名称">
            <el-input v-model.trim="form.name" clearable></el-input>
        </el-form-item>
    </zk-search-form-inline>
</template>

<script>
export default {
    data() {
        return {
            form: {}
        }
    }
}
</script>

