<template>
    <v-chart class="chart" :option="option" />
</template>

<script>
export default {
    name: 'lineChart',
    data() {
        return {
            option: {
                title: {
                    text: '最近预约'
                },
                tooltip: {
                    trigger: 'axis'
                },
                legend: {
                    data: ['铭德医院', '北大健康西城分院', '北大健康东城分院']
                },
                grid: {
                    left: '5%',
                    right: '14%',
                    bottom: '3%',
                    containLabel: true
                },
                toolbox: {
                    feature: {
                        saveAsImage: {}
                    }
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
                },
                yAxis: {
                    type: 'value'
                },
                series: [
                    {
                        name: '铭德医院',
                        type: 'line',
                        stack: 'Total',
                        data: [0, 0, 0, 0, 0, 0, 0]
                    },
                    {
                        name: '北大健康西城分院',
                        type: 'line',
                        stack: 'Total',
                        data: [0, 0, 0, 0, 0, 0, 0]
                    },
                    {
                        name: '北大健康东城分院',
                        type: 'line',
                        stack: 'Total',
                        data: [0, 0, 0, 0, 0, 0, 0]
                    }
                ]
            }
        }
    }
}
</script>

<style scoped>
.chart {
    width: 100%;
    height: 400px;
}
</style>
