<template>
    <el-dialog title="体检结果" :visible="visible" width="70%" @close="close">
        <el-form ref="form" :model="form" label-width="100px">
            <el-form-item label="名称" prop="name" :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                <el-input v-model.trim="form.name"></el-input>
            </el-form-item>
            <el-form-item label="检查结论" prop="med_items" :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                <div style="height: 200px; overflow: auto" v-html="report.JCJL">
                </div>
            </el-form-item>
            <el-form-item label="备注">
                <el-input type="textarea" v-model.trim="form.remarks"></el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="close">{{ $t('form.cancel') }}</el-button>
            <el-button @click="close" type="primary" :loading="loading" :disabled="loading">{{ $t('form.ok') }}
            </el-button>
        </span>
    </el-dialog>
</template>
<script>
export default {
    props: {
        visible: {
            default: false
        },
        form: {
        },
        isNew: {
            default: false
        }
    },
    data() {
        return {
            loading: false,
            report: {}
        }
    },
    methods: {
        async getData(){
            const resp = await this.$http.get(`/hospital/report/${this.form.code}`)
            console.log(resp)
        },
        close() {
            this.$emit('close')
        }
    },
    mounted() {
        // this.getData()
    }
}
</script>

<style scoped>

</style>
