<template>
    <div>
        <el-row style="margin:0" v-loading="loading">
            <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6" style="padding:10px">
                <count-card title="体检总人数" :count="counts.userCount" color="rgb(128, 176, 210)">
                    <div style="text-align: left;padding-left:20px;line-height:25px;font-weight: bold">
                        <div>未来预约人数：{{ counts.future_count }}</div>
                        <div>累计预约人数：{{ counts.appoint_count }}</div>
                        <div>体检总人数：{{ counts.user_count }}</div>
                    </div>
                </count-card>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6" style="padding:10px">
                <count-card title="已预约人数" :count="counts.appointCount0" icon="el-icon-s-shop" color="rgb(141, 210, 198)">
                    <div style="text-align: left;padding-left:20px;line-height:25px;font-weight: bold">
                        <div>已报告人数：{{ counts.report_count }}</div>
                        <div>已报告份数：{{ counts.report_count2 }}</div>
                        <div>已体检人数：{{ counts.checkup_count }}</div>
                    </div>
                </count-card>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6" style="padding:10px">
                <count-card title="费用合计" :count="counts.feeTotal" icon="el-icon-s-flag">
                    <div>费用合计</div>
                    <div class='grid-num' style="font-size: 24px">{{ counts.fee_total||0 }}元</div>
                    <div style="display:flex;margin-top:7px;">
                        <div style="padding: 5px;font-size:80%;flex:1">{{ counts.fee_jiankang||0 }}<div>健康体检</div>
                        </div>
                        <div style="padding: 5px;font-size:80%;flex:1">{{ counts.fee_zhiye||0 }}<div>职业体检</div>
                        </div>
                        <div style="padding: 5px;font-size:80%;flex:1">{{ counts.fee_nvgong||0 }}<div>女工体检</div>
                        </div>
                    </div>
                </count-card>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6" style="padding:10px">
                <count-card title="用户取消预约数" :count="counts.cancel_count" icon="el-icon-s-opportunity"
                    color="rgb(144, 147, 153)" />
            </el-col>
        </el-row>
        <el-row style="margin:0">
            <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12" style="padding:10px">
                <dashboardDanweiWancheng />
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12" style="padding:10px">
                <dashboardTijianjigourenshu2 />
                <div style="margin-top:20px">
                    <dashboardTijianjigoufeiyong />
                </div>
            </el-col>
            <el-col :span="24" style="padding:10px">
                <dashboardDanweiWanchengdu />
            </el-col>
        </el-row>
        <el-row style="margin:0">
            <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12" style="padding:10px">
                <dashboardDanweiPaihang />
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12" style="padding:10px">
                <dashboardGuoquYuyuequshi />
                <div style="margin-top:20px">
                    <dashboardWeilaiYuyuequshi />
                </div>
            </el-col>
        </el-row>
        <div>
            <el-row>
                <!-- <el-col :span="6" style="padding:10px">
                    <dashboardWanchengdu />
                </el-col> -->
                <el-col :span="24" style="padding:10px">
                    <calendarChart />
                </el-col>
            </el-row>
            <el-row>
                <el-col :xs="24" :sm="24" :md="24" :lg="14" :xl="14" style="padding:10px">
                    <dashboardTijianjigourenshu />
                </el-col>
                <el-col :xs="24" :sm="24" :md="24" :lg="10" :xl="10" style="padding:10px">
                    <dashboardTaocanzhanbi />
                </el-col>
            </el-row>
        </div>
        <el-row style="margin:0">
            <el-col  :xs="24" :sm="24" :md="24" :lg="7" :xl="7" style="padding:10px">
                <statAppointPingjunshu />
            </el-col>
            <el-col  :xs="24" :sm="24" :md="24" :lg="7" :xl="7" style="padding:10px">
                <dashboardWanchengdu />
            </el-col>
            <el-col  :xs="24" :sm="24" :md="24" :lg="10" :xl="10" style="padding:10px">
                <solugan />
            </el-col>
        </el-row>
        <el-row style="margin:0">
            <el-col  :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="padding:10px">
                <dashboardJinduJiankang />
            </el-col>
            <el-col  :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="padding:10px">
                <dashboardJinduZhiye />
            </el-col>
        </el-row>
    </div>
</template>

<script>

export default {
    name: "dashboard",
    path: "/main/dashboard",
    data() {
        return {
            loading: false,
            counts: {}
        };
    },
    methods: {
        getCount() {
            this.loading = true
            this.$http.get("/components/box/counts?123")
                .then(resp => {
                    this.counts = resp.data;
                }).catch(err => {
                    this.$message.error(err.message);
                }).finally(() => {
                    this.loading = false
                })
        }
    },
    mounted() {
        this.getCount();
    }
}
</script>
