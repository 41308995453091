<template>
    <zk-admin-table title="四级单位管理" :loading="loading" :table="table" @change="handleTableChange"
        @select="handleTableSelect">
        <template v-slot:buttons>
            <el-row style="float: right">
                <el-button size="mini" icon="el-icon-search" circle @click="searchVisible = !searchVisible"></el-button>
                <el-button size="mini" icon="el-icon-refresh" circle @click="getData"></el-button>
                <!-- <el-button size="mini" icon="el-icon-download" circle @click="doDownLoad"></el-button> -->
                <el-button size="mini" type="warning" icon="el-icon-plus" circle @click="handleAdd"
                    v-if="permission.subsidiary4_add"></el-button>
            </el-row>
        </template>
        <template v-slot:search>
            <search-form @search="handleSearch" v-show="searchVisible" />
        </template>
        <!-- <el-table-column type="selection" width="55"></el-table-column> -->
        <el-table-column type="index" label="序号" align="center"
            :index="(index) => { return (this.table.page - 1) * this.table.pageSize + index + 1 }" width="100" />
        <el-table-column prop="patient_name" align="left" label="三级单位" width=""></el-table-column>
        <el-table-column prop="name" align="left" label="名称" width=""></el-table-column>
        <el-table-column prop="staff_count" align="center" label="人员数量" width="">
            <template slot-scope="scope">
                <router-link class="link" :to="`/main/company/staff?fjdw_id=${scope.row.id}`">{{ scope.row.staff_count
                }}</router-link>
            </template>
        </el-table-column>
        <el-table-column prop="remarks" align="center" label="备注" width=""></el-table-column>
        <el-table-column prop="subsidiary_admin" align="center" label="单位管理员" width="">
            <template slot-scope="scope">
                <subsidiary_admin :ref="`subsidiary_admin_${scope.row.id}`" :sid="scope.row.id" :level="scope.row.level"
                    @add="handleAdminAdd(scope.row)" @edit="handleAdminUpdate" @delete="handleAdminDelete(scope.row)" />
            </template>
        </el-table-column>
        <el-table-column prop="subsidiary_admin" align="center" label="单位信息管理" width="150"
            >
            <template slot-scope="scope">
                <el-button size="mini" type="primary" @click="handleUpdate(scope.row)" icon="el-icon-edit"
                    v-if="permission.subsidiary4_edit"></el-button>
                <el-popconfirm :title="`确认删除[${scope.row.name}]吗？`" @confirm="handleDelete(scope.row)"
                    v-if="permission.subsidiary4_delete" style="margin-left:5px">
                    <el-button slot="reference" type="danger" size="mini" icon="el-icon-delete"></el-button>
                </el-popconfirm>
            </template>
        </el-table-column>
        <template v-slot:dialog>
            <data-admin-form ref="dataAdminForm" :visible="dialogAdminVisible" :form="form" @close="dialogAdminClose"
                @update="getData" :isNew="isNew" />
            <data-form ref="dataForm" :visible="dialogVisible" :form="form" @close="dialogClose" @update="getData"
                :isNew="isNew" />
        </template>
    </zk-admin-table>
</template>

<script>
import SearchForm from './widgets/searchForm'
import DataAdminForm from './widgets/dataAdminForm'
import DataForm from './widgets/dataForm'
import subsidiary_admin from './widgets/subsidiary_admin'
import data from './data'

export default {
    name: 'company-subsidiary4',
    path: '/main/company/subsidiary4',
    components: {
        SearchForm,
        DataAdminForm,
        DataForm,
        subsidiary_admin
    },
    data() {
        return {
            loading: false,
            table: data.table,
            dialogVisible: false,
            dialogAdminVisible: false,
            searchVisible: true,
            downloadVisible: false,
            search: {},
            form: {},
            isNew: false,
            multipleSelection: [],
            permission: {}
        }
    },
    methods: {
        dialogAdminClose(row) {
            this.dialogAdminVisible = false
            this.getData()
            this.$refs[`subsidiary_admin_${this.form.fjdw_id}`].getData()
        },
        dialogClose(row) {
            this.dialogVisible = false
            this.getData()
        },
        doDownLoad() {
            this.downloadVisible = true
        },
        handleAdd() {
            this.form = { level: 4, patient: null, name: '', remarks: '' }
            this.isNew = true
            this.dialogVisible = true
        },
        handleAdminAdd(row) {
            console.log(row)
            this.$refs['dataAdminForm'].activeName = 'first'
            this.form = {
                level: 5,
                sjdw: row.patient_name,
                sjdw_id: row.patient,
                fjdw: row.name,
                fjdw_id: row.id,
                username: '',
                password: '',
                name: '',
                contact: '',
                remarks: ''
            }
            this.isNew = true
            this.dialogAdminVisible = true
        },
        handleSearch(e) {
            this.search = e
            this.table.page = 1
            this.getData()
        },
        handleTableChange(e) {
            this.table = e
            this.getData()
        },
        handleTableSelect(val) {
            this.multipleSelection = val
        },
        async handleAdminUpdate(row) {
            console.log(row)
            this.isNew = false
            var temp = JSON.stringify(row)
            this.form = JSON.parse(temp)
            this.dialogAdminVisible = true
        },
        async handleUpdate(row) {
            console.log(row)
            this.isNew = false
            var temp = JSON.stringify(row)
            this.form = JSON.parse(temp)
            this.dialogVisible = true
        },
        async handleAdminDelete(row) {
            console.log(row)
            if (!row.id) {
                this.$message.error('请选择要删除的对象')
                return
            }
            // await this.$http.delete(`/user/${row.id}`)
            this.$message.success('删除成功')
            this.getData()
        },
        async handleDelete(row) {
            if (!row.id) {
                this.$message.error('请选择要删除的对象')
                return
            }
            const resp = await this.$http.delete(`/company/subsidiary/${row.level}/${row.id}`)
            if (resp.data.code != 200) {
                this.$message.error(resp.data.msg)
            } else {
                this.$message.success('删除成功')
            }
            this.getData()
        },
        async getData() {
            this.loading = true
            try {
                const resp = await this.$http.get(`/company/subsidiary?level=4&page=${this.table.page}&pageSize=${this.table.pageSize}`, { params: this.search })
                if (!resp.data) {
                    this.$message.error(resp.data.msg)
                } else {
                    this.table.data = resp.data.data.records
                    this.table.total = resp.data.data.total
                }
            } catch (e) {
                this.$message.error(e.message)
            }
            this.loading = false
        },
        async getPermission() {
            const resp = await this.$http.get('/permission')
            this.permission = resp.data
        }
    },
    mounted() {
        this.getPermission()
        this.getData()
    }
}
</script>
