<template>
    <v-chart v-loading='loading' ref='agePieChart' class='chart' :option='option' />
</template>

<script>
export default {
    name: 'agePieChart',
    data() {
        return {
            loading: false,
            option: {
                title: {
                    text: '年龄占比',
                    left: 'center'
                },
                color: ['#de6e6a', '#5971c0', '#FCCE10', '#E87C25', '#B5C334'],
                tooltip: {
                    trigger: 'item',
                    formatter: '{a} <br/>{b} : {c} ({d}%)'
                },
                series: [
                    {
                        name: '年龄占比',
                        type: 'pie',
                        radius: '55%',
                        center: ['50%', '60%'],
                        data: [
                            { value: Math.floor(Math.random() * 1000), name: '<20岁' },
                            { value: Math.floor(Math.random() * 1000), name: '20-30岁' },
                            { value: Math.floor(Math.random() * 1000), name: '30-40岁' },
                            { value: Math.floor(Math.random() * 1000), name: '40-50岁' },
                            { value: Math.floor(Math.random() * 1000), name: '50-60岁' },
                            { value: Math.floor(Math.random() * 1000), name: '>60岁' }
                        ],
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ]
            }
        }
    },
    methods: {
        async getData() {
            this.loading = true
            const resp = await this.$http.get('/statistics/age_pie_chart')
            console.log(resp.data)
            this.option.series[0].data = resp.data
            this.$refs['agePieChart'].setOption(this.option)
            this.loading = false
        }
    },
    mounted() {
        this.getData()
    }
}
</script>

<style scoped>
.chart {
    width: 100%;
    height: 400px;
}
</style>
