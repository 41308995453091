<template>
    <el-select :value="value" @input="$emit('input', $event)" placeholder="选择二级单位" :size="size" clearable filterable
        style="width:140px" :disabled="disabled">
        <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id" />
    </el-select>
</template>

<script>
export default {
    name: 'select-subsidiary-2',
    props: {
        value: {
            type: [String, Number, Object]
        },
        size: {
            default: 'small'
        },
        disabled: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            options: []
        }
    },
    methods: {
        async getData() {
            const hl = await this.$http.get('/company/subsidiary?level=2&page=1&pageSize=2000')
            this.options = hl.data.data.records
        }
    },
    mounted() {
        this.getData()
    }
}
</script>

<style lang="scss" scoped></style>
