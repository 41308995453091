<template>
    <el-select :value="value" @input="$emit('input', $event)" placeholder="体检类型" :size="size" clearable>
        <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id" />
    </el-select>
</template>

<script>
export default {
    name: 'select-tjlx',
    props: {
        value: {
            type: [String, Number, Object]
        },
        size: {
            default: 'small'
        }
    },
    data() {
        return {
            options: [
                {id: '健康体检', value:'健康体检'},
                {id: '职业体检', value:'职业体检'},
                {id: '女工体检', value:'女工体检'}
            ]
        }
    },
    methods: {
        async getData() {
            const hl = await this.$http.get('/hospital/hospital?page=1&pageSize=200')
            this.options = hl.data.data.records
        }
    },
    mounted() {
        // this.getData()
    }
}
</script>

