<template>
    <zk-search-form-inline :form="form" @search="$emit('search', form)">
        <el-form-item label="名称">
            <el-input v-model.trim="form.name" clearable></el-input>
        </el-form-item>
        <el-form-item label="时间">
            <el-date-picker v-model="form.daterange" size="small" type="datetimerange"
                value-format="yyyy-MM-dd HH:mm:ss" range-separator="-" start-placeholder="开始时间" end-placeholder="结束时间">
            </el-date-picker>
        </el-form-item>
    </zk-search-form-inline>
</template>

<script>
export default {
    data() {
        return {
            form: {}
        }
    }
}
</script>

