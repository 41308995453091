<template>
    <el-card class="box-card" shadow="hover">
        <div slot="header" class="clearfix">
            <span>年度体检整体完程度</span>
            <el-button style="float: right; padding: 3px 0" type="text" @click="getData" icon="el-icon-refresh"></el-button>
        </div>
        <v-chart ref="chart" class="chart" :option="option" v-loading="loading" />
    </el-card>
</template>

<script>
export default {
    name: 'dashboardWanchengdu',
    data() {
        return {
            loading: false,
            option: {
                grid: {
                    left: 0,
                    right: 0
                },
                // 设置环形图的大小和位置
                graphic: [
                    {
                        type: 'text',
                        left: 'center',
                        top: '44%',
                        style: {
                            text: '{d}',
                            font: 'bold 32px Arial',
                            // font: function (params) {
                            //     // 根据图表大小动态计算字体大小
                            //     return Math.max(params.rect.width, params.rect.height) / 1 + 'px Arial';
                            // },
                            fill: '#333'
                        }
                    },
                    {
                        type: 'text',
                        left: 'center',
                        top: '60%',
                        style: {
                            text: '完成度',
                            font: 'bold 12px Arial',
                            fill: '#333'
                        }
                    }
                ],
                series: [{
                    type: 'pie',
                    radius: ['60%', '80%'],
                    center: ['50%', '50%'],
                    data: [{
                        value: 75, //已完成的数值
                        itemStyle: {
                            color: '#1890ff' //已完成部分的颜色
                        }
                    },
                    {
                        value: 25, //未完成的数值
                        itemStyle: {
                            color: '#f0f2f5'
                        },
                        emphasis: {
                            color: '#f0f2f5'
                        }
                    }
                    ],
                    label: {
                        show: false
                    }
                }]
            }
        }
    },
    methods: {
        async getData() {
            this.loading = true
            const resp = await this.$http.get('/components/charts/dashboard/wanchengdu')
            let todo = resp.data.todo
            let finish = resp.data.finish
            this.option.graphic[0].style.text = `${(finish / (todo + finish) * 100).toFixed(2)}%`
            this.option.series[0].data[0].value = finish
            this.option.series[0].data[1].value = todo
            this.$refs['chart'].setOption(this.option)
            this.loading = false
        }
    },
    mounted() {
        this.getData().then(() => {
            this.$nextTick(() => {
                this.$refs.chart.resize()
            })
            window.addEventListener('resize', () => {
                if (this.$refs && this.$refs.chart) {
                    this.$refs.chart.resize()
                }
            })
        })
    }
}
</script>

<style scoped>
.chart {
    width: 100%;
    height: 300px;
}
</style>
