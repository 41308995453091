<template>
    <div>
        <el-button v-if="!form" :size="size" @click="dialogVisible = true">选择员工</el-button>
        <div v-else>
            <div style="float: right" v-if="!disabled">
                <el-button circle size="mini" icon="el-icon-close" @click="onClear" />
            </div>
            <div style="margin-top: 9px; line-height: 22px">
                <div>
                    <b>{{ form.XM }}</b>&nbsp;
                    <small>{{ form.XB }} {{ form.NL }}岁 {{ form.ZGLX }}</small>
                </div>
                <div>{{ form.SFZH }}</div>
                <div>{{ form.EJDW }} {{ form.SJDW }} {{ form.FJDW }}</div>
            </div>
        </div>
        <el-dialog title="选择员工" :visible.sync="dialogVisible" width="60%" append-to-body>
            <div>
                <el-form :inline="true" :model="search" :size="size" class="search-form">
                    <el-form-item label="姓名">
                        <el-input v-model.trim="search.name" clearable style="width: 100px"></el-input>
                    </el-form-item>
                    <el-form-item label="身份证号">
                        <el-input v-model.trim="search.SFZH" clearable style="width: 180px"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button @click="getTableData" icon="el-icon-search" type="primary" plain
                            :loading="loading">筛选</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <el-table stripe border :data="table.data" :loading="loading" height="300" @row-click="onSelect">
                <el-table-column label="单位" width="180">
                    <template slot-scope="scope">
                        <div>{{ scope.row.EJDW }}</div>
                        <small>{{ scope.row.SJDW }}</small>
                        <div>
                            <small>{{ scope.row.FJDW }}</small>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="人员信息" width="180">
                    <template slot-scope="scope">
                        <div>
                            {{ scope.row.XM }}
                            <small>{{ scope.row.XB }} {{ scope.row.NL }}岁</small>
                        </div>
                        <small>{{ scope.row.SFZH }}</small>
                    </template>
                </el-table-column>
                <el-table-column label="职工信息" width="100">
                    <template slot-scope="scope">
                        <div>{{ scope.row.ZGLX }}</div>
                        <small>档案年份: {{ scope.row.DANF }}</small>
                    </template>
                </el-table-column>
                <el-table-column label="备注" prop="remarks"></el-table-column>
            </el-table>
            <span slot="footer" class="dialog-footer">
                <div style="margin: 10px auto; text-align: center">
                    <el-pagination layout="total, prev, pager, next, sizes" :page-sizes="[10, 20, 30, 50, 100]"
                        :current-page="table.page" :total="table.total" :page-size="table.pageSize"
                        @current-change="handleCurrentChange" @size-change="handleSizeChange" />
                </div>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: "selectStaff",
    props: {
        value: {
            type: [Number, String, Object],
            default: () => {
                return {};
            },
        },
        size: {
            default: "small",
        },
        disabled: {
            default: false
        }
    },
    data() {
        return {
            loading: false,
            dialogVisible: false,
            table: {
                total: 0,
                data: [],
                page: 1,
                pageSize: 20,
            },
            search: {},
            form: null
        }
    },
    watch: {
        value: {
            immediate: false,
            handler(val) {
                this.getStaffById(val)
            }
        }
    },
    methods: {
        onClear() {
            this.form = null
            this.$emit("input", null)
            this.$parent.$emit('el.form.change')
        },
        onSelect(row) {
            this.form = row;
            this.dialogVisible = false;
            this.$emit("input", this.form.id)
            this.$parent.$emit('el.form.change')
        },
        handleCurrentChange(e) {
            this.table.page = e
            this.getTableData()
        },
        handleSizeChange(e) {
            this.table.pageSize = e
            this.table.page = 1
            this.getTableData()
        },
        async getStaffById(id) {
            const resp = await this.$http.get(`/company/staff/${id}`)
            this.form = resp.data
        },
        async getTableData() {
            this.loading = true
            try {
                const resp = await this.$http.get(
                    `/company/staff?page=${this.table.page}&pageSize=${this.table.pageSize}`,
                    { params: this.search }
                );
                if (!resp.data) {
                    this.$message.error(resp.data.msg)
                } else {
                    this.table.data = resp.data.data.records
                    this.table.total = resp.data.data.total
                }
            } catch (e) {
                this.$message.error(e.message)
            }
            this.loading = false
        }
    },
    mounted() {
        this.getTableData()
        if (this.value) this.getStaffById(this.value)
    }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
    padding: 0 20px;
}

::v-deep .el-form-item {
    margin-bottom: 0;
}

.search-form {
    text-align: left;
    margin-top: 15px;
    background-color: #f7f7f8;
    padding: 10px 20px;
    border: 1px solid #ebeef5;
}
</style>
