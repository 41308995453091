<template>
    <el-checkbox-group :value="value" @input="$emit('input', $event)">
        <el-checkbox :label="w.code" v-for="(w, i) in options" :key="i">{{ w.name }}</el-checkbox>
    </el-checkbox-group>
</template>
<script>
export default {
    name: 'checkbox-med-item',
    props: {
        value: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            options: []
        }
    },
    methods: {
        async getData() {
            const resp = await this.$http.get('/hospital/med-item?page=1&pageSize=1000')
            this.options = resp.data.data.records
        }
    },
    mounted() {
        this.getData()
    }
}
</script>
<style lang="scss" scoped>

</style>
