<template>
    <el-select :value="current" @input="$emit('input', $event)" placeholder="请选择" :size="size" clearable>
        <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id" />
    </el-select>
</template>

<script>
export default {
    name: 'select-report',
    props: {
        value: {
            type: [String, Number, Object]
        },
        size: {
            default: 'small'
        }
    },
    data() {
        return {
            current: '',
            options: [
                {id: 1, name:'已报告'},
                {id: 0, name:'未报告'}
            ]
        }
    },
    watch: {
        value: {
            immediate: true,
            handler(val) {
                this.current = val
            }
        }
    },
    methods: {
        async getData() {
        }
    },
    mounted() {
        // this.getData()
    }
}
</script>

