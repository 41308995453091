<template>
    <zk-search-form-inline :form="form" @search="$emit('search', form)" label-width="80px" style="text-align: left;">
        <el-form-item label="二级单位">
            <select-subsidiary-2 v-model="form.ejdw_id" />
        </el-form-item>
        <el-form-item label="三级单位">
            <select-subsidiary-3 :ejdw_id="form.ejdw_id" v-model="form.sjdw_id" />
        </el-form-item>
        <el-form-item label="体检机构">
            <select-hospital v-model="form.hospital_id" />
        </el-form-item>
        <el-form-item label="体检类型">
            <select-tjlx v-model="form.tjlx" />
        </el-form-item>
        <el-form-item label="体检套餐">
            <select-tjtc :hospital_id="form.hospital_id" v-model="form.tjtc" />
        </el-form-item>
        <el-form-item label="体检编号">
            <el-input v-model.trim="form.code" clearable></el-input>
        </el-form-item>
        <!-- eslint-disable-next-line -->
        <el-form-item label="姓　　名">
            <el-input v-model.trim="form.name" clearable></el-input>
        </el-form-item>
        <el-form-item label="身份证号">
            <el-input v-model.trim="form.idnum" clearable></el-input>
        </el-form-item>
        <el-form-item label="体检报告">
            <select-report v-model.trim="form.report" />
        </el-form-item>
        <el-form-item label="体检日期">
            <el-date-picker v-model="form.daterange" size="small" type="daterange" value-format="yyyy-MM-dd"
                range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期" style="width:250px">
            </el-date-picker>
        </el-form-item>
    </zk-search-form-inline>
</template>

<script>
export default {
    props: {
        value: {
            type: [String, Number, Object]
        },
        size: {
            default: 'small'
        }
    },
    data() {
        return {
            form: this.value
        }
    }
}
</script>

