<template>
    <div>
        <div v-loading="loading">{{ count }}</div>
    </div>
</template>
<script>
export default {
    name: 'planCount',
    props: {
        type: {
            type: [String]
        },
        sid: {
            type: [String, Number]
        },
        label: {
            type: [String]
        }
    },
    data() {
        return {
            loading: false,
            count: 0
        }
    },
    watch: {
        sid: {
            handler(newVal, oldVal) {
                console.log(newVal, oldVal)
                this.getData()
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        async getData() {
            this.loading = true
            if (this.sid && this.label) {
                const resp = await this.$http.get(`/task/label/count?type=${this.type}&sid=${this.sid}&label=${this.label}`)
                this.count = resp.data.count
            }
            this.loading = false
        }
    },
    mounted() {
        // console.log(this.name, this.label)
        this.getData()
    }
}
</script>