<template>
    <zk-admin-table title="体检报告" :loading="loading" :table="table" @change="handleTableChange" @select="handleTableSelect"
        @row-click="handleRowSelect">
        <template v-slot:buttons>
            <el-row style="float: right">
                <el-button size="mini" icon="el-icon-download" circle @click="getData('xlsx')"></el-button>
                <el-button size="mini" icon="el-icon-search" circle @click="searchVisible = !searchVisible"></el-button>
                <el-button size="mini" icon="el-icon-refresh" circle @click="getData()"></el-button>
            </el-row>
        </template>
        <template v-slot:search>
            <zk-search-form-inline :form="search" @search="getData()">
                <el-form-item label="" prop="hospital_id" clearable>
                    <select-hospital-multi v-model="search.hospital_ids" />
                </el-form-item>
                <el-form-item label="" prop="ejdw_id" clearable>
                    <select-subsidiary-2 v-model="search.ejdw_id" />
                </el-form-item>
                <el-form-item label="" prop="sjdw_id" clearable>
                    <select-subsidiary-3 :ejdw_id="search.ejdw_id" v-model="search.sjdw_id" />
                </el-form-item>
                <el-form-item label="姓名">
                    <el-input v-model.trim="search.name" clearable></el-input>
                </el-form-item>
                <el-form-item label="身份证号">
                    <el-input v-model.trim="search.idnum" clearable></el-input>
                </el-form-item>
            </zk-search-form-inline>
        </template>
        <el-table-column type="index" width="55"></el-table-column>
        <el-table-column label="体检机构" prop="hospital" width="200">
            <template slot-scope="scope">
                <div>{{ scope.row.hospital }}</div>
                <small>体检编号：{{ scope.row.TJBH }}</small>
            </template>
        </el-table-column>
        <el-table-column label="人员信息" width="180">
            <template slot-scope="scope">
                <div>
                    {{ scope.row.XM }}
                    <small>{{ scope.row.XB }} {{ scope.row.NL }}岁</small>
                </div>
                <small>{{ scope.row.SFZH }}</small>
            </template>
        </el-table-column>
        <el-table-column label="职工信息" width="">
            <template slot-scope="scope">
                <div>{{ scope.row.EJDW }}</div>
                <small>{{ scope.row.SJDW }}</small>
            </template>
        </el-table-column>
        <el-table-column label="体检类型" width="" align="left">
            <template slot-scope="scope">
                <div>{{ scope.row.TJLX }}</div>
                <small>{{ scope.row.TCMC }}</small>
            </template>
        </el-table-column>
        <el-table-column label="日期时间" width="" align="center">
            <template slot-scope="scope">
                <div>建档日期：{{ scope.row.JDRQ | date }}</div>
                <div>报告日期：{{ scope.row.JLRQ | date }}</div>
            </template>
        </el-table-column>
        <el-table-column label="备注" prop="remarks"></el-table-column>
        <template v-slot:dialog>
            <el-dialog title="体检结果" :visible="dialogVisible" width="70%" @close="handleDialogClose">
                <div style="height: 60vh; overflow: auto" v-loading="loading">
                    <div style="display: flex; flex-wrap: wrap; align-content: space-between; align-items: flex-end; ">
                        <div style=" padding: 20px 20px 20px 0; font-size: 120%; font-weight: bold; ">
                            {{ form.XM | hide_name }}
                        </div>
                        <div style="padding: 20px">{{ form.XB }}</div>
                        <div style="padding: 20px">{{ form.NL }}岁</div>
                        <div style="padding: 20px; flex: 1; text-align: right">
                            <!-- 套餐名称：{{ form.PROJECTNAME }} -->
                        </div>
                    </div>
                    <div
                        style=" padding-bottom: 10px; display: flex; flex-wrap: wrap; align-content: space-between; align-items: flex-end; ">
                        <div>体检套餐： {{ form.PROJECTNAME }}</div>
                        <div style="flex: 1; text-align: right">
                            报告时间：{{ form.JLRQ | formatDate }}
                        </div>
                    </div>
                    <div>
                        <!-- 体检项目： -->
                        <!-- <el-tag style="margin: 2px" type="info" size="mini" v-for="(e, i) in report.ycxm" :key="i">
                    {{ e.name }}
                </el-tag> -->
                    </div>
                    <!-- <div>完成科室: {{ report.jcxx.wcks }}</div> -->
                    <el-tabs v-model="activeName">
                        <el-tab-pane label="体检结论" name="JL">
                            <div>
                                <div v-html="form.ZJJL || ''" style="white-space: pre-wrap" />
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="体检建议" name="JY">
                            <div>
                                <div v-html="form.YLBJ || ''" style="white-space: pre-wrap" />
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="检查结果" name="JCJG">
                            <div>
                                <div v-html="form.JCJG || ''" style="white-space: pre-wrap" />
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="检查信息" name="JCXX">
                            <div v-for="(e, i) in form.JCXX" :key="i" v-if="e.JCJG.length > 0">
                                <h3>{{ e.KSMC }}</h3>
                                <el-table :data="e.JCJG" border stripe style="width: 100%">
                                    <!-- <el-table-column prop="LBMC" label="日期" width="180" /> -->
                                    <el-table-column type="index" label="序号" width="60" />
                                    <el-table-column prop="XXMC" label="项目名称" width="180" />
                                    <el-table-column prop="value" label="检查结果" />
                                    <el-table-column prop="Unit" label="单位" />
                                    <el-table-column prop="DefValue" label="参考值" />
                                </el-table>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="检验信息" name="HYXX">
                            <div v-for="(e, i) in form.HYXX" :key="i" v-if="e.JCJG.length > 0">
                                <h3>{{ e.KSMC }}</h3>
                                <el-table :data="e.JCJG" border stripe style="width: 100%">
                                    <!-- <el-table-column prop="LBMC" label="日期" width="180" /> -->
                                    <el-table-column type="index" label="序号" width="60" />
                                    <el-table-column prop="XXMC" label="项目名称" width="180" />
                                    <el-table-column prop="ItemResult" label="检查结果" />
                                    <el-table-column prop="ItemUnit" label="单位" />
                                    <el-table-column prop="DefValue" label="参考值" />
                                </el-table>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="影像信息" name="YXXX">
                            <div v-for="(e, i) in form.YXXX" :key="i" v-if="e.JCJG.length > 0">
                                <h3>{{ e.KSMC }}</h3>
                                <el-table :data="e.JCJG" border stripe style="width: 100%">
                                    <!-- <el-table-column prop="LBMC" label="日期" width="180" /> -->
                                    <el-table-column type="index" label="序号" width="60" />
                                    <el-table-column prop="XXMC" label="项目名称" width="180" />
                                    <el-table-column prop="Value" label="检查结果" />
                                </el-table>
                            </div>
                        </el-tab-pane>
                    </el-tabs>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="downloadPDF">下载体检报告</el-button>
                    <el-button @click="handleDialogClose" type="primary" :loading="loading" :disabled="loading">{{
                        $t("form.ok") }}
                    </el-button>
                </span>
            </el-dialog>
        </template>
    </zk-admin-table>
</template>

<script>
export default {
    name: "checkup-record2023",
    path: "/main/checkup/record2023",
    data() {
        return {
            loading: false,
            search: { hospital_ids: [] },
            dialogVisible: false,
            form: { TJXX: {}, ZJXX: {} },
            activeName: "JL",
            table: {
                columns: [
                    { title: "噪声", field: "SFZS", width: "60", align: "center" },
                    { title: "毒物", field: "SFDW", width: "60", align: "center" },
                    { title: "粉尘", field: "SFFC", width: "60", align: "center" },
                    { title: "放射", field: "SFFS", width: "60", align: "center" },
                    { title: "电工", field: "SFDG", width: "60", align: "center" },
                    { title: "高温", field: "SFGW", width: "60", align: "center" },
                    { title: "驾驶员", field: "SFJSY", width: "70", align: "center" },
                    { title: "高处", field: "SFGC", width: "60", align: "center" },
                    { title: "压力", field: "SFYL", width: "60", align: "center" },
                ],
                data: [],
                total: 0,
                page: 1,
                pageSize: 20,
            },
        };
    },
    filters: {
        hide_name(value) {
            if (!value) return "";
            return value.substring(0, 1) + new Array(value.length).join("*");
        },
        formatDate(value) {
            if (!value) return "";
            return new Date(value).format('yyyy-MM-dd')
        },
    },
    methods: {
        handleDialogClose() {
            this.dialogVisible = false;
        },
        handleSearch(e) {
            this.search = e;
            this.table.page = 1;
            this.getData();
        },
        handleTableChange(e) {
            this.table = e;
            this.getData();
        },
        handleRowSelect(row) {
            this.loading = true;
            this.$http
                .get(`/checkup/record2023/${row.id}`)
                .then((resp) => {
                    if (resp.data.status === 'success') {
                        this.form = resp.data.data
                        this.dialogVisible = true;
                    } else {
                        this.$message.error(resp.data.msg);
                    }
                })
                .catch((err) => {
                    this.$message.error(err.message);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        async downloadPDF() {
            try {
                const resp = await this.$http.get(
                    `/checkup/record2023/${this.form.TJBH}/pdf`,
                    { responseType: "blob" }
                );
                if (resp.data.type == 'application/json') {
                    this.$message.error('PDF生成失败，请稍后访问');
                    return;
                } else {
                    let blob = new Blob([resp.data], {
                        type: "application/pdf;charset=utf-8;",
                    });
                    var link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    link.download = decodeURI(
                        escape(resp.headers["content-disposition"].split("=")[1])
                    );
                    link.click();
                    window.URL.revokeObjectURL(link.href);
                }
            } catch (e) {
                this.$message.error(e.message);
            }
        },
        handleTableSelect(val) {
            this.multipleSelection = val;
        },
        async getData(xlsx) {
            this.loading = true;
            try {
                let responseType = xlsx ? 'blob' : 'json'
                this.search.export = xlsx ? 'xlsx' : ''
                const resp = await this.$http.get(
                    `/checkup/record2023?page=${this.table.page}&pageSize=${this.table.pageSize}`,
                    { params: this.search, responseType: responseType }
                );
                console.log(resp)
                // if (resp.headers['content-type'].includes('application/json')) {
                if (!xlsx) {
                    if (!resp.data) {
                        this.$message.error(resp.data.msg);
                    } else {
                        this.table.data = resp.data.data;
                        this.table.total = resp.data.total;
                    }
                } else {
                    let blob = new Blob([resp.data], { type: 'application/vnd.openxmlformats;charset=utf-8;' })
                    var link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.download = decodeURI(escape(resp.headers['content-disposition'].split('=')[1]))
                    link.click()
                    window.URL.revokeObjectURL(link.href)
                }
            } catch (error) {
                this.$message.error(error.message);
            }
            this.loading = false;
        },
    },
    mounted() {
        this.search = { ...this.search, ...this.$route.query };
        this.getData();
    },
};
</script>

<style scoped>
::v-deep .el-dialog__body {
    padding: 0 20px;
}
</style>
