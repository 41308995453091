<template>
    <el-select :value="value" @input="$emit('input', $event)" placeholder="选择二级单位" :size="size" clearable filterable
        style="width:290px" multiple collapse-tags>
        <el-checkbox v-model="selectAll" @change="handleSelectAll" style="padding:10px">
            全选
        </el-checkbox>
        <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id" />
    </el-select>
</template>

<script>
export default {
    name: 'select-subsidiary-2-multi',
    props: {
        value: {
            type: [Array]
        },
        size: {
            default: 'small'
        }
    },
    data() {
        return {
            selectAll: false,
            options: []
        }
    },
    methods: {
        handleSelectAll() {
            if (this.selectAll) {
                // 如果全选复选框被选中，将所有选项添加到 selectedOptions 中
                this.value = this.options.map(option => option.id);
            } else {
                // 如果全选复选框取消选中，清空 selectedOptions
                this.value = [];
            }
        },
        async getData() {
            const hl = await this.$http.get('/company/subsidiary?level=2&page=1&pageSize=2000')
            this.options = hl.data.data.records
        }
    },
    mounted() {
        this.getData()
    }
}
</script>

<style lang="scss" scoped></style>
