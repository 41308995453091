<template>
    <el-row v-loading="loading">
        <el-col :xs="12" :sm="12" :md="12" :lg="6" :xl="6" style="padding:10px">
            <count-card title="年度体检人数" :count="count.cid" color="rgb(128, 176, 210)" />
        </el-col>
        <el-col :xs="12" :sm="12" :md="12" :lg="6" :xl="6" style="padding:10px">
            <count-card title="年度体检人次" :count="count.jcxx" icon="el-icon-s-shop" color="rgb(141, 210, 198)" />
        </el-col>
        <el-col :xs="12" :sm="12" :md="12" :lg="6" :xl="6" style="padding:10px">
            <count-card title="已报告" :count="count.report" icon="el-icon-s-flag" />
        </el-col>
        <el-col :xs="12" :sm="12" :md="12" :lg="6" :xl="6" style="padding:10px">
            <count-card title="健康预警" :count="count.alert" icon="el-icon-s-opportunity" color="#bd0b0b" />
        </el-col>
    </el-row>
</template>

<script>
export default {
    name: 'boxCount',
    data() {
        return {
            loading: false,
            count: {
                cid: '?',
                jcxx: '?',
                report: '?',
                alert: '?'
            }
        }
    },
    methods: {
        async getData() {
            this.loading = true
            const resp = await this.$http.get('/statistics/count_box')
            this.count = resp.data
            this.loading = false
        }
    },
    mounted() {
        this.getData()
    }
}
</script>
