<template>
    <div>
        <el-page-header @back="$router.go(-1)" content="体检计划外" />
        <div>
            <div style="margin: 20px 0 10px;font-size: 120%;">当前体检任务：2023年度体检计划</div>
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="健康体检" name="first">
                    <el-table :data="tableData" style="width: 100%" size="mini" v-loading="loading">
                        <el-table-column type="index" label="序号" align="center" width="50" />
                        <el-table-column prop="name" label="单位" align="center" width="" />
                        <el-table-column prop="count" label="总人数" align="center" width="80" />
                        <el-table-column :label="item" align="center" v-for="(item, index) in tableLabel" :key="index">
                            <el-table-column prop="task_count" align="center" label="计划" width="60">
                                <template slot-scope="scope">
                                    <div @click="showDlg(scope.row)" style="cursor: pointer">
                                    <plan-count :name="scope.row.name" :label="item" />
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="task_sy" align="center" label="剩余" width="60">
                                <template>
                                    <plan-used />
                                </template>
                            </el-table-column>
                        </el-table-column>
                    </el-table>
                </el-tab-pane>
                <el-tab-pane label="职业体检" name="second">
                    <el-table :data="tableData" style="width: 100%" size="mini" v-loading="loading">
                        <el-table-column type="index" label="序号" align="center" width="50" />
                        <el-table-column prop="name" label="单位" align="center" width="" />
                        <el-table-column prop="count" label="总人数" align="center" width="80" />
                        <el-table-column :label="item" align="center" v-for="(item, index) in tableLabel" :key="index">
                            <el-table-column prop="task_count" align="center" label="计划" width="60">
                                <template slot-scope="scope">
                                    <plan-count :name="scope.row.name" :label="item" />
                                </template>
                            </el-table-column>
                            <el-table-column prop="task_sy" align="center" label="剩余" width="60">
                                <template>
                                    <plan-used />
                                </template>
                            </el-table-column>
                        </el-table-column>
                    </el-table>
                </el-tab-pane>
            </el-tabs>
        </div>
        <el-dialog :title="form.date" :visible.sync="dialogVisible" width="60%">
            <el-form ref="form" :model="form" label-width="80px">
                <el-form-item label="人员" prop="packages">
                    <el-table border ref="multipleTable" :data="tableData2" tooltip-effect="dark" style="width: 100%;"
                        height="250" @selection-change="handleSelectionChange">
                        <el-table-column type="selection" width="55" align="center" />
                        <el-table-column prop="name" label="姓名" width="120" />
                        <el-table-column prop="gender" label="性别" align="center" width="100"
                            :filters="[{ text: '男', value: '男' }, { text: '女', value: '女' }]"
                            :filter-method="filterHandler" filter-placement="bottom-end" />
                        <el-table-column prop="age" label="年龄" align="center" width="100" />
                        <el-table-column prop="desc" label="备注" show-overflow-tooltip />
                    </el-table>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="submit">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: 'task-planB',
    path: '/main/task/planB',
    data() {
        return {
            loading: false,
            dialogVisible: false,
            activeName: 'first',
            form: {},
            tableLabel: [], //['处级干部', '45岁以上', '35-44岁', '34岁以下', '二级工程师', '一级工程师', '企业高级专家', '企业首席专家', '女工/家属'],
            tableData: [],
            tableData2: []
        }
    },
    methods: {
        filterHandler(value, row, column) {
            const property = column['property']
            return row[property] === value
        },
        handleSelectionChange(val) {
            this.form.users = val
        },
        submit(){

        },
        // eslint-disable-next-line 
        showDlg(e) {
            // console.log(e)
            this.dialogVisible = true
        },
        handleClick(tab, event) {
            console.log(tab, event);
            this.tableLabel = []
            this.tableData = []
            this.getData()
        },
        async getData() {
            this.loading = true
            const resp = await this.$http.get(`/task/2023?type=${this.activeName}`)
            console.log(resp.data)
            const resp2 = await this.$http.get('/staff?page=1&pageSize=200', { params: this.search })
            if (!resp.data) {
                this.$message.error(resp.data.msg)
            } else {
                this.tableData2 = resp2.data.data.records
            }
            this.tableLabel = resp.data.tableLabel
            this.tableData = resp.data.tableData
            this.loading = false
        }
    },
    mounted() {
        // this.getData()
    }
}
</script>
