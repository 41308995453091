<template>
    <div>
        <div>
            <el-page-header @back="$router.go(-1)" content="异常数据" />
        </div>
        <div style="margin-top:20px;">
            <el-table :data="tableData" style="width: 100%" border stripe v-loading="loading">
                <el-table-column prop="id" type="index" />
                <el-table-column prop="DWMC" label="单位名称" width="" />
                <el-table-column prop="XM" label="姓名" width="" />
                <el-table-column prop="XB" label="性别" width="" />
                <el-table-column prop="NL" label="年龄" width="" />
                <el-table-column prop="SFZH" label="身份证号" width="" />
                <el-table-column prop="ZGLX" label="类型" width="" />
            </el-table>
        </div>
    </div>
</template>

<script>
export default {
    name: 'stat-analysis_up60',
    path: '/main/statistics/analysis_up60',
    data() {
        return {
            loading: false,
            tableData: []
        }
    },
    methods: {
        async getData() {
            this.loading = true
            const resp = await this.$http.get(`/todo/up60`)
            this.tableData = resp.data
            this.loading = false
        }
    },
    mounted() {
        this.getData()
    }
}
</script>

<style lang="scss" scoped>

</style>