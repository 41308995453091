<template>
    <v-chart ref="chart" :option="option" style="width: 100%;height:200px" />
</template>

<script>
export default {
    name: 'wanchengduPieChart',
    data() {
        return {
            option: {
                //提示框组件。开发实际中去掉了指针，提示框可以不用设置。
                tooltip: {
                    formatter: '{a} <br/>{b} : {c}%'
                },
                //工具栏。本次好像也没用到，刷新，下载，我觉得还是挺实用的，具体开需求！
                //下面属性才是仪表盘的核心！！反正我是这么认为的！！！
                series: [{
                    //类型
                    type: 'gauge',
                    //半径
                    radius: 150,
                    //起始角度。圆心 正右手侧为0度，正上方为90度，正左手侧为180度。
                    startAngle: 180,
                    //结束角度。
                    endAngle: 0,
                    center: ['50%', '90%'],
                    //仪表盘轴线相关配置。
                    axisLine: {
                        show: true,
                        // 属性lineStyle控制线条样式
                        lineStyle: {
                            width: 30,
                            color: [[1, '#63869e']]
                        }
                    },
                    //分隔线样式。
                    splitLine: {
                        show: false
                    },
                    //刻度样式。
                    axisTick: {
                        show: false
                    },
                    //刻度标签。
                    axisLabel: {
                        show: false
                    },
                    //仪表盘指针。
                    pointer: {
                        //这个show属性好像有问题，因为在这次开发中，需要去掉指正，我设置false的时候，还是显示指针，估计是BUG吧，我用的echarts-3.2.3；希望改进。最终，我把width属性设置为0，成功搞定！
                        show: false,
                        //指针长度
                        length: '90%',
                        width: 0
                    },
                    //仪表盘标题。
                    title: {
                        show: true,
                        offsetCenter: [0, '-40%'], // x, y，单位px
                        textStyle: {
                            color: '#hhh',
                            fontSize: 15
                        }
                    },
                    //仪表盘详情，用于显示数据。
                    detail: {
                        show: true,
                        offsetCenter: [0, '-10%'],
                        formatter: '{value}%',
                        textStyle: {
                            fontSize: 30
                        }
                    },
                    data: [{
                        value: 0,
                        name: '体检完成度'
                    }]
                }]
            }
        }
    },
    methods: {
        async getData() {
            this.loading = true
            const resp = await this.$http.get('/statistics/gender_pie_chart')
            this.option.series[0].data = resp.data
            this.$refs.chart.setOption(this.option)
            this.loading = false
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.$refs.chart.resize()
        })
        window.addEventListener('resize', () => {
            if (this.$refs && this.$refs.chart) {
                this.$refs.chart.resize()
            }
        })
        // this.getData()
        setInterval(() => {
            var random = (Math.random() * 100).toFixed(2)
            var color = [[random / 100, '#63869e'], [1, '#c23531']]
            this.option.series[0].axisLine.lineStyle.color = color
            this.option.series[0].data[0].value = random
            this.$refs.chart.setOption(this.option)
        }, 2000)
    }
}
</script>
