const data = {
    table: {
        columns: [
            {title: '单位代码', field: 'code', width: '140'},
            {title: '单位名称', field: 'name', width: ''},
            {title: '备注', field: 'remarks', width: ''}
        ],
        data: [],
        total: 0,
        page: 1,
        pageSize: 20
    }
}

export default data
