<template>
    <zk-admin-table title="家属管理" :loading="loading" :table="table" @change="handleTableChange" @select="handleTableSelect">
        <template v-slot:buttons>
            <el-row style="float: right">
                <el-button size="mini" icon="el-icon-search" circle @click="searchVisible = !searchVisible"></el-button>
                <el-button size="mini" icon="el-icon-refresh" circle @click="getData"></el-button>
                <el-button size="mini" icon="el-icon-download" circle @click="doDownLoad"
                    v-if="permission.staff_jiashu_export"></el-button>
                <!-- <el-button size="mini" type="warning" icon="el-icon-plus" circle @click="handleAdd"></el-button> -->
                <!-- <el-button size="mini" type="primary" icon="el-icon-edit" circle @click="handleMultiUpdate"></el-button> -->
                <!-- <el-button size="mini" type="danger" icon="el-icon-delete" circle @click="handleDelete"></el-button> -->
            </el-row>
        </template>
        <template v-slot:search>
            <search-form @search="handleSearch" v-show="searchVisible" />
        </template>
        <!-- <el-table-column type="selection" width="55"></el-table-column> -->
        <el-table-column type="index" label="序号" align="center"
            :index="(index) => { return (this.table.page - 1) * this.table.pageSize + index + 1 }" width="70" />
        <el-table-column align="left" v-for="(v, idx) in table.columns" :prop="v.field" :label="v.title" :width="v.width"
            :formatter="v.formatter" :key="idx">
        </el-table-column>
        <el-table-column label="身份证号" width="180">
            <template slot-scope="scope">
                <small > {{ isFullIdCard[scope.$index] ? scope.row.SFZH : maskIdCardRegex(scope.row.SFZH) }}</small>
                <i class="el-icon-view" style="cursor: pointer;padding-left: 10px" @click="toggleIdCard(scope.$index)"></i>
            </template>
        </el-table-column>
        <el-table-column align="center" label="是否家属">
            <template slot-scope="scope">
                <el-switch :value="scope.row.TJLX_NGJS == '家属'" v-if="permission.staff_jiashu_switch" active-color="#13ce66"
                    inactive-color="#ff4949" @change="handleSwitch($event, scope.row)" />
                <span>{{ scope.row.TJLX_NGJS }}</span>
            </template>
        </el-table-column>
        <el-table-column prop="remarks" label="备注" width=""></el-table-column>
        <template v-slot:dialog>
            <data-form :visible="dialogVisible" :form="form" @close="dialogClose" @update="getData" :isNew="isNew" />
        </template>
    </zk-admin-table>
</template>

<script>
import SearchForm from './widgets/searchForm'
import DataForm from './widgets/dataForm'
import data from './data'

export default {
    name: 'company-staff-jiashu',
    path: '/main/company/jiashu',
    components: {
        SearchForm,
        DataForm
    },
    data() {
        return {
            loading: false,
            table: data.table,
            dialogVisible: false,
            searchVisible: true,
            downloadVisible: false,
            search: {},
            form: {},
            isNew: false,
            multipleSelection: [],
            permission: {},
            isFullIdCard:{}
        }
    },
    methods: {
        maskIdCardRegex(idCard) {
            return idCard.replace(/^(.{4})(.*)(.{4})$/, function (match, p1, p2, p3) {
                return p1 + p2.replace(/./g, '*') + p3;
            });
        },
        toggleIdCard(index){
            // 切换显示状态
            this.$set(this.isFullIdCard, index, !this.isFullIdCard[index]);
        },
        async handleSwitch(e, row) {
            row.TJLX_NGJS = e ? '家属' : ''
            const resp = await this.$http.post('/company/staff', row)
            if (resp.data.code != 200) {
                this.$message.error(resp.data.msg)
            } else {
                this.$message.success('保存成功')
            }
            this.getData()
        },
        dialogClose() {
            this.dialogVisible = false
            this.getData()
        },
        async doDownLoad() {
            try {
                let params = this.search
                params.target = 'jiashu'
                params.export = 'xlsx'//'csv'
                const resp = await this.$http.get(`/company/staff?page=${this.table.page}&pageSize=${this.table.pageSize}`, { params: params, responseType: 'blob' })
                let blob = new Blob([resp.data], { type: 'application/vnd.openxmlformats;charset=utf-8;' })
                var link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = decodeURI(escape(resp.headers['content-disposition'].split('=')[1]))
                link.click()
                window.URL.revokeObjectURL(link.href)
            } catch (e) {
                this.$message.error(e.message)
            }
        },
        handleAdd() {
            this.form = {}
            this.isNew = true
            this.dialogVisible = true
        },
        handleSearch(e) {
            this.search = e
            delete this.search.export
            this.table.page = 1
            this.getData()
        },
        handleTableChange(e) {
            this.table = e
            this.getData()
        },
        handleTableSelect(val) {
            this.multipleSelection = val
        },
        async handleMultiUpdate() {
            if (this.multipleSelection.length !== 1) {
                this.$message.error('请选择一条记录')
                return
            }
            this.handleUpdate(this.multipleSelection[0])
        },
        async handleUpdate(row) {
            this.isNew = false
            var temp = JSON.stringify(row)
            this.form = JSON.parse(temp)
            this.dialogVisible = true
        },
        async handleDelete() {
            if (this.multipleSelection.length < 1) {
                this.$message.error('请选择要删除的对象')
                return
            }
            for (let e of this.multipleSelection) {
                await this.$http.delete(`/company/staff/${e.id}`)
            }
            this.$message.success('删除成功')
            this.getData()
        },
        async getData() {
            this.loading = true
            let params = this.search
            params.target = 'jiashu'
            try {
                const resp = await this.$http.get(`/company/staff?page=${this.table.page}&pageSize=${this.table.pageSize}`, { params: params })
                if (!resp.data) {
                    this.$message.error(resp.data.msg)
                } else {
                    this.table.data = resp.data.data.records
                    this.table.total = resp.data.data.total
                }
            } catch (e) {
                this.$message.error(e.message)
            }
            this.loading = false
        },
        async getPermission() {
            const resp = await this.$http.get('/permission')
            console.log(resp)
            this.permission = resp.data
        }
    },
    mounted() {
        this.getPermission()
        this.getData()
    }
}
</script>
