/* eslint-disable */
const getRoutes = (requireComponent) => {
    let routes = []
    requireComponent.keys().forEach(filename => {
        // filename 文件
        // 第i个组件
        const config = requireComponent(filename)
        // 组件名
        if (config.default.path) {
            // console.log(config.default)
            routes.push({
                path: config.default.path,
                name: config.default.name,
                // component: ()=>import(`@/components/${sub.componentName}`)
                component: config.default
            })
        }
    })
    return routes
}

export default getRoutes