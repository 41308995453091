<template>
    <div>
        <div>
            <div style="float:right">
                <selectHospital v-if="permission.task_scheduling" v-model="search.hospital_id" style="margin-right:10px" @input="getData" />
                <el-button type="default" size="small" @click="getData">刷新</el-button>
                <el-button type="primary" size="small" @click="$router.push('/main/task/scheduling/form')">排期</el-button>
            </div>
            <el-page-header @back="$router.go(-1)" content="体检排期表" />
        </div>
        <el-calendar :first-day-of-week="7" v-loading="loading" style="margin-top:20px;">
            <template slot="dateCell" slot-scope="{date, data}">
                <div :class="data.isSelected ? 'active' : ''" @click="selectDate(date)" style="height: 100%">
                    <div style="font-size:120%;">
                        {{ data.day.split('-').slice(1).join('-') }}
                    </div>
                    <div v-if="dates[date.format('yyyy-MM-dd')]" class="active" style="font-size:90%;margin-top:10px">
                        人数({{ dates[date.format('yyyy-MM-dd')].used }}/{{ dates[date.format('yyyy-MM-dd')].total }})</div>
                    <div v-else></div>
                </div>
            </template>
        </el-calendar>
    </div>
</template>

<script>
export default {
    name: 'task-scheduling',
    path: '/main/task/scheduling',
    data() {
        return {
            loading: false,
            dialogVisible: false,
            search: { hospital_id: null },
            tableData: [],
            permission: {},
            dates: {
                // '2023-03-12': {
                //     count: 22
                // }
            }
        }
    },
    methods: {
        selectDate(e) {
            this.$router.push({ name: 'task-scheduling-form', params: { date: e.format('yyyy-MM-dd'), hospital_id:this.search.hospital_id } })
        },
        async getData() {
            this.loading = true
            const resp = await this.$http.get('/task/scheduling/calendar', { params: this.search })
            if (!resp.data) {
                this.$message.error(resp.data.msg)
            } else {
                this.dates = resp.data.data
            }
            this.loading = false
        },
        async getPermission() {
            const resp = await this.$http.get('/permission')
            this.permission = resp.data
        }
    },
    mounted() {
        this.getData()
        this.getPermission()
    }
}
</script>

<style scoped>
:deep(.el-table .el-table__cell) {
    padding: 2px 0;
}
</style>