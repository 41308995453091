<template>
    <el-dialog :visible="visible" width="40%" @close="handleClose" class="dialog" :title="title">
        <el-form ref="form" :model="form" label-width="110px" size="medium" :rules="rules">
            <el-form-item label="身份证号：" prop="idnum">
                <el-autocomplete ref="autoComplete" v-model="form.idnum" :fetch-suggestions="querySearchAsync"
                    placeholder="请输入" clearable style="width:100%" @select="handleSelect">
                    <template slot-scope="{ item }">
                        <div class="name">{{ item.value }}</div>
                        <span class="sbname">{{ item.idnum }}</span>
                    </template>
                    <template slot="append">
                        <btn-read-id-card @result="readCard" />
                    </template>
                </el-autocomplete>
            </el-form-item>
            <el-form-item label="姓名：" prop="name">
                <el-input v-model="form.name" placeholder="请输入姓名"></el-input>
            </el-form-item>
            <el-form-item label="体检日期：" prop="date">
                <el-date-picker v-model="form.date" type="date" placeholder="选择日期" value-format='yyyy-MM-dd'
                    style="width: 100%">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="体检时间：" prop="time">
                <el-select v-model="form.time" placeholder="请选择" style="width:100%">
                    <el-option label="08:00-09:00" value="08:00-09:00"></el-option>
                    <el-option label="09:00-10:00" value="09:00-10:00"></el-option>
                    <el-option label="10:00-11:00" value="10:00-11:00"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="体检费用：" prop="fee">
                <el-input v-model="form.fee" placeholder="请输入内容" />
            </el-form-item>
            <el-form-item label="体检套餐" prop="package">
                <el-input v-model="form.package"></el-input>
            </el-form-item>
            <el-form-item label="情况说明">
                <el-input type="textarea" v-model="form.remarks" />
            </el-form-item>
            <el-form-item style="padding-top: 40px;text-align: right">
                <el-button type="primary" @click="onSubmit">确 定</el-button>
                <el-button @click="handleClose">取 消</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
</template>

<script>
export default {
    props: {
        visible: {
            default: false
        },
        form: {
            default: {}
        },
        title: {
            default: '添加/修改'
        }
    },
    data() {
        return {
            innerVisible: false,
            rules: {
                name: [
                    { required: true, message: '此项为必填项', trigger: 'select' }
                ],
                idnum: [
                    { required: true, message: '此项为必填项', trigger: 'blur' }
                ],
                date: [
                    { required: true, message: '此项为必填项', trigger: 'blur' }
                ],
                time: [
                    { required: true, message: '此项为必填项', trigger: 'blur' }
                ],
                fee: [
                    { required: true, message: '此项为必填项', trigger: 'blur' }
                ],
                package: [
                    { required: true, message: '此项为必填项', trigger: 'blur' }
                ]
            },
            current_packages: [],
            package: {
            }
        }
    },
    methods: {
        readCard(e) {
            // this.form.idnum = '230604198202254410'
            if (e.ret == 1) {
                this.form.idnum = e.data.code
                this.form.name = e.data.name
                setTimeout(() => {
                    this.$refs.autoComplete.$refs.input.focus() // 聚焦到输入框
                    this.$refs.autoComplete.$refs.input.dispatchEvent(new Event('input')) // 触发搜索
                }, 0)
            }
            else
                this.$message.error(e.msg)
        },
        async querySearchAsync(queryString, cb) {
            if (!queryString) return {}
            const resp = await this.$http.get('/company/staff?page=1&pageSize=20&SFZH=' + queryString)
            console.log(resp)
            const records = resp.data.data.records.map(e => {
                return {
                    value: e.XM, idnum: e.SFZH, fee: e.fee
                }
            })
            cb(records)
        },
        handleSelect(item) {
            this.form.name = item.value
            this.form.idnum = item.idnum
            this.form.fee = parseFloat(item.fee).toFixed(2)
        },
        async onSubmit() {
            var va = false
            this.$refs.form.validate((valid) => {
                if (valid) {
                    va = true
                }
            })
            if (!va) {
                return
            }
            try {
                let url = '/fee/daikou'
                const resp = await this.$http.post(url, this.form)
                if (resp.data.code != 200) {
                    this.$message.error(resp.data.msg)
                } else {
                    this.$message.success('提交成功！')
                    this.handleClose()
                }
            } catch (e) {
                this.$message.error(e.message)
            }
            this.$emit('update')
        },
        handleClose() {
            this.$emit('close')
        }
    }
}
</script>

<style lang="scss" scoped>
.dialog ::v-deep .el-dialog__body {
    padding: 10px 20px;
}

.name {
    text-overflow: ellipsis;
    overflow: hidden;
}

.sbname {
    font-size: 12px;
    color: #b4b4b4;
}
</style>
