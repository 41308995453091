<template>
    <div>
        <el-page-header @back="$router.go(-1)" content="个人费用明细" />
        <div class="page">
            <div style="text-align: center;margin:30px">
                <h1>体检费用明细</h1>
            </div>
            <el-row style="line-height: 50px">
                <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">患者姓名： {{ data.jcxx.name|hide_name }}</el-col>
                <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12" style="text-align:right" class="textalign">体检编号： {{ data.jcxx.code }}</el-col>
                <el-col :xs="24" :sm="24" :md="24" :lg="18" :xl="18" style="text-align: left" class="textalign">套餐名称： {{ data.jcxx.xmzh }}</el-col>
                <el-col :xs="24" :sm="24" :md="24" :lg="6" :xl="6" style="text-align: right" class="textalign">套餐费用： {{ data.jcxx.fee | formatFee }} 元</el-col>
                <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12" style="text-align: left" class="textalign">体检时间： {{ data.jcxx.jdrq }}</el-col>
                <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12" style="text-align: right" class="textalign">项目费用合计： {{ sum | formatFee }} 元</el-col>
            </el-row>
            <el-table :data="data.ycxm" stripe border style="width: 100%">
                <el-table-column type="index" width="70" align="center" />
                <el-table-column prop="name" label="项目名称" width="" />
                <el-table-column prop="fee" label="费用" width="150" align="right" :formatter="formatFee" />
            </el-table>
            <el-form :inline="true" :model="data" size="mini"
                style="text-align: center;margin-top:30px;background-color: #f7f7f8;padding-top: 20px;border:1px solid #EBEEF5">
                <el-form-item>
                    <el-button type="primary" size="mini">打印</el-button>
                </el-form-item>
                <!-- <el-form-item>
                    <el-button type="primary" size="mini">下载PDF</el-button>
                </el-form-item> -->
            </el-form>
        </div>
    </div>
</template>

<script>
export default {
    name: 'stat-feePersionView',
    path: '/main/statistics/feePerson/:id',
    data() {
        return {
            sum: 0,
            data: {
                jcxx: {}
            }
        }
    },
    filters: {
        formatFee(value) {
            const pi = parseFloat(value)
            return pi.toFixed(2)
        },
        hide_name(value) {
            return value.substring(0, 1) + new Array(value.length).join('*')
        }
    },
    methods: {
        formatFee(row, column) {
            const value = row[column.property]
            let a = Number(value)  //转为数字格式
            let b = a.toLocaleString('zh', { style: 'currency', currency: 'CNY' })
            return b
        },
        async getData() {
            const resp = await this.$http.get(`/statistics/feePerson/${this.$route.params.id}`)
            this.data = resp.data
            this.data.ycxm.map(e => {
                this.sum += parseFloat(e.fee)
            })
        }
    },
    mounted() {
        this.getData()
    }
}
</script>

<style lang="scss" scoped>
.page {
    max-width: 800px;
    margin: 20px auto 10px;
    border: 1px solid #eee;
    padding: 20px;
    @media (max-width: 767px) {
        .textalign{
            text-align: left !important;
        }
    }
    @media (max-width: 900px) {
        .textalign{
            text-align: left !important;
        }
    }
}
</style>
