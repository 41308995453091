<template>
    <el-select :value="value" @input="$emit('input', $event)" placeholder="职工类型" :size="size" clearable>
        <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id" />
    </el-select>
</template>

<script>
export default {
    name: 'select-zglx',
    props: {
        value: {
            type: [String, Number, Object]
        },
        size: {
            default: 'small'
        }
    },
    data() {
        return {
            options: [
                {id: '普通职工', value:'普通职工'},
                {id: '资深技师', value:'资深技师'},
                {id: '二级工程师', value:'二级工程师'},
                {id: '一级工程师', value:'一级工程师'},
                {id: '企业高级专家', value:'企业高级专家'},
                {id: '企业首席专家', value:'企业首席专家'},
                {id: '油田公司技能专家', value:'油田公司技能专家'},
                {id: '集团公司技能专家', value:'集团公司技能专家'},
                {id: '集团公司技能大师', value:'集团公司技能大师'},
                {id: '处级干部', value:'处级干部'},
                {id: '局级干部', value:'局级干部'}
            ]
        }
    },
    methods: {
        async getData() {
            const hl = await this.$http.get('/hospital/hospital?page=1&pageSize=200')
            this.options = hl.data.data.records
        }
    },
    mounted() {
        // this.getData()
    }
}
</script>

