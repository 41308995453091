<template>
    <v-chart ref='daterangebar' v-loading='loading' class='chart el-table--scrollable-x el-table--scrollable-y' :option='option'/>
</template>

<script>

    export default {
        name: 'dateRangeBar',
        data() {
            return {
                loading: false,
                option: {
                    tooltip: {
                        trigger: 'axis',
                        formatter(params) {
                            if (params[1].data && params[0].data) {
                                return `<div>开始时间：${params[1].data}</div>` + `<div>结束时间：${params[0].data}</div>`
                            } else {
                                return ''
                            }
                        },
                        axisPointer: {
                            type: 'shadow'
                        }
                    },
                    grid: {
                        containLabel: true,
                        show: false,
                        right: 20,
                        left: 20,
                        bottom: 20,
                        top: 20,
                        backgroundColor: '#fff'
                    },
                    xAxis: {
                        type: 'time',
                        axisTick: {
                            show: true
                        },
                        axisLine: {
                            show: true
                        },
                        splitLine: {
                            show: false
                        }
                    },
                    yAxis: {
                        // inverse: true, // y 轴数据翻转，该操作是为了保证项目一放在最上面，项目七在最下面
                        // axisTick: {
                        //     show: false
                        // },
                        axisLine: {
                            show: true
                        },
                        data: []
                    },
                    series: [
                        {
                            name: '预约时间',
                            type: 'bar',
                            stack: 'duration',
                            itemStyle: {
                                color: '#007acc',
                                borderColor: '#fff',
                                borderWidth: 1
                            },
                            zlevel: -1,
                            data: [] // 结束时间
                        },
                        {
                            name: '预约时间',
                            type: 'bar',
                            stack: 'duration',
                            itemStyle: {
                                color: '#fff'
                            },
                            zlevel: -1,
                            z: 9,
                            data: [] // 开始时间
                        }
                    ]
                }
            }

        },
        methods: {
            async getData() {
                await this.$http.get('/task/daterange')
                    .then(resp => {
                        var tableData = resp.data.data
                        var ydata = []
                        var end = []
                        var start = []
                        tableData.forEach(function (item) {
                            ydata.unshift(item.name)
                            end.unshift(item.yy_end)
                            start.unshift(item.yy_start)
                        })
                        this.option.yAxis.data = ydata
                        this.option.series[0].data = end
                        this.option.series[1].data = start
                        this.$refs['daterangebar'].setOption(this.option)
                    }).catch(err => {
                        this.$message.error(err.message)
                    })
            },
            init(){
                this.$nextTick(() => {
                    this.$refs.daterangebar.resize()
                })
                window.addEventListener('resize', () => {
                    if (this.$refs && this.$refs.daterangebar) {
                        this.$refs.daterangebar.resize()
                    }
                })
            }
        },
        mounted() {
            this.getData()
            this.init()
        }
    }
</script>

<style scoped>
    .chart {
        width: 100%;
        height: 100%;
    }


</style>
