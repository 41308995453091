<template>
    <div>
        <el-page-header @back="$router.go(-1)" content="机构费用统计" />
        <el-form :inline="true" :model="search" size="mini"
            style="text-align: center;margin-top:30px;background-color: #f7f7f8;padding-top: 20px;border:1px solid #EBEEF5">
            <el-form-item label="时间">
                <el-date-picker v-model="search.daterange" type="daterange" value-format="yyyy-MM-dd"
                    range-separator="-" start-placeholder="开始时间" end-placeholder="结束时间">
                </el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" size="mini" @click="getData">查询</el-button>
            </el-form-item>
        </el-form>
        <el-row style="line-height: 50px">
            <el-col :span="12">
                体检数量合计： {{count}}
            </el-col>
            <el-col :span="12" style="text-align:right">
                体检费用合计： {{total}}
            </el-col>
        </el-row>
        <el-table :data="tableData" style="width: 100%" border stripe v-loading="loading">
            <el-table-column prop="id" type="index" />
            <el-table-column prop="dwmc" label="单位名称" width="" />
            <el-table-column prop="count" label="体检数量" width="" />
            <el-table-column prop="total" label="费用合计" :formatter="formatFee" />
        </el-table>
    </div>
</template>

<script>
export default {
    name: 'stat-feeHospital',
    path: '/main/statistics/feeHospital',
    data() {
        return {
            loading: false,
            search: {},
            tableData: [],
            count: 0,
            total: 0
        }
    },
    methods: {
        _formatFee(value){
            let a = Number(value)  //转为数字格式
            let b = a.toLocaleString('zh', { style: 'currency', currency: 'CNY' })
            return b
        },
        formatFee(row, column) {
            const value = row[column.property]
            return this._formatFee(value)
        },
        async getData() {
            this.loading = true
            this.count = 0
            this.total = 0
            const resp = await this.$http.get('/statistics/feeHospital', {params:this.search})
            this.tableData = resp.data
            this.tableData.map(e=>{
                this.count += parseInt(e.count)
                this.total += parseFloat(e.total)
            })
            this.total = this._formatFee(this.total)
            this.loading = false
        }
    },
    mounted() {
        this.getData()
    }
}
</script>

<style lang="scss" scoped>
    @media (max-width: 767px) {
        ::v-deep .el-date-editor--daterange.el-input, ::v-deep .el-date-editor--daterange.el-input__inner,  ::v-deep .el-date-editor--timerange.el-input,  ::v-deep .el-date-editor--timerange.el-input__inne{
            width: 200px !important;
        }

    }
    @media (max-width: 992px) {
        ::v-deep .el-date-editor--daterange.el-input, ::v-deep .el-date-editor--daterange.el-input__inner,  ::v-deep .el-date-editor--timerange.el-input,  ::v-deep .el-date-editor--timerange.el-input__inne{
            width: 200px !important;
        }

    }
</style>
