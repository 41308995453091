<template>
    <div>
        <el-page-header @back="$router.go(-1)" content="体检费用统计" />
        <el-form :inline="true" :model="form" size="mini" class="search-form">
            <el-form-item label="体检机构">
                <selectHospitalMulti size="mini" v-model="form.hospital_ids" />
            </el-form-item>
            <el-form-item label="二级单位">
                <select-subsidiary-2-multi size="mini" v-model="form.ejdw_ids" />
            </el-form-item>
            <el-form-item label="日期范围">
                <el-date-picker v-model="form.daterange" type="daterange" value-format="yyyy-MM-dd" range-separator="-"
                    start-placeholder="开始时间" end-placeholder="结束时间" style="width:270px">
                </el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" size="mini" @click="handleSearch">筛选</el-button>
            </el-form-item>

        </el-form>
        <el-row style="margin:20px 0">
            <el-col :span="24">
                <statAppointFeeDates :search="form" ref="renshuDates" />
            </el-col>
        </el-row>
        <el-row style="margin:20px 0">
            <el-col :span="24">
                <statAppointFeeDates2 :search="form" ref="renshuDates2" />
            </el-col>
        </el-row>
        <el-row style="margin:20px 0">
            <el-col :span="24">
                <statAppointSourceFeeDates :search="form" ref="sourceDates" />
            </el-col>
        </el-row>
        <div style="margin:50px 0;">
            <el-divider content-position="center">已经到底了</el-divider>
        </div>
    </div>
</template>

<script>
export default {
    name: "statistics-fee",
    path: "/main/statistics/fee",
    data() {
        let beforDate = new Date(new Date().setDate(new Date().getDate() - 15))
        let afterDate = new Date(Date.now() + 1000 * 60 * 60 * 24 * 15)
        return {
            form: {
                status: 1,
                hospital_ids: [],
                ejdw_ids: [],
                daterange: [beforDate.format('yyyy-MM-dd'), afterDate.format('yyyy-MM-dd')]
            }
        };
    },
    methods: {
        handleSearch() {
            this.$refs.renshuDates.getData()
            this.$refs.renshuDates2.getData()
            this.$refs.sourceDates.getData()
            this.$refs.xiangmuJiankang.getData()
            this.$refs.xiangmuZhiye.getData()
            this.$refs.xiangmuNvgong.getData()
        }
    }
}
</script>

<style lang="scss" scoped>
.form {
    margin: 10px auto;
    text-align: center;
    max-width: 380px;

    .title {
        padding: 20px;
        font-weight: bold;
        color: #4f9fe7;
    }
}

.search-form {
    text-align: center;
    margin-top: 15px;
    background-color: #f7f7f8;
    padding-top: 12px;
    border: 1px solid #EBEEF5;

    ::v-deep .el-form-item {
        margin-bottom: 12px;
    }
}
</style>