<template>
    <div>
        <el-page-header @back="$router.go(-1)" content="体检报告" />
        <div class="page" v-loading="loading">
            <div style="display: flex;flex-wrap: wrap; align-content: space-between;align-items: flex-end;">
                <div style="padding: 20px 20px 20px 0;font-size: 120%;font-weight: bold;">{{
                    report.jcxx.name | hide_name
                }}</div>
                <div style="padding: 20px">{{ report.jcxx.gender }}</div>
                <div style="padding: 20px">{{ report.jcxx.age }}岁</div>
                <div style="padding:20px;flex:1;text-align: right">单位：{{ report.jcxx.dwmc }}</div>
            </div>
            <div
                style="padding-bottom: 10px;display: flex;flex-wrap: wrap; align-content: space-between;align-items: flex-end;">
                <div>体检套餐： {{ report.jcxx.xmzh }}</div>
                <div style="flex:1;text-align:right">体检时间：{{ report.jcxx.jdrq }}</div>
            </div>
            <div>
                体检项目：
                <el-tag style="margin: 2px" type="info" size="mini" v-for="(e, i) in report.ycxm" :key="i">
                    {{ e.name }}
                </el-tag>
            </div>
            <!-- <div>完成科室: {{ report.jcxx.wcks }}</div> -->
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="体检结论" name="JL">
                    <div>
                        <div v-html="report.JLB.JCJG || ''" style="white-space:pre-wrap" />
                    </div>
                </el-tab-pane>
                <el-tab-pane label="体检建议" name="JY">
                    <div>
                        <div v-html="report.JLB.YLBJ || ''" style="white-space:pre-wrap" />
                    </div>
                </el-tab-pane>
                <el-tab-pane v-if="report.jcxx.wcks.includes('YB')" label="一般情况" name="一般">
                    <el-table :data="report.YBB" style="width: 100%">
                        <el-table-column prop="xxmc" label="子项名称" width="150" />
                        <el-table-column prop="CValue" label="值" width="120" />
                        <el-table-column prop="Doctor" label="记录医生" width="" />
                    </el-table>
                </el-tab-pane>
                <el-tab-pane v-if="report.jcxx.wcks.includes('HY')" label="实验室" name="HY">
                    <el-table :data="report.HYB" style="width: 100%" :span-method="objectSpanMethod">
                        <el-table-column prop="xmmc" label="项目名称" width="140" />
                        <el-table-column prop="xxmc" label="子项名称" width="150" />
                        <el-table-column prop="ItemResult" label="值" width="120" />
                        <el-table-column prop="ItemUnit" label="单位" width="120" />
                        <el-table-column prop="Flag" label="是否异常" width="120" />
                        <el-table-column prop="DefValue" label="参考值" width="" />
                    </el-table>
                </el-tab-pane>
                <el-tab-pane v-if="report.jcxx.wcks.includes('BC')" label="彩超室" name="BC">
                    <el-table :data="report.BCB" style="width: 100%">
                        <el-table-column prop="xxmc" label="名称" width="150" />
                        <el-table-column prop="MValue" label="内容" width="" />
                        <el-table-column prop="Doctor" label="记录医生" width="120" />
                    </el-table>
                </el-tab-pane>
                <el-tab-pane v-if="report.jcxx.wcks.includes('DX')" label="放射科" name="DX">
                    <el-table :data="report.DXB" style="width: 100%">
                        <el-table-column prop="xxmc" label="名称" width="150" />
                        <el-table-column prop="MValue" label="内容" width="" />
                        <el-table-column prop="Doctor" label="记录医生" width="120" />
                    </el-table>
                </el-tab-pane>
                <el-tab-pane v-if="report.jcxx.wcks.includes('MR')" label="磁共振" name="MR">
                    <el-table :data="report.MRB" style="width: 100%">
                        <el-table-column prop="xxmc" label="名称" width="150" />
                        <el-table-column prop="MValue" label="内容" width="" />
                        <el-table-column prop="Doctor" label="记录医生" width="120" />
                    </el-table>
                </el-tab-pane>
                <el-tab-pane v-if="report.jcxx.wcks.includes('CT')" label="CT" name="CT">
                    <el-table :data="report.CTB" style="width: 100%">
                        <el-table-column prop="xxmc" label="名称" width="150" />
                        <el-table-column prop="MValue" label="内容" width="" />
                        <el-table-column prop="Doctor" label="记录医生" width="120" />
                    </el-table>
                </el-tab-pane>
                <el-tab-pane v-if="report.jcxx.wcks.includes('PC')" label="Pet-CT" name="PC">Pet-CT</el-tab-pane>
                <el-tab-pane v-if="report.jcxx.wcks.includes('US')" label="多普勒" name="US">
                    <el-table :data="report.USB" style="width: 100%">
                        <el-table-column prop="xxmc" label="名称" width="150" />
                        <el-table-column prop="MValue" label="内容" width="" />
                        <el-table-column prop="Doctor" label="记录医生" width="120" />
                    </el-table>
                </el-tab-pane>
                <!-- <el-tab-pane v-if="report.jcxx.wcks.includes('FK')" label="妇科" name="FK"></el-tab-pane> -->
                <!-- <el-tab-pane v-if="report.jcxx.wcks.includes('WJ')" label="胃镜" name="WJ"></el-tab-pane> -->
                <el-tab-pane v-if="report.jcxx.wcks.includes('ED')" label="病理" name="ED">
                    <el-table :data="report.EDB" style="width: 100%">
                        <el-table-column prop="xxmc" label="名称" width="150" />
                        <el-table-column prop="MValue" label="内容" width="" />
                        <el-table-column prop="Doctor" label="记录医生" width="120" />
                    </el-table>
                </el-tab-pane>
                <el-tab-pane v-if="report.jcxx.wcks.includes('EL')" label="心电" name="EL">
                    <el-table :data="report.ELB" style="width: 100%">
                        <el-table-column prop="xxmc" label="名称" width="150" />
                        <el-table-column prop="MValue" label="内容" width="" />
                        <el-table-column prop="Doctor" label="记录医生" width="120" />
                    </el-table>
                </el-tab-pane>
                <el-tab-pane v-if="report.jcxx.wcks.includes('NK')" label="内科" name="NK">
                    <el-table :data="report.NKB" style="width: 100%">
                        <el-table-column prop="xxmc" label="名称" width="150" />
                        <el-table-column prop="MValue" label="内容" width="" />
                        <el-table-column prop="Doctor" label="记录医生" width="120" />
                    </el-table>
                </el-tab-pane>
                <el-tab-pane v-if="report.jcxx.wcks.includes('SK')" label="神经内科" name="SK">
                    <el-table :data="report.SKB" style="width: 100%">
                        <el-table-column prop="xxmc" label="名称" width="150" />
                        <el-table-column prop="MValue" label="内容" width="" />
                        <el-table-column prop="Doctor" label="记录医生" width="120" />
                    </el-table>
                </el-tab-pane>
                <el-tab-pane v-if="report.jcxx.wcks.includes('WK')" label="外科" name="WK">
                    <el-table :data="report.WKB" style="width: 100%">
                        <el-table-column prop="xxmc" label="名称" width="150" />
                        <el-table-column prop="MValue" label="内容" width="" />
                        <el-table-column prop="Doctor" label="记录医生" width="120" />
                    </el-table>
                </el-tab-pane>
                <!-- <el-tab-pane v-if="report.jcxx.wcks.includes('YK')" label="眼科" name="YK"></el-tab-pane> -->
                <!-- <el-tab-pane v-if="report.jcxx.wcks.includes('EBH')" label="耳鼻喉" name="EBH"></el-tab-pane>
                <el-tab-pane v-if="report.jcxx.wcks.includes('KQ')" label="口腔科" name="KQ"></el-tab-pane>
                <el-tab-pane v-if="report.jcxx.wcks.includes('PF')" label="皮肤科" name="PF"></el-tab-pane>
                <el-tab-pane v-if="report.jcxx.wcks.includes('EQ')" label="动态心电" name="EQ"></el-tab-pane>
                <el-tab-pane v-if="report.jcxx.wcks.includes('EF')" label="肺功能" name="EF"></el-tab-pane> -->
                <el-tab-pane v-if="report.jcxx.wcks.includes('RX')" label="乳腺扫描" name="RX">
                    <el-table :data="report.RXB" style="width: 100%">
                        <el-table-column prop="xxmc" label="名称" width="150" />
                        <el-table-column prop="MValue" label="内容" width="" />
                        <el-table-column prop="Doctor" label="记录医生" width="120" />
                    </el-table>
                </el-tab-pane>
                <!-- <el-tab-pane v-if="report.jcxx.wcks.includes('EM')" label="骨密度" name="EM"></el-tab-pane>
                <el-tab-pane v-if="report.jcxx.wcks.includes('EC')" label="动脉硬化" name="EC"></el-tab-pane>
                <el-tab-pane v-if="report.jcxx.wcks.includes('EJ')" label="肝硬度" name="EJ"></el-tab-pane>
                <el-tab-pane v-if="report.jcxx.wcks.includes('WZ')" label="问诊" name="WZ"></el-tab-pane> -->
                <el-tab-pane v-if="report.jcxx.wcks.includes('EG')" label="C14" name="EG">
                    <el-table :data="report.EGB" style="width: 100%">
                        <el-table-column prop="xxmc" label="名称" width="150" />
                        <el-table-column prop="MValue" label="内容" width="" />
                        <el-table-column prop="Doctor" label="记录医生" width="120" />
                    </el-table>
                </el-tab-pane>
                <el-tab-pane v-if="report.jcxx.wcks.includes('EP')" label="人体成分" name="EP">
                    <el-table :data="report.EPB" style="width: 100%">
                        <el-table-column prop="xxmc" label="名称" width="150" />
                        <el-table-column prop="MValue" label="内容" width="" />
                        <el-table-column prop="Doctor" label="记录医生" width="120" />
                    </el-table>
                </el-tab-pane>
                <el-tab-pane v-if="report.jcxx.wcks.includes('EK')" label="糖尿病早期筛查" name="EK">
                    <el-table :data="report.EKB" style="width: 100%">
                        <el-table-column prop="xxmc" label="名称" width="150" />
                        <el-table-column prop="MValue" label="内容" width="" />
                        <el-table-column prop="Doctor" label="记录医生" width="120" />
                    </el-table>
                </el-tab-pane>
                <!-- <el-tab-pane v-if="report.jcxx.wcks.includes('JSY')" label="驾驶员体检" name="JSY"></el-tab-pane> -->
                <!-- <el-tab-pane v-if="report.jcxx.wcks.includes('EH')" label="人体抗组试验" name="EH"></el-tab-pane> -->
                <!-- <el-tab-pane v-if="report.jcxx.wcks.includes('HRA')" label="HRA健康风险评估" name="HRA"></el-tab-pane> -->
                <!-- <el-tab-pane v-if="report.jcxx.wcks.includes('ES')" label="动态血压" name="ES"></el-tab-pane> -->
                <!-- <el-tab-pane v-if="report.jcxx.wcks.includes('EN')" label="电测听" name="EN"></el-tab-pane> -->
                <el-tab-pane v-if="report.jcxx.wcks.includes('FZInfo')" label="随访" name="FZInfo">
                    <el-table :data="report.FZInfo" style="width: 100%">
                        <el-table-column prop="xxmc" label="名称" width="150" />
                        <el-table-column prop="MValue" label="内容" width="" />
                        <el-table-column prop="Doctor" label="记录医生" width="120" />
                    </el-table>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>

<script>
export default {
    name: 'hospital-jcxx-report',
    path: '/main/hospital/jcxx/report/:id',
    data() {
        return {
            loading: false,
            activeName: 'JL',
            report: {
                jcxx: { wcks: [] }, ycxm: [], JLB: { JCJG: '' }, HYB: [],
                YBB: [], BCB: [], DXB: [], MRB: [], CTB: [], USB: [], EDB: [], ELB: [], NKB: [],
                SKB: [], WKB: [], RX: [], EG: [], EP: [], EK: [], FZInfo: []
            }
        }
    },
    filters: {
        hide_name(value) {
            return value.substring(0, 1) + new Array(value.length).join('*')
        }
    },
    methods: {
        /* 表格合并列和行 */
        objectSpanMethod({ row, column, rowIndex, columnIndex }) {
            let data = this.report.HYB //拿到当前table中数据
            let cellValue = row[column.property] //当前位置的值
            let noSortArr = ['ItemResult', 'ItemUnit', 'Flag', 'DevValue'] //不需要合并的字段（不进行合并行的prop）
            if (cellValue && !noSortArr.includes(column.property)) {
                let prevRow = data[rowIndex - 1] //获取到上一条数据
                let nextRow = data[rowIndex + 1] //下一条数据
                if (prevRow && prevRow[column.property] === cellValue) { //当有上一条数据，并且和当前值相等时
                    return { rowspan: 0, colspan: 0 }
                } else {
                    let countRowspan = 1
                    while (nextRow && nextRow[column.property] === cellValue) { //当有下一条数据并且和当前值相等时,获取新的下一条
                        nextRow = data[++countRowspan + rowIndex]
                    }
                    if (countRowspan > 1) {
                        return { rowspan: countRowspan, colspan: 1 }
                    }
                }
            }
        },
        handleClick(tab, event) {
            console.log(tab, event)
        },
        async getData() {
            this.loading = true
            console.log(this.$route.params.code)
            const resp = await this.$http.get(`/hospital/report/old/${this.$route.params.id}`)
            console.log(resp)
            if (resp.data.code === 200)
                this.report = resp.data.data
            this.loading = false
        }
    },
    mounted() {
        this.getData()
    }
}
</script>

<style scoped>
.page {
    margin: 20px auto;
    max-width: 800px;
    border: 1px solid #eee;
    padding: 20px;
}
</style>