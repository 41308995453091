<template>
    <div class="solugan">
        <h1 style="font-size: 200%">员工体检管理平台</h1>
        <div style="max-width: 780px;">
            <h3 style="text-align:center">2024年度体检工作整体工作进度</h3>
            <el-steps :active="1" finish-status="success" align-center>
                <el-step title="分配账号"></el-step>
                <el-step title="核对人员信息"></el-step>
                <el-step title="确认套餐费用"></el-step>
                <el-step title="体检排期"></el-step>
                <el-step title="员工体检"></el-step>
                <el-step title="检后健康管理"></el-step>
            </el-steps>
        </div>
    </div>
</template>

<script>
export default {
    name: 'solugan',
}
</script>

<style scoped>
.solugan {
    /* background: url(./assets/hero.png) no-repeat; */
    background-position: right 20px center;
    padding: 0px 20px 0px 20px;
    margin-bottom: 30px;
    background-repeat: no-repeat;
    /* background-position: right bottom; */
    background-size: 40%;
    opacity: 0.8;
}
::v-deep .el-step__title {
    writing-mode:tb-rl;
    margin: 10px auto;
}
</style>