<template>
    <el-dialog title="编辑" :visible="visible" width="40%" @close="close">
        <div style="padding:30px; text-align: center">
            当前同步状态: 未同步
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="close">{{ $t('form.cancel') }}</el-button>
            <el-button @click="formSubmit" type="primary" :loading="loading" :disabled="loading">{{ $t('form.ok') }}
            </el-button>
        </span>
    </el-dialog>
</template>
<script>
export default {
    props: {
        visible: {
            default: false
        },
        form: {
            default: {}
        },
        isNew: {
            default: false
        }
    },
    data() {
        return {
            loading: false,
            activeName: 'tab1'
        }
    },
    methods: {
        async formSubmit() {
            const valid = await this.$refs['form'].validate().then(res => res).catch(error => error)
            if (!valid) return
            const resp = await this.$http.post('/hospital/hospital', this.form)
            if (resp.data.code != 200) {
                this.$message.error(resp.data.msg)
            } else {
                this.$message.success('保存成功')
                this.$emit('update')
                this.$emit('close')
            }
        },
        close() {
            this.$emit('close')
        }
    },
    mounted() {

    }
}
</script>

<style scoped>
/deep/ .el-dialog__body {
    padding: 0 20px;
}
</style>
