import { render, staticRenderFns } from "./xiaofei.vue?vue&type=template&id=597234a7&scoped=true&"
import script from "./xiaofei.vue?vue&type=script&lang=js&"
export * from "./xiaofei.vue?vue&type=script&lang=js&"
import style0 from "./xiaofei.vue?vue&type=style&index=0&id=597234a7&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/.pnpm/vue-loader@15.10.1_cache-loader@4.1.0_css-loader@3.6.0_vue-template-compiler@2.6.14_webpack@4.46.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "597234a7",
  null
  
)

export default component.exports