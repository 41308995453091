<template>
    <div>
        <div>
            <div style="float:right">
                <select-hospital size="mini" style="margin-right:10px" v-model="search.hospital_id"></select-hospital>
                <el-button size="mini" @click="getData">刷新</el-button>
            </div>
            <el-page-header @back="$router.go(-1)" content="报告数量" />
        </div>
        <el-table :data="tableData" style="width: 100%" height="calc(100vh - 120px)">
            <el-table-column prop="date" label="日期" width="180" />
            <el-table-column prop="count" label="数量" width="180" />
            <el-table-column prop="address" label="备注" />
            <el-table-column label="操作" width="120" align="center">
                <template slot-scope="scope">
                    <el-button size="mini" type="primary" plain @click="exportExcel(scope.row)">导出名单</el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
export default {
    name: 'hospital-interface-count',
    path: '/main/hospital/interface/report_counts',
    data() {
        return {
            search: {},
            tableData: []
        }
    },
    methods: {
        async exportExcel(row) {
            try {
                this.loading = true
                let params = this.search
                params.date = row.date
                params.export = 'xlsx'//'csv'
                const resp = await this.$http.get(`/hospital/interface/report_export_by_date`, { params: params, responseType: 'blob' })
                let blob = new Blob([resp.data], { type: 'application/vnd.openxmlformats;charset=utf-8;' })
                var link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = decodeURI(escape(resp.headers['content-disposition'].split('=')[1]))
                link.click()
                window.URL.revokeObjectURL(link.href)
            } catch (e) {
                this.$message.error(e.message)
            } finally {
                this.loading = false
            }
        },
        async getData() {
            const resp = await this.$http.get('/hospital/interface/report_counts', {params: this.search})
            console.log(resp.data)
            this.tableData = resp.data.data
        }
    },
    mounted() {
        this.getData()
    }
}
</script>