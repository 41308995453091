<template>
    <el-dialog title="编辑" :visible="visible" width="60%" :before-close="close">
        <el-tabs v-model="activeName" style="height: 55vh;overflow: auto;">
            <el-tab-pane label="基本信息" name="tab1">
                <el-form ref="form1" :model="form" label-width="100px">
                    <el-row>
                        <el-row>
                            <el-col :span="8">
                                <el-form-item label="职工类型" prop="ZGLX"
                                    :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                                    <el-select v-model="form.ZGLX" placeholder="请选择">
                                        <el-option label="普通职工" value="普通职工"></el-option>
                                        <el-option label="资深技师" value="资深技师"></el-option>
                                        <el-option label="二级工程师" value="二级工程师"></el-option>
                                        <el-option label="一级工程师" value="一级工程师"></el-option>
                                        <el-option label="企业高级专家" value="企业高级专家"></el-option>
                                        <el-option label="企业首席专家" value="企业首席专家"></el-option>
                                        <el-option label="油田公司技能专家" value="油田公司技能专家"></el-option>
                                        <el-option label="集团公司技能专家" value="集团公司技能专家"></el-option>
                                        <el-option label="集团公司技能大师" value="集团公司技能大师"></el-option>
                                        <el-option label="处级干部" value="处级干部"></el-option>
                                        <el-option label="局级干部" value="局级干部"></el-option>
                                        <el-option label="博士研究生(激励)" value="博士研究生(激励)"></el-option>
                                        <el-option label="硕士研究生(激励)" value="硕士研究生(激励)"></el-option>
                                        <el-option label="本科生(激励)" value="本科生(激励)"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="工种" prop="ZYGG"
                                    :rules="{ validator: checkGongling, trigger: 'blur' }">
                                    <el-input v-model.trim="form.ZYGG" clearable></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="工龄" prop="GL"
                                    :rules="[{ validator: checkGongling, trigger: 'blur' }]">
                                    <el-input v-model.trim="form.GL" clearable></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="8">
                                <el-form-item label="姓名" prop="XM"
                                    :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                                    <el-input v-model.trim="form.XM"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="身份证号" prop="SFZH" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },
    { pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/, message: '身份证号格式不正确', trigger: 'blur' }]">
                                    <el-input v-model.trim="form.SFZH" @blur="readAge"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="性别" prop="XB"
                                    :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                                    <el-radio-group v-model="form.XB">
                                        <el-radio label="男">男</el-radio>
                                        <el-radio label="女">女</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="8">
                                <el-form-item label="二级单位" prop="EJDWID"
                                    :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                                    <select-subsidiary-2 v-model="form.EJDWID" />
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="三级单位" prop="SJDWID"
                                    :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                                    <select-subsidiary-3 :ejdw_id="form.EJDWID" v-model="form.SJDWID" />
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="四级单位" prop="SJDWID"
                                    :rules="{ required: false, message: '不能为空', trigger: 'blur' }">
                                    <select-subsidiary-4 :sjdw_id="form.SJDWID" v-model="form.FJDWID" />
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="16">
                                <el-form-item label="女工/家属" prop="TJLX_NGJS"
                                    :rules="{ required: false, message: '不能为空', trigger: 'blur' }">
                                    <el-radio-group v-model="form.TJLX_NGJS">
                                        <el-radio label="">无</el-radio>
                                        <el-radio label="女工">女工</el-radio>
                                        <el-radio label="家属">家属</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="档案年份" prop="DANF" v-show="$store.getters.permission.staff_DANF"
                                    :rules="{ validator: validateDAanf, trigger: 'blur' }">
                                    <el-input v-model.trim="form.DANF"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-col :span="24">
                            <el-form ref="form6" :model="form" label-width="100px">
                                <el-form-item label="备注">
                                    <el-input type="textarea" rows="2" v-model.trim="form.remarks"></el-input>
                                </el-form-item>
                            </el-form>
                        </el-col>
                    </el-row>
                    <!-- <el-form-item label="体检费" prop="fee" :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                        <el-input v-model.trim="form.fee"></el-input>
                    </el-form-item> -->
                </el-form>
            </el-tab-pane>
            <el-tab-pane label="职业信息" name="tab2">
                <el-form ref="form2" :model="form" label-width="120px">
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="噪声" prop="SFZS"
                                :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                                <el-radio-group v-model="form.SFZS">
                                    <el-radio label="是">是</el-radio>
                                    <el-radio label="否">否</el-radio>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item label="接害工龄" prop="ZSJHGL" v-if="form.SFZS == '是'"
                                :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                                <el-input v-model.trim="form.ZSJHGL"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="高温" prop="SFGW"
                                :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                                <el-radio-group v-model="form.SFGW">
                                    <el-radio label="是">是</el-radio>
                                    <el-radio label="否">否</el-radio>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item label="接害工龄" prop="GWJHGL" v-if="form.SFGW == '是'"
                                :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                                <el-input v-model.trim="form.GWJHGL"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="毒物" prop="SFDW"
                                :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                                <el-radio-group v-model="form.SFDW">
                                    <el-radio label="是">是</el-radio>
                                    <el-radio label="否">否</el-radio>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item label="接害工龄" prop="DWJHGL" v-if="form.SFDW == '是'"
                                :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                                <el-input v-model.trim="form.DWJHGL"></el-input>
                            </el-form-item>
                            <el-form-item label="接害因素" prop="JHYS" v-if="form.SFDW == '是'"
                                :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                                <el-checkbox-group v-model="form.JHYS">
                                    <el-checkbox label="硫化氢" name="JHYS"></el-checkbox>
                                    <el-checkbox label="溶剂汽油" name="JHYS"></el-checkbox>
                                    <el-checkbox label="氨" name="JHYS"></el-checkbox>
                                    <el-checkbox label="苯" name="JHYS"></el-checkbox>
                                    <el-checkbox label="氯气" name="JHYS"></el-checkbox>
                                    <el-checkbox label="锰及化合物" name="JHYS"></el-checkbox>
                                    <el-checkbox label="甲醇" name="JHYS"></el-checkbox>
                                    <el-checkbox label="盐酸" name="JHYS"></el-checkbox>
                                    <el-checkbox label="一氧化碳" name="JHYS"></el-checkbox>
                                    <el-checkbox label="硫酸" name="JHYS"></el-checkbox>
                                    <el-checkbox label="氢氧化钠" name="JHYS"></el-checkbox>
                                    <el-checkbox label="氮氧化钠" name="JHYS"></el-checkbox>
                                    <el-checkbox label="二氧化硫" name="JHYS"></el-checkbox>
                                    <el-checkbox label="戊烷" name="JHYS"></el-checkbox>
                                    <el-checkbox label="正己烷" name="JHYS"></el-checkbox>
                                    <el-checkbox label="四氯化碳" name="JHYS"></el-checkbox>
                                    <el-checkbox label="酸雾酸酐" name="JHYS"></el-checkbox>
                                    <el-checkbox label="致喘物" name="JHYS"></el-checkbox>
                                </el-checkbox-group>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="驾驶员" prop="SFJSY"
                                :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                                <el-radio-group v-model="form.SFJSY">
                                    <el-radio label="是">是</el-radio>
                                    <el-radio label="否">否</el-radio>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item label="接害工龄" prop="JSYJHGL" v-if="form.SFJSY == '是'"
                                :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                                <el-input v-model.trim="form.JSYJHGL"></el-input>
                            </el-form-item>
                            <el-form-item label="类型" prop="JSYJHLX" v-if="form.SFJSY == '是'"
                                :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                                <el-select v-model="form.JSYJHLX" placeholder="请选择">
                                    <el-option label="大型车驾驶员" value="大型车驾驶员"></el-option>
                                    <el-option label="小型车驾驶员" value="小型车驾驶员"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="粉尘" prop="SFFC"
                                :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                                <el-radio-group v-model="form.SFFC">
                                    <el-radio label="是">是</el-radio>
                                    <el-radio label="否">否</el-radio>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item label="接害工龄" prop="FCJHGL" v-if="form.SFFC == '是'"
                                :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                                <el-input v-model.trim="form.FCJHGL"></el-input>
                            </el-form-item>
                            <el-form-item label="接害类型" prop="FCJHLX" v-if="form.SFFC == '是'"
                                :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                                <el-select v-model="form.FCJHLX" placeholder="请选择">
                                    <el-option label="煤尘" value="煤尘"></el-option>
                                    <el-option label="矽尘" value="矽尘"></el-option>
                                    <el-option label="棉尘" value="棉尘"></el-option>
                                    <el-option label="其他粉尘" value="其他粉尘"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="高处作业" prop="SFGC"
                                :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                                <el-radio-group v-model="form.SFGC">
                                    <el-radio label="是">是</el-radio>
                                    <el-radio label="否">否</el-radio>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item label="接害工龄" prop="GCJHGL" v-if="form.SFGC == '是'"
                                :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                                <el-input v-model.trim="form.GCJHGL"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="放射" prop="SFFS"
                                :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                                <el-radio-group v-model="form.SFFS">
                                    <el-radio label="是">是</el-radio>
                                    <el-radio label="否">否</el-radio>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item label="接害工龄" prop="FSJHGL" v-if="form.SFFS == '是'"
                                :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                                <el-input v-model.trim="form.FSJHGL"></el-input>
                            </el-form-item>
                            <el-form-item label="放射类型" prop="FSJHLX" v-if="form.SFFS == '是'"
                                :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                                <el-select v-model="form.FSJHLX" placeholder="请选择">
                                    <el-option label="工业辐照3A" value="3A"></el-option>
                                    <el-option label="工业探伤3B" value="3B"></el-option>
                                    <el-option label="发光涂料工业3C" value="3C"></el-option>
                                    <el-option label="放射性同位素生产3D" value="3D"></el-option>
                                    <el-option label="测井3E" value="3E"></el-option>
                                    <el-option label="加速器运行3F" value="3F"></el-option>
                                    <el-option label="其它3G" value="3G"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="放射剂量" prop="FSJHJL" v-if="form.SFFS == '是'" placeholder="上一年度接触放射剂量"
                                :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                                <el-input v-model.trim="form.FSJHJL"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="压力容器作业" prop="SFYL"
                                :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                                <el-radio-group v-model="form.SFYL">
                                    <el-radio label="是">是</el-radio>
                                    <el-radio label="否">否</el-radio>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item label="接害工龄" prop="YLJHGL" v-if="form.SFYL == '是'"
                                :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                                <el-input v-model.trim="form.YLJHGL"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="电工" prop="SFDG"
                                :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                                <el-radio-group v-model="form.SFDG">
                                    <el-radio label="是">是</el-radio>
                                    <el-radio label="否">否</el-radio>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item label="接害工龄" prop="DGJHGL" v-if="form.SFDG == '是'"
                                :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                                <el-input v-model.trim="form.DGJHGL"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </el-tab-pane>
        </el-tabs>
        <span slot="footer" class="dialog-footer">
            <el-button @click="close">{{ $t('form.cancel') }}</el-button>
            <el-button @click="formSubmit" type="primary" :loading="loading" :disabled="loading">{{ $t('form.ok') }}
            </el-button>
        </span>
    </el-dialog>
</template>

<script>
export default {
    props: {
        visible: {
            default: false
        },
        form: {
            default: {}
        },
        isNew: {
            default: false
        }
    },
    data() {
        return {
            loading: false,
            activeName: 'tab1',
        }
    },
    methods: {
        validateDAanf(rule, value, callback) {
            if (value && value !== '') {
                value = parseInt(value)
                if (this.form.SFZH && this.form.SFZH.length > 17) {
                    var UUserCard = this.form.SFZH;
                    var birthDateStr = UUserCard.substring(6, 10);
                    var starAge = parseInt(birthDateStr) - 3;
                    var endAge = parseInt(birthDateStr) + 3;
                    if (starAge <= value && value <= endAge) {
                        callback();
                    } else {
                        callback(new Error(`档案年份不能大于${endAge},不能小于${starAge}`));
                    }
                } else {
                    callback(new Error('请先输入身份证号'));
                }
            } else {
                callback();
            }
        },
        checkGongling(rule, value, callback) {
            const fieldsToCheck = ['SFZS', 'SFGW', 'SFDW', 'SFJSY', 'SFFC', 'SFGC', 'SFFS', 'SFYL', 'SFDG'];
            if (!value && fieldsToCheck.some(field => this.form[field] === '是')) {
                return callback(new Error('有职业病体检时不能为空'));
            }
            callback();
        },
        async formSubmit() {
            const valid = await this.$refs['form1'].validate().catch(() => false)
            if (!valid) {
                this.activeName = 'tab1'
                return
            }
            const valid2 = await this.$refs['form2'].validate().catch(() => false)
            if (!valid2) {
                this.activeName = 'tab2'
                return
            }
            const resp = await this.$http.post('/company/staff', this.form)
            if (resp.data.code != 200) {
                this.$message.error(resp.data.msg)
            } else {
                this.$message.success('保存成功')
                this.$emit('update')
                this.$emit('close')
            }
        },
        close() {
            this.$emit('close')
        },
        readAge() {
            //获取年龄
            if (this.form.SFZH.length != 18) return
            const currentYear = new Date().getFullYear();
            const birthYear = parseInt(this.form.SFZH.substr(6, 4), 10);
            this.form.NL = currentYear - birthYear // + 1;
            this.form.XB = (this.form.SFZH.slice(14, 17) % 2 ? "1" : "2" == '1') ? '男' : '女'
            this.form.DANF = birthYear
        },
    },
    mounted() {

    }
}
</script>

<style scoped>
::v-deep .el-dialog__body {
    padding: 0 20px;
}

/* ::v-deep .el-form-item {
        margin-bottom: 10px !important;
    } */
</style>
