<template>
    <v-chart ref="chart" v-loading="loading" class="chart" :option="option" />
</template>

<script>
export default {
    name: 'ejdwWanchengduBarChart',
    data() {
        return {
            loading: false,
            option: {
                title: {
                    text: '二级单位体检完成情况',
                    left: 'center'
                },
                color: ['#3c78d8', '#cc4125'],
                grid: {
                    left: '5%', // 与容器左侧的距离
                    right: '5%', // 与容器右侧的距离
                    top: '12%', // 与容器顶部的距离
                    bottom: '15%' // 与容器底部的距离
                },
                legend: {
                    left: 'center',
                    top: 'bottom',
                    data: ['已检', '未检']
                },
                tooltip: {
                    //trigger: 'item',
                    //formatter: '{a} <br/>{b} : {c} ({d}%)'
                },
                xAxis: {
                    data: []
                },
                yAxis: {},
                barGap: '10%',
                series: [
                    {
                        name: '已检',
                        type: 'bar',
                        stack: '总数',
                        // barWidth: 49,
                        data: []//这里在data数据里放完成的数据

                    },
                    {
                        name: '未检',
                        type: 'bar',
                        stack: '总数',
                        data: [],//这里在data数据里放未结案的数据
                        label: {
                            show: false,
                            formatter: '{c}'
                        }
                    }
                ]
            }
        }
    },
    methods: {
        async getData() {
            this.loading = true
            const resp = await this.$http.get(`/statistics/ejdw_wanchengdu_bar_chart?id=${this.id}`)
            console.log(resp.data)
            this.option.xAxis.data = resp.data.label
            this.option.series[0].data = resp.data.done
            this.option.series[1].data = resp.data.left
            this.$refs.chart.setOption(this.option)
            this.loading = false
        }
    },
    mounted() {
        this.getData()
        this.$nextTick(() => {
            this.$refs.chart.resize()
        })
        window.addEventListener('resize', () => {
            if (this.$refs && this.$refs.chart) {
                this.$refs.chart.resize()
            }
        })
    }
}
</script>

<style scoped>
.chart {
    width: 100%;
    height: 400px;
}
</style>
