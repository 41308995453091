<template>
    <div>
        <el-table :data="tableData" stripe style="width: 100%">
            <el-table-column type="index" width="55" align="center" />
            <el-table-column prop="name" label="名称" width="200" />
            <el-table-column prop="code" label="项目代码" width="120" />
            <el-table-column prop="summary" label="简介" show-overflow-tooltip />
            <el-table-column prop="fee" label="金额" width="120" />
            <el-table-column prop="remark" label="备注" />
            <el-table-column label="操作" width="100" align="center">
                <template slot-scope="scope">
                    <el-button plain size="mini" @click="handleUpdate(scope.row)">编辑</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-dialog title="编辑表单" :visible.sync="dialogVisible" width="60%">
            <el-form ref="form" :model="form" label-width="100px">
                <el-form-item label="名称" prop="name" :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                    <el-input v-model.trim="form.name" readonly></el-input>
                </el-form-item>
                <el-form-item label="项目代码" prop="code" :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                    <el-input v-model.trim="form.code"></el-input>
                </el-form-item>
                <el-form-item label="项目金额" prop="fee" :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                    <el-input v-model.trim="form.fee" readonly></el-input>
                </el-form-item>
                <el-form-item label="项目介绍">
                    <el-input type="textarea" v-model="form.summary"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="handleSubmit">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            tableData: [],
            dialogVisible: false,
            form: { gender: [] },
        }
    },
    methods: {
        handleUpdate(row) {
            this.form = row
            this.form.gender = this.form.gender || []
            this.dialogVisible = true
        },
        async handleSubmit() {
            const valid = await this.$refs['form'].validate().catch(() => false)
            if (!valid) return
            this.$http.post('/hospital/package2/bixuan', this.form)
                .then(resp => {
                    this.$message[resp.data.status](resp.data.msg)
                    if (resp.data.status == 'success') {
                        this.getData()
                        this.dialogVisible = false
                    }
                })
                .catch(error => {
                    this.$message.error(error.message)
                })
        },
        async getData() {
            this.$http.get('/hospital/package2/bixuan')
                .then(resp => {
                    this.tableData = resp.data.data
                }).catch(err => {
                    this.$message.error(err.message)
                })
        }
    },
    mounted() {
        this.getData()
    }
}
</script>
