<template>
    <div>0</div>
</template>
<script>
export default {
    name: 'planUsed',
    data() {
        return {
        }
    }
}
</script>