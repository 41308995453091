<template>
    <zk-search-form-inline :form="form" @search="$emit('search', form)">
        <el-form-item label="姓名">
            <el-input v-model.trim="form.name" clearable></el-input>
        </el-form-item>
        <el-form-item label="身份证号">
            <el-input v-model.trim="form.id_num" clearable></el-input>
        </el-form-item>
        <el-form-item label="体检机构">
            <el-select v-model="form.online" clearable placeholder="请选择">
                <el-option label="在线" :value="1">
                </el-option>
                <el-option label="离线" :value="0">
                </el-option>
                <el-option label="未激活" :value="3">
                </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="录入时间">
            <el-date-picker v-model="form.daterange" size="small" type="datetimerange"
                value-format="yyyy-MM-dd HH:mm:ss" range-separator="-" start-placeholder="开始时间" end-placeholder="结束时间">
            </el-date-picker>
        </el-form-item>
    </zk-search-form-inline>
</template>

<script>
export default {
    data() {
        return {
            form: {}
        }
    }
}
</script>

