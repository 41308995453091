const data = {
    table: {
        columns: [
            {title: 'No.', field: 'id', width: '140'},
            {title: '姓名', field: 'name', width: '200'},
            {title: '身份证号', field: 'id_num', width: '180'},
            {title: '性别', field: 'gender', width: '150'},
            {title: '年龄', field: 'age', width: '150'},
            {title: '体检项目', field: 'project', width: '150'},
            {title: '备注', field: 'desc', width: ''}
        ],
        data: [],
        total: 0,
        page: 1,
        pageSize: 20
    }
}

export default data
