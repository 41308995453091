const fee_formatter = (row, column) => {
    const value = row[column.property]
    return parseFloat(value).toFixed(2)
}

const data = {
    table: {
        columns: [
            {title: '二级单位', field: 'EJDW', width: '150'},
            {title: '三级单位', field: 'SJDW', width: '150'},
            {title: '四级单位', field: 'FJDW', width: '150'},
            {title: '姓名', field: 'XM', width: '100'},
            {title: '身份证号', field: 'SFZH', width: '170'},
            {title: '性别', field: 'XB', width: '80', align: 'center'},
            {title: '职工类型', field: 'ZGLX', width: '100', align: 'center'},
            // {title: '年龄', field: 'NL', width: '60', align: 'center'},
            {title: '档案年份', field: 'DANF', width: '80', align: 'center'},
            {title: '噪声', field: 'SFZS', width: '60', align: 'center'},
            {title: '毒物', field: 'SFDW', width: '60', align: 'center'},
            {title: '粉尘', field: 'SFFC', width: '60', align: 'center'},
            {title: '放射', field: 'SFFS', width: '60', align: 'center'},
            {title: '电工', field: 'SFDG', width: '60', align: 'center'},
            {title: '高温', field: 'SFGW', width: '60', align: 'center'},
            {title: '驾驶员', field: 'SFJSY', width: '70', align: 'center'},
            {title: '高处', field: 'SFGC', width: '60', align: 'center'},
            {title: '压力', field: 'SFYL', width: '60', align: 'center'},
            {title: '体检费', field: 'fee', width: '100', formatter: fee_formatter},
            {title: '备注', field: 'remarks', width: ''}
        ],
        data: [],
        total: 0,
        page: 1,
        pageSize: 20
    }
}

export default data
