<template>
    <v-chart ref="chart" :option="option" style="width: 100%;height:400px" v-loading="loading" />
</template>

<script>
export default {
    name: 'wanchengduPieChart2',
    data() {
        return {
            loading: false,
            option: {
                title: {
                    text: '体检整体完成度',
                    left: 'center'
                },
                legend: {
                    top: 'bottom'
                },
                color: ['#cc4125', '#ccc'],
                tooltip: {
                    trigger: 'item',
                    formatter: '{a} <br/>{b} : {c} ({d}%)'
                },
                series: [
                    {
                        name: '体检完成度',
                        type: 'pie',
                        radius: ['40%', '60%'],
                        center: ['50%', '50%'],
                        avoidLabelOverlap: false,
                        label: {
                            normal: {
                                show: true,
                                position: 'center',
                                formatter: '{per|{d}%}\r\n{b} : {c} 人',
                                rich: {
                                    per: {
                                        fontSize: 35,
                                        fontFamily: '微软雅黑',
                                        color: '#454c5c'
                                    },
                                    a: {
                                        fontFamily: '微软雅黑',
                                        fontSize: 16,
                                        color: '#6c7a89',
                                        lineHeight: 30
                                    }
                                },
                                textStyle: {
                                    color: '#727272'
                                }
                            },
                            emphasis: {
                                show: false
                            }
                        },
                        itemStyle: {
                            // borderRadius: 10,
                            borderColor: '#fff',
                            borderWidth: 2
                        },
                        data: []
                        // emphasis: {
                        //     itemStyle: {
                        //         shadowBlur: 10,
                        //         shadowOffsetX: 0,
                        //         shadowColor: 'rgba(0, 0, 0, 0.5)'
                        //     }
                        // }
                    }
                ]
            }
        }
    },
    methods: {
        async getData() {
            this.loading = true
            const resp = await this.$http.get('/statistics/wanchengdu')
            const chart_data = resp.data.map(e=>{return {...e, label:{show: e.name == '已完成'}}})
            this.option.series[0].data = chart_data
            // this.option.series[0].data = [
            //     {
            //         name: '完成',   //数据，name对应Angular
            //         value: 83,  //对应显示的部分数据即80%
            //         label: { show: true },
            //         itemStyle: {
            //             normal: {
            //                 color: 'rgb(50,197,233)',
            //             }
            //         }
            //     },
            //     {
            //         value: 22,
            //         label: { show: false },
            //         itemStyle: {
            //             normal: {
            //                 color: 'transparent'
            //             }
            //         }
            //     }
            // ]
            this.$refs.chart.setOption(this.option)
            this.loading = false
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.$refs.chart.resize()
        })
        window.addEventListener('resize', () => {
            if (this.$refs && this.$refs.chart) {
                this.$refs.chart.resize()
            }
        })
        this.getData()
    }
}
</script>
