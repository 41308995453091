<template>
    <zk-admin-table title="高危人员（优先体检）" :loading="loading" :table="table" @change="handleTableChange"
        @select="handleTableSelect">
        <template v-slot:buttons>
            <el-row style="float: right">
                <el-button size="mini" icon="el-icon-search" circle @click="searchVisible = !searchVisible"></el-button>
                <el-button size="mini" icon="el-icon-refresh" circle @click="getData"></el-button>
                <el-button size="mini" icon="el-icon-download" circle @click="handleExport"
                    :loading="loading"></el-button>
            </el-row>
        </template>
        <template v-slot:search>
            <zk-search-form-inline :form="search" @search="getData" style="text-align: left;">
                <!-- <el-form-item label="人员状态">
                    <el-select v-model="search.status" placeholder="请选择" style="width:100px;" clearable>
                        <el-option label="正常" value="0"></el-option>
                        <el-option label="冻结" value="100"></el-option>
                    </el-select>
                </el-form-item> -->
                <el-form-item label="">
                    <select-tjlx v-model="search.TJLX" style="width: 120px" />
                </el-form-item>
                <el-form-item label="" prop="ejdw_id" clearable>
                    <select-subsidiary-2 v-model="search.ejdw_id" />
                </el-form-item>
                <el-form-item label="" prop="sjdw_id" clearable>
                    <select-subsidiary-3 :ejdw_id="search.ejdw_id" v-model="search.sjdw_id" />
                </el-form-item>
                <el-form-item label="姓名">
                    <el-input v-model.trim="search.XM" clearable></el-input>
                </el-form-item>
                <el-form-item label="身份证号">
                    <el-input v-model.trim="search.SFZH" clearable></el-input>
                </el-form-item>
            </zk-search-form-inline>
        </template>
        <el-table-column type="index" width="55"></el-table-column>
        <el-table-column label="单位" prop='hospital' width="200">
            <template slot-scope="scope">
                <div>{{ scope.row.EJDW }}</div>
                <small>{{ scope.row.SJDW }} {{ scope.row.FJDW }}</small>
            </template>
        </el-table-column>
        <el-table-column label="体检人" width="200">
            <template slot-scope="scope">
                <div>{{ scope.row.XM }}
                    <small>{{ scope.row.XB }} {{ scope.row.NL }}</small>
                </div>
                <div>{{ scope.row.SFZH }}</div>
            </template>
        </el-table-column>
        <el-table-column label="费用(健康+职业+女工)" width="240">
            <template slot-scope="scope">
                <div>{{ scope.row.fee_total }}</div>
                <div>{{ scope.row.fee }}+{{ scope.row.fee_zhiye || 0 }}+{{ scope.row.fee_nvgong || 0 }}</div>
            </template>
        </el-table-column>
        <el-table-column label="职业体检" width="" align="left">
            <template slot-scope="scope">
                <el-tag v-if="scope.row.SFZS == '是'">噪声</el-tag>
                <el-tag v-if="scope.row.SFDW == '是'">毒物</el-tag>
                <el-tag v-if="scope.row.SFFC == '是'">粉尘</el-tag>
                <el-tag v-if="scope.row.SFFS == '是'">放射</el-tag>
                <el-tag v-if="scope.row.SFDG == '是'">电工</el-tag>
                <el-tag v-if="scope.row.SFGW == '是'">高温</el-tag>
                <el-tag v-if="scope.row.SFJSY == '是'">驾驶员</el-tag>
                <el-tag v-if="scope.row.SFGC == '是'">高处</el-tag>
                <el-tag v-if="scope.row.SFYL == '是'">压力</el-tag>
            </template>
        </el-table-column>
        <el-table-column label="职业信息" width="" align="left">
            <template slot-scope="scope">
                <el-tag size="mini" v-if="scope.row.GL">总工龄：{{ scope.row.GL }}</el-tag>
                <el-tag size="mini" v-if="scope.row.ZYGG">工种：{{ scope.row.ZYGG }}</el-tag>
                <el-tag size="mini" v-if="scope.row.JHYS && scope.row.JHYS.length > 0">接害因素：{{ scope.row.JHYS.join(',')
                    }}</el-tag>
                <el-tag size="mini" v-if="scope.row.ZSJHGL">噪声工龄：{{ scope.row.ZSJHGL }}</el-tag>
                <el-tag size="mini" v-if="scope.row.GWJHGL">高温工龄：{{ scope.row.GWJHGL }}</el-tag>
                <el-tag size="mini" v-if="scope.row.DWJHGL">毒物工龄：{{ scope.row.DWJHGL }}</el-tag>
                <el-tag size="mini" v-if="scope.row.JSYJHGL">驾驶员工龄：{{ scope.row.JSYJHGL }}</el-tag>
                <el-tag size="mini" v-if="scope.row.FCJHGL">粉尘工龄：{{ scope.row.FCJHGL }}</el-tag>
                <el-tag size="mini" v-if="scope.row.GCJHGL">高处工龄：{{ scope.row.GCJHGL }}</el-tag>
                <el-tag size="mini" v-if="scope.row.FSJHGL">放射工龄：{{ scope.row.FSJHGL }}</el-tag>
                <el-tag size="mini" v-if="scope.row.YLJHGL">压力工龄：{{ scope.row.YLJHGL }}</el-tag>
                <el-tag size="mini" v-if="scope.row.DGJHGL">电工工龄：{{ scope.row.DGJHGL }}</el-tag>
                <el-tag size="mini" v-if="scope.row.FSJHLX">放射类型：{{ scope.row.FSJHLX }}</el-tag>
                <el-tag size="mini" v-if="scope.row.FSJHJL">放射剂量：{{ scope.row.FSJHJL }}</el-tag>
                <el-tag size="mini" v-if="scope.row.FCJHLX">粉尘类型：{{ scope.row.FCJHLX }}</el-tag>
                <el-tag size="mini" v-if="scope.row.JSYJHLX">驾驶员类型：{{ scope.row.JSYJHLX }}</el-tag>
            </template>
        </el-table-column>
        <el-table-column align="left" v-for="(v, idx) in table.columns" :prop="v.field" :label="v.title"
            :width="v.width" :formatter="v.formatter" :key="idx">
        </el-table-column>
        <!-- <el-table-column align="center" width="120" label="操作" fixed="right">
            <template slot-scope="scope">
                <el-button type="warning" size="mini" @click="handleUpdate(scope.row)" icon="el-icon-edit"></el-button>
            </template>
        </el-table-column> -->
        <template v-slot:dialog>
        </template>
        <template v-slot:download>
        </template>
    </zk-admin-table>
</template>

<script>
export default {
    name: 'task-gaowei',
    path: '/main/task/gaowei',
    data() {
        return {
            loading: false,
            searchVisible: true,
            search: { source: 'appoint' },
            table: {
                columns: [
                    { title: '备注', field: 'desc', width: '' }
                ],
                data: [],
                total: 0,
                page: 1,
                pageSize: 20
            }
        }
    },
    methods: {
        handleSearch(e) {
            this.search = e
            this.table.page = 1
            this.getData()
        },
        handleTableChange(e) {
            this.table = e
            this.getData()
        },
        handleTableSelect(val) {
            this.multipleSelection = val
        },
        async handleExport() {
            this.loading = true
            try {
                let params = JSON.parse(JSON.stringify(this.search))
                params.export = 'xlsx'//'csv'
                const resp = await this.$http.get(`/task/gaowei?page=${this.table.page}&pageSize=${this.table.pageSize}`, { params: params, responseType: 'blob' })
                let blob = new Blob([resp.data], { type: 'application/vnd.openxmlformats;charset=utf-8;' })
                var link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = decodeURI(escape(resp.headers['content-disposition'].split('=')[1]))
                link.click()
                window.URL.revokeObjectURL(link.href)
            } catch (e) {
                this.$message.error(e.message)
            }
            this.loading = false
        },
        async getData() {
            this.loading = true
            const resp = await this.$http.get(`/task/gaowei?page=${this.table.page}&pageSize=${this.table.pageSize}`, { params: this.search })
            if (!resp.data) {
                this.$message.error(resp.data.msg)
            } else {
                this.table.data = resp.data.data
                this.table.total = resp.data.total
            }
            this.loading = false
        }
    },
    mounted() {
        this.search = { ...this.search, ...this.$route.query }
        this.getData()
    }
}
</script>
