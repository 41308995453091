<template>
    <zk-search-form-inline :form="form" @search="$emit('search', form)">
        <el-form-item label="类型">
            <el-input v-model.trim="form.type" clearable></el-input>
        </el-form-item>
        <el-form-item label="名称">
            <el-input v-model.trim="form.name" clearable></el-input>
        </el-form-item>
        <el-form-item label="创建时间">
            <el-date-picker v-model="form.daterange" size="small" type="daterange"
                value-format="yyyy-MM-dd" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期">
            </el-date-picker>
        </el-form-item>
    </zk-search-form-inline>
</template>

<script>
export default {
    data() {
        return {
            form: {}
        }
    }
}
</script>

