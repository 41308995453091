const data = {
    table: {
        columns: [
            {title: 'No.', field: 'id', width: '100'},
            {title: '用户名', field: 'username', width: '100'},
            {title: '姓名', field: 'name', width: '200'},
            {title: '联系电话', field: 'contact', width: '150'},
            {title: '单位名称', field: 'company_name', width: ''},
            {title: '备注', field: 'remarks', width: ''}
        ],
        data: [],
        total: 0,
        page: 1,
        pageSize: 20
    }
}

export default data
