export default {
    app: {
        title: 'Admin Dashboard',
        home: 'Home',
        passwd: 'Change Password',
        logout: 'Logout'
    },
    menu: {
        dashboard: 'Dashboard',
        company: 'Company',
        company_form: 'Company Form',
        company_index: 'Company List',
        company_overdue: 'Company Overdue',
        oplog: 'Option Log'
    },
    skin: {
        Blue: 'Blue',
        Green: 'Green'
    }
}
