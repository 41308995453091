<template>
    <zk-admin-table title="疾病分级" :loading="loading" :table="table" @change="handleTableChange"
        @select="handleTableSelect">
        <template v-slot:buttons>
            <el-row style="float: right">
                <el-button size="mini" icon="el-icon-search" circle @click="searchVisible = !searchVisible"></el-button>
                <el-button size="mini" icon="el-icon-refresh" circle @click="getData"></el-button>
                <el-button size="mini" icon="el-icon-upload2" circle @click="uploadVisible = true"></el-button>
            </el-row>
        </template>
        <!-- <template v-slot:search> -->
        <!-- <search-form @search="handleSearch" v-show="searchVisible" /> -->
        <!-- </template> -->

        <!-- <el-table-column align="left" v-for="(v, idx) in table.columns" :prop="v.field" :label="v.title"
            :width="v.width" :formatter="v.formatter" :key="idx">
        </el-table-column> -->
        <el-table-column prop="id" type="index" />
        <el-table-column prop="DWMC" label="单位名称" width="" />
        <el-table-column prop="XM" label="姓名" width="" />
        <el-table-column prop="XB" label="性别" width="" />
        <el-table-column prop="NL" label="年龄" width="" />
        <el-table-column prop="SFZH" label="身份证号" width="" show-overflow-tooltip/>
        <el-table-column prop="count" label="关注疾病数量" align="center" width="" />
        <el-table-column prop="count" label="健康分级" align="center" width="">
            <template slot-scope="scope">
                {{ scope.row.count|levelStr }}
            </template>
        </el-table-column>
        <!-- <el-table-column align="center" width="120" label="操作" fixed="right">
            <template slot-scope="scope">
                <el-button type="warning" size="mini" @click="handleUpdate(scope.row)" icon="el-icon-edit"></el-button>
            </template>
        </el-table-column> -->
        <template v-slot:dialog>
            <!-- <data-form :visible="dialogVisible" :form="form" @close="dialogClose" @update="getData" :isNew="isNew" />
                <data-upload :visible="uploadVisible" @close="uploadVisible = false" /> -->
        </template>
    </zk-admin-table>
</template>

<script>
export default {
    name: 'stat-analysis_level',
    path: '/main/statistics/analysis_level',
    data() {
        return {
            loading: false,
            uploadVisible: true,
            tableData: [],
            table: {
                columns: [
                    { title: '单位名称', field: 'DWMC', width: '220' },
                    { title: '姓名', field: 'XM', width: '100' },
                    { title: '性别', field: 'XB', width: '80' },
                    { title: '年龄', field: 'NL', width: '80' },
                    { title: '疾病名称', field: 'SFZH', width: '' },
                    { title: '确诊年份', field: 'count', width: '' },
                    { title: '补助金额', field: 'BZJE', width: '' }
                ],
                data: [],
                total: 0,
                page: 1,
                pageSize: 20
            }
        }
    },
    filters: {
        levelStr(e) {
            let level = parseInt(e)
            if (level > 5) return '高危'
            if (level > 2) return '中危'
            return '低危'
        }
    },
    methods: {
        handleTableSelect(e) {
            this.table = e
            this.getData()
        },
        handleTableChange(e) {
            this.table = e
            this.getData()
        },
        async getData() {
            this.loading = true
            try {
                const resp = await this.$http.get(`/todo/gaowei?page=${this.table.page}&pageSize=${this.table.pageSize}`, { params: this.search })
                if (!resp.data) {
                    this.$message.error(resp.data.msg)
                } else {
                    this.table.data = resp.data.data.records
                    this.table.total = resp.data.data.total
                }
            } catch (e) {
                this.$message.error(e.message)
            }
            this.loading = false
        }
    },
    mounted() {
        this.getData()
    }
}
</script>

<style lang="scss" scoped>

</style>
