const data = {
    table: {
        columns: [
            {title: '创建时间', field: 'created', width: '180'},
            {title: '随访人', field: 'type', width: '150'},
            {title: '受访人', field: 'name', width: '200'},
            {title: '内容', field: 'remarks', width: ''}
        ],
        data: [],
        total: 0,
        page: 1,
        pageSize: 20
    }
}

export default data
