<template>
    <div class="page">
        <div class="header">
            <el-button style="float:right" size="mini" @click="$router.push({ name: 'task-tuanti-solo' })">单个录入</el-button>
            <el-page-header @back="$router.go(-1)" content="团体预约" />
        </div>
        <div class="search">
            <div style="float:right">
                <el-button size="small" @click="onSubmit" icon="el-icon-right" type="primary">确定导入</el-button>
            </div>
            <el-form :inline="true" :model="search" size="small">
                <el-form-item label="选择日期">
                    <el-select v-model="form.date" placeholder="请选择" @change="getTimes">
                        <el-option :label="`${d.date}(剩余${d.total - d.used})`" :value="d.date" v-for="d, i in dates"
                            :key="i"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="选择时间">
                    <el-select v-model="form.time" placeholder="请选择">
                        <el-option :label="d.text" :value="d.value" v-for="d, i in times" :key="i"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button @click="onAdd" icon="el-icon-plus" type="primary" plain :loading="loading">导入名单</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="main-content">
            <div class="table-content">
                <el-table :data="tableData" border stripe size="medium" v-loading="loading" width="100%" height="100%"
                    :header-cell-style="{ backgroundColor: '#FAFAFA' }">
                    <el-table-column type="index" label="序号" align="center" width="50" />
                    <el-table-column label="单位" width="180">
                        <template slot-scope="scope">
                            {{ scope.row.EJDW }}
                            <div style="font-size:90%">{{ scope.row.SJDW }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="姓名" prop="name" width="180">
                        <template slot-scope="scope">
                            {{ scope.row.name }} <span style="font-size:90%">{{ scope.row.XB }} {{ scope.row.NL }}岁 {{
                                scope.row.ZGLX }}</span>
                            <div>{{ scope.row.idnum }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="费用" width="150" align="left">
                        <template slot-scope="scope">
                            <div>
                                {{ scope.row.fee_total }}
                            </div>
                            <small>{{ scope.row.fee }}+{{ scope.row.fee_zhiye }}+{{ scope.row.fee_nvgong }}</small>
                        </template>
                    </el-table-column>
                    <el-table-column label="接害因素" width="120">
                        <template slot-scope="scope">
                            {{ scope.row.JHYS.join(', ') }}
                        </template>
                    </el-table-column>
                    <el-table-column label="套餐信息" width="">
                        <template slot-scope="scope">
                            <el-select v-model="scope.row.project.taocan" value-key="id" placeholder="选择套餐" size="mini"
                                clearable style="width:200px;margin:2px" @change="selectTaocan">
                                <el-option :label="t.name" :value="t" v-for="(t, i2) in taocan" :key="i2"
                                    :disabled="canSelected(t, scope.row.DANF)"></el-option>
                            </el-select>
                            <el-select @change="zixuanChange($event, scope.row)" size="mini" style="width:280px"
                                v-model="scope.row.project.zixuan" multiple collapse-tags placeholder="请选择自选项目"
                                value-key="id">
                                <el-option v-for="item in zixuan" :key="item.id"
                                    :label="`${item.name}(${(item.fee * 0.85).toFixed(2)})`" :value="item">
                                </el-option>
                            </el-select>
                            <el-select v-if="scope.row.TJLX_NGJS" v-model="scope.row.project.nvgong" value-key="id"
                                placeholder="选择女工家属套餐" size="mini" style="width:140px;margin:2px" @change="selectNvgong">
                                <el-option :label="t.name" :value="t" v-for="(t, i2) in nvgong" :key="i2"></el-option>
                            </el-select>
                            <el-select v-if="scope.row.JHYS.length > 0" @change="zhiyeChange($event, scope.row)" size="mini"
                                style="width:280px" v-model="scope.row.project.zhiye" multiple collapse-tags
                                placeholder="请选择职业体检" value-key="id">
                                <el-option v-for="item in zhiye" :key="item.id" :label="`${item.name}(${item.feeHeji})`"
                                    :value="item">
                                </el-option>
                            </el-select>
                            <!-- <el-popover placement="right" title="职业体检" width="400" trigger="click"
                                v-if="scope.row.project.zhiye && scope.row.project.zhiye.length > 0">
                                <el-table :data="scope.row.project.zhiye" size="small" width="400" heigth="300">
                                    <el-table-column width="" property="name" label="名称"></el-table-column>
                                    <el-table-column width="100" property="feeHeji" label="金额"></el-table-column>
                                </el-table>
                                <div style="padding-top:10px;text-align:center">合计：{{ scope.row.fee_zhiye }}</div>
                                <el-tag slot="reference" type="success" size="medium" effect="plain">
                                    职业体检
                                </el-tag>
                            </el-popover> -->
                        </template>
                    </el-table-column>
                    <!-- <el-table-column align="center" width="120" label="操作" fixed="right">
                        <template slot-scope="scope">
                            <el-button type="warning" size="mini" @click="handleUpdate(scope)" icon="el-icon-edit">
                            </el-button>
                        </template>
                    </el-table-column> -->
                </el-table>
            </div>
        </div>
        <el-dialog title="团体预约" :visible.sync="dialogVisible" width="40%">
            <div style="display: flex">
                <el-form ref="form" :model="form" label-width="120px" width="100%" style="flex: 1">
                    <el-form-item label="身份证号" prop="idnum_list"
                        :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                        <el-input type="textarea" v-model="form.idnum_list" :rows="5"></el-input>
                    </el-form-item>
                </el-form>
                <div style="padding-left: 20px">
                    <btn-read-id-card @result="onReadCard" />
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="onImport">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog title="套餐设置" :visible.sync="projectVisible" width="40%">
            <el-form ref="form" :model="form" label-width="140px">
                <el-form-item label="体检套餐">
                    <el-select v-model="form.taocan" value-key="id" placeholder="请选择" style="width:100%"
                        @change="selectTaocan">
                        <el-option label="无" value=""></el-option>
                        <el-option :label="t.name" :value="t" v-for="(t, i1) in taocan" :key="i1"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="自选套餐">
                    <el-cascader :options="options" :props="props" clearable style="width:100%"></el-cascader>
                </el-form-item>
                <el-form-item label="女工家属套餐" v-if="tableData[tableIndex] && tableData[tableIndex].TJLX_NGJS">
                    <el-select v-model="form.nvgong" value-key="id" placeholder="请选择" style="width:100%"
                        @change="selectNvgong">
                        <el-option :label="t.name" :value="t" v-for="(t, i2) in nvgong" :key="i2"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="projectVisible = false">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: 'task-tuanti',
    path: '/main/task/tuanti',
    data() {
        return {
            loading: false,
            dialogVisible: false,
            projectVisible: false,
            search: {},
            idnum_list: [],
            form: {
                taocan: '',
                date: '',
                time: '',
                idnum_list: '',
                duwu: []
            },
            tableData: [],
            tableIndex: -1,
            taocan: [],
            zixuan: [],
            zhiye: [],
            nvgong: [],
            dates: [],
            times: [],
            props: { multiple: true },
            options: [
                { value: 3, label: '普陀' },
                { value: 4, label: '黄埔' },
                { value: 5, label: '徐汇' }
            ]
        }
    },
    methods: {
        valueFormat(value) {
            const flattenedOptions = this.taocan.flatMap(option => {
                return [option, ...(option.children || [])]
            })
            return flattenedOptions.find(option => option.id === value)
        },
        getTJLX(DANF) {
            if (2023 - parseInt(DANF) >= 45) return '45岁及以上'  // 1750
            if (2023 - parseInt(DANF) >= 35) return '35-44岁'  // 35-44岁 1050
            if (2023 - parseInt(DANF) >= 16) return '34岁及以下' // 34岁及以下 700
        },
        // eslint-disable-next-line 
        zixuanChange(zixuan, row) {

        },
        zhiyeChange(zhiye, row) {
            let fee_zhiye = zhiye.reduce((sum, item) => sum + parseFloat(item.feeHeji), 0)
            row.fee_zhiye = fee_zhiye
            row.fee_total = (parseFloat(row.fee) + row.fee_nvgong + fee_zhiye).toFixed(2)
        },
        canSelected(t, DANF) {
            return !t.zglx.includes(this.getTJLX(DANF))
        },
        onReadCard(e) {
            if (e.ret == 1) {
                this.form.idnum_list += e.data.code + '\n'
                this.getData()
            }
            else
                this.$message.error(e.msg)
        },
        selectTaocan(e) {
            if (this.tableData[this.tableIndex])
                this.tableData[this.tableIndex].project.taocan = e
        },
        selectNvgong(e) {
            if (this.tableData[this.tableIndex])
                this.tableData[this.tableIndex].project.nvgong = e
        },
        onAdd() {
            this.dialogVisible = true
        },
        onImport() {
            this.$http.post('/task/tuanti/import', this.form)
                .then(resp => {
                    if (resp.data.code == 200) {
                        this.$message.success(resp.data.msg)
                        this.tableData = resp.data.data
                        this.dialogVisible = false
                    } else {
                        this.$message.error(resp.data.msg)
                    }
                }).catch(err => {
                    this.$message.error(err.message)
                })
        },
        onSubmit() {
            if (!this.form.date) {
                this.$message.error('请选择日期')
                return
            }
            if (!this.form.time) {
                this.$message.error('请选择时间')
                return
            }
            for (const e of this.tableData) {
                if (!e.project.taocan) {
                    this.$message.error(`${e.name} 套餐未填写`)
                    return
                }
                if (e.TJLX_NGJS && !e.project.nvgong) {
                    this.$message.error(`${e.name} 女工家属套餐未填写`)
                    return
                }
            }
            this.loading = true
            this.$http.post('/task/tuanti/appoint', {
                date: this.form.date,
                time: this.form.time,
                data: this.tableData
            }).then(resp => {
                if (resp.data.code == 200) {
                    this.$message.success(resp.data.msg)
                } else {
                    this.$message.error(resp.data.msg)
                }
            }).catch(e => {
                this.$message.error(e.message)
            }).finally(() => {
                this.loading = false
            })
        },
        async handleUpdate(e) {
            this.tableIndex = e.$index
            this.projectVisible = true
            await this.$http.get(`/task/tuanti/project/2?idnum=${e.row.idnum}`)
                .then(resp => {
                    this.taocan = resp.data.data
                })
        },
        getTimes() {
            this.form.time = ''
            this.$http.get(`/task/tuanti/times?date=${this.form.date}`)
                .then(resp => {
                    this.times = resp.data
                })
        },
        getData() {
            this.$http.get('/task/tuanti/project/2')
                .then(resp => {
                    this.taocan = resp.data.data
                })
            this.$http.get('/task/tuanti/project/3')
                .then(resp => {
                    this.zixuan = resp.data.data
                })
            this.$http.get('/task/tuanti/project/4')
                .then(resp => {
                    this.zhiye = resp.data.data
                })
            this.$http.get('/task/tuanti/project/5')
                .then(resp => {
                    this.nvgong = resp.data.data
                })
            this.$http.get('/task/tuanti/dates')
                .then(resp => {
                    this.dates = resp.data.dates
                })
        }
    },
    mounted() {
        this.getData()
    }
}
</script>

<style lang="scss" scoped>
.page {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.header {
    line-height: 40px;

    .btns {
        float: right;
    }
}

.search {
    margin-top: 15px;
    padding: 10px;
    background-color: #FAFAFA;
    border-top: 1px solid #EBEEF5;
    border-left: 1px solid #EBEEF5;
    border-right: 1px solid #EBEEF5;

    ::v-deep .el-form-item {
        padding: 0 !important;
        margin-bottom: 0 !important;
    }
}

.main-content {
    flex: 1;
    position: relative;
}

.table-content {
    position: absolute;
    width: 100%;
    height: 100%;
}

.pager {
    padding-top: 10px;
    text-align: center;
}

.el-tag {
    margin: 2px;
}
</style>