const len_formatter = (row, column) => {
    const value = row[column.property]
    return value.length || 0
}

const data = {
    table: {
        columns: [
            {title: '套餐代码', field: 'code', width: '140'},
            {title: '套餐名称', field: 'name', width: '280'},
            {title: '可用性别', field: 'gender', width: '180'},
            {title: '费用金额', field: 'fee', width: '180'},
            {title: '项目数量', field: 'med_items', width: '180', formatter: len_formatter},
            {title: '备注', field: 'remarks', width: ''}
        ],
        data: [],
        total: 0,
        page: 1,
        pageSize: 20
    }
}

export default data
