<template>
    <div>
        <el-page-header @back="$router.go(-1)" content="体检项目控制" />
        <el-table :data="tableData" border style="width: 100%;margin-top:20px">
            <el-table-column type="index" width="50" />
            <el-table-column prop="type" label="项目类型" width="180" />
            <el-table-column prop="package" label="项目" width="180" />
            <el-table-column prop="daterange" label="可用时间" width="180" />
            <el-table-column prop="usercount" label="可用人员" />
            <el-table-column align="center" width="120" label="操作" fixed="right">
                <template slot-scope="scope">
                    <el-button type="warning" size="mini" @click="handleUpdate(scope.row)" icon="el-icon-edit">
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-dialog title="编辑" :visible.sync="dialogVisible" width="60%">
            <el-form ref="form" :model="form" label-width="80px">
                <el-form-item label="人员" prop="packages">
                    <el-table border ref="multipleTable" :data="userData" tooltip-effect="dark" style="width: 100%;"
                        height="250" @selection-change="handleSelectionChange">
                        <el-table-column type="selection" width="55" align="center" />
                        <el-table-column prop="name" label="姓名" width="120" />
                        <el-table-column prop="gender" label="性别" align="center" width="100"
                            :filters="[{ text: '男', value: '男' }, { text: '女', value: '女' }]"
                            :filter-method="filterHandler" filter-placement="bottom-end" />
                        <el-table-column prop="age" label="年龄" align="center" width="100" />
                        <el-table-column prop="desc" label="备注" show-overflow-tooltip />
                    </el-table>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="submit">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: 'task-item',
    path: '/main/task/item',
    data() {
        return {
            dialogVisible: false,
            tableData: [],
            form: { users: [] },
            userData: []
        }
    },
    methods: {
        // eslint-disable-next-line 
        handleUpdate(row) {
            this.dialogVisible = true
        },
        handleSelectionChange() {

        },
        filterHandler() {

        },
        submit() {
            this.dialogVisible = false
        },
        async getData() {
            this.loading = true
            const resp = await this.$http.get('/staff?page=1&pageSize=200', { params: this.search })
            if (!resp.data) {
                this.$message.error(resp.data.msg)
            } else {
                this.userData = resp.data.data.records
            }
        }
    },
    mounted() {
        this.getData()
    }
}
</script>

<style scoped>
.notfound {
    padding: 40px 0;
    text-align: left;
}
</style>