<template>
    <zk-search-form-inline :form="form" @search="$emit('search', form)" style="text-align:left">
        <el-form-item label="" prop="ejdw_id" clearable>
            <select-subsidiary-2 v-model="form.ejdw_id" />
        </el-form-item>
        <el-form-item label="" prop="sjdw_id" clearable>
            <select-subsidiary-3 :ejdw_id="form.ejdw_id" v-model="form.sjdw_id" />
        </el-form-item>
        <el-form-item label="" prop="fjdw_id" clearable>
            <select-subsidiary-4 :sjdw_id="form.sjdw_id" v-model="form.fjdw_id" />
        </el-form-item>
        <el-form-item label="" prop="ZGLX" clearable>
            <select-zglx v-model="form.ZGLX" style="width: 120px" />
        </el-form-item>
        <el-form-item label="" prop="TJLX" clearable>
            <select-tjlx v-model="form.TJLX" style="width: 120px" />
        </el-form-item>
        <el-form-item label="" prop="XB" clearable>
            <el-select v-model="form.XB" placeholder="性别" style="width: 80px" clearable>
                <el-option label="男" value="男"></el-option>
                <el-option label="女" value="女"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="">
            <el-input v-model.trim="form.name" clearable placeholder="姓名" style="width: 100px"></el-input>
        </el-form-item>
        <el-form-item label="">
            <el-input v-model.trim="form.SFZH" clearable placeholder="身份证号" style="width: 180px"></el-input>
        </el-form-item>
        <el-form-item label="" prop="ZGLX" clearable>
            <el-select v-model="form.status" clearable placeholder="状态" style="width: 100px">
                <el-option label="正常" :value="0"></el-option>
                <el-option label="冻结" :value="100"></el-option>
            </el-select>
        </el-form-item>
    </zk-search-form-inline>
</template>

<script>
export default {
    props: {
        form: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            // form: {}
        }
    }
}
</script>

