<template>
    <el-dialog title="体检结果" :visible="visible" width="60%" @close="close">
        <el-form ref="form" :model="form" label-width="100px">
            <el-form-item label="标题" prop="title" :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                <el-input v-model.trim="form.title"></el-input>
            </el-form-item>
            <el-form-item label="描述" prop="desc" :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                <el-input v-model.trim="form.desc"></el-input>
            </el-form-item>
            <el-form-item label="图标" prop="image" :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                <el-input v-model.trim="form.image"></el-input>
            </el-form-item>
            <el-form-item label="内容">
                <!-- <el-input type="textarea" v-model.trim="form.content" rows="10"></el-input> -->
                <quill-editor v-model="form.content" ref="myQuillEditor" :options="editorOption"
                    @blur="onEditorBlur($event)" @focus="onEditorFocus($event)" @change="onEditorChange($event)"
                    @ready="onEditorReady($event)">
                </quill-editor>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="close">{{ $t('form.cancel') }}</el-button>
            <el-button @click="formSubmit" type="primary" :loading="loading" :disabled="loading">{{ $t('form.ok') }}
            </el-button>
        </span>
    </el-dialog>
</template>
<script>
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
export default {
    components: {
        quillEditor
    },
    props: {
        visible: {
            default: false
        },
        form: {
        },
        isNew: {
            default: false
        }
    },
    data() {
        return {
            loading: false,
            editorOption: {
                modules: {
                    toolbar: [
                        ['bold', 'italic', 'underline', 'strike'], // 加粗 斜体 下划线 删除线
                        ['blockquote', 'code-block'], // 引用  代码块
                        [{ header: 1 }, { header: 2 }], // 1、2 级标题
                        [{ list: 'ordered' }, { list: 'bullet' }], // 有序、无序列表
                        [{ script: 'sub' }, { script: 'super' }], // 上标/下标
                        [{ indent: '-1' }, { indent: '+1' }], // 缩进
                        // [{ direction: 'rtl' }], // 文本方向
                        // [{ size: ['12', '14', '16', '18', '20', '22', '24', '28', '32', '36'] }], // 字体大小
                        // [{ header: [1, 2, 3, 4, 5, 6] }], // 标题
                        [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
                        // [{ font: ['songti'] }], // 字体种类
                        [{ align: [] }], // 对齐方式
                        ['clean'], // 清除文本格式
                        ['image', 'video'] // 链接、图片、视频
                    ]
                },
                placeholder: '请输入正文'
            },
        }
    },
    methods: {
        // 失去焦点事件
        onEditorBlur(quill) {
            console.log('editor blur!', quill)
        },
        // 获得焦点事件
        onEditorFocus(quill) {
            console.log('editor focus!', quill)
        },
        // 准备富文本编辑器
        onEditorReady(quill) {
            console.log('editor ready!', quill)
        },
        // 内容改变事件
        onEditorChange({ quill, html, text }) {
            console.log('editor change!', quill, html, text)
            this.content = html
        },
        async formSubmit() {
            const valid = await this.$refs['form'].validate().then(res => res).catch(error => error)
            if (!valid) return
            const resp = await this.$http.post('/mp_settings/tuwen', this.form)
            if (resp.data.code != 200) {
                this.$message.error(resp.data.msg)
            } else {
                this.$message.success('保存成功')
                this.$emit('update')
                this.$emit('close')
            }
        },
        close() {
            this.$emit('close')
        }
    }
}
</script>

 
<style lang="scss">
.vue-quill-editor {
  .quill-editor{
    line-height: normal;
    .ql-container.ql-snow{
        line-height: normal !important;
        height: 550px !important;
        font-size:14px;
    }
    .ql-snow {
        .ql-tooltip[data-mode=link]::before {
            content: "请输入链接地址:";
        }
        .ql-tooltip.ql-editing a.ql-action::after {
            border-right: 0px;
            content: '保存';
            padding-right: 0px;
        }
        .ql-tooltip[data-mode=video]::before {
            content: "请输入视频地址:";
        }
        .ql-picker.ql-size {
            .ql-picker-label[data-value="12px"]::before,
            .ql-picker-item[data-value="12px"]::before {
                content: '12px';
            }
            .ql-picker-label[data-value="14px"]::before,
            .ql-picker-item[data-value="14px"]::before {
                content: '14px';
            }
            .ql-picker-label[data-value="16px"]::before,
            .ql-picker-item[data-value="16px"]::before {
                content: '16px';
            }
            .ql-picker-label[data-value="18px"]::before,
            .ql-picker-item[data-value="18px"]::before {
                content: '18px';
            }
            .ql-picker-label[data-value="20px"]::before,
            .ql-picker-item[data-value="20px"]::before {
                content: '20px';
            }
            .ql-picker-label[data-value="24px"]::before,
            .ql-picker-item[data-value="24px"]::before {
                content: '24px';
            }
            .ql-picker-label[data-value="28px"]::before,
            .ql-picker-item[data-value="28px"]::before {
                content: '28px';
            }
            .ql-picker-label[data-value="32px"]::before,
            .ql-picker-item[data-value="32px"]::before {
                content: '32px';
            }
            .ql-picker-label[data-value="36px"]::before,
            .ql-picker-item[data-value="36px"]::before {
                content: '36px';
            }
        }
        .ql-picker.ql-header {
            .ql-picker-label::before,
            .ql-picker-item::before {
                content: '文本';
            }
            .ql-picker-label[data-value="1"]::before,
            .ql-picker-item[data-value="1"]::before {
                content: '标题1';
            }
            .ql-picker-label[data-value="2"]::before,
            .ql-picker-item[data-value="2"]::before {
                content: '标题2';
            }
            .ql-picker-label[data-value="3"]::before,
            .ql-picker-item[data-value="3"]::before {
                content: '标题3';
            }
            .ql-picker-label[data-value="4"]::before,
            .ql-picker-item[data-value="4"]::before {
                content: '标题4';
            }
            .ql-picker-label[data-value="5"]::before,
            .ql-picker-item[data-value="5"]::before {
                content: '标题5';
            }
            .ql-picker-label[data-value="6"]::before,
            .ql-picker-item[data-value="6"]::before {
                content: '标题6';
            }
        }
        .ql-picker.ql-font{
            .ql-picker-label[data-value="SimSun"]::before,
            .ql-picker-item[data-value="SimSun"]::before {
                content: "宋体";
                font-family: "SimSun" !important;
            }
            .ql-picker-label[data-value="SimHei"]::before,
            .ql-picker-item[data-value="SimHei"]::before {
                content: "黑体";
                font-family: "SimHei";
            }
            .ql-picker-label[data-value="Microsoft-YaHei"]::before,
            .ql-picker-item[data-value="Microsoft-YaHei"]::before {
                content: "微软雅黑";
                font-family: "Microsoft YaHei";
            }
            .ql-picker-label[data-value="KaiTi"]::before,
            .ql-picker-item[data-value="KaiTi"]::before {
                content: "楷体";
                font-family: "KaiTi" !important;
            }
            .ql-picker-label[data-value="FangSong"]::before,
            .ql-picker-item[data-value="FangSong"]::before {
                content: "仿宋";
                font-family: "FangSong";
            }
        }
    }
    .ql-align-center{
      text-align: center;
    }
    .ql-align-right{
      text-align: right;
    }
    .ql-align-left{
      text-align: left;
    }
  }
  }
</style>
