// main.js

import Vue from 'vue';

Vue.filter('capitalize', function (value) {
    if (!value) return '';
    return value.charAt(0).toUpperCase() + value.slice(1);
});

Vue.filter('currency', function (value) {
    return `$${value.toFixed(2)}`;
});

Vue.filter('date', function (value) {
    // console.log(1,typeof value)
    let d = new Date(value)
    return d.format('yyyy-MM-dd hh:mm:ss')
});

