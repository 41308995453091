import { render, staticRenderFns } from "./searchForm.vue?vue&type=template&id=52144adf&"
import script from "./searchForm.vue?vue&type=script&lang=js&"
export * from "./searchForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/.pnpm/vue-loader@15.10.1_cache-loader@4.1.0_css-loader@3.6.0_vue-template-compiler@2.6.14_webpack@4.46.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports