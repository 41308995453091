<template>
    <div class="page">
        <div class="header">
            <div class="btns">
                <!-- <el-button size="mini" type="default" plain @click="exportXLSX" :loading="loading">导出体检结算单</el-button> -->
            </div>
            <el-page-header @back="$router.go(-1)" content="收费记录" />
        </div>
        <div style="margin-bottom:15px">
            <feeLogBox />
        </div>
        <fee-table style="flex:1" />
    </div>
</template>

<script>
export default {
    name: 'fee-log',
    path: '/main/fee/log',
    data() {
        return {
            loading: false,
            search: { type: 'appoint' },
            table: {
                data: [],
                total: 0,
                page: 1,
                pageSize: 20
            }
        }
    },
    methods: {
        handlePageChange(e) {
            this.table.page = e
            this.getData()
        },
        handlePageSizeChange(e) {
            this.table.pageSize = e
            this.getData()
        },
        async getData() {
            this.loading = true
            const resp = await this.$http.get(`/task/appoint/record?page=${this.table.page}&pageSize=${this.table.pageSize}&status=1`, { params: this.search })
            if (!resp.data) {
                this.$message.error(resp.data.msg)
            } else {
                this.table.data = resp.data.data.records
                this.table.total = resp.data.data.total
            }
            this.loading = false
        }
    },
    mounted() {
        // this.getData()
    }
}
</script>

<style lang="scss" scoped>
.page {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.header {
    padding-bottom: 10px;

    .btns {
        float: right;
    }
}

.search {
    padding: 10px;
    background-color: #FAFAFA;
    border-top: 1px solid #EBEEF5;
    border-left: 1px solid #EBEEF5;
    border-right: 1px solid #EBEEF5;

    ::v-deep .el-form-item {
        padding: 0 !important;
        margin-bottom: 0 !important;
    }
}

.main-content {
    flex: 1;
    position: relative;
}

.table-content {
    position: absolute;
    width: 100%;
    height: 100%;
}

.pager {
    padding-top: 10px;
    text-align: center;
}
</style>