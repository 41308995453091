<template>
    <el-dialog title="单位信息" :visible="visible" width="40%" @close="close">
        <el-form ref="form" :model="form" label-width="100px">
            <el-form-item label="三级单位" prop="patient" :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                <select-subsidiary-3 :ejdw_id="form.ejdw_id" v-model="form.patient" />
            </el-form-item>
            <el-form-item label="单位名称" prop="name" :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                <el-input v-model.trim="form.name"></el-input>
            </el-form-item>
            <el-form-item label="备注">
                <el-input type="textarea" v-model.trim="form.remarks"></el-input>
            </el-form-item>
            <div style="text-align: right">
                <el-button @click="close">{{ $t('form.cancel') }}</el-button>
                <el-button @click="formSubmit" type="primary" :loading="loading" :disabled="loading">{{
                    $t('form.ok')
                }}</el-button>
            </div>
        </el-form>
    </el-dialog>
</template>
<script>
export default {
    props: {
        visible: {
            default: false
        },
        form: {
            default: {}
        },
        isNew: {
            default: false
        }
    },
    data() {
        return {
            loading: false,
            activeName: 'first'
        }
    },
    methods: {
        async formSubmit() {
            const valid = await this.$refs['form'].validate().catch(() => false)
            if (!valid) return
            const resp = await this.$http.post('/company/subsidiary', this.form)
            if (resp.data.code != 200) {
                this.$message.error(resp.data.msg)
            } else {
                this.$message.success('保存成功')
                this.$emit('update')
                this.$emit('close')
            }
        },
        close() {
            this.$emit('close')
        }
    },
    mounted() {

    }
}
</script>

<style scoped>
::v-deep .el-dialog__body {
    padding: 0 20px 30px 20px;
}
</style>
