<template>
    <el-dialog title="体检结果" :visible="visible" width="40%" @close="close">
        <el-form ref="form" :model="form" label-width="100px">
            <el-form-item label="标识" prop="slug" :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                <el-input v-model.trim="form.slug"></el-input>
            </el-form-item>
            <el-form-item label="标题" prop="title" :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                <el-input v-model.trim="form.title"></el-input>
            </el-form-item>
            <el-form-item label="正文">
                <!-- <el-input type="textarea" v-model.trim="form.content"></el-input> -->
                <quill-editor v-model="form.content" ref="myQuillEditor" :options="editorOption"
                    @blur="onEditorBlur($event)" @focus="onEditorFocus($event)" @change="onEditorChange($event)"
                    @ready="onEditorReady($event)">
                </quill-editor>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="close">{{ $t('form.cancel') }}</el-button>
            <el-button @click="formSubmit" type="primary" :loading="loading" :disabled="loading">{{ $t('form.ok') }}
            </el-button>
        </span>
    </el-dialog>
</template>
<script>
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
export default {
    components: {
        quillEditor
    },
    props: {
        visible: {
            default: false
        },
        form: {
        },
        isNew: {
            default: false
        }
    },
    data() {
        return {
            loading: false,
            editorOption: {
                modules: {
                    toolbar: [
                        ['bold', 'italic', 'underline', 'strike'], // 加粗 斜体 下划线 删除线
                        ['blockquote', 'code-block'], // 引用  代码块
                        [{ header: 1 }, { header: 2 }], // 1、2 级标题
                        [{ list: 'ordered' }, { list: 'bullet' }], // 有序、无序列表
                        [{ script: 'sub' }, { script: 'super' }], // 上标/下标
                        [{ indent: '-1' }, { indent: '+1' }], // 缩进
                        // [{ direction: 'rtl' }], // 文本方向
                        // [{ size: ['12', '14', '16', '18', '20', '22', '24', '28', '32', '36'] }], // 字体大小
                        // [{ header: [1, 2, 3, 4, 5, 6] }], // 标题
                        [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
                        // [{ font: ['songti'] }], // 字体种类
                        [{ align: [] }], // 对齐方式
                        ['clean'], // 清除文本格式
                        ['image', 'video'] // 链接、图片、视频
                    ]
                },
                placeholder: '请输入正文'
            },
        }
    },
    methods: {
        // 失去焦点事件
        onEditorBlur(quill) {
            console.log('editor blur!', quill)
        },
        // 获得焦点事件
        onEditorFocus(quill) {
            console.log('editor focus!', quill)
        },
        // 准备富文本编辑器
        onEditorReady(quill) {
            console.log('editor ready!', quill)
        },
        // 内容改变事件
        onEditorChange({ quill, html, text }) {
            console.log('editor change!', quill, html, text)
            this.content = html
        },
        async formSubmit() {
            const valid = await this.$refs['form'].validate().then(res => res).catch(error => error)
            if (!valid) return
            const resp = await this.$http.post('/mp_settings/flatpage', this.form)
            if (resp.data.code != 200) {
                this.$message.error(resp.data.msg)
            } else {
                this.$message.success('保存成功')
                this.$emit('update')
                this.$emit('close')
            }
        },
        close() {
            this.$emit('close')
        }
    }
}
</script>

<style scoped>

</style>
