<template>
    <div>
        <div>
            <el-page-header @back="$router.go(-1)" content="慢病信息" />
        </div>
        <el-row style="margin:20px 0">
            <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12" style="padding-right: 10px">
                <analysis-manbing-table-renshu />
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12" style="padding-left: 10px">
                <analysis-manbing-table-bingshu />
            </el-col>
        </el-row>
        <div style="margin:20px 0;">
                <analysis-manbing-table />
        </div>
    </div>
</template>

<script>
export default {
    name: 'stat-analysis_manbing',
    path: '/main/statistics/analysis_manbing',
    data() {
        return {}
    }
}
</script>

<style lang="scss" scoped></style>
