<template>
    <zk-admin-table title="VIP预约记录" :loading="loading" :table="table" @change="handleTableChange"
        @select="handleTableSelect">
        <template v-slot:buttons>
            <el-row style="float: right">
                <el-button size="mini" icon="el-icon-download" @click="handleExport">导出</el-button>
                <el-button size="mini" icon="el-icon-search" circle @click="searchVisible = !searchVisible"></el-button>
                <el-button size="mini" icon="el-icon-refresh" circle @click="getData"></el-button>
            </el-row>
        </template>
        <template v-slot:search>
            <zk-search-form-inline :form="search" @search="getData">
                <el-form-item label="" prop="ejdw_id" clearable>
                    <select-subsidiary-2 v-model="search.ejdw_id" />
                </el-form-item>
                <el-form-item label="" prop="sjdw_id" clearable>
                    <select-subsidiary-3 :ejdw_id="search.ejdw_id" v-model="search.sjdw_id" />
                </el-form-item>
                <el-form-item label="姓名">
                    <el-input v-model.trim="search.name" clearable></el-input>
                </el-form-item>
                <el-form-item label="身份证号">
                    <el-input v-model.trim="search.idnum" clearable></el-input>
                </el-form-item>
                <el-form-item label="预约日期">
                    <el-date-picker v-model="search.daterange" type="daterange" range-separator="至" start-placeholder="开始日期"
                        value-format="yyyy-MM-dd" end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item>
            </zk-search-form-inline>
        </template>
        <el-table-column type="index" width="55"></el-table-column>
        <el-table-column label="单位" width="">
            <template slot-scope="scope">
                <div>{{ scope.row.EJDW }}</div>
                <small>{{ scope.row.SJDW }}</small>
            </template>
        </el-table-column>
        <el-table-column label="预约信息" width="140">
            <template slot-scope="scope">
                <div>{{ scope.row.date }}</div>
                <div>
                    <small>{{ scope.row.time }}</small>
                </div>
            </template>
        </el-table-column>
        <el-table-column label="姓名" prop="name" width="170">
            <template slot-scope="scope">
                {{ scope.row.name }} <small>{{ scope.row.XB }} {{ scope.row.NL }}岁 {{ scope.row.ZGLX }}</small>
                <div style="font-size:90%">{{ scope.row.idnum }}</div>
            </template>
        </el-table-column>
        <el-table-column label="费用信息" width="150">
            <template slot-scope="scope">
                <div>{{ scope.row.fee_total }}</div>
                <small>{{ scope.row.fee }}+{{ scope.row.fee_zhiye }}+{{ scope.row.fee_nvgong }}</small>
            </template>
        </el-table-column>
        <el-table-column label="体检项目" width="200">
            <template slot-scope="scope">
                <el-popover placement="top" width="" trigger="click" v-if="scope.row.project.taocan"
                    :content="`${scope.row.project.taocan.name} - ${parseFloat(scope.row.project.taocan.feeZhehou) + 340}元`">
                    <el-tag slot="reference" effect="plain" size="small" type="primary" v-if="scope.row.project.taocan">
                        {{ scope.row.project.taocan.name }}
                    </el-tag>
                </el-popover>
                <el-popover placement="right" title="自选套餐" width="400" trigger="click"
                    v-if="scope.row.project.zixuan.length > 0">
                    <el-table :data="scope.row.project.zixuan" size="small" width="400" height="300">
                        <el-table-column width="" property="name" label="名称"></el-table-column>
                        <el-table-column width="100" property="fee" label="金额"></el-table-column>
                    </el-table>
                    <div style="padding-top:10px;text-align:center">实收：{{ scope.row.fee }}</div>
                    <el-tag slot="reference" type="warning" size="small" effect="plain">
                        自选套餐
                    </el-tag>
                </el-popover>
                <el-popover placement="right" title="职业体检" width="400" trigger="click"
                    v-if="scope.row.project.zhiye && scope.row.project.zhiye.length > 0">
                    <el-table :data="scope.row.project.zhiye" size="small" width="400" heigth="300">
                        <el-table-column width="" property="name" label="名称"></el-table-column>
                        <el-table-column width="100" property="feeHeji" label="金额"></el-table-column>
                    </el-table>
                    <div style="padding-top:10px;text-align:center">合计：{{ scope.row.fee_zhiye }}</div>
                    <el-tag slot="reference" type="success" size="small" effect="plain">
                        职业体检
                    </el-tag>
                </el-popover>
                <el-popover placement="top" width="" trigger="click" v-if="scope.row.project.nvgong"
                    :content="`${scope.row.project.nvgong.name} - 181元`">
                    <el-tag slot="reference" effect="plain" type="danger" size="small">
                        {{ scope.row.project.nvgong.name }}
                    </el-tag>
                </el-popover>
            </template>
        </el-table-column>
        <el-table-column align="left" v-for="(v, idx) in table.columns" :prop="v.field" :label="v.title" :width="v.width"
            :formatter="v.formatter" :key="idx">
        </el-table-column>
        <!-- <el-table-column align="center" width="120" label="操作" fixed="right">
            <template slot-scope="scope">
                <el-button type="warning" size="mini" @click="handleUpdate(scope.row)" icon="el-icon-edit"></el-button>
            </template>
        </el-table-column> -->
        <template v-slot:dialog>
        </template>
        <template v-slot:download>
        </template>
    </zk-admin-table>
</template>

<script>
export default {
    name: 'task-vip',
    path: '/main/task/vip',
    data() {
        let today = new Date().format('yyyy-MM-dd')
        return {
            loading: false,
            search: { idnum: '', daterange: [today, today] },
            table: {
                columns: [
                    { title: '联系电话', field: 'phone', width: '' },
                    { title: '体检医院', field: 'hospital', width: '' },
                    { title: '备注', field: 'desc', width: '' }
                ],
                data: [],
                total: 0,
                page: 1,
                pageSize: 20
            }
        }
    },
    methods: {
        handleSearch(e) {
            this.search = e
            this.table.page = 1
            this.getData()
        },
        handleTableChange(e) {
            this.table = e
            this.getData()
        },
        handleTableSelect(val) {
            this.multipleSelection = val
        },
        async handleExport() {
            try {
                let params = JSON.parse(JSON.stringify(this.search))
                params.export = 'xlsx'//'csv'
                const resp = await this.$http.get(`/task/vip`, { params: params, responseType: 'blob' })
                let blob = new Blob([resp.data], { type: 'application/vnd.openxmlformats;charset=utf-8;' })
                var link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = decodeURI(escape(resp.headers['content-disposition'].split('=')[1]))
                link.click()
                window.URL.revokeObjectURL(link.href)
            } catch (e) {
                this.$message.error(e.message)
            }
        },
        async getData() {
            this.loading = true
            this.$http.get(`/task/vip?page=${this.table.page}&pageSize=${this.table.pageSize}`, { params: this.search })
                .then(resp => {
                    if (!resp.data) {
                        this.$message.error(resp.data.msg)
                    } else {
                        this.table.data = resp.data.data
                        this.table.total = resp.data.total
                    }
                }).catch(err => {
                    this.$message.error(err.message)
                }).finally(() => {
                    this.loading = false
                })
        }
    },
    mounted() {
        this.search = { ...this.search, ...this.$route.query }
        this.getData()
    }
}
</script>
