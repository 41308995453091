<template>
    <el-card class="box-card" shadow="hover">
        <div slot="header" class="clearfix">
            <span>体检费用统计</span>
            <el-form :inline="true" :model="search" class="form-inline" size="mini">
                <el-form-item label="">
                    <select-hospital v-model="search.hospital_id" size="mini" style="width:150px" @input="getData"/>
                </el-form-item>
                <el-form-item label="">
                    <select-subsidiary-2 v-model="search.ejdw_id" size="mini" style="width:150px" @input="getData"/>
                </el-form-item>
                    <el-form-item label="">
                        <select-subsidiary-3 :ejdw_id="search.ejdw_id" v-model="search.sjdw_id" size="mini"
                            style="width:150px" @input="getData" />
                    </el-form-item>
                    <el-form-item label="">
                        <select-subsidiary-4 :sjdw_id="search.sjdw_id" v-model="search.fjdw_id" size="mini"
                            style="width:150px" @input="getData" />
                    </el-form-item>
                <el-form-item label="">
                    <el-date-picker v-model="search.daterange" type="daterange" range-separator="至" start-placeholder="开始日期"
                        style="width:260px" value-format="yyyy-MM-dd" end-placeholder="结束日期" @change="getData">
                    </el-date-picker>
                </el-form-item>
            </el-form>
        </div>
        <el-row :gutter="20" style="height:100%;margin:5px 3px 0 15px" v-loading="loading">
            <el-col v-for="(p, i) in boxData" :key="i" :xs="24" :sm="12" :md="12" :lg="boxData.length==i+1?4:5" :xl="boxData.length==i+1?4:5" style="height:100%;padding-left:0px;">
                <div
                    style="border:0px solid #eee;height:100%;background-color:#fff;padding-top:10px;min-width:100px;cursor:pointer">
                    <div style="height:40%;text-align:left;margin:0 10px 0 10px;">
                        <span style="font-size:15px;">{{ p.name }}</span>
                    </div>
                    <div style="height:60%;text-align:left;margin:0 10px 0 10px;">
                        <span style="font-size:25px;color:#1fb1ea;font-weight:bold;">¥{{ p.fee | money }}</span>
                    </div>
                </div>
            </el-col>
        </el-row>
        <!-- <el-row :gutter="20" style="border-top: 1px solid #EBEEF5;margin-top:30px">
            <el-col :span="6">
                <v-chart autoresize :option="option_taocan" style="height: 200px" v-loading="loading"></v-chart>
            </el-col>
            <el-col :span="6">
                <v-chart autoresize :option="option_taocan" style="height: 200px" v-loading="loading"></v-chart>
            </el-col>
            <el-col :span="6">
                <v-chart autoresize :option="option_taocan" style="height: 200px" v-loading="loading"></v-chart>
            </el-col>
            <el-col :span="6">
                <v-chart autoresize :option="option_nvgong" style="height: 200px" v-loading="loading"></v-chart>
            </el-col>
        </el-row> -->
    </el-card>
</template>

<script>
export default {
    name: 'feeLogBox',
    data() {
        let option = {
            grid: {
                top: '10%',
                bottom: '0px',
                left: 0,
                right: 0
            },
            tooltip: {
                trigger: 'item',
                formatter: '{b}: {c} ({d}%)'
            },
            series: [
                {
                    type: 'pie',
                    radius: '45%',
                    data: [
                        // { value: 1048, name: 'Search Engine' },
                        // { value: 735, name: 'Direct' },
                        // { value: 580, name: 'Email' },
                        // { value: 484, name: 'Union Ads' },
                        // { value: 300, name: 'Video Ads' }
                    ],
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    }
                }
            ]
        }
        return {
            loading: false,
            search: { daterange: [] },
            boxData: [
                { name: '费用合计', fee: '-' },
                { name: '健康体检', fee: '-' },
                { name: '职业体检费用', fee: '-' },
                { name: '女工/家属费用', fee: '-' },
                { name: '现场代扣费用', fee: '-' }
            ],
            option_taocan: JSON.parse(JSON.stringify(option)),
            option_nvgong: JSON.parse(JSON.stringify(option))
        }
    },
    filters: {
        money(value) {
            if (parseFloat(value).toString() == 'NaN') return '0.0'
            var intPart = Number(value).toFixed(0) //获取整数部分
            var intPartFormat = intPart
                .toString()
                .replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') //将整数部分逢三一断
            var floatPart = '.00' //预定义小数部分
            var value2Array = value.split('.')
            //=2表示数据有小数位
            if (value2Array.length == 2) {
                floatPart = value2Array[1].toString() //拿到小数部分
                if (floatPart.length == 1) {
                    //补0,
                    return intPartFormat + '.' + floatPart + '0'
                } else {
                    return intPartFormat + '.' + floatPart
                }
            } else {
                return intPartFormat + floatPart
            }
        }
    },
    methods: {
        async getData() {
            this.loading = true
            const resp = await this.$http.get('/components/box/fee/logbox',{params: this.search})
            console.log(resp.data)
            this.boxData = resp.data.counts
            this.option_taocan.series[0].data = resp.data.taocan
            this.option_nvgong.series[0].data = resp.data.nvgong
            // this.$refs['chart'].setOption(this.option)
            this.loading = false
        }
    },
    mounted() {
        this.getData()
    }
}
</script>

<style scoped>
.form-inline {
    float: right;
    margin-top:-2px
}
::v-deep .el-card__header{
    height: auto;
}
</style>
