<template>
    <el-card class="box-card" shadow="hover">
        <div slot="header" class="clearfix">
            <span>体检机构每日{{ search.status == 1 ? '登记人数' : '预约人数' }} <small>(合计：{{ total }}人)</small></span>
            <el-form :inline="true" :model="form" style="float:right">
                <!-- <el-form-item label="预约日期">
                    <el-date-picker v-model="form.daterange" size="mini" type="daterange" range-separator="至"
                    style="width: 240px"
                        start-placeholder="开始日期" value-format="yyyy-MM-dd" end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item> -->
                <el-button style="padding: 3px 0" type="text" @click="getData" icon="el-icon-refresh"></el-button>
            </el-form>
        </div>
        <v-chart autoresize ref="chart" :option="option" style="height: 200px" v-loading="loading"></v-chart>
    </el-card>
</template>

<script>
export default {
    name: 'statAppointRenshuDates2',
    props: {
        search: {
            type: [Object],
            default: {}
        }
    },
    data() {
        return {
            loading: false,
            form: { daterange: [] },
            total: 0,
            option: {
                grid: {
                    top: 40,
                    bottom: 20,
                    left: 50,
                    right: 15,
                },
                legend: {
                    left: 'center',
                    top: 'top',
                    data: ['油田总院', '龙南医院', '人民医院', '第二医院']
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    },
                    formatter: function (params) {
                        const ageRange = params[0].name
                        let result = `${ageRange}<br/>`
                        params.forEach(e=>{
                            result += `${e.seriesName}: ${e.data}<br/>`
                        })
                        return result
                    }
                },
                xAxis: {
                    type: 'category',
                    data: []
                },
                yAxis: {
                    type: 'value'
                },
                series: [
                    {
                        name: '油田总院',
                        data: [],
                        type: 'bar',
                        itemStyle: {
                            color: '#1890ff'
                        }
                    },
                    {
                        name: '龙南医院',
                        data: [],
                        type: 'bar',
                        itemStyle: {
                            color: '#de6e6a'
                        }
                    },
                    {
                        name: '人民医院',
                        data: [],
                        type: 'bar',
                        itemStyle: {
                            color: '#226e6a'
                        }
                    },
                    {
                        name: '第二医院',
                        data: [],
                        type: 'bar',
                        itemStyle: {
                            color: '#de336a'
                        }
                    }
                ]
            }

        }
    },
    methods: {
        async getData() {
            this.loading = true
            const resp = await this.$http.get('/components/charts/statistics/appoint/renshudates', { params: this.search })
            this.option.xAxis.data = []
            this.option.series[0].data = []
            this.option.series[1].data = []
            this.option.series[2].data = []
            this.option.series[3].data = []
            this.total = 0
            resp.data.forEach(e => {
                this.option.xAxis.data.push(e.name.slice(5))
                this.option.series[0].data.push(e.count_ytzy)
                this.option.series[1].data.push(e.count_lnyy)
                this.option.series[2].data.push(e.count_rmyy)
                this.option.series[3].data.push(e.count_deyy)
                this.total += e.total
            })
            this.$refs['chart'].setOption(this.option)
            this.loading = false
        }
    },
    mounted() {
        this.getData().then(() => {
        })
    }
}
</script>
