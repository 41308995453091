<template>
    <zk-admin-table title="体检报告全文检索" :loading="loading" :table="table" @change="handleTableChange">
        <template v-slot:buttons>
            <el-row style="float: right">
                <el-button size="mini" icon="el-icon-search" circle @click="searchVisible = !searchVisible"></el-button>
                <el-button size="mini" icon="el-icon-refresh" circle @click="getData"></el-button>
                <!-- <el-button size="mini" icon="el-icon-upload2" circle @click="uploadVisible = true"></el-button> -->
                <!-- <el-button size="mini" type="warning" icon="el-icon-plus" circle @click="handleAdd"></el-button>
                <el-button size="mini" type="primary" icon="el-icon-edit" circle @click="handleMultiUpdate"></el-button>
                <el-button size="mini" type="danger" icon="el-icon-delete" circle @click="handleDelete"></el-button> -->
            </el-row>
        </template>
        <template v-slot:search>
            <search-form @search="handleSearch" v-show="searchVisible" />
        </template>
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column align="center" v-for="(v, idx) in table.columns" :prop="v.field" :label="v.title"
            :width="v.width" :formatter="v.formatter" :key="idx">
        </el-table-column>
        <el-table-column align="center" width="120" label="操作" fixed="right">
            <template slot-scope="scope">
                <el-button plain type="default" size="mini" @click="handleUpdate(scope.row)" icon="el-icon-s-order">体检结果</el-button>
            </template>
        </el-table-column>
        <template v-slot:dialog>
        </template>
    </zk-admin-table>
</template>

<script>
import SearchForm from './widgets/searchForm'
import data from './data'

export default {
    name: 'checkup-morbid',
    path: '/main/checkup/morbid',
    components: {
        SearchForm
    },
    data() {
        return {
            loading: false,
            table: data.table,
            searchVisible: true,
            search: {}
        }
    },
    methods: {
        handleSearch(e) {
            this.search = e
            this.table.page = 1
            this.getData()
        },
        handleTableChange(e) {
            this.table = e
            this.getData()
        },
        async handleUpdate(row) {
            this.$router.push({name:'hospital-jcxx-report', params:{id: row.id}})
        },
        async getData() {
            this.loading = true
            try {
                const resp = await this.$http.get(`/search/text?page=${this.table.page}&pageSize=${this.table.pageSize}`, { params: this.search })
                console.log(resp.data)
                if (resp.data.code != 200) {
                    this.$message.error(resp.data.msg)
                } else {
                    this.table.data = resp.data.data.records
                    this.table.total = resp.data.data.total
                }
            } catch (e) {
                this.$message.error(e.message)
            }
            this.loading = false
        }
    },
    mounted() {
        // this.getData()
    }
}
</script>
