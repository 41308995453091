<template>
    <zk-admin-table title="已检人员" :loading="loading" :table="table" @change="handleTableChange" @select="handleTableSelect">
        <template v-slot:buttons>
            <el-row style="float: right">
                <el-button size="mini" icon="el-icon-search" circle @click="searchVisible = !searchVisible"></el-button>
                <el-button size="mini" icon="el-icon-refresh" circle @click="getData"></el-button>
            </el-row>
        </template>
        <template v-slot:search>
            <zk-search-form-inline :form="search" @search="getData" style="text-align: left;">
                <el-form-item label="人员状态">
                    <el-select v-model="search.status" placeholder="请选择" style="width:100px;" clearable>
                        <el-option label="正常" value="0"></el-option>
                        <el-option label="冻结" value="100"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="">
                    <select-hospital v-model="search.hospital_id" />
                </el-form-item>
                <el-form-item label="" prop="ejdw_id" clearable>
                    <select-subsidiary-2 v-model="search.ejdw_id" />
                </el-form-item>
                <el-form-item label="" prop="sjdw_id" clearable>
                    <select-subsidiary-3 :ejdw_id="search.ejdw_id" v-model="search.sjdw_id" />
                </el-form-item>
                <el-form-item label="姓名">
                    <el-input v-model.trim="search.name" clearable></el-input>
                </el-form-item>
                <el-form-item label="身份证号">
                    <el-input v-model.trim="search.idnum" clearable></el-input>
                </el-form-item>
                <el-form-item label="日期">
                    <el-date-picker v-model="search.daterange"
                                    style="float: right; padding: 3px 10px;width: 260px"
                                    type="daterange"
                                    value-format="yyyy-MM-dd">
                    </el-date-picker>
                </el-form-item>
            </zk-search-form-inline>
        </template>
        <el-table-column type="index" width="55"></el-table-column>
        <el-table-column label="体检机构" prop='hospital' width="150">
            <template slot-scope="scope">
                <div>{{ scope.row.hospital }}</div>
                <small>{{ scope.row.source }}</small>
            </template>
        </el-table-column>
        <el-table-column label="单位" prop='hospital' width="200">
            <template slot-scope="scope">
                <div>{{ scope.row.EJDW }}</div>
                <small>{{ scope.row.SJDW }} {{ scope.row.FJDW }}</small>
            </template>
        </el-table-column>
        <el-table-column label="体检人" width="200">
            <template slot-scope="scope">
                <div>{{ scope.row.name }}
                    <small>{{ scope.row.XB }} {{ scope.row.NL }}</small>
                </div>
                <div>{{ scope.row.idnum }}</div>
            </template>
        </el-table-column>
        <el-table-column label="费用(健康+职业+女工)" width="240">
            <template slot-scope="scope">
                <div>{{ scope.row.fee_total }}</div>
                <div>{{ scope.row.fee }}+{{ scope.row.fee_zhiye || 0 }}+{{ scope.row.fee_nvgong || 0 }}</div>
            </template>
        </el-table-column>
        <el-table-column label="时间" width="300">
            <template slot-scope="scope">
                <div>预约时间：{{ scope.row.date }} {{ scope.row.time }}</div>
                <div>刷卡时间：{{ scope.row.payTime }}</div>
            </template>
        </el-table-column>
        <el-table-column align="left" v-for="(v, idx) in table.columns" :prop="v.field" :label="v.title" :width="v.width"
            :formatter="v.formatter" :key="idx">
        </el-table-column>
        <!-- <el-table-column align="center" width="120" label="操作" fixed="right">
            <template slot-scope="scope">
                <el-button type="warning" size="mini" @click="handleUpdate(scope.row)" icon="el-icon-edit"></el-button>
            </template>
        </el-table-column> -->
        <template v-slot:dialog>
        </template>
        <template v-slot:download>
        </template>
    </zk-admin-table>
</template>

<script>
export default {
    name: 'task-checkup',
    path: '/main/task/checkup',
    data() {
        return {
            loading: false,
            searchVisible: true,
            search: { source: 'appoint' },
            table: {
                columns: [
                    { title: '备注', field: 'desc', width: '' }
                ],
                data: [],
                total: 0,
                page: 1,
                pageSize: 20
            }
        }
    },
    methods: {
        handleSearch(e) {
            this.search = e
            this.table.page = 1
            this.getData()
        },
        handleTableChange(e) {
            this.table = e
            this.getData()
        },
        handleTableSelect(val) {
            this.multipleSelection = val
        },
        async getData() {
            this.loading = true
            const resp = await this.$http.get(`/task/checkup?page=${this.table.page}&pageSize=${this.table.pageSize}`, { params: this.search })
            if (!resp.data) {
                this.$message.error(resp.data.msg)
            } else {
                this.table.data = resp.data.data
                this.table.total = resp.data.total
            }
            this.loading = false
        }
    },
    mounted() {
        this.search = { ...this.search, ...this.$route.query }
        this.getData()
    }
}
</script>
