<template>
    <v-chart v-loading="loading" ref="chart" class="chart" :option="option" :autoresize="true" />
</template>

<script>
export default {
    name: 'analysisPieChart',
    props: {
        id: {
            type: [String, Number, Object]
        }
    },
    data() {
        return {
            loading: false,
            option: {
                title: {
                    text: '性别占比',
                    left: 'center'
                },
                tooltip: {
                    trigger: 'item',
                    formatter: '{b} : {c} ({d}%)'
                },
                series: [
                    {
                        type: 'pie',
                        radius: '55%',
                        center: ['50%', '60%'],
                        data: [
                        ],
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ]
            }
        }
    },
    methods: {
        async getData() {
            this.loading = true
            const resp = await this.$http.get(`/statistics/analysis_pie_chart?id=${this.id}`)
            this.option.series[0].data = resp.data
            this.$refs['chart'].setOption(this.option)
            this.loading = false
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.$refs.chart.resize()
        })
        window.addEventListener('resize', ()=>{
            if (this.$refs && this.$refs.chart) {
                this.$refs.chart.resize()
            }
        })
        this.getData()
    }
}
</script>

<style scoped>
.chart {
    width: 100%;
    height: 400px;
}
</style>
