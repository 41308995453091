<template>
    <el-select :value="current" @input="$emit('input', $event)" placeholder="选择三级单位" :size="size" clearable filterable
        v-loading="loading" style="width:140px" :disabled="disabled">
        <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id" />
    </el-select>
</template>

<script>
export default {
    name: 'select-subsidiary-3',
    props: {
        value: {
            type: [String, Number, Object]
        },
        ejdw_id: {
            type: [String, Number],
            default: ''
        },
        size: {
            default: 'small'
        },
        disabled: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            loading: false,
            current: this.value,
            options: []
        }
    },
    watch: {
        value: {
            immediate: false,
            handler(val) {
                this.current = val
            }

        },
        current: {
            immediate: false,
            handler(newVal) {
                this.$emit('input', newVal)
            }
        },
        ejdw_id: {
            // immediate: true,
            handler(newVal) {
                this.getData()
            }
        }
    },
    methods: {
        async getData() {
            this.loading = true
            const hl = await this.$http.get(`/company/subsidiary?level=3&ejdw_id=${this.ejdw_id || ''}&page=1&pageSize=2000`)
            this.options = hl.data.data.records
            this.loading = false
        }
    },
    mounted() {
        this.getData()
    }
}
</script>

<style lang="scss" scoped></style>
