<template>
    <zk-admin-table title="体检记录" :loading="loading" :table="table" @change="handleTableChange"
        @select="handleTableSelect">
        <template v-slot:buttons>
            <el-row style="float: right">
                <el-button size="mini" icon="el-icon-search" circle @click="searchVisible = !searchVisible"></el-button>
                <el-button size="mini" icon="el-icon-refresh" circle @click="getData" :disabled="loading"
                    :loading="loading"></el-button>
                <!-- <el-button size="mini" icon="el-icon-upload2" circle @click="uploadVisible = true"></el-button> -->
            </el-row>
        </template>
        <template v-slot:search>
            <search-form @search="handleSearch" v-model="search" v-show="searchVisible" />
        </template>
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column align="left" v-for="(v, idx) in table.columns" :prop="v.field" :label="v.title"
            :width="v.width" :formatter="v.formatter" :key="idx">
        </el-table-column>
        <el-table-column align="center" width="120" label="操作" fixed="right">
            <template slot-scope="scope">
                <el-button v-if="scope.row.report == 1" plain type="success" size="mini"
                    @click="handleUpdate(scope.row)" icon="el-icon-s-order">评估已完成</el-button>
                <el-button v-else plain type="danger" size="mini" icon="el-icon-s-order">结果未回报</el-button>
            </template>
        </el-table-column>
        <template v-slot:dialog>
            <data-form :visible="dialogVisible" :loading="dialogLoading" :form="form" @close="dialogClose" />
            <data-upload :visible="uploadVisible" @close="dialogClose" />
        </template>
    </zk-admin-table>
</template>

<script>
import SearchForm from './widgets/searchForm'
import DataForm from './widgets/dataForm'
import DataUpload from './widgets/dataUpload'
import data from './data'

export default {
    name: 'assess-records',
    path: '/main/assess/record',
    components: {
        SearchForm,
        DataForm,
        DataUpload
    },
    data() {
        const current = new Date()
        const end = current.format('yyyy-MM-dd')
        const start = current.setDate(current.getDate() - 30)
        const daterange = [new Date(start).format('yyyy-MM-dd'), end]
        return {
            loading: false,
            table: data.table,
            dialogVisible: false,
            dialogLoading: false,
            searchVisible: true,
            uploadVisible: false,
            search: {
                report: 1,
                daterange: daterange
            },
            form: {
                jcxx: {}
            },
            isNew: false,
            multipleSelection: []
        }
    },
    methods: {
        dialogClose() {
            this.dialogVisible = false
            this.uploadVisible = false
        },
        handleSearch(e) {
            this.search = e
            this.table.page = 1
            this.getData()
        },
        handleTableChange(e) {
            this.table = e
            this.getData()
        },
        handleTableSelect(val) {
            this.multipleSelection = val
        },
        async handleUpdate(row) {
            // this.$router.push({name:'hospital-jcxx-report', params:{id: row.id}})
            this.dialogVisible = true
            this.dialogLoading = true
            const resp = await this.$http.get(`/hospital/report/${row.id}`)
            console.log(resp)
            if (resp.data.code === 200)
                this.form = resp.data.data
            this.form.jcxx.wcks = []
            this.dialogLoading = false
        },
        async getData() {
            this.loading = true
            try {
                const resp = await this.$http.get(`/hospital/jcxx?page=${this.table.page}&pageSize=${this.table.pageSize}`, { params: this.search })
                if (!resp.data) {
                    this.$message.error(resp.data.msg)
                } else {
                    this.table.data = resp.data.data.records
                    this.table.total = resp.data.data.total
                }
            } catch (e) {
                this.$message.error(e.message)
            }
            this.loading = false
        }
    },
    mounted() {
        this.getData()
    }
}
</script>
