<template>
    <div class='el-card is-hover-shadow'>
        <div class='el-card__body' style='padding: 0px;'>
            <div class='grid-content grid-con-1'>
                <i :class='`${icon} grid-con-icon`' :style='`background-color: ${color}`'></i>
                <div class='grid-cont-right'>
                    <slot>
                        <div class='grid-num'>{{ count }}</div>
                        <div>{{ title }}</div>
                    </slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'countCard',
    props: {
        count: {
            default: 0
        },
        title: {
            default: '总数'
        },
        color: {
            default: 'rgb(128, 176, 226)'
        },
        icon: {
            default: 'el-icon-s-custom'
        }
    }
}
</script>

<style scoped>
.grid-content {
    display: flex;
    align-items: center;
    height: 130px;
}

.grid-cont-right {
    flex: 1;
    text-align: center;
    font-size: 14px;
    color: #999;
}

.grid-num {
    font-size: 30px;
    font-weight: bold;
}

.grid-con-icon {
    font-size: 50px;
    width: 100px;
    height: 130px;
    text-align: center;
    line-height: 130px;
    color: #fff;
}

.grid-con-3 .grid-con-icon {
    background: rgb(195, 191, 226);
}

.grid-con-3 .grid-num {
    color: rgb(195, 191, 226);
}

.grid-con-1 .grid-con-icon {
    background: rgb(128, 176, 210);
}

.grid-con-1 .grid-num {
    color: rgb(128, 176, 210);
}

.grid-con-2 .grid-con-icon {
    background: rgb(141, 210, 198);
}

.grid-con-2 .grid-num {
    color: rgb(141, 210, 198);
}

.grid-con-4 .grid-con-icon {
    background: rgb(144, 147, 153);
}

.grid-con-4 .grid-num {
    color: rgb(144, 147, 153);
}
</style>