<template>
    <el-select :value="value" @input="$emit('input', $event)" placeholder="请选择" :size="size" clearable>
        <template #prefix>
            <span style="padding-left: 5px;">
                <i :class="value" />
            </span>
        </template>
        <el-option v-for="item in options" :key="item" :label="item" :value="item">
            <span style="float: left"> <i :class="item"></i> </span>
            <span style="float: right; color: #8492a6; font-size: 13px">{{ item }}</span>
        </el-option>
    </el-select>
</template>

<script>
export default {
    name: 'selectIcon',
    props: {
        value: {
            type: [String, Number, Object],
            default: ''
        },
        size: {
            default: 'small'
        }
    },
    data() {
        return {
            options: ['el-icon-setting', 'el-icon-help']
        }
    },
    methods: {
        async getData() {
            // const hl = await this.$http.get('/hospital/hospital?page=1&pageSize=200')
            // this.options = hl.data.data.records
        }
    },
    mounted() {
        this.getData()
    }
}
</script>

<style lang="scss" scoped>

</style>
