<template>
    <div>
        <el-page-header @back="$router.go(-1)" content="体检进度统计" />
        <el-form :inline="true" :model="search" size="mini"
            style="text-align: center;margin-top:15px;background-color: #f7f7f8;padding-top: 20px;border:1px solid #EBEEF5">
            <el-form-item label="类型">
                <el-select v-model="search.type" clearable placeholder="请选择" style="width:130px">
                    <el-option label="健康体检" value="jk"></el-option>
                    <el-option label="职业体检" value="zy"></el-option>
                </el-select>
            </el-form-item>
            <!-- <el-form-item label="二级单位">
                <select-subsidiary-2-multi size="mini" v-model="search.ejdw_ids" />
            </el-form-item> -->
            <el-form-item label="时间">
                <el-date-picker v-model="search.daterange" type="daterange" value-format="yyyy-MM-dd" range-separator="-"
                    start-placeholder="开始时间" end-placeholder="结束时间">
                </el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" size="mini" @click="getData">查询</el-button>
            </el-form-item>

        </el-form>
        <el-table border :data="tableData" style="width: 100%" size="mini" v-loading="loading">
            <el-table-column type="index" label="序号" align="center" width="50" />
            <el-table-column prop="alias" label="单位" align="left" width="" />
            <!-- <el-table-column prop="yy_note" label="提示文字" align="left" width="280" /> -->
            <el-table-column prop="yy_start" label="开始日期" align="center" width="" />
            <el-table-column prop="yy_end" label="结束日期" align="center" width="" />
            <el-table-column prop="appoint_count" label="已体检人数" align="center" width="" sortable />
            <el-table-column prop="staff_count" label="应检人数" align="center" width="" sortable />
            <el-table-column prop="chaojian" label="超检人数" align="center" width="" sortable />
            <el-table-column prop="weijian" label="未检人数" align="center" width="" sortable />
            <el-table-column align="center" width="" label="完成度" sortable>
                <template slot-scope="scope">
                    {{ scope.row.appoint_percentage | toFixed }}%
                </template>
            </el-table-column>
            <el-table-column align="center" width="200" label="操作">
                <template slot-scope="scope">
                    <el-button type="default" plain size="mini" @click="exportStaff(scope.row)">
                        未检人员
                    </el-button>
                    <el-button type="default" plain size="mini" @click="exportAppoint(scope.row)">
                        超检人员
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
export default {
    name: 'stat-jindu',
    path: '/main/statistics/jindu',
    data() {
        return {
            loading: false,
            search: { daterange: [], ejdw_ids: [] },
            tableData: []
        }
    },
    filters: {
        toFixed(value) {
            return parseFloat(value).toFixed(2)
        },
    },
    methods: {
        async exportAppoint(row) {
            this.loading = true
            try {
                const resp = await this.$http.post(`/statistics/diff/chaojian`, row, { params: this.search, responseType: 'blob' })
                let blob = new Blob([resp.data], { type: 'application/vnd.openxmlformats;charset=utf-8;' })
                var link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = decodeURI(escape(resp.headers['content-disposition'].split('=')[1]))
                link.click()
                window.URL.revokeObjectURL(link.href)
            } catch (e) {
                this.$message.error(e.message)
            }
            this.loading = false
        },
        async exportStaff(row) {
            this.loading = true
            try {
                const resp = await this.$http.post(`/statistics/diff/weijian`, row, { params: this.search, responseType: 'blob' })
                let blob = new Blob([resp.data], { type: 'application/vnd.openxmlformats;charset=utf-8;' })
                var link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = decodeURI(escape(resp.headers['content-disposition'].split('=')[1]))
                link.click()
                window.URL.revokeObjectURL(link.href)
            } catch (e) {
                this.$message.error(e.message)
            }
            this.loading = false
        },
        async getData() {
            this.loading = true
            await this.$http.get('/statistics/jindu', { params: this.search })
                .then(resp => {
                    this.tableData = resp.data.data
                }).catch(err => {
                    this.$message.error(err.message)
                })
            this.loading = false
        }
    },
    mounted() {
        this.getData()
    }
}
</script>

<style lang="scss" scoped>
.form {
    margin: 10px auto;
    text-align: center;
    max-width: 380px;

    .title {
        padding: 20px;
        font-weight: bold;
        color: #4f9fe7;
    }
}

@media (max-width: 767px) {

    ::v-deep .el-date-editor--datetimerange.el-input,
    ::v-deep .el-date-editor--datetimerange.el-input__inner {
        width: 200px !important;
    }

}

@media (max-width: 992px) {

    ::v-deep .el-date-editor--datetimerange.el-input,
    ::v-deep .el-date-editor--datetimerange.el-input__inner {
        width: 200px !important;
    }
}
</style>
