// const len_formatter = (row, column) => {
//     const value = row[column.property]
//     return value.length || 0
// }
const name_formatter = (row, column) => {
    const value = row[column.property]
    return value.substring(0,1) + new Array(value.length).join('*')
}

const data = {
    table: {
        columns: [
            {title: '体检机构', field: 'hospital_name', width: '140'},
            {title: '体检编号', field: 'TJBH', width: '140'},
            {title: '姓名', field: 'XM', width: '100', formatter: name_formatter},
            {title: '性别', field: 'XB', width: '80'},
            {title: '年龄', field: 'NL', width: '80'},
            {title: '体检日期', field: 'TJRQ', width: '180'},
            {title: '套餐名称', field: 'TCMC', width: '300'},
            {title: '二级单位', field: 'EJDWMC', width: '200'},
            {title: '三级单位', field: 'SJDWMC', width: '280'}
            // {title: '项目数量', field: 'jcxm', width: '180', formatter: len_formatter},
            // {title: '备注', field: 'remarks', width: ''}
        ],
        data: [],
        total: 0,
        page: 1,
        pageSize: 20
    }
}

export default data
