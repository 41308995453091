const data = {
    table: {
        columns: [
            {title: '标题', field: 'title', width: '360'},
            {title: '作者', field: 'owner', width: '120'},
            {title: '描述', field: 'desc', width: ''},
        ],
        data: [],
        total: 0,
        page: 1,
        pageSize: 20
    }
}

export default data
