import Vue from 'vue';
import VueClipBoard from 'vue-clipboard2'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/display.css' // 响应式
import http from "./http"
import './prototype'
import './echarts'
import './filter'
import '../components'
import Print from 'vue-print-nb'
Vue.use(Print)

Vue.use(VueClipBoard)
Vue.use(ElementUI);
// Vue.use(ElementUI, { size: 'small', zIndex: 3000 });

Vue.config.productionTip = false
Vue.prototype.$http = http
