<template>
    <el-card class="box-card" shadow="hover">
        <div slot="header" class="clearfix">
            <span>过去7天预约情况</span>
            <el-button style="float: right; padding: 3px 0" type="text" @click="getData" icon="el-icon-refresh"></el-button>
        </div>
        <v-chart v-if="option.series[0].data.length > 0" ref="chart" class="chart" :option="option" v-loading="loading" />
        <el-empty v-else class="chart" description="没有记录"></el-empty>
    </el-card>
</template>

<script>
export default {
    name: 'dashboardGuoquYuyuequshi',
    data() {
        return {
            loading: false,
            option: {
                grid: {
                    top: 10,
                    bottom: 30
                },
                tooltip: {
                    trigger: 'item'
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: []
                },
                yAxis: {
                    type: 'value'
                },
                series: [
                    {
                        data: [],
                        type: 'line',
                        smooth: true
                    }
                ]
            }
        }
    },
    methods: {
        async getData() {
            this.loading = true
            const resp = await this.$http.get('/components/charts/dashboard/guoquyuyuequshi')
            this.option.xAxis.data = resp.data.dates
            this.option.series[0].data = resp.data.values
            // this.$refs['chart'].setOption(this.option)
            this.loading = false
        }
    },
    mounted() {
        this.getData().then(() => {
            this.$nextTick(() => {
                this.$refs.chart.resize()
            })
            window.addEventListener('resize', () => {
                if (this.$refs && this.$refs.chart) {
                    this.$refs.chart.resize()
                }
            })
        })
    }
}
</script>

<style scoped>
.chart {
    width: 100%;
    height: 200px;
}

.title {
    text-align: center;
}
</style>
