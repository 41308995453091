<template>
    <zk-admin-table title="套餐内项目" :loading="loading" :table="table" @change="handleTableChange">
        <template v-slot:buttons>
            <el-row style="float: right">
                <el-button size="mini" icon="el-icon-plus" circle @click="handleAdd"></el-button>
                <el-button size="mini" icon="el-icon-search" circle @click="searchVisible = !searchVisible"></el-button>
                <el-button size="mini" icon="el-icon-refresh" circle @click="getData"></el-button>
            </el-row>
        </template>
        <template v-slot:search>
            <zk-search-form-inline :form="search" @search="getData">
                <el-form-item label="名称">
                    <el-input v-model.trim="search.name" clearable></el-input>
                </el-form-item>
            </zk-search-form-inline>
        </template>
        <el-table-column type="index" width="55"></el-table-column>
        <el-table-column show-overflow-tooltip align="left" v-for="(v, idx) in table.columns" :prop="v.field" :label="v.title" :width="v.width"
            :formatter="v.formatter" :key="idx">
        </el-table-column>
        <el-table-column align="center" width="120" label="操作" fixed="right">
            <template slot-scope="scope">
                <el-button type="warning" size="mini" @click="handleUpdate(scope.row)" icon="el-icon-edit"></el-button>
            </template>
        </el-table-column>
        <template v-slot:dialog>
            <el-dialog title="编辑表单" :visible.sync="dialogVisible" width="60%">
            <el-form ref="form" :model="form" label-width="100px">
                <el-form-item label="名称" prop="name" :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                    <el-input v-model.trim="form.name" readonly1></el-input>
                </el-form-item>
                <el-form-item label="项目代码" prop="code" :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                    <el-input v-model.trim="form.code"></el-input>
                </el-form-item>
                <el-form-item label="项目金额" prop="fee" :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                    <el-input v-model.trim="form.fee" readonly1></el-input>
                </el-form-item>
                <el-form-item label="性别限制">
                    <el-checkbox-group v-model="form.gender">
                        <el-checkbox label="男"></el-checkbox>
                        <el-checkbox label="女"></el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item label="项目介绍">
                    <el-input type="textarea" v-model="form.summary"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="handleSubmit">确 定</el-button>
            </span>
        </el-dialog>
        </template>
    </zk-admin-table>
</template>

<script>
export default {
    name: 'hospital-package2-items',
    path: '/main/hospital/package2/items/:id',
    data() {
        return {
            loading: false,
            dialogVisible: false,
            form: {},
            search: {},
            table: {
                columns: [
                    { title: '名称', field: 'name', width: '100' },
                    { title: '项目代码', field: 'code', width: '180' },
                    { title: '金额', field: 'fee', width: '150' },
                    { title: '介绍', field: 'summary', width: '' },
                ],
                data: [],
                total: 0,
                page: 1,
                pageSize: 20
            }
        }
    },
    methods: {
        handleAdd() {
            this.form = {package_id: this.$route.params.id, gender: []}
            this.dialogVisible = true
        },
        handleUpdate(row) {
            this.form = row
            this.form.gender = this.form.gender || []
            this.dialogVisible = true
        },
        async handleSubmit() {
            const valid = await this.$refs['form'].validate().catch(() => false)
            if (!valid) return
            this.$http.post('/hospital/package2/items', this.form)
                .then(resp => {
                    this.$message[resp.data.status](resp.data.msg)
                    if (resp.data.status == 'success') {
                        this.getData()
                        this.dialogVisible = false
                    }
                })
                .catch(error => {
                    this.$message.error(error.message)
                })
        },
        handleSearch(e) {
            this.search = e
            this.table.page = 1
            this.getData()
        },
        handleTableChange(e) {
            this.table = e
            this.getData()
        },
        async getData() {
            this.$http.get('/hospital/package2/items/' + this.$route.params.id)
                .then(resp => {
                    this.table.data = resp.data.data
                    this.table.total = resp.data.total
                }).catch(err => {
                    this.$message.error(err.message)
                })
        }
    },
    mounted() {
        console.log(this.$route.params)
        this.getData()
    }
}
</script>
