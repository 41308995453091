<template>
    <div>
        <div>
            <el-page-header @back="$router.go(-1)" content="重点关注人群" />
        </div>
        <el-row style="margin:15px 0">
            <div style="line-height:40px">
                <el-radio-group v-model="group" size="mini" style="" @change="getData">
                    <el-radio-button label="">全部</el-radio-button>
                    <el-radio-button label="5">5种疾病</el-radio-button>
                    <el-radio-button label="4">4种疾病</el-radio-button>
                    <el-radio-button label="3">3种疾病</el-radio-button>
                    <el-radio-button label="2">2种疾病</el-radio-button>
                </el-radio-group>
                <div style="float:right">
                <div v-if="group == ''">有2种及以上疾病的合计{{ count }}人</div>
                <div v-else>有{{ group }}种疾病的合计{{ count }}人</div>
                </div>
            </div>
            <el-col :xs="12" :sm="12" :md="12" :lg="6" :xl="6" v-for="(e, i) in data" :key="i">
                <div :class="`box box-${e.level}`"
                    @click="$router.push({ name: 'statistics-analysis-compose-view', query: { name: e.name } })">
                    <div :class="`b bg-level-${e.level}`">
                        <div class="c"></div>
                        <span>{{ e.level | formatter_level }}</span>
                    </div>
                    <h3 class="count">{{ e.count }}</h3>
                    <div class="name">{{ e.name }}</div>
                    <div class="remarks">{{ e.remarks }}</div>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
export default {
    name: 'statistics-analysis_compose',
    path: '/main/statistics/analysis_compose',
    data() {
        return {
            group: '',
            data: [],
            count: 0,
        }
    },
    filters: {
        formatter_level(v) {
            const dict = {
                '1': '轻微',
                '2': '高危'
            }
            return dict[v] || '一般'
        }
    },
    methods: {
        async getData() {
            const resp = await this.$http.get(`/analysis/analysis_compose?group=${this.group}`)
            this.data = resp.data.data
            this.count = resp.data.count
        }
    },
    mounted() {
        this.getData()
    }
}
</script>

<style lang="scss" scoped>
.box {
    margin: 5px;
    padding: 10px 10px 30px 10px;
    border: 1px solid #eee;
    border-radius: 10px;
    text-align: center;
    height: 110px;
    overflow: hidden;
    cursor: pointer;
    position: relative;

    .count {
        font-size: 30px;
        margin: 0;
        padding: 10px 0;
    }

    .name {
        font-weight: bold;
    }

    .remarks {
        font-size: 90%;
        max-width: 180px;
        max-height: 40px;
        margin: 0 auto;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box; //特别显示模式
        -webkit-line-clamp: 2; //行数
        line-clamp: 2;
        -webkit-box-orient: vertical; //盒子中内容竖直排列
    }

    .b {
        width: 100px;
        height: 100px;
        position: absolute;
        /*background: green;*/
        top: -50px;
        right: -50px;
        transform: rotate(45deg);
    }

    .b span {
        position: absolute;
        bottom: 0;
        display: block;
        font-size: 16px;
        color: #fff;
        background: rgb(249, 178, 178);
        width: 100px;
        text-align: center;
    }
}

.box:hover {
    background-color: #e5e5e5;
    color: #333;
}

.bg-level-1 span {
    background-color: rgb(255, 108, 108) !important;
    color: white;
}

.bg-level-2 span {
    background-color: rgb(132, 5, 5) !important;
    color: white;
}
</style>
