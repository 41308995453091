<template>
    <div id="app" class="darkBlue">
        <router-view></router-view>
    </div>
</template>

<script>
export default {
    name: 'app',
    mounted() {
        document.title = this.$t('app.title')
    }
}
</script>

<style lang="scss">
html,
body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    height: 100%;
}

.el-container {
    height: 100%;
}

.el-message {
    min-width: 100px !important;
}

/*.el-calendar-table:not(.is-range) td.next, .el-calendar-table:not(.is-range) td.prev{*/
/*  pointer-events: none;*/
/*}*/
.todo {
    padding: 30px;
    color: red;
}

.darkBlue {
    .el-aside {
        background-color: #112d4e;

        .header-left {
            background-color: #112d4e !important;
        }

        .el-aside,
        .el-menu-vertical-demo,
        .el-submenu,
        .el-menu,
        .el-loading-mask {
            background-color: #112d4e;
            color: white;
        }

        .el-menu-item,
        .el-submenu__title,
        .el-submenu__title i,
        .el-menu-item i {
            color: white !important;
        }

        .el-menu-item:hover,
        .el-submenu__title:hover,
        .el-submenu__title i:hover {
            color: white;
            background-color: #3f72af;
        }

        .el-menu .is-active {
            color: white;
            background-color: #1b4069;
        }
    }
}

.blackWhite {
    .el-aside {
        background-color: #000;

        .header-left {
            background-color: #000 !important;
        }

        .el-menu-vertical-demo,
        .el-submenu,
        .el-menu,
        .el-loading-mask {
            background-color: #000;
            color: white;
        }

        .el-menu-item,
        .el-submenu__title,
        .el-submenu__title i,
        .el-menu-item i {
            color: white !important;
        }

        .el-menu-item:hover,
        .el-submenu__title:hover,
        .el-submenu__title i:hover {
            color: white;
            background-color: #233448;
        }

        .el-menu .is-active {
            color: white;
            background-color: #233448;
        }
    }
}
</style>
