const data = {
    table: {
        columns: [
            // {title: 'No.', field: 'id', width: '90'},
            // {title: '二级单位', field: 'patient_name', width: ''},
            {title: '名称', field: 'name', width: ''},
            {title: '人员数量', field: 'staff_count', width: '100'},
            {title: '备注', field: 'remarks', width: ''}
        ],
        data: [],
        total: 0,
        page: 1,
        pageSize: 20
    }
}

export default data
