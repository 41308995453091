<template>
    <zk-search-form-inline :form="form" @search="$emit('search', form)">
        <el-form-item label="二级单位" prop="ejdw_id" clearable>
            <select-subsidiary-2 v-model="form.ejdw_id" />
        </el-form-item>
        <el-form-item label="名称">
            <el-input v-model.trim="form.name" clearable></el-input>
        </el-form-item>
    </zk-search-form-inline>
</template>

<script>
export default {
    data() {
        return {
            form: {}
        }
    }
}
</script>

