<template>
    <div>
        <el-page-header @back="$router.go(-1)" content="体检数量限制" />
        <div>
            <el-table :data="tableData" style="width: 100%;margin-top:20px" v-loading="loading">
                <el-table-column type="index" label="序号" align="center" width="50" />
                <el-table-column prop="name" label="单位" align="left" width="" />
                <el-table-column prop="morning_limit_min" label="上午最少可预约数" align="left" width="180" />
                <el-table-column prop="morning_limit_max" label="上午最多可预约数" align="left" width="180" />
                <el-table-column prop="afternoon_limit_min" label="下午最少可预约数" align="left" width="180" />
                <el-table-column prop="afternoon_limit_max" label="下午最多可预约数" align="left" width="180" />
                <el-table-column prop="custom_limit_max" label="自由选项可预约数" align="left" width="180" />
                <el-table-column align="center" width="120" label="操作" fixed="right">
                    <template slot-scope="scope">
                        <el-button type="warning" size="mini" @click="handleUpdate(scope.row)" icon="el-icon-edit">
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <el-dialog title="限制二级单位可预约日期范围" :visible.sync="dialogVisible" width="40%">
            <el-form ref="form" :model="form" label-width="150px" width="100%">
                <el-form-item label="体检结构" prop="name">
                    <el-input v-model.trim="form.name" readonly style="width:100%"></el-input>
                </el-form-item>
                <el-form-item label="上午最少可预约数" prop="morning_limit_min">
                    <el-input v-model.trim="form.morning_limit_min" clearable style="width:100%"></el-input>
                </el-form-item>
                <el-form-item label="上午最多可预约数" prop="morning_limit_max">
                    <el-input v-model.trim="form.morning_limit_max" clearable style="width:100%"></el-input>
                </el-form-item>
                <el-form-item label="下午最少可预约数" prop="afternoon_limit_min">
                    <el-input v-model.trim="form.afternoon_limit_min" clearable style="width:100%"></el-input>
                </el-form-item>
                <el-form-item label="下午最多可预约数" prop="afternoon_limit_max">
                    <el-input v-model.trim="form.afternoon_limit_max" clearable style="width:100%"></el-input>
                </el-form-item>
                <el-form-item label="自由选项可预约数" prop="custom_limit_max">
                    <el-input v-model.trim="form.custom_limit_max" clearable style="width:100%"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="submit">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: 'task_hospital_limit',
    path: '/main/task/hospital_limit',
    data() {
        return {
            loading: false,
            dialogVisible: false,
            form: { id: null, name: '', limit_min: 0, limit_max: 0 },
            tableData: []
        }
    },
    methods: {
        submit() {
            this.$http.post('/task/hospital_limit', this.form)
                .then(resp => {
                    this.$message.success(resp.data.msg)
                    this.dialogVisible = false
                    this.getData()
                }).catch(err => {
                    this.$message.error(err.message)
                })
        },
        handleUpdate(e) {
            this.form = JSON.parse(JSON.stringify(e))
            this.dialogVisible = true
        },
        async getData() {
            this.loading = true
            await this.$http.get('/task/hospital_limit')
                .then(resp => {
                    console.log(resp.data.data)
                    this.tableData = resp.data.data
                }).catch(err => {
                    this.$message.error(err.message)
                })
            this.loading = false
        }
    },
    mounted() {
        this.getData()
    }
}
</script>
