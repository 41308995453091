<template>
    <div>
        <el-page-header @back="$router.go(-1)" :content="title" />
        <el-form :inline="true" :model="form" size="mini"
            style="text-align: center;margin-top:30px;background-color: #f7f7f8;padding-top: 20px;border:1px solid #EBEEF5">
            <el-form-item label="机构">
                <select-hospital size="mini" v-model="search.hospital_id" />
            </el-form-item>
            <el-form-item label="时间">
                <el-date-picker v-model="search.daterange" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss"
                    range-separator="-" start-placeholder="开始时间" end-placeholder="结束时间">
                </el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" size="mini" plain>查询</el-button>
            </el-form-item>

        </el-form>
        <el-row style="margin:30px 0">
            <el-col :xs="24" :sm="24" :md="24" :lg="16" :xl="16">
                <analysis-bar-chart2 :id="$route.params.id" />
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
                <analysis-pie-chart :id="$route.params.id" />
                <analysis-hospital-pie-chart :id="$route.params.id" />
            </el-col>
        </el-row>
        <el-row style="margin:30px 0">
            <el-col :span="24">
                <analysis-bar-chart :id="$route.params.id" />
            </el-col>
        </el-row>
        <el-row style="margin:30px 0">
            <el-col :span="24">
                <analysis-age-scatter-chart :id="$route.params.id" />
            </el-col>
        </el-row>
        <analysis-ejdw-table style="margin-top:20px" />
        <analysis-patient-table style="margin-top: 20px" />
    </div>
</template>

<script>
export default {
    name: 'statistics-analysis-view',
    path: '/main/statistics/analysis/:id',
    data() {
        return {
            title: '疾病分析',
            search: {},
            form: {},
            table: {
                loading: false,
                page: 1,
                pageSize: 20,
                total: 0,
                tableData: []
            }
        }
    },
    methods: {
        handleCurrentChange(e) {
            this.table.page = e
            this.getTableData()
        },
        handleSizeChange(e) {
            this.table.pageSize = e
            this.table.page = 1
            this.getTableData()
        },
        async getData() {
            const resp = await this.$http.get(`/analysis/ZJJL/${this.$route.params.id}`)
            this.form = resp.data.data
            this.title = `疾病分析 - ${this.form.name}`
        },
        async getTableData() {
            this.table.loading = true
            const tableData = await this.$http.get(`/analysis/ZJJL/${this.$route.params.id}/list?page=${this.table.page}&pageSize=${this.table.pageSize}`)
            this.table.tableData = tableData.data.data.records
            this.table.total = tableData.data.data.total
            this.table.loading = false
        }
    },
    mounted() {
        this.getData()
        this.getTableData()
    }
}
</script>

<style lang="scss" scoped>
.form {
    margin: 10px auto;
    text-align: center;
    max-width: 380px;

    .title {
        padding: 20px;
        font-weight: bold;
        color: #4f9fe7;
    }
}
@media (max-width: 767px) {
    ::v-deep .el-date-editor--datetimerange.el-input,    ::v-deep .el-date-editor--datetimerange.el-input__inner{
        width: 200px !important;
    }

}
@media (max-width: 992px) {
    ::v-deep .el-date-editor--datetimerange.el-input,   ::v-deep .el-date-editor--datetimerange.el-input__inner{
        width: 200px !important;
    }
}
</style>
