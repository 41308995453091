<template>
    <el-card class="box-card" shadow="hover">
        <div slot="header" class="clearfix">
            <span>单位体检完成情况</span>
            <el-button style="float: right; padding: 3px 0" type="text" @click="getData"
                icon="el-icon-refresh"></el-button>
        </div>
        <v-chart v-if="option.series[0].data.length > 0" ref="chart" class="chart" :option="option"
            v-loading="loading" />
        <el-empty v-else class="chart" description="没有记录"></el-empty>
    </el-card>
</template>

<script>
export default {
    name: 'dashboardDanweiWanchengdu',
    data() {
        return {
            loading: false,
            option: {
                color: ['#3c78d8', '#cc4125'],
                grid: {
                    left: '5%', // 与容器左侧的距离
                    right: '5%', // 与容器右侧的距离
                    top: '12%', // 与容器顶部的距离
                    bottom: '20%' // 与容器底部的距离
                },
                legend: {
                    left: 'center',
                    top: 'top',
                    data: ['已检', '未检']
                },
                tooltip: {
                    trigger: 'axis',
                    formatter: function (params) {
                        var result = params[0].name + '<br/>'
                        for (var i = 0; i < params.length; i++) {
                            result += params[i].seriesName + ': ' + params[i].data + '<br/>'
                        }
                        return result
                    }
                },
                xAxis: {
                    axisLabel: {
                        color: '#666',
                        interval: 0,
                        formatter: function (value) {
                            return value.split('').join('\n')
                        }
                    },
                    data: []
                },
                yAxis: {},
                barGap: '90%',
                series: [
                    {
                        name: '已检',
                        type: 'bar',
                        stack: '总数',
                        // barWidth: 49,
                        data: []//这里在data数据里放完成的数据

                    },
                    {
                        name: '未检',
                        type: 'bar',
                        stack: '总数',
                        data: [] //这里在data数据里放未结案的数据
                    }
                ]
            }
        }
    },
    methods: {
        async getData() {
            this.loading = true
            const resp = await this.$http.get('/components/charts/dashboard/danweiwanchengdu')
            this.option.xAxis.data = resp.data.label
            this.option.series[0].data = resp.data.done
            this.option.series[1].data = resp.data.left
            // this.$refs.chart.setOption(this.option)
            this.loading = false
        }
    },
    mounted() {
        this.getData().then(() => {
            this.$nextTick(() => {
                if (this.$refs.chart)
                    this.$refs.chart.resize()
            })
            window.addEventListener('resize', () => {
                if (this.$refs && this.$refs.chart) {
                    this.$refs.chart.resize()
                }
            })
        })
    }
}
</script>

<style scoped>
.chart {
    width: 100%;
    height: 400px;
}
</style>
