<template>
    <div class="page">
        <div class="header">
            <!-- <el-button style="float:right" size="mini" @click="$router.push({ name: 'task-tuanti-solo' })">单个录入</el-button> -->
            <el-page-header @back="$router.go(-1)" content="系统工具" />
        </div>
        <el-row :gutter="20" style="margin-top:18px">
            <el-col :span="8">
                <el-card shadow="hover">
                    <div slot="header" class="clearfix">
                        <span>身份证号</span>
                    </div>
                    <el-form ref="form" :model="form">
                        <el-form-item label="">
                            <el-input type="textarea" :rows="7" v-model="form.idnum_list"></el-input>
                        </el-form-item>
                    </el-form>
                </el-card>
            </el-col>
            <el-col :span="16">
                <el-card shadow="hover" style="height:280px">
                    <div slot="header" class="clearfix">
                        <span>批量操作</span>
                        <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button>
                    </div>
                    <el-row>
                        <el-button @click="onImport('dongjie')" :loading="loading">批量冻结</el-button>
                        <el-button @click="onImport('chachong')" :loading="loading">在册人员</el-button>
                        <el-button @click="onImport('chachong')" :loading="loading">查重复</el-button>
                        <el-button @click="onImport('notin')" :loading="loading">未在名册</el-button>
                        <el-button @click="onImport('import')" :loading="loading">导入查看</el-button>
                        <el-button @click="onImport('unsetjiashu')" :loading="loading">去掉家属</el-button>
                        <el-button @click="onImport('setjiashu')" :loading="loading">增加家属</el-button>
                    </el-row>
                </el-card>
            </el-col>
        </el-row>
        <div class="main-content" style="margin-top:18px">
            <div class="table-content">
                <el-table :data="tableData" border stripe size="medium" v-loading="loading" width="100%" height="100%"
                    :header-cell-style="{ backgroundColor: '#FAFAFA' }">
                    <el-table-column type="index" label="序号" align="center" width="50" />
                    <el-table-column label="体检信息" width="180">
                        <template slot-scope="scope">
                            <div>{{ scope.row.appoint_hospital }}</div>
                            <div>{{ scope.row.appoint_id }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="姓名" prop="name" width="180">
                        <template slot-scope="scope">
                            {{ scope.row.name||scope.row.XM }} <span style="font-size:90%">{{ scope.row.XB }} {{ scope.row.NL }}岁 {{
                                scope.row.ZGLX }}</span>
                            <div>{{ scope.row.idnum || scope.row.SFZH }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="费用" width="150" align="left">
                        <template slot-scope="scope">
                            <div>
                                {{ scope.row.fee_total }}
                            </div>
                            <small>{{ scope.row.fee }}+{{ scope.row.fee_zhiye }}+{{ scope.row.fee_nvgong }}</small>
                        </template>
                    </el-table-column>
                    <el-table-column label="接害因素" width="120">
                        <template slot-scope="scope">
                            {{ scope.row.JHYS ? scope.row.JHYS.join(', ') : '' }}
                        </template>
                    </el-table-column>
                    <el-table-column label="单位" width="">
                        <template slot-scope="scope">
                            {{ scope.row.EJDW }}
                            <div style="font-size:90%">{{ scope.row.SJDW }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="女工家属" prop="TJLX_NGJS" width=""/>
                </el-table>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'settings-tools',
    path: '/main/settings/tools',
    data() {
        return {
            loading: false,
            dialogVisible: false,
            form: { status: '1' },
            tableData: [],
            tableIndex: -1,
        }
    },
    methods: {
        onImport(action) {
            this.loading = true
            this.$http.post(`/settings/tools/${action || 'import'}`, this.form)
                .then(resp => {
                    if (resp.data.code == 200) {
                        this.$alert(resp.data.msg, '执行成功', {type: 'success'})
                        this.tableData = resp.data.data
                        this.dialogVisible = false
                    } else {
                        this.$alert(resp.data.msg, '出错了', {type: 'error'})
                    }
                }).catch(err => {
                    this.$alert(err.message, '出错了', {type: 'error'})
                }).finally(() => {
                    this.loading = false
                })
        },
        onSubmit() {
            this.loading = true
            this.$http.post('/task/tuanti/appoint', {
            }).then(resp => {
                if (resp.data.code == 200) {
                    this.$message.success(resp.data.msg)
                } else {
                    this.$message.error(resp.data.msg)
                }
            }).catch(e => {
                this.$message.error(e.message)
            }).finally(() => {
                this.loading = false
            })
        },
        getData() {
        }
    },
    mounted() {
        this.getData()
    }
}
</script>

<style lang="scss" scoped>
.page {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.header {
    line-height: 40px;

    .btns {
        float: right;
    }
}

.main-content {
    flex: 1;
    position: relative;
}

.table-content {
    position: absolute;
    width: 100%;
    height: 100%;
}

.pager {
    padding-top: 10px;
    text-align: center;
}

.el-tag {
    margin: 2px;
}
</style>