<template>
    <div>
        <!-- <el-alert title="当前为演示页面，内容非真实数据" type="warning" show-icon style="margin-bottom: 10px" /> -->
        <el-page-header @back="$router.go(-1)" content="费用代扣查询" />
        <div style="text-align: left;padding:30px;">
            <el-form :inline="true" :model="form" class="demo-form-inline">
                <el-form-item label="身份证号">
                    <el-input v-model="form.idnum" clearable placeholder="请输入身份证号" style="width: 200px"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSubmit">查询</el-button>
                    <btn-read-id-card @result="readCard" size="large" />
                </el-form-item>
            </el-form>
        </div>
        <div style="padding-left: 30px" v-if="tableData.length > 0">
            <div v-for="t, i in tableData" :key="i" style="line-height:35px">
                <div>姓　　名: {{ t.XM }}</div>
                <div>二级单位: {{ t.EJDW }}</div>
                <div>三级单位: {{ t.SJDW }}</div>
                <div>四级单位: {{ t.FJDW }}</div>
                <div>体检费用: {{ t.fee }}</div>
                <div>接害因素:
                    <el-tag v-for="v, j in t.JHYS" :key="j" style="margin-right:10px" size="small">{{ v }}</el-tag>
                </div>
                <div>女工家属: {{ t.TJLX_NGJS }}</div>
                <div style="margin: 10px 0">
                    <el-tag class="tag" v-if="t.openid" type="success">已绑定微信</el-tag>
                    <el-tag class="tag" v-else type="warning">未绑定微信</el-tag>

                    <el-tag class="tag" v-if="t.appoint_status == 0" type="danger">已预约</el-tag>
                    <el-tag class="tag" v-else-if="t.appoint_status == 1" type="danger">已收费</el-tag>
                    <el-tag class="tag" v-else-if="t.appoint_status == -1" type="danger">已取消</el-tag>
                    <el-tag class="tag" v-else-if="t.appoint_status == -2" type="danger">已删除</el-tag>
                    <el-tag class="tag" v-else type="success">未预约</el-tag>

                    <el-tag class="tag" v-if="t.daikou_id" type="danger">已代扣</el-tag>
                    <el-tag class="tag" v-else type="success">未代扣</el-tag>
                </div>
                <div v-if="t.appoint_id" style="margin: 20px 0">
                    <div style="line-height:20px">
                        <small>
                            于{{ t.appoint_created }} 通过 {{ t.source }} <br>
                            在{{ t.hospital }} {{ t.date }} {{ t.time }}的体检
                        </small>
                    </div>
                    <div style="line-height:20px" v-if="t.appoint_paytime">
                        <small>
                            已于{{ t.appoint_paytime }}收费
                        </small>
                    </div>
                </div>
            </div>
            <div>
                <el-table :data="tableData" style="width: 100%">
                    <el-table-column prop="SFZS" label="噪声" width="80" />
                    <el-table-column prop="SFDW" label="毒物" width="80" />
                    <el-table-column prop="SFFC" label="粉尘" width="80" />
                    <el-table-column prop="SFFS" label="放射" width="80" />
                    <el-table-column prop="SFDG" label="电工" width="80" />
                    <el-table-column prop="SFGW" label="高温" width="80" />
                    <el-table-column prop="SFJSY" label="驾驶员" width="80" />
                    <el-table-column prop="SFGC" label="高处" width="80" />
                    <el-table-column prop="SFYL" label="压力" width="80" />
                </el-table>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'fee-daikoujilu',
    path: '/main/fee/daikoujilu',
    data() {
        return {
            loading: false,
            form: { idnum: '' },
            tableData: []
        }
    },
    methods: {
        readCard(e) {
            // this.form.idnum = '230605197103141615'
            if (e.ret == 1) {
                this.form.idnum = e.data.code
                this.onSubmit()
            }
            else
                this.$message.error(e.msg)
        },
        onSubmit() {
            this.$http.get('/company/userinfo', { params: this.form })
                .then(resp => {
                    this.tableData = resp.data
                }).catch(err => {
                    this.$message.error(err.message)
                })
        }
    },
    mounted() {
    }
}
</script>

<style scoped>
.tag {
    margin: 5px 10px 5px 0;
}
</style>