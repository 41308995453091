<template>
    <el-card class="box-card" shadow="hover">
        <div slot="header" class="clearfix">
            <span>体检机构套餐人数</span>
            <el-button style="float: right; padding: 3px 0" type="text" @click="getData" icon="el-icon-refresh"></el-button>
        </div>
        <v-chart ref="chart" class="chart" :option="option" v-loading="loading" />
    </el-card>
</template>

<script>
export default {
    name: 'dashboardTijianjigourenshu',
    data() {
        return {
            loading: false,
            option: {
                grid: {
                    top: 40,
                    bottom: 20
                },
                legend: {
                    left: 'center',
                    top: 'top',
                    data: ['固定套餐', '自选项目', '职业体检', '女工家属']
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    },
                    formatter: function (params) {
                        const ageRange = params[0].name
                        return `${ageRange}<br/>
                        固定套餐: ${params[0].data}人<br/>自选套餐: ${params[1].data}人<br/>
                        职业体检: ${params[2].data}人<br/>女工家属: ${params[3].data}人
                        `
                    }
                },
                xAxis: {
                    type: 'category',
                    data: ['34岁及以下', '35-44岁', '45岁及以上', '处级干部']
                },
                yAxis: {
                    type: 'value'
                },
                series: [
                    {
                        name: '固定套餐',
                        data: [Math.floor(Math.random() * 1000), Math.floor(Math.random() * 1000), Math.floor(Math.random() * 1000), Math.floor(Math.random() * 1000)],
                        type: 'bar',
                        itemStyle: {
                            color: '#1890ff' // 指定第一组柱状图的颜色为绿色
                        }
                    },
                    {
                        name: '自选项目',
                        data: [Math.floor(Math.random() * 1000), Math.floor(Math.random() * 1000), Math.floor(Math.random() * 1000), Math.floor(Math.random() * 1000)],
                        type: 'bar',
                        itemStyle: {
                            color: '#de6e6a' // 指定第一组柱状图的颜色为绿色
                        }
                    },
                    {
                        name: '职业体检',
                        data: [Math.floor(Math.random() * 1000), Math.floor(Math.random() * 1000), Math.floor(Math.random() * 1000), Math.floor(Math.random() * 1000)],
                        type: 'bar',
                        itemStyle: {
                            color: '#226e6a' // 指定第一组柱状图的颜色为绿色
                        }
                    },
                    {
                        name: '女工家属',
                        data: [Math.floor(Math.random() * 1000), Math.floor(Math.random() * 1000), Math.floor(Math.random() * 1000), Math.floor(Math.random() * 1000)],
                        type: 'bar',
                        itemStyle: {
                            color: '#de336a' // 指定第一组柱状图的颜色为绿色
                        }
                    }
                ]
            }
        }
    },
    methods: {
        async getData() {
            this.loading = true
            // const resp = await this.$http.get('/enterprise/dashboard/charts/nianlingxingbie')
            const resp = await this.$http.get('/components/charts/dashboard/tijianjigourenshu')
            this.option.xAxis.data = resp.data.name
            this.option.series[0].data = resp.data.taocan
            this.option.series[1].data = resp.data.zixuan
            this.option.series[2].data = resp.data.zhiye
            this.option.series[3].data = resp.data.nvgong
            this.$refs['chart'].setOption(this.option)
            this.loading = false
        }
    },
    mounted() {
        this.getData().then(() => {
            this.$nextTick(() => {
                this.$refs.chart.resize()
            })
            window.addEventListener('resize', () => {
                if (this.$refs && this.$refs.chart) {
                    this.$refs.chart.resize()
                }
            })
        })
    }
}
</script>

<style scoped>
.chart {
    width: 100%;
    height: 300px;
}
</style>
