<template>
    <v-chart ref='chart' v-loading='loading' class='chart' :option='option' />
</template>

<script>
export default {
    name: 'ageBarChart',
    data() {
        return {
            loading: false,
            option: {
                title: {
                    text: '年龄分布',
                    left: 'center'
                },
                // tooltip: {
                //     trigger: 'axis',
                //     axisPointer: {
                //         type: 'shadow'
                //     },
                //     formatter: function (params) {
                //         var tar = params[1];
                //         return tar.name + '<br/>' + tar.seriesName + ' : ' + tar.value;
                //     }
                // },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                // legend: {
                //     data: ['Forest', 'Steppe', 'Desert', 'Wetland']
                // },
                color: ['#de6e6a', '#5971c0', '#FCCE10', '#E87C25', '#B5C334'],
                xAxis: {
                    type: 'category',
                    data: ['大庆油田总医院', '大庆市龙南医院', '大庆市人民医院', '大庆市第二医院']
                },
                yAxis: {
                    type: 'value'
                },
                series: []
            }
        }
    },
    methods: {
        async getData() {
            const ids = ['1000', '1001', '1002', '1003']
            const key = ['<20', '20-29', '30-39', '40-49', '50-59', '60-69', '70+']
            const request = ids.map(x => this.$http.get(`/statistics/age_pie_chart?company_id=${x}`))
            // const resp1 = this.$http.get('/statistics/age_pie_chart?company_id=1000')
            // const resp2 = this.$http.get('/statistics/age_pie_chart?company_id=1001')
            const a = await Promise.all(request)
            const aaa = a.map(e => {
                let result = {}
                let td = e.data.reduce((obj, item) => ((obj[item.name] = item.value), obj), {})
                for (var i = 0; i < key.length; i++) {
                    result[key[i]] = td[key[i]] || 0
                }
                return result
            })
            this.option.series = []
            for (var i = 0; i < key.length; i++) {
                console.log(aaa[0][key[i]])
                this.option.series.push({
                    name: key[i],
                    data: [
                        aaa[0][key[i]],
                        aaa[1][key[i]],
                        aaa[2][key[i]],
                        aaa[3][key[i]]
                    ],
                    type: 'bar'
                })
            }
            this.$refs['chart'].setOption(this.option)
        }
    },
    mounted() {
        this.getData()
        this.$nextTick(() => {
            this.$refs.chart.resize()
        })
        window.addEventListener('resize', () => {
            if (this.$refs && this.$refs.chart) {
                this.$refs.chart.resize()
            }
        })
    }
}
</script>

<style scoped>
.chart {
    width: 100%;
    height: 400px;
}
</style>
