const data = {
    table: {
        columns: [
            {title: '名称', field: 'name', width: '200'},
            {title: '关键词', field: 'keywords', width: '180'},
            {title: '级别', field: 'level', width: '180'},
            {title: '告警', field: 'alert', width: '180'},
            {title: '描述', field: 'remarks', width: ''}
        ],
        data: [],
        total: 0,
        page: 1,
        pageSize: 20
    }
}

export default data
