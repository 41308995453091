export default {
    app_title: '团体健康体检管理',
    app: {
        title: '团体健康体检管理',
        home: '首页',
        passwd: '修改密码',
        logout: '退出'
    },
    menu: {
        dashboard: '系统首页',
        task: '体检任务',
        task_plan: '年度体检计划',
        task_planB: '年度体检计划外',
        task_user: '体检人员数量',
        task_scheduling: '体检排期表',
        task_item: '特殊项目控制',
        task_surplus: '剩余未检人员',
        task_checkup: '已检人员',
        task_appoint: '预约记录',
        task_vip: 'VIP预约记录',
        task_tuanti: '团体预约',
        task_tuanti_solo: '预约收费',
        task_daterange: '体检时间安排',
        task_hospital_limit: '体检人数限制',
        task_gaowei: '高危人员（优先体检）',
        checkup: '体检记录',
        checkup_record: '体检记录',
        checkup_record2023: '体检记录(新)',
        checkup_screen: '分项数值筛选',
        checkup_morbid: '报告全文检索',
        checkup_alert: '健康预警',
        checkup_suifang: '随访记录',
        assess: '健康评估',
        assess_record: '健康评估记录',
        assess_confirm: '健康评估审核',
        statistics: '统计分析',
        statistics_appoint: '体检预约统计',
        statistics_checkup: '体检登记统计',
        statistics_fee: '体检费用统计',
        statistics_staff: '体检人员状态',
        statistics_gender: '男女比例',
        statistics_age: '年龄分布',
        statistics_analysis: '疾病分析',
        statistics_analysis_compose: '重点关注人群',
        statistics_analysis_level: '高/中/低危人群',
        statistics_analysis_zaizhidabing: '在职大病人员',
        statistics_analysis_manbing: '慢性病信息',
        statistics_analysis_up60: '待处理数据',
        statistics_idnum_duplicates: '重复数据',
        statistics_fee_differ: '费用不符',
        statistics_fee_person: '个人费用统计',
        statistics_fee_company: '二级单位费用统计',
        statistics_fee_hospital: '机构费用统计',
        statistics_fee_platform: '油田公司费用统计',
        company: '集团单位管理',
        company_subsidiary2: '二级单位管理',
        company_subsidiary3: '三级单位管理',
        company_subsidiary4: '四级单位管理',
        company_staff: '单位员工管理',
        company_staff_dongjie: '冻结人员管理',
        company_jiashu: '家属管理',
        company_staff_zhiyebiangeng: '职业体检变更',
        company_staff_ruzhitiaogang: '新入职/人员调岗',
        company_user: '单位用户管理',
        hospital:'体检机构管理',
        hospital_list:'体检机构信息',
        hospital_user:'体检机构账号',
        hospital_dwmc:'体检单位管理',
        hospital_med_item: '体检项目设置',
        hospital_jcmxx: '体检子项目设置',
        hospital_package: '体检套餐设置',
        hospital_package2: '体检套餐设置',
        hospital_jcxx: '体检记录',
        hospital_interface:'接口同步记录',
        hospital_upload:'接口数据上传',
        hospital_white_list: '接口白名单设置',
        fee: '费用管理',
        fee_xiaofei: '消费统计',
        fee_log: '收费记录',
        fee_statement: '体检结算单',
        fee_chongzhi: '费用充值',
        fee_daikoushenqing: '代扣申请',
        fee_daikoushenpi: '代扣审批',
        fee_daikoujilu: '代扣查询',
        settings: '系统设置',
        settings_roles: '角色管理',
        settings_dict: '字典管理',
        settings_analysis: '疾病监测',
        settings_tools: '系统工具',
        mp_settings: '微信设置',
        mp_settings_flatpage: '简单页面',
        mp_settings_tuwen: '图文内容',
        help: '使用说明',
        help_no_permission: '未开通权限',
        oplog: '系统日志'
    },
    form: {
        search: '搜索',
        ok: '确认',
        cancel: '取消',
        export_xsl: '导出Excel'
    }
}
