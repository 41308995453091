<template>
    <el-card class="box-card" shadow="hover">
        <div slot="header" class="clearfix">
            <span>预计完成时间</span>
            <div style="float:right">
                <el-button style="padding: 3px 0" type="text" @click="getData" icon="el-icon-refresh"></el-button>
                <el-popover placement="bottom" title="过滤条件" width="350" trigger="click">
                    <el-form ref="form" :model="form" label-width="90px" size="mini">
                    </el-form>
                    <div style="text-align: right; margin: 0">
                        <el-button size="mini" type="text" @click="visible = false">取消</el-button>
                        <el-button type="primary" size="mini" @click="visible = false">确定</el-button>
                    </div>
                    <!-- <el-button slot="reference" style="padding: 3px 0;margin-left:10px;" type="text"
                        icon="el-icon-setting"></el-button> -->
                </el-popover>
            </div>
        </div>
        <div style="height:300px;">
            <el-date-picker v-model="form.daterange" type="daterange" size="mini" style="width:260px" range-separator="至"
                @change="getData" start-placeholder="开始日期" value-format="yyyy-MM-dd" end-placeholder="结束日期">
            </el-date-picker>
            <div class="count1">
                平均 <el-tag>{{ data.avg }}</el-tag> /每日
            </div>
            <div class="count2">
                预计需要<b>{{ data.surplus }}</b>后天完成
            </div>
            <div class="count3">
                预计 <el-tag>{{ data.expect }}</el-tag> 完成
            </div>
        </div>
    </el-card>
</template>
<script>
export default {
    name: 'statAppointPingjunshu',
    data() {
        // let beforDate = new Date(new Date().setDate(new Date().getDate() - 30)).format('yyyy-MM-dd')
        let beforDate = '2023-04-04'
        let afterDate = new Date().format('yyyy-MM-dd')
        return {
            form: {
                daterange: [beforDate, afterDate]
            },
            data: {}
        }
    },
    methods: {
        async getData() {
            const resp = await this.$http.get('/components/box/stat/appoint/pingjunshu', { params: this.form })
            console.log(resp.data)
            this.data = resp.data
        }
    },
    mounted() {
        this.getData()
    }
}
</script>

<style lang="scss" scoped>
.count1,
.count2,
.count3 {
    line-height: 90px;

}

.count2 {
    text-align: center;

    b {
        background-color: #1890ff;
        color: white;
        border-radius: 50%;
        height: 100px;
        padding: 35px;
    }
}

.count3 {
    text-align: right;
}
</style>