const data = {
    table: {
        columns: [
            // {title: 'No.', field: 'id', width: '90'},
            {title: '二级单位', field: 'EJDW', width: '150'},
            {title: '三级单位', field: 'SJDW', width: '250'},
            {title: '姓名', field: 'XM', width: '100'},
            // {title: '身份证号', field: 'SFZH', width: '180'},
            {title: '职工类型', field: 'ZGLX', width: '100'},
            // {title: '备注', field: 'remarks', width: ''}
        ],
        data: [],
        total: 0,
        page: 1,
        pageSize: 20
    }
}

export default data
