<template>
    <div>
        <!-- <el-alert title="当前为演示页面，内容非真实数据" type="warning" show-icon style="margin-bottom: 10px" /> -->
        <div>
            <div style="float:right">
                <el-button size="small" type="primary" @click="handleAdd">代扣申请</el-button>
            </div>
            <el-page-header @back="$router.go(-1)" content="费用代扣申请" />
        </div>
        <el-table :data="tableData" border style="width: 100%;margin-top:20px">
            <el-table-column type="index" width="50" align="center" />
            <el-table-column prop="name" label="姓名" width="80" />
            <el-table-column prop="idnum" label="身份证号" width="180" />
            <el-table-column prop="date" label="体检日期" width="120" />
            <el-table-column prop="time" label="体检时间" width="120" />
            <el-table-column prop="package" label="体检套餐" width="" />
            <el-table-column prop="fee" label="费用" width="180" />
            <el-table-column prop="status" label="状态" width="180" />
            <el-table-column align="center" width="200" label="操作" fixed="right">
                <template slot-scope="scope">
                    <el-button type="primary" plain size="mini" @click="handlePrint(scope.row)">
                        打印小票
                    </el-button>

                    <el-popover style="margin-left: 10px" :ref="`popover-${scope.$index}`" placement="top" width="180">
                        <p>确定删除[{{ scope.row.name }}]的记录吗？</p>
                        <div>
                            <el-button type="danger" size="mini" @click="handleRemove(scope.$index, scope.row)">确定
                            </el-button>
                            <el-button size="mini" type="default" @click="$refs[`popover-${scope.$index}`].doClose()">取消
                            </el-button>
                        </div>
                        <el-button size="mini" type="danger" slot="reference" icon="el-icon-delete"></el-button>
                    </el-popover>
                </template>
            </el-table-column>
        </el-table>
        <dataForm :visible="dialogVisible" :form="form" @close="dialogClose" />
        <print-form :visible="printVisible" :form="form" @close="dialogClose" @update="getData" />
    </div>
</template>

<script>
import dataForm from './widgets/dataForm.vue'
import PrintForm from './widgets/printForm'
export default {
    name: 'fee-daikoushenqing',
    path: '/main/fee/daikoushenqing',
    components: {
        dataForm,
        PrintForm
    },
    data() {
        return {
            active: 1,
            dialogVisible: false,
            visible: false,
            printVisible: false,
            tableData: [],
            form: { name: '', idnum: '', fee: '', date: '', time: '', package: '', remarks: '' }
        }
    },
    methods: {
        dialogClose() {
            this.dialogVisible = false
            this.printVisible = false
            this.getData()
        },
        handleAdd() {
            this.form = { name: '', idnum: '', fee: '', date: new Date().format('yyyy-MM-dd'), time: '08:00-09:00', package: '', remarks: '' }
            this.dialogVisible = true
        },
        handlePrint(row) {
            this.form = row
            this.printVisible = true
        },
        async handleRemove(index, row) {
            console.log(row)
            this.$refs[`popover-${index}`].doClose()
            const resp = await this.$http.delete(`/fee/daikou/${row.id}`)
            if (resp.data.code == 200)
                this.$message.success('删除成功')
            else
                this.$message.error(resp.data.msg)
            this.getData()
        },
        async getData() {
            this.loading = true
            const resp = await this.$http.get('/fee/daikou?page=1&pageSize=20', { params: this.search })
            console.log(resp.data.data)
            if (!resp.data) {
                this.$message.error(resp.data.msg)
            } else {
                this.tableData = resp.data.data
            }
        }
    },
    mounted() {
        this.getData()
    }
}
</script>

<style scoped>
.notfound {
    padding: 40px 0;
    text-align: left;
}
</style>