<template>
    <div>
        <el-page-header @back="$router.go(-1)" content="接口上传导入" />
        <div class="form">
            <div class="title">
                批量导入接口数据
            </div>
            <el-upload v-loading="loading" drag action="/api/hospital/upload/jcxx" :before-upload="onProgress"
                :on-success="onSuccess" :headers="headers" :on-error="onError" :on-progress="onProgress" multiple>
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                <div class="el-upload__tip" slot="tip">下载Excel模板</div>
            </el-upload>
        </div>
    </div>
</template>

<script>
export default {
    name: 'hospital_data_upload',
    path: '/main/hospital/upload',
    data() {
        return {
            loading: false
        }
    },
    computed: {
        headers() {
            const token = localStorage.getItem('token')
            return {
                Authorization: `Bearer ${token}`
            }
        }
    },
    methods: {
        onProgress() {
            this.loading = true
        },
        onSuccess(resp, file, fileList) {
            console.log(resp, file, fileList)
            if (resp.code === 200)
                this.$message.success(resp.msg)
            else
                this.$message.error(resp.msg)
            this.loading = false
        },
        onError(err) {
            // console.log(err)
            this.$message.error('导入失败！')
            this.loading = false
        }
    }
}
</script>

<style lang="scss" scoped>
.form {
    margin: 10px auto;
    text-align: center;
    max-width: 380px;

    .title {
        padding: 20px;
        font-weight: bold;
        color: #4f9fe7;
    }
}
</style>