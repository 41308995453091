<template>
    <v-chart ref="chart" :option="option" style="width: 100%;height:400px" />
</template>

<script>
export default {
    name: 'tjlxPieChart',
    data() {
        return {
            option: {
                title: {
                    text: '体检类型占比',
                    left: 'center'
                },
                legend: {
                    top: 'bottom'
                },
                color: ['#FCCE10', '#cc4125', '#3c78d8', '#E87C25', '#B5C334'],
                tooltip: {
                    trigger: 'item',
                    formatter: '{a} <br/>{b} : {c} ({d}%)'
                },
                series: [
                    {
                        name: '体检类型比例',
                        type: 'pie',
                        radius: ['10%', '50%'],
                        center: ['50%', '50%'],
                        roseType: 'area',
                        itemStyle: {
                            borderRadius: 8
                        },
                        data: []
                        // emphasis: {
                        //     itemStyle: {
                        //         shadowBlur: 10,
                        //         shadowOffsetX: 0,
                        //         shadowColor: 'rgba(0, 0, 0, 0.5)'
                        //     }
                        // }
                    }
                ]
            }
        }
    },
    methods: {
        async getData() {
            this.loading = true
            const resp = await this.$http.get('/statistics/tjlx_pie_chart')
            this.option.series[0].data = resp.data
            this.$refs.chart.setOption(this.option)
            this.loading = false
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.$refs.chart.resize()
        })
        window.addEventListener('resize', () => {
            if (this.$refs && this.$refs.chart) {
                this.$refs.chart.resize()
            }
        })
        this.getData()
    }
}
</script>
