<template>
    <v-chart v-loading="loading" ref="chart" class="chart" :option="option" />
</template>

<script>
export default {
    name: 'pieChartHospital',
    data() {
        return {
            loading: false,
            option: {
                title: {
                    text: '机构体检人员分布',
                    left: 'center'
                },
                tooltip: {
                    trigger: 'item',
                    formatter: '{a} <br/>{b} : {c} ({d}%)'
                },
                toolbox: {
                    show: true,
                    top: 0,
                    right: '5%',
                    feature: {
                        saveAsImage: {
                            show: true,
                            excludeComponents: ['toolbox'],
                            pixelRatio: 2
                        }
                    }
                },
                series: [
                    {
                        name: '体检人员分布',
                        type: 'pie',
                        radius: '55%',
                        center: ['50%', '60%'],
                        // minAngle: 15,//最小角度
                        // startAngle: 120, //起始角度
                        label: {
                            show: true,
                            // formatter: '{b} : {c} ({d}%)'
                            formatter: '{b}'
                        },
                        labelLine: { show: true },
                        data: [
                            // { value: Math.floor(Math.random() * 1000), name: '大庆油田总医院' },
                            // { value: Math.floor(Math.random() * 1000), name: '大庆市人民医院' },
                            // { value: Math.floor(Math.random() * 1000), name: '大庆市龙南医院' },
                            // { value: Math.floor(Math.random() * 1000), name: '大庆市第二医院' },
                        ],
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ]
            }
        }
    },
    methods: {
        async getData() {
            this.loading = true
            const resp = await this.$http.get('/statistics/pie/hospital')
            this.option.series[0].data = resp.data
            this.$refs.chart.setOption(this.option)
            this.loading = false
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.$refs.chart.resize()
        })
        window.addEventListener('resize', () => {
            if (this.$refs && this.$refs.chart) {
                this.$refs.chart.resize()
            }
        })
        this.getData()
    }
}
</script>

<style scoped>
.chart {
    width: 100%;
    height: 400px;
}
</style>
