<template>
    <el-card class="box-card" shadow="hover">
        <div slot="header" class="clearfix">
            <span>单位体检完成人数</span>
            <div style="float:right">
                <el-button style="padding: 3px 0" type="text" @click="getData" icon="el-icon-refresh"></el-button>
                <el-popover placement="bottom" title="过滤条件" width="350" trigger="click">
                    <el-form ref="form" :model="form" label-width="90px" size="mini">
                        <el-form-item label="体检机构">
                            <el-select v-model="form.region" multiple collapse-tags placeholder="请选择活动区域"
                                style="width:100%">
                                <el-option label="区域一" value="shanghai"></el-option>
                                <el-option label="区域二" value="beijing"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="二级单位">
                            <el-select v-model="form.region" multiple collapse-tags placeholder="请选择活动区域"
                                style="width:100%">
                                <el-option label="区域一" value="shanghai"></el-option>
                                <el-option label="区域二" value="beijing"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="统计周期">
                            <el-date-picker v-model="form.daterange" type="daterange" style="width:260px"
                                range-separator="至" start-placeholder="开始日期" value-format="yyyy-MM-dd"
                                end-placeholder="结束日期">
                            </el-date-picker>
                        </el-form-item>
                    </el-form>
                    <div style="text-align: right; margin: 0">
                        <el-button size="mini" type="text" @click="visible = false">取消</el-button>
                        <el-button type="primary" size="mini" @click="visible = false">确定</el-button>
                    </div>
                    <!-- <el-button slot="reference" style="padding: 3px 0;margin-left:10px;" type="text"
                        icon="el-icon-setting"></el-button> -->
                </el-popover>
            </div>
        </div>
        <v-chart v-if="option.series[0].data.length > 0" ref="chart" class="chart" :option="option"
            v-loading="loading" />
        <el-empty v-else class="chart" description="没有记录"></el-empty>
    </el-card>
</template>

<script>
export default {
    name: 'dashboardDanweiWancheng',
    data() {
        return {
            loading: false,
            form: {},
            option: {
                tooltip: {
                    trigger: 'item',
                    formatter: '{b} : {c}'
                },
                grid: {
                    top: '1%',
                    left: '1%',
                    right: '3%',
                    bottom: '1%',
                    containLabel: true
                },
                xAxis: {
                    type: 'value'
                },
                yAxis: {
                    type: 'category',
                    axisLabel: {
                        interval: 0
                    },
                    data: []
                },
                series: [
                    {
                        data: [],
                        type: 'bar'
                        //color: function () { return "#" + Math.floor(Math.random() * (256 * 256 * 256 - 1)).toString(16); }
                    }
                ]
            }
        }
    },
    methods: {
        async getData() {
            this.loading = true
            this.option.yAxis.data = []
            this.option.series[0].data = []
            const resp = await this.$http.get('/components/charts/dashboard/danweipaihang2')
            resp.data.sort((a, b) => { return a.value - b.value })
            resp.data.map(e => {
                this.option.yAxis.data.push(e.name)
                this.option.series[0].data.push(e.value)
            })
            // this.$refs['chart'].setOption(this.option)
            this.loading = false
        }
    },
    mounted() {
        this.getData().then(() => {
            this.$nextTick(() => {
                if (this.$refs.chart)
                    this.$refs.chart.resize()
            })
            window.addEventListener('resize', () => {
                if (this.$refs && this.$refs.chart) {
                    this.$refs.chart.resize()
                }
            })
        })
    }
}
</script>

<style scoped>
.chart {
    width: 100%;
    height: 522px;
}

.title {
    text-align: center;
}
</style>
