<template>
    <el-card class="box-card" shadow="hover">
        <div slot="header" class="clearfix">
            <span>体检机构费用</span>
            <el-form :inline="true" :model="form" style="float:right">
                <!-- <el-form-item label="预约日期">
                    <el-date-picker v-model="form.daterange" size="mini" type="daterange" range-separator="至"
                    style="width: 240px"
                        start-placeholder="开始日期" value-format="yyyy-MM-dd" end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item> -->
                <el-button style="padding: 3px 0" type="text" @click="getData" icon="el-icon-refresh"></el-button>
            </el-form>
        </div>
        <v-chart v-if="option.series[0].data.length > 0" ref="chart" class="chart" :option="option"
            v-loading="loading" />
        <el-empty v-else class="chart" description="没有记录"></el-empty>
    </el-card>
</template>

<script>
export default {
    name: 'dashboardTijianjigoufeiyong',
    data() {
        return {
            loading: false,
            form: { daterange: [] },
            option: {
                grid: {
                    top: 40,
                    bottom: 20
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                xAxis: {
                    type: 'category',
                    data: ['大庆油田总医院', '大庆龙南医院', '大庆市人民医院', '大庆市第二医院']
                },
                yAxis: {
                    type: 'value'
                },
                series: [
                    {
                        data: [120, 200, 150, 80],
                        type: 'bar',
                        label: {
                            show: true, //开启显示
                            position: 'top', //在上方显示
                            color: 'black',
                            fontSize: 12
                        }
                    }
                ]
            }
        }
    },
    methods: {
        async getData() {
            this.loading = true
            // const resp = await this.$http.get('/enterprise/dashboard/charts/nianlingxingbie')
            const resp = await this.$http.get('/components/charts/dashboard/tijianjigoufeiyong2')
            this.option.xAxis.data = []
            this.option.series[0].data = []
            resp.data.forEach(e => {
                this.option.xAxis.data.push(e.name)
                this.option.series[0].data.push(e.value)
            })
            this.$refs['chart'].setOption(this.option)
            this.loading = false
        }
    },
    mounted() {
        this.getData().then(() => {
            this.$nextTick(() => {
                if (this.$refs.chart)
                    this.$refs.chart.resize()
            })
            window.addEventListener('resize', () => {
                if (this.$refs && this.$refs.chart) {
                    this.$refs.chart.resize()
                }
            })
        })
    }
}
</script>

<style scoped>
.chart {
    width: 100%;
    height: 200px;
}
</style>
