<template>
    <el-card class="box-card" body-style="padding:0" shadow="hover">
        <div slot="header" class="clearfix">
            <span>人员名单</span>
            <div style="float:right">
                <el-button size="mini" :loading="export_loading" @click="exportXLSX">导出excel</el-button>
                <el-button size="mini" @click="getTableData">刷新</el-button>
            </div>
        </div>
        <el-table :data="table.tableData" strip style="width: 100%" height="580px" v-loading="table.loading">
            <el-table-column type="index" width="50" align="center">
                <template slot-scope="scope">
                    <div v-text="scope.$index + 1 + ((table.page - 1) * table.pageSize)"></div>
                </template>
            </el-table-column>
            <el-table-column prop="hospital_name" label="体检机构" width="180" />
            <el-table-column prop="name" label="姓名" width="180" :formatter="name_formatter" />
            <el-table-column prop="gender" label="性别" width="90" />
            <el-table-column prop="age" label="年龄" width="90" />
            <el-table-column prop="jdrq" label="体检时间" width="200" />
            <el-table-column prop="ycxm.length" label="项目数量" />
            <el-table-column align="center" width="120" label="操作" fixed="right">
                <template slot-scope="scope">
                    <el-button plain type="default" size="mini"
                        @click="$router.push({ name: 'hospital-jcxx-report', params: { id: scope.row.id } })"
                        icon="el-icon-s-order">体检结果</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div style="margin: 10px auto;text-align: center;">
            <el-pagination layout="total, prev, pager, next, sizes" :page-sizes="[10, 20, 30, 50, 100]"
                :current-page="table.page" :total="table.total" :page-size="table.pageSize"
                @current-change="handleCurrentChange" @size-change="handleSizeChange" />
        </div>
    </el-card>
</template>

<script>
export default {
    name: 'analysis-patient-table',
    props: {
        id: {
            type: [String, Number, Object]
        }
    },
    data() {
        return {
            export_loading: false,
            table: {
                loading: false,
                page: 1,
                pageSize: 20,
                total: 0,
                tableData: []
            }
        }
    },
    methods: {
        name_formatter(row, column) {
            const value = row[column.property]
            return value.substring(0, 1) + new Array(value.length).join('*')
        },
        handleCurrentChange(e) {
            this.table.page = e
            this.getTableData()
        },
        handleSizeChange(e) {
            this.table.pageSize = e
            this.table.page = 1
            this.getTableData()
        },
        async exportXLSX() {
            this.export_loading = true
            let params = { export: 'xlsx' }
            const resp = await this.$http.get(`/analysis/ZJJL/${this.$route.params.id}/list?page=${this.table.page}&pageSize=${this.table.pageSize}`, { params: params, responseType: 'blob' })
            console.log(resp.data)
            let blob = new Blob([resp.data], { type: 'application/vnd.openxmlformats;charset=utf-8;' })
            var link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = decodeURI(escape(resp.headers['content-disposition'].split('=')[1]))
            link.click()
            window.URL.revokeObjectURL(link.href)
            this.export_loading = false
        },
        async getTableData() {
            this.table.loading = true
            const tableData = await this.$http.get(`/analysis/ZJJL/${this.$route.params.id}/list?page=${this.table.page}&pageSize=${this.table.pageSize}`)
            this.table.tableData = tableData.data.data.records
            this.table.total = tableData.data.data.total
            this.table.loading = false
        }
    },
    mounted() {
        this.getTableData()
    }
}
</script>