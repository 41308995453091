<template>
    <div>
        <el-page-header @back="$router.go(-1)" content="年龄分布" />
        <el-form :inline="true" :model="form" size="mini"
            style="text-align: center;margin-top:30px;background-color: #f7f7f8;padding-top: 20px;border:1px solid #EBEEF5">
            <el-form-item label="时间">
                <el-date-picker v-model="form.daterange" size="small" type="datetimerange"
                    value-format="yyyy-MM-dd HH:mm:ss" range-separator="-" start-placeholder="开始时间"
                    end-placeholder="结束时间">
                </el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" size="mini" >查询</el-button>
            </el-form-item>
        </el-form>
        <el-row style="margin:30px 0">
            <el-col :xs="24" :sm="24" :md="24" :lg="16" :xl="16">
                <age-bar-chart />
            </el-col>
            <el-col  :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
                <age-pie-chart />
            </el-col>
        </el-row>
    </div>
</template>

<script>
export default {
    name: 'stat-age',
    path: '/main/statistics/age',
    data() {
        return {
            form: {}
        }
    },
    methods: {
        onSuccess(resp, file, fileList) {
            console.log(resp, file, fileList)
            this.$message(resp.msg)
        },
        onError(err) {
            console.log(err)
            this.$message.error('导入失败！')
        }
    }
}
</script>

<style lang="scss" scoped>
.form {
    margin: 10px auto;
    text-align: center;
    max-width: 380px;

    .title {
        padding: 20px;
        font-weight: bold;
        color: #4f9fe7;
    }
}
@media (max-width: 767px) {
    ::v-deep .el-date-editor--datetimerange.el-input,   ::v-deep .el-date-editor--datetimerange.el-input__inner{
        width: 200px !important;
    }

}
@media (max-width: 992px) {
    ::v-deep .el-date-editor--datetimerange.el-input,  ::v-deep  .el-date-editor--datetimerange.el-input__inner{
        width: 200px !important;
    }
}
</style>
