<template>
    <zk-search-form-inline :form="form" @search="$emit('search', form)" style="text-align:left">
        <el-form-item label="二级单位" prop="ejdw_id" clearable>
            <select-subsidiary-2 v-model="form.ejdw_id" />
        </el-form-item>
        <el-form-item label="三级单位" prop="sjdw_id" clearable>
            <select-subsidiary-3 :ejdw_id="form.ejdw_id" v-model="form.sjdw_id" />
        </el-form-item>
        <el-form-item label="姓名">
            <el-input v-model.trim="form.name" clearable style="width: 100px"></el-input>
        </el-form-item>
        <el-form-item label="身份证号">
            <el-input v-model.trim="form.SFZH" clearable style="width: 100px"></el-input>
        </el-form-item>
        <el-form-item label="是否家属" prop="TJLX_NGJS" clearable>
            <el-select v-model="form.TJLX_NGJS" placeholder="请选择" style="width: 100px" clearable>
                <el-option label="是" value="家属"></el-option>
                <el-option label="否" value=""></el-option>
            </el-select>
        </el-form-item>
    </zk-search-form-inline>
</template>

<script>
export default {
    data() {
        return {
            form: {}
        }
    }
}
</script>

