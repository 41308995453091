<template>
    <div>
        <box-count />
        <div v-if="role_id == 3">
            <el-row style="margin:30px 0">
                <el-col :span="16" style="background-color:#f7f7f8;padding: 20px 0">
                    <tjlx-bar-chart />
                </el-col>
                <el-col :span="8" style="background-color:#f7f7f8; padding: 20px 0">
                    <wanchengdu-pie-chart2 />
                </el-col>
            </el-row>
            <el-row style="margin:30px 0">
                <el-col :span="16" style="background-color:#f7f7f8;padding: 20px 0">
                    <calendar-chart />
                </el-col>
                <el-col :span="8" style="background-color:#f7f7f8; padding: 20px 0">
                    <pie-chart-hospital />
                </el-col>
            </el-row>
        </div>
        <div v-else-if="role_id == 5">
            <el-row style="margin:30px 0">
                <el-col :span="16" style="background-color:#f7f7f8;padding: 20px 0">
                    <calendar-chart />
                </el-col>
                <el-col :span="8" style="background-color:#f7f7f8; padding: 20px 0">
                    <tjlx-pie-chart />
                </el-col>
            </el-row>
        </div>
        <div v-else>
            <el-row style="margin:30px 0">
                <el-col :span="16">
                    <ejdw-wanchengdu-bar-chart />
                </el-col>
                <el-col :span="8">
                    <wanchengdu-pie-chart2 />
                </el-col>
            </el-row>
            <el-row style="margin:30px 0">
                <el-col :span="16" style="background-color:#f7f7f8;padding: 20px 0">
                    <calendar-chart />
                </el-col>
                <el-col :span="8" style="background-color:#f7f7f8; padding: 20px 0">
                    <pie-chart-hospital />
                </el-col>
            </el-row>
            <el-row style="margin:30px 0">
                <el-col :span="8">
                    <tjlx-pie-chart />
                </el-col>
                <el-col :span="16">
                    <tjlx-bar-chart />
                </el-col>
            </el-row>
            <el-row style="margin:30px 0">
                <el-col :span="16">
                    <age-bar-chart />
                </el-col>
                <el-col :span="8">
                    <age-pie-chart />
                </el-col>
            </el-row>
            <el-row style="margin:30px 0">
                <el-col :span="16">
                    <gender-bar-chart />
                </el-col>
                <el-col :span="8">
                    <gender-pie-chart />
                </el-col>
                <el-col :span="24" style="background-color:#f7f7f8;border: 1px solid #eceef4;padding: 20px 0">
                    <gender-dist-chart />
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
export default {
    name: 'dashboard_all_charts',
    path: '/main/dashboard/charts',
    data() {
        return {
        }
    },
    computed: {
        role_id() {
            let username = localStorage.getItem("username")
            if (['ytzy', 'lnyy', 'rnyy', 'deyy'].includes(username)) {
                return 5
            }
            if (username.indexOf("ejdw") != -1 ) {
                return 3
            }
        }
    }
}
</script>
