<template>
    <el-select :value="current" @input="$emit('input', $event)" placeholder="请选择" :size="size" clearable>
        <el-option v-for="item in options" :key="item.name" :label="`${item.name}(${item.count})`" :value="item.name" />
    </el-select>
</template>

<script>
export default {
    name: 'select-tjtc',
    props: {
        value: {
            type: [String, Number, Object]
        },
        hospital_id: {
            type: [String, Number],
            default: ''
        },
        size: {
            default: 'small'
        }
    },
    data() {
        return {
            current: '',
            options: []
        }
    },
    watch: {
        value: {
            immediate: true,
            handler(val) {
                this.current = val
            }

        },
        current: {
            immediate: true,
            handler(newVal) {
                this.$emit('input', newVal)
            }
        },
        hospital_id: {
            immediate: true,
            handler(newVal) {
                this.current = ''
                this.getData()
            }
        }
    },
    methods: {
        async getData() {
            const hl = await this.$http.get(`/hospital/TJTC?hospital_id=${this.hospital_id}&page=1&pageSize=2000`)
            this.options = hl.data.data.records
        }
    },
    mounted() {
        this.getData()
    }
}
</script>
