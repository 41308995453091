<template>
    <div class="btn">
        <el-upload v-if="!disabled" ref="upload-demo" :action="url" accept=".jpg,.jpeg,.png" list-type="picture-card"
            :headers="headers" :show-file-list="true" :limit="10" :on-preview="handlePreview" :file-list="fileList"
            :before-remove="beforeRemove" :on-remove="handleRemove" :on-success="handleSuccess" :on-error="handleError"
            :auto-upload="true">
            <i class="el-icon-plus"></i>
            <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
        </el-upload>
        <div v-else>
            <el-image style="width: 100px; height: 100px; margin: 10px;" :src="e.url" :preview-src-list="fileList.map(i => i.url)"
                v-for="(e, i) in fileList" :key="i" />
        </div>
        <el-dialog :visible.sync="dialogVisible" append-to-body>
            <img width="100%" :src="dialogImageUrl" alt="">
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: 'btnUploadImg',
    props: {
        value: Array,
        size: {
            type: String,
            default: 'mini'
        },
        type: {
            type: String,
            default: 'default'
        },
        url: {
            type: String,
            default: '/api/upload'
        },
        disabled: {
            type: Boolean,
            default: true,
        }
    },
    data() {
        return {
            dialogVisible: false,
            dialogImageUrl: '',
        }
    },
    computed: {
        headers() {
            const token = localStorage.getItem('token')
            return {
                Authorization: `Bearer ${token}`
            }
        },
        fileList: {
            get() {
                return this.value || [];
            },
            set(newValue) {
                this.$emit('input', newValue);
            },
        },
    },
    methods: {
        //点击删除文件时触发删除确认提醒
        // eslint-disable-next-line
        beforeRemove(file, fileList) {
            return this.$confirm(`确定移除 ${file.name}？`)
        },
        // eslint-disable-next-line
        handleRemove(file, fileList) {
            this.fileList = [...fileList]
        },
        // 点击文件时触发
        handlePreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        //上传成功的自定义callback
        handleSuccess(resp) {
            //清空文件列表
            this.$message[resp.status](resp.msg)
            this.$nextTick(() => {
                this.fileList = this.fileList.concat(resp.data)
            })
        },
        //上传失败的自定义callback
        handleError(err, file) {
            this.$message({
                showClose: true,
                message: file.name + ' 上传失败,' + err.message,
                type: 'error'
            })
        },
        close() {
            this.dialogVisible = false
            this.$emit('close')
        }
    }
}
</script>

<style scoped>
.btn {
    display: inline-block;
}
</style>