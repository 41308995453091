<template>
    <div>
        <el-page-header @back="$router.go(-1)" content="年龄分布" />
        <div v-loading="loading" class="chart" style="margin-top:40px;">
            <v-chart ref="chart" class="chart" :option="option" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'stat-reli',
    path: '/main/statistics/reli',
    data() {
        return {
            loading: false,
            option: {
                title: {
                    top: 0,
                    left: 'center',
                    text: '2021-2022糖耐量异常人数分布热力图'
                },
                tooltip: {
                    trigger: 'item',
                    formatter: function (params) {
                        return `<div>${params.value[0]}</div>
                                <div>人数: ${params.value[1]}</div>`
                    },
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                visualMap: {
                    min: 0,
                    max: 20,
                    calculable: true,
                    orient: 'vertical',
                    show: false
                },
                calendar: [
                    {
                        left: '5%',/** 图表距离画布左侧的间隔 (自适应必设置)*/
                        right: '1%',/** 图表距离画布右侧的间隔 (自适应必设置)*/
                        // top: '20',/** 距离顶部的间隔 */
                        range: '2022',
                        cellSize: ['auto', 20]
                    },
                    {
                        top: 260,
                        left: '5%',/** 图表距离画布左侧的间隔 (自适应必设置)*/
                        right: '1%',/** 图表距离画布右侧的间隔 (自适应必设置)*/
                        range: '2021',
                        cellSize: ['auto', 20]
                    }
                ],
                series: [
                    {
                        type: 'heatmap',
                        coordinateSystem: 'calendar',
                        calendarIndex: 0,
                        data: []
                    },
                    {
                        type: 'heatmap',
                        coordinateSystem: 'calendar',
                        calendarIndex: 1,
                        data: []
                    }
                ]
            }
        }
    },
    methods: {
        async getData() {
            this.loading = true
            // const resp = await this.$http.get('/statistics/calendar_chart')
            let data2022 = [
['2022-01-05',	5],
['2022-01-06',	8],
['2022-01-07',	17],
['2022-01-09',	1],
['2022-01-10',	2],
['2022-01-11',	4],
['2022-01-12',	1],
['2022-01-13',	1],
['2022-01-14',	6],
['2022-01-16',	1],
['2022-01-18',	3],
['2022-01-19',	1],
['2022-01-20',	3],
['2022-01-21',	19],
['2022-01-24',	1],
['2022-01-25',	2],
['2022-01-26',	1],
['2022-01-27',	1],
['2022-01-28',	3],
['2022-01-30',	1],
['2022-02-08',	2],
['2022-02-10',	4],
['2022-02-11',	4],
['2022-02-15',	2],
['2022-02-17',	1],
['2022-02-18',	2],
['2022-02-21',	2],
['2022-02-22',	4],
['2022-02-23',	5],
['2022-02-24',	2],
['2022-02-25',	4],
['2022-02-27',	2],
['2022-02-28',	1],
['2022-03-01',	2],
['2022-03-02',	5],
['2022-03-03',	3],
['2022-03-04',	6],
['2022-03-06',	2],
['2022-03-07',	3],
['2022-03-08',	3],
['2022-03-09',	4],
['2022-03-10',	10],
['2022-03-11',	12],
['2022-03-13',	2],
['2022-03-15',	3],
['2022-03-16',	5],
['2022-03-17',	5],
['2022-03-18',	5],
['2022-03-19',	1],
['2022-03-20',	1],
['2022-03-22',	2],
['2022-03-23',	4],
['2022-03-24',	5],
['2022-03-25',	7],
['2022-03-28',	3],
['2022-03-29',	2],
['2022-03-30',	1],
['2022-03-31',	5],
['2022-04-01',	10],
['2022-04-02',	2],
['2022-04-03',	2],
['2022-04-07',	1],
['2022-04-08',	2],
['2022-04-11',	1],
['2022-04-13',	2],
['2022-04-15',	4],
['2022-04-17',	1],
['2022-04-19',	2],
['2022-04-21',	3],
['2022-04-22',	5],
['2022-04-25',	3],
['2022-04-26',	1],
['2022-04-27',	4],
['2022-04-28',	2],
['2022-04-29',	7],
['2022-05-02',	1],
['2022-05-03',	1],
['2022-05-05',	3],
['2022-05-06',	3],
['2022-05-07',	1],
['2022-05-08',	1],
['2022-05-09',	1],
['2022-05-10',	4],
['2022-05-11',	7],
['2022-05-13',	8],
['2022-05-15',	1],
['2022-05-16',	1],
['2022-05-17',	6],
['2022-05-18',	4],
['2022-05-19',	4],
['2022-05-20',	18],
['2022-05-22',	6],
['2022-05-23',	16],
['2022-05-24',	21],
['2022-05-25',	19],
['2022-05-26',	26],
['2022-05-27',	51],
['2022-05-28',	2],
['2022-05-29',	11],
['2022-05-30',	13],
['2022-05-31',	27],
['2022-06-01',	19],
['2022-06-02',	35],
['2022-06-03',	5],
['2022-06-04',	5],
['2022-06-05',	8],
['2022-06-06',	6],
['2022-06-07',	17],
['2022-06-08',	40],
['2022-06-09',	25],
['2022-06-10',	47],
['2022-06-11',	15],
['2022-06-12',	11],
['2022-06-13',	7],
['2022-06-14',	32],
['2022-06-15',	44],
['2022-06-16',	45],
['2022-06-17',	65],
['2022-06-18',	20],
['2022-06-19',	18],
['2022-06-20',	7],
['2022-06-21',	33],
['2022-06-22',	44],
['2022-06-23',	38],
['2022-06-24',	97],
['2022-06-25',	9],
['2022-06-26',	8],
['2022-06-27',	7],
['2022-06-28',	35],
['2022-06-29',	40],
['2022-06-30',	29],
['2022-07-01',	62],
['2022-07-02',	6],
['2022-07-03',	7],
['2022-07-04',	6],
['2022-07-05',	34],
['2022-07-06',	39],
['2022-07-07',	25],
['2022-07-08',	44],
['2022-07-10',	6],
['2022-07-11',	18],
['2022-07-12',	23],
['2022-07-13',	44],
['2022-07-14',	67],
['2022-07-15',	61],
['2022-07-16',	6],
['2022-07-17',	20],
['2022-07-18',	18],
['2022-07-19',	47],
['2022-07-20',	46],
['2022-07-21',	52],
['2022-07-22',	59],
['2022-07-23',	1],
['2022-07-24',	15],
['2022-07-25',	7],
['2022-07-26',	46],
['2022-07-27',	38],
['2022-07-28',	37],
['2022-07-29',	64],
['2022-07-30',	2],
['2022-07-31',	6],
['2022-08-01',	7],
['2022-08-02',	28],
['2022-08-03',	30],
['2022-08-04',	44],
['2022-08-05',	59],
['2022-08-06',	8],
['2022-08-07',	5],
['2022-08-08',	10],
['2022-08-09',	15],
['2022-08-10',	21],
['2022-08-11',	39],
['2022-08-12',	67],
['2022-08-13',	5],
['2022-08-14',	13],
['2022-08-15',	4],
['2022-08-16',	41],
['2022-08-17',	26],
['2022-08-18',	45],
['2022-08-19',	85],
['2022-08-20',	8],
['2022-08-21',	14],
['2022-10-09',	30],
['2022-10-10',	22],
['2022-10-11',	44],
['2022-10-12',	47],
['2022-10-13',	58],
['2022-10-14',	87],
['2022-10-15',	13],
['2022-10-16',	16],
['2022-10-17',	20],
['2022-10-18',	43],
['2022-10-19',	53],
['2022-10-20',	74],
['2022-10-21',	91],
['2022-10-22',	10],
['2022-10-23',	10],
['2022-10-24',	65],
['2022-10-25',	8],
['2022-10-26',	51],
['2022-10-27',	47],
['2022-10-28',	84],
['2022-10-29',	10],
['2022-10-30',	15],
['2022-10-31',	13],
['2022-11-01',	43],
['2022-11-02',	63],
['2022-11-03',	57],
['2022-11-04',	78],
['2022-11-05',	5],
['2022-11-06',	18],
['2022-11-11',	2],
['2022-11-13',	5],
['2022-11-14',	4],
['2022-11-15',	11],
['2022-11-16',	11],
['2022-11-17',	6],
['2022-11-18',	30],
['2022-11-19',	3],
['2022-11-20',	14],
['2022-11-21',	5],
['2022-11-22',	6],
['2022-11-23',	25],
['2022-11-24',	21],
['2022-11-25',	37],
['2022-11-26',	4],
['2022-11-27',	11],
['2022-11-28',	4],
['2022-11-29',	4],
['2022-11-30',	15],
['2022-12-01',	22],
['2022-12-02',	43],
['2022-12-03',	6],
['2022-12-04',	16],
['2022-12-05',	18],
['2022-12-06',	28],
['2022-12-07',	19],
['2022-12-08',	31],
['2022-12-09',	64],
['2022-12-10',	6],
['2022-12-11',	23],
['2022-12-12',	16],
['2022-12-13',	20],
['2022-12-14',	18],
['2022-12-15',	23],
['2022-12-16',	29],
['2022-12-18',	11],
['2022-12-19',	6],
['2022-12-20',	7],
['2022-12-21',	7],
['2022-12-22',	3],
['2022-12-23',	8],
            ]
            let data2021 = [
['2021-01-05',	2],
['2021-01-06',	1],
['2021-01-07',	2],
['2021-01-08',	8],
['2021-01-10',	2],
['2021-01-12',	1],
['2021-01-13',	1],
['2021-01-15',	5],
['2021-01-19',	1],
['2021-01-22',	2],
['2021-01-23',	1],
['2021-01-26',	1],
['2021-01-27',	2],
['2021-01-28',	1],
['2021-02-01',	3],
['2021-02-03',	2],
['2021-02-04',	2],
['2021-02-07',	1],
['2021-02-08',	1],
['2021-02-09',	1],
['2021-02-10',	1],
['2021-02-19',	1],
['2021-02-20',	5],
['2021-02-21',	3],
['2021-02-23',	5],
['2021-02-24',	5],
['2021-02-25',	3],
['2021-02-26',	5],
['2021-02-28',	2],
['2021-03-01',	2],
['2021-03-02',	2],
['2021-03-03',	5],
['2021-03-04',	2],
['2021-03-05',	1],
['2021-03-07',	1],
['2021-03-10',	1],
['2021-03-11',	4],
['2021-03-12',	2],
['2021-03-16',	3],
['2021-03-17',	3],
['2021-03-18',	2],
['2021-03-19',	15],
['2021-03-21',	2],
['2021-03-22',	1],
['2021-03-23',	5],
['2021-03-24',	5],
['2021-03-25',	5],
['2021-03-26',	13],
['2021-03-27',	1],
['2021-03-28',	1],
['2021-03-30',	2],
['2021-03-31',	3],
['2021-04-01',	1],
['2021-04-02',	10],
['2021-04-06',	1],
['2021-04-07',	5],
['2021-04-08',	6],
['2021-04-09',	12],
['2021-04-11',	1],
['2021-04-12',	4],
['2021-04-13',	4],
['2021-04-14',	6],
['2021-04-15',	3],
['2021-04-16',	4],
['2021-04-18',	1],
['2021-04-20',	3],
['2021-04-21',	4],
['2021-04-22',	3],
['2021-04-23',	7],
['2021-04-25',	6],
['2021-04-26',	5],
['2021-04-27',	9],
['2021-04-28',	20],
['2021-04-29',	11],
['2021-04-30',	22],
['2021-05-07',	15],
['2021-05-08',	32],
['2021-05-09',	1],
['2021-05-10',	10],
['2021-05-11',	21],
['2021-05-12',	54],
['2021-05-13',	6],
['2021-05-14',	46],
['2021-05-15',	1],
['2021-05-16',	10],
['2021-05-17',	5],
['2021-05-18',	20],
['2021-05-19',	39],
['2021-05-20',	17],
['2021-05-21',	28],
['2021-05-22',	1],
['2021-05-23',	2],
['2021-05-24',	12],
['2021-05-25',	9],
['2021-05-26',	25],
['2021-05-27',	21],
['2021-05-28',	32],
['2021-05-30',	11],
['2021-05-31',	9],
['2021-06-01',	14],
['2021-06-02',	14],
['2021-06-03',	15],
['2021-06-04',	29],
['2021-06-06',	10],
['2021-06-07',	10],
['2021-06-08',	16],
['2021-06-09',	15],
['2021-06-10',	22],
['2021-06-11',	33],
['2021-06-13',	10],
['2021-06-14',	7],
['2021-06-15',	1],
['2021-06-16',	13],
['2021-06-17',	14],
['2021-06-18',	11],
['2021-06-19',	24],
['2021-06-20',	4],
['2021-06-21',	2],
['2021-06-22',	11],
['2021-06-23',	25],
['2021-06-24',	20],
['2021-06-25',	34],
['2021-06-26',	3],
['2021-06-27',	8],
['2021-06-28',	4],
['2021-06-29',	17],
['2021-06-30',	12],
['2021-07-01',	19],
['2021-07-02',	27],
['2021-07-04',	2],
['2021-07-05',	1],
['2021-07-06',	12],
['2021-07-07',	17],
['2021-07-08',	14],
['2021-07-09',	28],
['2021-07-10',	1],
['2021-07-11',	13],
['2021-07-12',	4],
['2021-07-13',	8],
['2021-07-14',	16],
['2021-07-15',	10],
['2021-07-16',	28],
['2021-07-18',	2],
['2021-07-19',	3],
['2021-07-20',	9],
['2021-07-21',	11],
['2021-07-22',	19],
['2021-07-23',	31],
['2021-07-25',	4],
['2021-07-26',	3],
['2021-07-27',	10],
['2021-07-28',	14],
['2021-07-29',	7],
['2021-07-30',	25],
['2021-08-01',	6],
['2021-08-02',	4],
['2021-08-03',	14],
['2021-08-04',	18],
['2021-08-05',	13],
['2021-08-06',	26],
['2021-08-07',	1],
['2021-08-08',	2],
['2021-08-09',	3],
['2021-08-10',	10],
['2021-08-11',	3],
['2021-08-12',	12],
['2021-08-13',	22],
['2021-08-14',	2],
['2021-08-15',	2],
['2021-08-16',	3],
['2021-08-17',	6],
['2021-08-18',	7],
['2021-08-19',	12],
['2021-08-20',	30],
['2021-08-21',	4],
['2021-08-22',	3],
['2021-08-23',	6],
['2021-08-24',	29],
['2021-08-25',	23],
['2021-08-26',	20],
['2021-08-27',	30],
['2021-08-28',	5],
['2021-08-29',	5],
['2021-08-30',	4],
['2021-08-31',	22],
['2021-09-01',	28],
['2021-09-02',	16],
['2021-09-03',	45],
['2021-09-04',	2],
['2021-09-05',	5],
['2021-09-06',	8],
['2021-09-07',	24],
['2021-09-08',	32],
['2021-09-09',	44],
['2021-09-10',	68],
['2021-09-11',	4],
['2021-09-12',	11],
['2021-09-13',	12],
['2021-09-14',	24],
['2021-09-15',	19],
['2021-09-16',	27],
['2021-09-17',	15],
['2021-09-18',	47],
['2021-09-19',	2],
['2021-09-20',	2],
['2021-09-21',	3],
['2021-09-22',	1],
['2021-09-23',	9],
['2021-09-24',	30],
['2021-09-26',	6],
['2021-09-27',	21],
['2021-09-28',	10],
['2021-09-29',	21],
['2021-09-30',	38],
['2021-10-05',	4],
['2021-10-06',	3],
['2021-10-07',	2],
['2021-10-08',	6],
['2021-10-09',	22],
['2021-10-11',	8],
['2021-10-12',	26],
['2021-10-13',	17],
['2021-10-14',	18],
['2021-10-15',	55],
['2021-10-16',	8],
['2021-10-17',	12],
['2021-10-18',	14],
['2021-10-19',	25],
['2021-10-20',	37],
['2021-10-21',	32],
['2021-10-22',	51],
['2021-10-23',	4],
['2021-10-24',	16],
['2021-10-25',	6],
['2021-10-26',	23],
['2021-10-27',	22],
['2021-10-28',	28],
['2021-10-29',	42],
['2021-10-30',	3],
['2021-10-31',	5],
['2021-11-01',	9],
['2021-11-02',	14],
['2021-11-03',	15],
['2021-11-04',	20],
['2021-11-05',	30],
['2021-11-07',	7],
['2021-11-08',	5],
['2021-11-09',	12],
['2021-11-10',	3],
['2021-11-11',	10],
['2021-11-12',	16],
['2021-11-14',	3],
['2021-11-15',	4],
['2021-11-16',	6],
['2021-11-17',	12],
['2021-11-18',	5],
['2021-11-19',	23],
['2021-11-21',	8],
['2021-11-22',	11],
['2021-11-23',	14],
['2021-11-24',	8],
['2021-11-25',	15],
['2021-11-26',	30],
['2021-11-28',	7],
['2021-11-29',	12],
['2021-11-30',	4],
['2021-12-01',	14],
['2021-12-02',	17],
['2021-12-03',	9],
['2021-12-04',	1],
['2021-12-05',	3],
['2021-12-06',	4],
['2021-12-07',	18],
['2021-12-08',	23],
['2021-12-09',	21],
['2021-12-10',	36],
['2021-12-12',	8],
['2021-12-13',	2],
['2021-12-14',	16],
['2021-12-15',	22],
['2021-12-16',	12],
['2021-12-17',	31],
['2021-12-18',	1],
['2021-12-19',	6],
['2021-12-20',	6],
['2021-12-21',	14],
['2021-12-22',	9],
['2021-12-23',	10],
['2021-12-24',	26],
['2021-12-26',	6],
['2021-12-27',	2],
['2021-12-28',	15],
['2021-12-29',	11],
['2021-12-30',	16],
['2021-12-31',	20 ],               
            ]
            this.option.series[0].data = data2022
            this.option.series[1].data = data2021
            this.$refs.chart.setOption(this.option)
            this.loading = false
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.$refs.chart.resize()
        })
        window.addEventListener('resize', () => {
            if (this.$refs && this.$refs.chart) {
                this.$refs.chart.resize()
            }
        })
        this.getData()
    }
}
</script>

<style lang="scss" scoped>
.chart {
    width: 100%;
    height: 400px;
}
</style>
