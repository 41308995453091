<template>
    <el-button :type="type" :size="size" @click="handleReadCard" :loading="loading">{{ label }}</el-button>
</template>

<script>
export default {
    name: 'btn-read-id-card',
    props: {
        label: {
            default: '读身份证'
        },
        size: {
            default: 'mini'
        },
        type: {
            default: 'default'
        },
        icon: {
            default: 'el-icon-s-custom'
        }
    },
    data() {
        return {
            loading: false
        }
    },
    methods: {
        handleReadCard() {
            this.loading = true
            let timeout = setTimeout(() => {
                if (this.loading) {
                    this.loading = false
                    this.$emit('result', { ret: -1, msg: '访问超时' })
                }
            }, 5000)
            this.$http({
                // url: 'http://10.211.55.3:9432/',
                url: 'http://127.0.0.1:9432/',
                method: 'post',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: new URLSearchParams({ action: "--ReadCard" }).toString(),
                responseType: 'json',
                transformRequest: [
                    (data, headers) => {
                        delete headers.Authorization;
                        return data;
                    }
                ]
            }).then(resp => {
                console.log(resp.data)
                clearTimeout(timeout)
                this.$emit('result', resp.data)
            }).catch(err => {
                clearTimeout(timeout)
                this.$emit('result', { ret: -1, msg: err.message })
            }).finally(() => {
                this.loading = false
            })
        }
    }
}
</script>