<template>
    <el-dialog title="编辑" :visible="visible" width="60%" @close="close">
        <el-form ref="form" :model="form" label-width="80px">
            <el-form-item label="选择日期" prop="dates">
                <el-date-picker type="dates" value-format="yyyy-MM-dd" v-model="form.dates" placeholder="选择一个或多个日期"
                    style="width: 100%" />
            </el-form-item>
            <el-form-item label="选择时间" prop="times">
                <time-inputs v-model="form.times" />
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="close">取 消</el-button>
            <el-button type="primary" @click="submit">确 定</el-button>
        </span>
    </el-dialog>
</template>
<script>
export default {
    props: {
        visible: {
            default: false
        },
        form: {
            default: {}
        }
    },
    data() {
        return {
        }
    },
    methods: {
        submit() {
            this.$http.post('/task/scheduling', this.form)
                .then(resp => {
                    if (resp.data.code == 200) {
                        this.$message.success('保存成功')
                        this.$emit('update')
                        this.$emit('close')
                    } else {
                        this.$message.error(resp.data.msg)
                    }
                }).catch(err => {
                    this.$message.error(err.message)
                })
        },
        close() {
            this.$emit('close')
        }
    },
    mounted() {

    }
}
</script>

<style scoped>
::v-deep .el-dialog__body {
    padding: 0 20px;
}
</style>
