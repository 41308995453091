<template>
    <el-dialog title="单位管理员信息" :visible="visible" width="40%" @close="close">
        <el-tabs v-model="activeName">
            <el-tab-pane label="用户管理" name="first">
                <el-form ref="form" :model="form" label-width="100px">
                    <el-form-item label="单位名称" prop="sjdw"
                        :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                        <el-input v-model.trim="form.sjdw" readonly></el-input>
                    </el-form-item>
                    <el-form-item label="真实姓名" prop="name"
                        :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                        <el-input v-model.trim="form.name"></el-input>
                    </el-form-item>
                    <el-form-item label="联系方式" prop="contact"
                        :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                        <el-input v-model.trim="form.contact"></el-input>
                    </el-form-item>
                    <el-form-item label="用户名" prop="username" :rules="[
                        { required: true, message: '不能为空', trigger: 'blur' }, 
                        { pattern: /^1\d{10}$/, message: '用户名统一使用手机号', trigger: 'blur' }
                    ]">
                        <el-input v-model.trim="form.username"></el-input>
                    </el-form-item>
                    <el-form-item label="密码" prop="password" v-if="isNew"
                        :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                        <el-input v-model.trim="form.password"></el-input>
                    </el-form-item>
                    <el-form-item label="备注">
                        <el-input type="textarea" v-model.trim="form.remarks"></el-input>
                    </el-form-item>
                    <div style="text-align: right">
                        <el-button @click="close">{{ $t('form.cancel') }}</el-button>
                        <el-button @click="formSubmit" type="primary" :loading="loading" :disabled="loading">{{
                            $t('form.ok')
                        }}</el-button>
                    </div>
                </el-form>
            </el-tab-pane>
            <el-tab-pane label="重置密码" name="second" v-if="!isNew">
                <el-form ref="form_passwd" :model="form" label-width="100px">
                    <el-form-item label="新密码" prop="password"
                        :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                        <el-input v-model.trim="form.password"></el-input>
                    </el-form-item>
                    <el-form-item label="确认密码" prop="password1"
                        :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                        <el-input v-model.trim="form.password1"></el-input>
                    </el-form-item>
                    <div style="text-align: right">
                        <el-button @click="close">{{ $t('form.cancel') }}</el-button>
                        <el-button @click="resetPassword" type="primary" :loading="loading" :disabled="loading">{{
                            $t('form.ok')
                        }}</el-button>
                    </div>
                </el-form>
            </el-tab-pane>
            <el-tab-pane label="删除用户" name="three" v-if="!isNew">
                <el-alert :title="`确认要删除 ${form.name} 吗？此操作不可恢复！`" type="warning" show-icon :closable="false" />
                <div style="text-align: right;margin-top:20px">
                    <el-button @click="close">{{ $t('form.cancel') }}</el-button>
                    <el-button @click="handleDelete" type="primary" :loading="loading" :disabled="loading">{{
                        $t('form.ok')
                    }}</el-button>
                </div>
            </el-tab-pane>
        </el-tabs>
    </el-dialog>
</template>

<script>
export default {
    props: {
        visible: {
            default: false
        },
        form: {
            default: {}
        },
        isNew: {
            default: false
        }
    },
    data() {
        return {
            loading: false,
            activeName: 'first'
        }
    },
    methods: {
        async handleDelete() {
            let row = this.form
            console.log(row)
            if (!row.id) {
                this.$message.error('请选择要删除的对象')
                return
            }
            await this.$http.delete(`/user/${row.id}`)
            this.$message.success('删除成功')
            this.$emit('update')
            this.$emit('close')
        },
        async resetPassword() {
            const valid = await this.$refs['form_passwd'].validate().catch(() => false)
            if (!valid) return
            const resp = await this.$http.post('/user/passwd', this.form)
            if (resp.data.code != 200) {
                this.$message.error(resp.data.msg)
            } else {
                this.$message.success('保存成功')
                this.$emit('update')
                this.$emit('close')
            }
        },
        async formSubmit() {
            const valid = await this.$refs['form'].validate().catch(() => false)
            if (!valid) return
            const resp = await this.$http.post('/user', this.form)
            if (resp.data.code != 200) {
                this.$message.error(resp.data.msg)
            } else {
                this.$message.success('保存成功')
                this.$emit('update')
                this.$emit('close')
            }
        },
        close() {
            this.$emit('close')
        }
    },
    mounted() {

    }
}
</script>

<style scoped>
::v-deep .el-dialog__body {
    padding: 0 20px 30px 20px;
}
</style>
