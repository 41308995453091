const data = {
    table: {
        columns: [
            // {title: '', field: 'id', width: '55'},
            {title: '名称', field: 'name', width: ''},
            {title: '人员数量', field: 'staff_count', width: '100'},
            // {title: '备注', field: 'remarks', width: ''}
        ],
        data: [],
        total: 0,
        page: 1,
        pageSize: 20
    }
}

export default data
