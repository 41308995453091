<template>
    <el-dialog title="编辑" :visible="visible" width="40%" @close="close">
        <el-form ref="form" :model="form" label-width="100px">
            <el-form-item label="项目代码" prop="code" :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                <el-input v-model.trim="form.code" />
            </el-form-item>
            <el-form-item label="项目名称" prop="name" :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                <el-input v-model.trim="form.name" />
            </el-form-item>
            <el-form-item label="备注">
                <el-input type="textarea" v-model.trim="form.remarks"></el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="close">{{ $t('form.cancel') }}</el-button>
            <el-button @click="formSubmit" type="primary" :loading="loading" :disabled="loading">{{ $t('form.ok') }}
            </el-button>
        </span>
    </el-dialog>
</template>
<script>
export default {
    props: {
        visible: {
            default: false
        },
        form: {
            packages: []
        },
        isNew: {
            default: false
        }
    },
    data() {
        return {
            loading: false,
            activeName: 'tab1'
        }
    },
    methods: {
        async formSubmit() {
            const valid = await this.$refs['form'].validate().then(res => res).catch(error => error)
            if (!valid) return
            const resp = await this.$http.post('/hospital/dwmc', this.form)
            if (resp.data.code != 200) {
                this.$message.error(resp.data.msg)
            } else {
                this.$message.success('保存成功')
                this.$emit('update')
                this.$emit('close')
            }
        },
        close() {
            this.$emit('close')
        }
    },
    mounted() {

    }
}
</script>

<style scoped>
</style>
