<template>
    <el-card class="box-card" body-style="padding:0" shadow="hover">
        <div slot="header" class="clearfix">
            <span>疾病对应的人数 {{ total }}</span>
            <el-button style="float: right; padding: 3px 0" type="text" @click="getTableData">刷新</el-button>
        </div>
        <el-table :data="table.tableData" strip style="width: 100%;" height="300px" v-loading="table.loading">
            <el-table-column type="index" width="50" align="center">
                <template slot-scope="scope">
                    <div v-text="scope.$index + 1 + ((table.page - 1) * table.pageSize)"></div>
                </template>
            </el-table-column>
            <el-table-column prop="ZDMC" label="诊断名称" width="" />
            <el-table-column prop="count" label="数量" width="" />
        </el-table>
    </el-card>
</template>

<script>
export default {
    name: 'analysis-manbing-table-bingshu',
    data() {
        return {
            total: 0,
            table: {
                loading: false,
                page: 1,
                pageSize: 20,
                total: 0,
                tableData: []
            }
        }
    },
    methods: {
        handleCurrentChange(e) {
            this.table.page = e
            this.getTableData()
        },
        handleSizeChange(e) {
            this.table.pageSize = e
            this.table.page = 1
            this.getTableData()
        },
        async getTableData() {
            this.table.loading = true
            const resp = await this.$http.get('/manbing/bingshu')
            this.table.tableData = resp.data.data
            this.total = this.table.tableData.reduce((acc, obj) => acc + obj.count, 0)
            this.table.loading = false
        }
    },
    mounted() {
        this.getTableData()
    }
}
</script>