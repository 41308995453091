<template>
    <div style="display: flex;flex-direction: column; height: 100%;">
        <div style="margin-bottom:15px">
            <div style="float:right">
                <el-button size="mini" icon="el-icon-search" circle @click="searchVisible = !searchVisible"></el-button>
                <el-button size="mini" icon="el-icon-refresh" circle @click="getData"></el-button>
            </div>
            <el-page-header @back="$router.go(-1)" content="体检结算单" />
        </div>
        <div v-show="searchVisible">
            <el-form :inline="true" :model="search" size="mini"
                style="text-align: center;background-color: #f7f7f8;padding: 10px;border:1px solid #EBEEF5">
                <select-hospital-multi size="mini" v-model="search.hospital_ids" style="margin-right:10px"
                    @input="getData" />
                <el-date-picker v-model="search.daterange" size="mini" type="daterange" value-format="yyyy-MM-dd"
                    range-separator="-" start-placeholder="开始时间" end-placeholder="结束时间" style="margin-right:10px"
                    @change="getData">
                </el-date-picker>
                <el-button type="primary" size="mini" @click="getData" :loading="loading">查询</el-button>
                <el-button size="mini" type="default" plain @click="exportXLSX" :loading="loading">导出excel</el-button>
            </el-form>
        </div>
        <el-table :data="tableData" border style="flex: 1;width: 100%;" height="100%" v-loading="loading">
            <el-table-column type="index" width="55" align="center" />
            <el-table-column prop="name" label="单位名称" width="" />
            <!-- <el-table-column prop="alias" label="别名" width="" /> -->
            <el-table-column prop="jiankang" label="健康体检消费(元)" width="" :formatter="formatAmount" />
            <el-table-column prop="zhiye" label="职业体检消费(元)" width="" :formatter="formatAmount" />
            <el-table-column prop="nvgong" label="女工体检消费(元)" width="" :formatter="formatAmount" />
            <el-table-column prop="total" label="总消费(元)" width="" :formatter="formatAmount" />
        </el-table>
    </div>
</template>

<script>
export default {
    name: 'fee-statement',
    path: '/main/fee/statement',
    data() {
        return {
            searchVisible: true,
            loading: false,
            search: { hospital_ids: [], daterange: [] },
            tableData: [],
        }
    },
    methods: {
        formatAmount(row, column) {
            const value = row[column.property]
            return parseFloat(value).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        async exportXLSX() {
            this.loading = true
            try {
                let params = JSON.parse(JSON.stringify(this.search))
                params.export = 'xlsx'
                const resp = await this.$http.get(`/fee/xiaofei?page=1&pageSize=20`, { params: params, responseType: 'blob' })
                let blob = new Blob([resp.data], { type: 'application/vnd.openxmlformats;charset=utf-8;' })
                var link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = decodeURI(escape(resp.headers['content-disposition'].split('=')[1]))
                link.click()
                window.URL.revokeObjectURL(link.href)
            } catch (error) {
                this.$message.error(error.message)
            }
            this.loading = false
        },
        async getData() {
            this.loading = true
            try {
                const resp = await this.$http.get('/fee/xiaofei?page=1&pageSize=20', { params: this.search })
                if (!resp.data.code == 200) {
                    this.$message.error(resp.data.msg)
                } else {
                    this.tableData = resp.data.data
                }
            } catch (error) {
                this.$message.error(error.message)
            }
            this.loading = false
        }
    },
    mounted() {
        this.getData()
    }
}
</script>

<style scoped>
.notfound {
    padding: 40px 0;
    text-align: left;
}
</style>